import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StatusNotification } from 'interfaces/notification';
import ModalCreateNotificationStatus from './ModalCreateNotificationStatus'
import ModalEditNotificationStatus from './ModalEditNotificationStatus'
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  notifications: StatusNotification[];
}


export default function StatusNotificationsTable(props: IProps) {
  const classes = useStyles();
  const { notifications } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEdit = (notification: StatusNotification) => {
    setOpenEdit(true);
    setSelectedNotification(notification)
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedNotification(undefined)
  };


  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>Adicionar notificação de status</Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications?.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell component="th" scope="row">
                {notification.id}
              </TableCell>
              <TableCell>{notification.message}</TableCell>
              <TableCell>{notification.status === 'Fazendo' ? 'Accepted' : notification.status === 'A fazer' ? 'A Fazer' : notification.status === 'Feito' ? 'Preparing' : notification.status}</TableCell>
              <TableCell><EditIcon onClick={() => handleClickOpenEdit(notification)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateNotificationStatus
        open={open}
        onClose={handleClose}
      />
      <ModalEditNotificationStatus
        open={openEdit}
        notification={selectedNotification}
        onClose={handleClose}
      />
    </TableContainer>
  );
}
