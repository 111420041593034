import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getUrlName } from 'helpers/common';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useStyles } from './styles';
import Menu from '../Menu';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { useHistory } from 'react-router-dom';
import './styles.css';
import { EMAIL, APP_NAME, NETWORK } from 'settings';
import Logo from 'helpers/logo';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { FormControl, Select, MenuItem, InputLabel, Typography, Button } from "@material-ui/core";
import { GET_NETWORK_NAME } from 'settings';

interface IProps {
  children: React.ReactNode;
}
const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'shadow-md bg-purple-dark',
    scrollTo: true
  }
});

export default function Content(props: IProps) {
  const location = useLocation();
  const history = useHistory();
  const networks = useSelector<any>(state => state.networks)
  const [open, setOpen] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false)
  const [condition, setCondition] = React.useState(navigator.onLine);
  const [mobile, setMobile] = React.useState(false)
  const [testMode, setTestMode] = useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  };
  const handleDrawerClose = () => {
    setOpen(false)

  };
  const loadIsTestMode = () => {
    console.log("NETWORK ", NETWORK)
    if (NETWORK === "12") {
      setTestMode(true)
    } else {
      setTestMode(false)
    }
  }


  const { children } = props;

  const classes = useStyles();
  window.addEventListener("resize", function () {
    setMobile(window.innerWidth <= 600)
  });
  window.addEventListener('online', () => {
    setCondition(true);
  });
  window.addEventListener('offline', () => {
    setCondition(false);
  });
  tour.addStep({
    id: 'order-step',
    text: () => {
      return `<p>In Orders, you can see all the live active orders. You can accept and reject orders, schedule a driver, track the delivery and complete the order. You will get an email and hear a ding when a new order is made.</p>`;
    },
    attachTo: {
      element: '.d-order-item',
      on: 'left'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Skip',
        action() {
          localStorage.removeItem('firstAccess');
          tour.complete();
        },
        classes: 'tour-buttons-back'
      },
      {
        text: 'Next',
        action: () => {
          history.push('/dashboard/completed');
          tour.next();
        },
        classes: 'tour-buttons'
      }
    ]
  });

  tour.addStep({
    id: 'completed-step',
    text: 'In History, you can see Completed and Canceled orders. You can filter by a range of dates. Click the order to review the details.',
    attachTo: {
      element: '.d-completed-item',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        action: () => {
          history.push('/dashboard/order');
          tour.back();
        },
        classes: 'tour-buttons-back'
      },
      {
        text: 'Next',
        action: () => {
          history.push('/dashboard/stock');
          tour.next();
        },
        classes: 'tour-buttons'
      }
    ]
  });
  tour.addStep({
    id: 'products-step',
    text: 'To add your products. First, create all the <b>Categories</b>.Then press <b>Add product</b> and complete the form and save. You can also update your menu with the new Categories and Products you want to include.',
    attachTo: {
      element: '.d-menus-item',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        action: () => {
          history.push('/dashboard/completed');
          tour.back();
        },
        classes: 'tour-buttons-back'
      },
      {
        text: 'Next',
        action: () => {
          history.push('/dashboard/stores');
          tour.next();
        },
        classes: 'tour-buttons'
      }
    ]
  });
  tour.addStep({
    id: 'fourth-step',
    text: `In Stores, you can open or close your store as well as edit your Store information. If you want to change any of your store details please contact us at <a href="mailto:${EMAIL}" target="_blank">${EMAIL}</a>`,
    attachTo: {
      element: '.d-manager-item',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        action: () => {
          history.push('/dashboard/stock');
          tour.back();
        },
        classes: 'tour-buttons-back'
      },
      {
        text: 'Next',
        action: () => {
          history.push('/dashboard/order');
          tour.next();
        },
        classes: 'tour-buttons'
      }
    ]
  });
  tour.addStep({
    id: 'fifth-step',
    text: `Are you planning to be out, or need to stop accepting orders? You can toggle to be Open or Closed here.`,
    attachTo: {
      element: '.switch-force-close',
      on: 'bottom'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        classes: 'tour-buttons-back',
        action: () => {
          history.push('/dashboard/stores');
          tour.back();
        }
      },
      {
        text: 'Next',
        classes: 'tour-buttons',
        action: () => {
          tour.next();
        }
      }
    ]
  });
  tour.addStep({
    id: 'sixth-step',
    text: `If you want to restart the tour you can always click on this icon`,
    attachTo: {
      element: '.restart-tour-button',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        action: () => {
          history.push('/dashboard/order');
          tour.back();
        },
        classes: 'tour-buttons-back'
      },
      {
        text: 'Next',
        action: tour.next,
        classes: 'tour-buttons'
      }
    ]
  });
  tour.addStep({
    id: 'seventh-step',
    text: `<b>Now you are ready to start selling with your business at ${APP_NAME}!</b>`,

    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Back',
        action: tour.back,
        classes: 'tour-buttons-back'
      },
      {
        text: 'Complete',
        action() {
          localStorage.removeItem('firstAccess');
          tour.complete();
        },
        classes: 'tour-buttons'
      }
    ]
  });

  const handlechangeNetwork = (network) => {
    localStorage.setItem("network", network)
    window.location.reload()
  }
  useEffect(() => {
    setFullScreen(!document.fullscreenEnabled)
  }, [document.fullscreenEnabled])

  useEffect(() => {
    loadIsTestMode()
  }, [NETWORK])
  useEffect(() => {
    const domExists = document.querySelector('.d-order-item');

    if (
      localStorage.getItem('firstAccess') &&
      localStorage.getItem('state') &&
      domExists
    ) {
      history.push('/dashboard/order');
      tour.start();
    }
  }, []);
  const secondaryColor = () => {
    switch (NETWORK) {
      case '2':
        return '#FF931E';
      case '4':
        return '#ff5a23';
      default:
        return 'black';
    }
  };
  const toggleFullScreen = () => {
    try {
      if (fullScreen) {
        setFullScreen(false)
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen()
          .then(function () {
            setFullScreen(true)
          })
          .catch(function (error) {
            setFullScreen(false)
          });
      }
    } catch (error) {
      console.log("error ", error)
    }
  }
  useEffect(() => {
    var closedDrawerpages = ["comercialInvoice", "order"]
    var location = window.location.pathname.split("/")
    const lastPage = location[location.length - 1]
    if (closedDrawerpages.includes(lastPage)) {
      setOpen(false)
    }
  }, [window.location.pathname])
  console.log(`localStorage.getItem("network")`, localStorage.getItem("network"))
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={clsx(classes.appBar, open && classes.appBarShift)}>
        {condition ? (
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <div
              className={
                classes.showHeader
              }
            >
              <Typography
                component="h1"
                variant="h6"
                noWrap
                color="primary"
                style={{
                  visibility:
                    window.location.pathname === '/dashboard/order'
                      ? 'hidden'
                      : null
                }}
                className={classes.title}
              >
                {getUrlName(location.pathname)}
              </Typography>
            </div>
            {getUrlName(location.pathname) === 'Produção' && (
              <Button
                className={classes.activeButton}
                onClick={() => (window.location.href = '/dashboard/package')}
              >
                Production Packages
              </Button>
            )}{' '}
            {getUrlName(location.pathname) === 'Purchases' && (
              <Button
                className={classes.activeButton}
                onClick={() =>
                  (window.location.href = '/dashboard/ingredientpackage')
                }
              >
                Purchase Packages
              </Button>
            )}
            <div className="contact-us-and-question-mark">
              {networks["network"].length > 1 ? <FormControl style={{ marginRight: "15px", width: "100px" }} variant='standard'>
                <InputLabel id="network-label" >Network</InputLabel>
                <Select label="Network" labelId="network-label" id="select" value={localStorage.getItem("network")} onChange={(e) => handlechangeNetwork(e.target.value)}>
                  {networks['network'].map(el => (
                    <MenuItem value={el}>{GET_NETWORK_NAME(el)}</MenuItem>
                  ))}
                </Select>
              </FormControl> : null}
              {NETWORK === "6" || NETWORK === "12" ? <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                <Switch checked={testMode} onChange={(e) => {
                  setTestMode(e.target.checked)
                  if (e.target.checked) {
                    localStorage.setItem("network", "12")
                  } else {
                    localStorage.setItem("network", "6")
                  }

                  window.location.reload()
                }} />
                <Typography color="secondary">Test mode</Typography>
              </div> : null}
              <a
                target="_blank"
                href={`mailto:${EMAIL}`}
                style={{ cursor: 'pointer' }}
                className={classes.contactUsButton}
              >
                <Typography color="secondary">Contact us</Typography>
              </a>
              {fullScreen ?
                <IconButton onClick={() => { toggleFullScreen() }}>
                  <FullscreenExitIcon />
                </IconButton> :
                <IconButton onClick={() => { toggleFullScreen() }}>
                  <FullscreenIcon />
                </IconButton>}

              <IconButton>
                <div
                  onClick={() => tour.start()}
                  style={{ cursor: 'pointer' }}
                  className="restart-tour-button"
                >
                  <svg
                    height={25}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                  >
                    <g fill={secondaryColor()} data-name="Question Mark">
                      <path
                        stroke-width="2"
                        stroke="#ff5a23"
                        d="M32 56.735A24.735 24.735 0 1 1 56.736 32 24.764 24.764 0 0 1 32 56.735Zm0-46.47A21.735 21.735 0 1 0 53.736 32 21.76 21.76 0 0 0 32 10.265Z"
                      />
                      <path
                        stroke-width="2"
                        stroke="#ff5a23"
                        d="M32 44.97a2.015 2.015 0 0 1-2-2 1.722 1.722 0 0 1 .04-.39 1.643 1.643 0 0 1 .11-.38 2.329 2.329 0 0 1 .19-.341 2.174 2.174 0 0 1 .55-.549 1.589 1.589 0 0 1 .34-.19 1.757 1.757 0 0 1 .38-.11 1.994 1.994 0 0 1 1.8.55 1.419 1.419 0 0 1 .25.3 2.329 2.329 0 0 1 .19.341 1.643 1.643 0 0 1 .11.38 1.722 1.722 0 0 1 .04.39 2.015 2.015 0 0 1-2 2zm-7.3-19.141a1.5 1.5 0 0 1-1.368-2.1c.085-.192 2.159-4.7 8.669-4.7a1.5 1.5 0 0 1 0 3c-4.491 0-5.877 2.815-5.934 2.935a1.515 1.515 0 0 1-1.367.865z"
                      />
                      <path
                        stroke-width="2"
                        stroke="#ff5a23"
                        d="M32 38.9a1.5 1.5 0 0 1-1.5-1.5v-1.892c0-2.311 2.01-3.587 3.953-4.82 2.755-1.75 4.4-2.97 3.455-5.777-.18-.347-1.646-2.881-5.908-2.881a1.5 1.5 0 0 1 0-3c6.51 0 8.584 4.51 8.669 4.7.017.037.031.074.044.111 1.844 5.254-2.066 7.735-4.652 9.377-1.2.762-2.561 1.626-2.561 2.288V37.4a1.5 1.5 0 0 1-1.5 1.5Z"
                      />
                    </g>
                  </svg>
                </div>
              </IconButton>
            </div>
          </Toolbar>
        ) : (
          <Toolbar
            className={classes.toolbar}
            style={{ backgroundColor: 'brown' }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon style={{ color: '#124c4d' }} />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              style={{
                color: 'white',
                backgroundColor: 'brown',
                textAlign: 'center',
                fontFamily: 'Roboto'
              }}
              className={classes.title}
            >
              No connection, please verify your internet connection!
            </Typography>
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant={mobile ? "temporary" : "permanent"}
        onClose={() => setOpen(false)}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div
          style={{
            position: 'fixed',
            width: 'inherit',
            height: '95vh',
            overflowX: 'hidden',
            borderRight: 'solid 1px rgba(0, 0, 0, 0.12) ',
            backgroundColor: 'white'
          }}
        >
          {open && (
            <div className={classes.toolbarIcon}>
              <Logo styling={{ marginRight: '-30px', height: "65px", width: "180px" }} />
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon style={{ color: '#BBBBBB' }} />
              </IconButton>
            </div>
          )}

          <Divider />
          <Menu openDrawer={() => setOpen(true)} drawerOpen={open} />
        </div>
      </Drawer>
      <main id="main" className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
