import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IManagerState,
  ETypesManager,
  IManagerAction
} from 'interfaces/managerDashboard';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { NETWORK } from 'settings';
import { mountQueryURL } from 'utils/query';

/* Authentication State. */
const initialState: IManagerState = {
  fetch: false,
  balance: {
    revenue: {
      card: 0
    },

    revenue_count: {
      card: 0
    },

    where_go: {
      delivery: 0,
      collection: 0
    },
    where_go_count: {
      delivery: 0,
      collection: 0
    },
    cost: {
      drivers: 0,
      general: 0,
      discount: 0
    }
    /* timings: {
      doing_done: '',
      doing_depot: '',
      depot_transit: '',
      transit_delivered: '',
      doing_completed: ''
    } */
  },
  orders: {
    open: 0,
    closed: 0,
    news: [],
    average: [],
    total_average: 0
  },
  users: {
    total: 0,
    news: 0
  },
  ranks: {
    products: [],
    total_products: 0,
    total_products_value: 0,
    neighborhood: [],
    total_neighborhood: 0,
    total_neighborhood_value: 0
  }
};

/* Authentication Reducer. */
export default (
  state: IManagerState = initialState,
  action: IManagerAction
): IManagerState => {
  switch (action.type) {
    case ETypesManager.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesManager.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesManager.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesManager.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchManagerDashboard = (): IFetchAction => ({
  type: ETypesManager.FETCH
});

export const successManagerDashboard = (
  payload: IManagerState
): ISuccessAction => ({
  type: ETypesManager.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesManager.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesManager.RESET
});

/* Authentication Side Effects Functions. */
export const fetchDashboard =
  (params: any): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchManagerDashboard());
      const query = mountQueryURL(params);
      const response = await fetch({
        method: EMethod.GET,
        url: `/calculations/calculate_all/${query}`
      });
      dispatch(successManagerDashboard(response));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchDashboardWithDateRange =
  (initial: string, end: string, store: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchManagerDashboard());
      const response = await fetch({
        method: EMethod.GET,
        url: `/calculations/calculate_all/?starts=${initial}&ends=${end}&store=${store}&network=${NETWORK}`
      });
      dispatch(successManagerDashboard(response));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
