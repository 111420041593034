import {
  IBalcony,
  IBalconyState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  ISearchAction,
  IBalconyAction,
  Dispatch,
  ThunkAction,
  ETypesBalcony,
  IFirstLoadAction
} from 'interfaces/balcony';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from 'utils/query';
import { NETWORK } from 'settings';

/* Balcony State. */
const initialState: IBalconyState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* Balcony Reducer. */
export default (
  state: IBalconyState = initialState,
  action: IBalconyAction
): IBalconyState => {
  switch (action.type) {
    case ETypesBalcony.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesBalcony.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesBalcony.SUCCESS:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesBalcony.SEARCH:
      return {
        ...state,
        fetch: false,
        count: 0,
        results: [action.payload],
        next: '',
        previous: '',
        error: ''
      };
    case ETypesBalcony.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Balcony Action Creators Functions. */
export const fetchBalcony = (): IFetchAction => ({
  type: ETypesBalcony.FETCH
});

export const successBalcony = (payload: IBalconyState): ISuccessAction => ({
  type: ETypesBalcony.SUCCESS,
  payload
});

export const firstLoadBalcony = (payload: IBalconyState): IFirstLoadAction => ({
  type: ETypesBalcony.FIRSTLOAD,
  payload
});

export const failureBalcony = (payload: string): IFailureAction => ({
  type: ETypesBalcony.FAILURE,
  payload
});

export const searchBalcony = (payload: IBalcony): ISearchAction => ({
  type: ETypesBalcony.SEARCH,
  payload
});

/* Balcony Side Effects Functions. */
let results: IBalcony[] = [];
export const getBalcony =
  (url: string = undefined, params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let request_url;
      if (!url) {
        dispatch(fetchBalcony());
        const query = params
          ? `${mountQueryURL(params)}&network=${NETWORK}`
          : `?network=${NETWORK}`;
        request_url = `/orders/api/orders_balcony_resumed/${query}`;
      } else request_url = url;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      if (url) results = [...results, ...response.results];
      else results = [...response.results];
      response.results = results;

      dispatch(successBalcony(response));
    } catch (error) {
      dispatch(failureBalcony(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const searchBalconyByID =
  (id: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await fetch({
        method: EMethod.GET,
        url: `orders/api/orders_balcony_resumed/${id}/get_order/`
      });
      dispatch(searchBalcony(response));
    } catch (error) {
      dispatch(failureBalcony(error));
    }
  };
