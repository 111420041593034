import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteArtist } from 'redux/ducks/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    artist: { artist_id: number, name: string }; refresh: () => void;

}

export default function ModalDeleteIngredient(props: SimpleDialogProps) {
    const { onClose, open, artist, refresh } = props;

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const submitDelete = async () => {
        dispatch(
            deleteArtist(artist.artist_id)
        ).then((result: boolean) => {
            if (result) {
                refresh()
                handleClose();
            }
        });
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Delete artist {artist?.name}?
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitDelete} color="primary">
                    {' '}
                    Delete{' '}
                </Button>
                <Button onClick={handleClose}> Cancel </Button>
            </div>
        </Dialog>
    );
}
