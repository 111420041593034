import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import ProviderTable from './ProviderTable';
import { fetchProviders } from 'redux/ducks/stock';
import useStyles from './styles';
import { IStoreState } from 'interfaces/store_test';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from 'redux/ducks/store';

var storeFilter: { id: number; name: string }[] = [];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { stock, authentication } = useSelector<IStore, IStore>(
    (state) => state
  );
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);

  storeFilter = [];
  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  if (authentication.is_superuser) {
    storeFilter.push({ id: 0, name: 'ALL' });
  }
  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-config');
    d.item(0).style.color = '#ff5a23';

    d.item(1).style.color = '#ff5a23';
  });
  useEffect(() => {
    if (!authentication.is_superuser && !activeStoreFilter) {
      setActiveStoreFilter(storeFilter[0]?.id);
    }
  }, [storeFilter]);

  const control = 0;
  useEffect(() => {
    dispatch(fetchProviders({ store: activeStoreFilter }));
    dispatch(getStore());
  }, [control, dispatch, activeStoreFilter]);
  return (
    <>
      <Loading show={stock.fetch} />
      <Grid container spacing={3}>
        <Grid style={{ marginBottom: 10, marginLeft: 15 }}>
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>
          {storeFilter.map((elmnt) => (
            <Button
              key={uuidv4()}
              onClick={() => {
                handleActiveStoreFilter(elmnt.id);
              }}
              variant="outlined"
              size="small"
              className={
                activeStoreFilter === elmnt.id
                  ? classes.activeButton
                  : classes.button
              }
            >
              {elmnt.name}
            </Button>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '1vw' }}>
        <ProviderTable providers={stock.providers} store={activeStoreFilter} />
      </Grid>
    </>
  );
}
