import React, { useState, useEffect, useMemo } from 'react';
import { IKitchen } from 'interfaces/kitchen';
import formatDate from 'date-fns/format';
import { enGB } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

// import { getDateByStatus, hideButtonByDate } from 'helpers/kitchen';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { fetch } from '../../utils/request';
import {
  getPaymentLogo,
  getWhatsappWithPicPayMessage,
  getWhatsappWithPagarmeMessage
} from 'helpers/common';
import { getDeliveryType, getWhastappUrl, } from 'helpers/kitchen';
import { useStyles } from './styles';
import Motorcycle from '../../assets/img/motorcycle.png';
import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import PrintIcon from '@material-ui/icons/Print';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import { IStoreState } from 'interfaces/store_test';
import { NETWORK } from "settings"
import Animate from 'components/Common/Animate';
import Receipt from 'helpers/Receipt';
import CancelOrderModal from './CancelOrderModal';
import RefundOrderModal from './RefundOrderModal';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ModalScheduleDate from './ModalScheduleDate';
import moment from 'moment';
import StuartDeliverModal from './StuartDeliveryModal';
import TrakingNumberModal from './TrackingNumber';
import ConfirmTracking from './ConfirmTracking';
import Currency from 'utils/currency';
import { shopURL } from 'utils/shopURL';
// import { useDispatch } from 'react-redux';
// import { setOrderIsPaid } from 'redux/ducks/kitchen';
interface IProps {
  bodyStyle: string;
  order: IKitchen;
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showScheduleBtn?: boolean;
  showDeliveryTrackBtn?: boolean;
  showExtraInfo?: boolean;
  showDriverBtn?: boolean;
  isFirstStage?: boolean;
  currentOrderID: number;
  setKitchenStatus: () => void;
  setRevertKitchenStatus: () => void;
  headerTitle: string;
}

const Order: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    bodyStyle,
    order,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showScheduleBtn,
    showDeliveryTrackBtn,
    showExtraInfo,
    showDriverBtn,
    isFirstStage,
    // currentOrderID,
    setKitchenStatus,
    setRevertKitchenStatus,
    headerTitle
  } = props;
  const classes = useStyles();

  const [pagarMeURL, setPagarMeURL] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openRefund, setOpenRefund] = React.useState(false);
  const [trackingNumber, setTrackingNumber] = useState(false)
  const [selectedOrder, setSelectedOrder] = React.useState(undefined);
  const [confirmTracking, setConfirmTracking] = useState(false)
  const [showModalSheduleDate, setShowModalSheduleDate] = useState(false);
  const handleModalSheduleDateOpen = () => setShowModalSheduleDate(true);
  const handleModalSheduleDateClose = () => setShowModalSheduleDate(false);
  const [openStuart, setOpenStuart] = useState('');
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const store = results.find((store) => store.name === order.store_name);
  // const dispatch = useDispatch();

  // const handlePaid = (order: IKitchen) => {
  //   dispatch(setOrderIsPaid(order, { infos: { is_paid: true } }));
  // };

  const handleClickOpen = (order: IKitchen) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedOrder(undefined);
    setOpen(false);
  };



  const reorder_stuart = async () => {
    await fetch({
      url: `/orders/api/orders/${order.order_id}/get_stuart_job/`,
      method: 'GET'
    });
  };
  useEffect(() => {
    (async () => {
      if (order.payment && order.payment.toLowerCase() === 'pagarme') {
        const data = await fetch({
          url: `/orders/api/orders/${order.order_id}/pagarme_link/`,
          method: 'GET'
        });
        setPagarMeURL(data);
      }
    })();
  }, [order.order_id, order.payment]);

  const driverPhone = order?.driver?.phone_number.replace(/\D/g, '');
  const addressEncoded = !order?.delivery_address
    ? order.delivery_address
    : order.delivery_address;

  // this used to be a regular expression that wasn't working in safari, so i changed to this
  let found = null;
  // if (order.delivery_address !== null) {
  //   const hyphenSplitted = order.delivery_address.split('-');
  //   if (hyphenSplitted.length > 0) {
  //     found = hyphenSplitted[2].split(' ')[1];
  //   }
  // }
  const bairro = found != null ? found : null;
  // ---------------------------------------------

  const renderProductName = (item: any) => {
    if (item?.product?.half && !item.product?.double) {
      return `${item?.quantity + 'x' ?? ''} ${item.flavors.length > 0
        ? item.flavors
          .map((flavor: { name: string }) => flavor.name)
          .join('/')
        : item?.product?.name ?? 'Unavailable'
        }`;
    }
    return `${item?.quantity + 'x' ?? ''} ${item?.product?.name}`;
  };

  const background_type = (type: string) => {
    const values = {
      D: '#ccf6c8',
      C: '#f6d6ad',
      M: '#a6f6f1'
    };

    return values[type];
  };

  const memoizedModalScheduleDate = useMemo(
    () => (
      <ModalScheduleDate
        open={showModalSheduleDate}
        onClose={handleModalSheduleDateClose}
        order={order}
      />
    ),
    [showModalSheduleDate]
  );

  const SheduleDateButton = () => {
    return (
      <>
        <Button
          size="medium"
          onClick={handleModalSheduleDateOpen}
          style={{ minWidth: '0px', color: '#000' }}
          title="Schedule an alternative delivery time and date"
        >
          <EventAvailableIcon />
        </Button>
        {memoizedModalScheduleDate}
      </>
    );
  };
  const DeliveryTrackButton = () => {
    return (
      <Button
        size="medium"
        onClick={() => setOpenStuart(order.stuart_tracking_url)}
        style={{ minWidth: '0px', color: '#000' }}
        title="Track delivery by Stuart"
      >
        <MapOutlinedIcon />
      </Button>
    );
  };

  const translatePiStatus = (status, payment) => {
    if (payment !== "Card") return ""
    const statuses = {
      'succeeded': "Payment captured",
      'requires_capture': "Payment uncaptured",
      'requires_payment_method': "Payment unfulfilled"

    }
    return statuses[status] ? statuses[status] : status
  }
  const handleNextStatus = () => {
    if (headerTitle === "DOING" && !order.delivery_tracking_number && order.type_of_order.includes("D")) {
      setConfirmTracking(true)
    } else {
      setKitchenStatus()
    }
  }
  const handlePreordered = () => {
    const preorderItems = order.items.filter((item) => {
      if (item.variation) {
        var selected_variation = item.product["variation"].filter(el => el.id === item.variation.id)
        return selected_variation.stock <= 0;
      }
      return item.product['stock'] <= 0;
    });
    return preorderItems.length >= 1
  }
  return (
    <Animate key={order.order_id}>
      <Card className={bodyStyle}>
        <CardContent style={{ padding: '5px' }}>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: '20px' }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography // Order Code
                variant="overline"
                className={classes.overlineLineHeight}
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  lineHeight: 1,
                  backgroundColor: background_type(
                    order.type_of_order.split('-')[1] || ''
                  )
                }}
              >
                {order?.order_code}
              </Typography>
              {handlePreordered() && <Typography style={{ fontWeight: "bold", lineHeight: 1.6 }}>
                Preordered
              </Typography>}
            </div>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'bold'
              }}
            >
              {order?.store_name}
            </Typography>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {order.date_ordered && (
                <Typography // Date Delivery
                  className={`${classes.pos} ${classes.overlineLineHeight}`}
                  variant="overline"
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    marginBottom: '5px',
                    marginTop: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  {formatDate(
                    new Date(order.date_ordered),
                    'EEE  dd/MMM HH:mm',
                    { locale: enGB }
                  )}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            {order?.table && (
              <Grid>
                <Typography // Order Code
                  style={{
                    lineHeight: 1,
                    fontWeight: 'bold'
                  }}
                >
                  Table: {order?.table?.name}
                </Typography>
              </Grid>
            )}
            <Grid style={{ width: "inherit" }}>
              {headerTitle === 'NEW ORDERS' ? (
                <Typography // Order Client
                  style={{
                    lineHeight: 1.6,
                    fontWeight: 'bold'
                  }}
                >
                  {order?.client?.name && order?.client?.name !== 'name'
                    ? order?.client?.name
                    : order?.owner?.name && order?.owner?.name !== 'name'
                      ? order?.owner?.name
                      : ''}
                  {order?.merchant_customer_name}
                </Typography>
              ) : (
                <Typography // Order Client
                  style={{
                    lineHeight: 1.6,
                    fontWeight: 'bold'
                  }}
                >
                  {order?.client?.name && order?.client?.name !== 'name'
                    ? order?.client?.name
                    : order?.owner?.name && order?.owner?.name !== 'name'
                      ? order?.owner?.name
                      : ''}
                  {order?.merchant_customer_name}
                </Typography>
              )}
              {order?.owner?.phone_number ? <>{order?.owner?.phone_number !== "" && !order?.owner?.phone_number.includes(".") ?
                <Typography style={{ lineHeight: 1.6, lineBreak: "anywhere", fontSize: "14px" }}>
                  {order?.owner?.phone_number}
                </Typography> : null}</> : null}
              {order.owner ? order.owner["user"] ? (order.owner['user']['email'] !== "" && !order.owner['user']['email'].includes("flogi") ?
                <Typography style={{ lineHeight: 1.6, lineBreak: "anywhere", fontSize: "14px" }}>
                  {order.owner['user']['email']}
                </Typography> : null) : null : null}
              {order.payment === "none" ? <Typography style={{ color: "red" }}>Abandoned cart</Typography> : null}
              {showIsPaid && (
                <Typography
                  style={{
                    color: 'green'
                  }}
                >
                  {order?.is_paid && 'Paid'}
                </Typography>
              )}
              {headerTitle !== "NEW ORDERS" && (NETWORK === "6" || NETWORK === "15") && order?.type_of_order.includes('D') ?
                <Button style={{ border: "1px solid black", margin: '5px 0px' }} onClick={() => {
                  setSelectedOrder(order)
                  setTrackingNumber(true)
                }}>{order.delivery_tracking_number ? "Edit" : "Add"} tracking number</Button> : null}
              {(NETWORK === "6" || NETWORK === "15") && order.payment === "Card" && !order.is_paid ? <Button style={{ border: "1px solid green", color: "green", margin: '5px 0px' }} onClick={() => {
                window.open(`${shopURL()}payment/${order?.order_id}`)
              }}>Payment link</Button> : null}
              <Typography>
                {translatePiStatus(order.pi_status, order.payment)}
              </Typography>
              {/* {order.type_of_order !== 'FC-M' && showIsPaid && !order?.is_paid && (
                <Button
                  onClick={() => {
                    handlePaid(order);
                  }}
                  style={{ backgroundColor: '#ff6961', marginTop: '1vw' }}
                >
                  Confirm Payment
                </Button>
              )} */}
            </Grid>
            {showIsPaid && (
              <Grid>
                {['picpay', 'pagarme'].includes(order?.payment) ? (
                  <a
                    href={
                      order?.payment === 'picpay'
                        ? getWhatsappWithPicPayMessage(order)
                        : getWhatsappWithPagarmeMessage(order, pagarMeURL)
                    }
                    target="blank"
                  >
                    <img
                      src={getPaymentLogo(order?.payment)}
                      alt="Logo"
                      width="50"
                      height="50"
                    />
                  </a>
                ) : null}
              </Grid>
            )}
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            {order?.items?.map((item) =>
              item.quantity > 0 ? (
                <Grid
                  key={uuidv4()}
                  style={{
                    backgroundColor: '#f5f5f5',
                    marginBottom: 10,
                    padding: 8,
                    borderRadius: 10,
                    width: '100%'
                  }}
                >
                  {item.product.image && NETWORK === "6" && <img src={item.product.image} alt="" height={45} width={45} />}
                  <div>
                    <Typography // Product Name
                      variant="h6"
                      className={classes.productInfo}
                    >
                      {renderProductName(item)}
                    </Typography>
                    {item.variation ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "15px" }}>
                      <Typography className={classes.productInfo} style={{ color: "GrayText" }}>{item.variation.name}</Typography>

                    </div> : null}
                  </div>
                  {item?.product?.double &&
                    item?.flavors?.length > 0 &&
                    item?.flavors?.map((flavor, index) => (
                      <Typography
                        key={index}
                        variant="overline"
                        className={classes.overlineLineHeight}
                        paragraph
                        style={{ marginTop: 12 }}
                      >
                        Stack {index + 1}: {flavor.name}
                      </Typography>
                    ))}
                  {item?.extra_orders?.length > 0 && (
                    <Typography
                      paragraph
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{
                        fontWeight: 'bold',
                        marginTop: 10,
                        marginBottom: 'unset'
                      }}
                    >
                      Extras
                    </Typography>
                  )}
                  {item?.extra_orders?.length > 0 &&
                    item?.extra_orders?.map((extra) => (
                      <Typography
                        key={extra.id}
                        variant="overline"
                        className={classes.overlineLineHeight}
                        paragraph
                        style={{ margin: 0 }}
                      >
                        {`${extra?.quantity}x ${extra?.ingredient}`}
                      </Typography>
                    ))}
                  {item.comment && (
                    <Grid className={classes.comment}>
                      <Typography
                        variant="overline"
                        className={classes.overlineLineHeight}
                        style={{ fontWeight: 'bold' }}
                      >
                        Observation:
                      </Typography>
                      <Typography
                        paragraph
                        variant="overline"
                        className={classes.overlineLineHeight}
                        style={{ margin: 0 }}
                      >
                        {item.comment}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              ) : null
            )}
            {order?.merchant_items?.map((item, index) => (
              <Grid
                key={uuidv4()}
                style={{
                  backgroundColor: '#f5f5f5',
                  marginBottom: 10,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 10,
                  width: '100%'
                }}
              >
                <Typography // Product Name
                  variant="h6"
                  className={classes.productInfo}
                  key={index}
                >
                  {item.quantity + 1}x {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {order.delivery_address && bairro && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                Neighborhood:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {bairro}
              </Typography>
            </Grid>
          )}
          {order?.merchant_delivery_address && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                Neighborhood:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.merchant_delivery_address !== null &&
                  JSON.parse(order?.merchant_delivery_address).neighborhood}
              </Typography>
            </Grid>
          )}
          {order?.merchant_delivery_address && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                Address:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.merchant_delivery_address !== null &&
                  JSON.parse(order?.merchant_delivery_address).formattedAddress}
              </Typography>
            </Grid>
          )}

          {headerTitle !== 'DONE' && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ font: 'bold' }}
              >
                TOTAL:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                <Currency />
                {new Intl.NumberFormat('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(order?.total_order)}{' '}
              </Typography>
              {/**              <Price style={{ font: 'bold' }} className={classes.overlineLineHeight} value={order?.total_order.toFixed(2)} />
 */}
            </Grid>
          )}
          {order?.merchant_customer_cpf && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                ID NUMBER:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.merchant_customer_cpf}
              </Typography>
            </Grid>
          )}
          {order?.merchant_order_id && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                Ifood order number:{' '}
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.merchant_order_id}
              </Typography>
            </Grid>
          )}
          {order.final_comment && (
            <>
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  COMMENT
                </Typography>
              </Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                {`${order.final_comment}`}
              </Typography>
            </>
          )}
          {showExtraInfo && (
            <>
              {headerTitle === 'DONE' && order.stuart_delivery_status ? (
                <Grid>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                  >
                    Stuart status:{' '}
                  </Typography>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                    style={{ fontWeight: 'bold' }}
                  >
                    {order.stuart_delivery_status}
                  </Typography>
                </Grid>
              ) : (
                <Grid>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                  >
                    Type:{' '}
                  </Typography>
                  <Typography
                    variant="overline"
                    className={classes.overlineLineHeight}
                    style={{ fontWeight: 'bold' }}
                  >
                    {`${getDeliveryType(order?.type_of_order)}`}
                  </Typography>
                </Grid>
              )}
              {/* {`${getDeliveryType(order?.type_of_order)}` === 'Delivery' && (
                <>
                  <Grid>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      onClick={() => setOpenStuart(order.stuart_tracking_url)}
                    >
                      Deliveryman:{' '}
                    </Typography>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{ fontWeight: 'bold' }}
                    >
                      {`${
                        order?.gomotoboy_driver_name
                          ? order?.gomotoboy_driver_name
                          : 'NOT SELECTED'
                      }`}
                    </Typography>
                  </Grid>
                </>
              )} */}
            </>
          )}
        </CardContent>
        {showDriverBtn && order.delivery_address && order.driver?.phone_number && (
          <>
            <CardActions>
              <Grid container justify="center">
                <Button size="small">
                  <a
                    target="blank"
                    href={getWhastappUrl(order, driverPhone, addressEncoded)}
                  >
                    <img src={Motorcycle} width="30" height="30" alt="Driver" />
                  </a>
                </Button>
              </Grid>
            </CardActions>
          </>
        )}

        <CardActions>
          <Grid container justify="space-between">
            {order.stuart_delivery_status === 'cancelled' &&
              headerTitle === 'DONE' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button
                  onClick={() => reorder_stuart()}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Reorder delivery
                </Button>
              </div>
            ) : (
              <>
                {moment().isBefore(order?.date_delivery) ? (
                  <>
                    {' '}
                    {showBackBtn ? (
                      <Button
                        size="small"
                        onClick={setRevertKitchenStatus}
                        style={{ minWidth: '0px' }}
                        title="Previous"
                      >
                        <LeftIcon />
                      </Button>
                    ) : headerTitle === 'NEW ORDERS' ? (
                      <Button
                        size="small"
                        onClick={() => {
                          if (order.payment === "Invoice" && order.is_paid === true) {
                            setSelectedOrder(order)
                            setOpenRefund(true)
                          } else
                            handleClickOpen(order)
                        }}
                        style={{ minWidth: '0px', color: '#ff5a23' }}
                        title="Cancel"
                      >
                        {order.payment === "Invoice" && order.is_paid === true ? "Refund" : <CancelIcon />}

                      </Button>
                    ) : null}
                  </>
                ) : headerTitle === 'NEW ORDERS' ? (
                  <Button
                    size="small"
                    onClick={() => {
                      if (order.payment === "Invoice" && order.is_paid === true) {
                        setSelectedOrder(order)
                        setOpenRefund(true)
                      } else
                        handleClickOpen(order)
                    }}
                    style={{ minWidth: '0px', color: '#ff5a23' }}
                    title="Cancel"
                  >
                    {order.payment === "Invoice" && order.is_paid === true ? "Refund" : <CancelIcon />}
                  </Button>
                ) : null}
                {headerTitle === "DOING" && <Button
                  size="small"
                  onClick={() => {
                    if (order.payment === "Invoice" && order.is_paid === true) {
                      setSelectedOrder(order)
                      setOpenRefund(true)
                    } else
                      handleClickOpen(order)
                  }}
                  style={{ minWidth: '0px', color: '#ff5a23' }}
                >
                  {order.payment === "Invoice" && order.is_paid === true ? "Refund" : <CancelIcon />}
                </Button>}
                {showPrintBtn && <Receipt trigger={<PrintIcon />} order={order} storeName={store.name} />}
                {headerTitle === 'DONE' ? null : (
                  <>{showScheduleBtn && <SheduleDateButton />}</>
                )}
                {showDeliveryTrackBtn &&
                  order.stuart_tracking_url &&
                  !store.own_delivery && <DeliveryTrackButton />}
                {/* {showPrintBtn && <EventAvailableIcon />} */}
                {showOKBtn ? (
                  isFirstStage ? (
                    <Button
                      size="small"
                      onClick={setKitchenStatus}
                      style={{ minWidth: '0px', color: '#23ba23' }}
                    >
                      Accept
                    </Button>
                  ) : (
                    <>
                      {headerTitle === 'DOING' ? (
                        <>
                          {
                            moment().diff(order?.date_delivery, 'days') < 1 &&
                              order?.type_of_order.includes('-D') ? (
                              <Button
                                size="small"
                                onClick={handleNextStatus}
                                style={{ minWidth: '0px' }}
                                title="Request Driver"
                              >
                                {/* Request
                            <br />
                            driver */}
                                <RightIcon />
                              </Button>
                            ) : (
                              <>
                                {order?.type_of_order.includes('-C') && (
                                  <Button
                                    size="small"
                                    onClick={handleNextStatus}
                                    style={{ minWidth: '0px' }}
                                    title="Next"
                                  >
                                    <RightIcon />
                                  </Button>
                                )}
                              </>
                            )
                          }
                        </>
                      ) : headerTitle === 'DONE' &&
                        order.stuart_tracking_url ? null : (
                        <Button
                          size="small"
                          onClick={handleNextStatus}
                          style={{ minWidth: '0px' }}
                          title="Next"
                        >
                          <RightIcon />
                        </Button>
                      )}
                    </>
                  )
                ) : (
                  <Button disabled>{''}</Button>
                )}
              </>
            )}
          </Grid>
        </CardActions>
        <CancelOrderModal
          open={open}
          onClose={handleClose}
          order={selectedOrder}
        />
        <RefundOrderModal
          open={openRefund}
          onClose={() => { setOpenRefund(false) }}
          order={selectedOrder}
        />
        <TrakingNumberModal
          open={trackingNumber}
          onClose={() => { setTrackingNumber(false) }}
          order={selectedOrder}
        />
        <ConfirmTracking
          open={confirmTracking}
          onClose={() => { setConfirmTracking(false) }}
          order={order}
          confirm={() => {
            setKitchenStatus()
            setConfirmTracking(false)
          }}
        />
        <StuartDeliverModal
          open={openStuart === '' ? false : true}
          onClose={() => setOpenStuart('')}
          url={openStuart}
        />
      </Card>
    </Animate>
  );
};

export default Order;
