import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteProvider, fetchProviders } from 'redux/ducks/stock';
import { Provider } from 'interfaces/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  provider: Provider;
}

export default function ModalCreateProvider(props: SimpleDialogProps) {
  const { onClose, open, provider } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const submitDelete = async () => {
    dispatch(deleteProvider(provider?.id)).then((result: boolean) => {
      if (result) {
        handleClose();
        dispatch(fetchProviders());
      }
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete Provider {provider?.name}?
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitDelete} color="primary">
          Delete
        </Button>
        <Button onClick={handleClose}> Cancel </Button>
      </div>
    </Dialog>
  );
}
