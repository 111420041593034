import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { PRIMARY_COLOR } from 'settings';
interface IProps {
  done: () => void;
}

export default function WelcomePage(props: IProps) {
  const { done } = props;
  // const state: string = localStorage.getItem('state');
  //const stateForName = JSON.parse(state);
  //const { name } = stateForName.authentication;

  return (
    <div>
      <Typography
        style={{
          marginTop: '80px',
          marginBottom: '40px',
          marginLeft: '15%',
          fontSize: '28px',
          fontFamily: 'Work Sans, sans-serif'
        }}
      >
        {`Welcome to ${process.env.REACT_APP_NAME}, your real-time order manager!`}
      </Typography>
      <Typography
        style={{
          marginBottom: '18px',
          marginLeft: '15%',
          fontSize: '18px',
          maxWidth: '408px',
          fontFamily: 'Work Sans, sans-serif'
        }}
      >
        Let’s complete your profile and create your store and first product.{' '}
      </Typography>
      <Typography
        style={{
          marginBottom: '5%',
          marginLeft: '15%',
          fontSize: '18px',
          maxWidth: '408px',
          fontFamily: 'Work Sans, sans-serif'
        }}
      >
        And whenever you have any questions or need help, we are available
        through our email:{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: PRIMARY_COLOR() }}
          href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
        >
          {process.env.REACT_APP_CONTACT_EMAIL}
        </a>
      </Typography>
      <Button
        style={{
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          marginLeft: '15%',
          borderRadius: '15px',
          height: '48px',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={done}
      >
        Click here to begin
      </Button>
    </div>
  );
}
