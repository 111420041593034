import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { IconButton, InputBase } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { PRIMARY_COLOR } from 'settings';
import TagsRow from './GiftCardRow';
import { IGiftCard } from 'interfaces/store';

const useStyles = makeStyles({
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: '15px'
    },
    input: {
        flex: 1,
        paddingLeft: '8px'
    },
    iconButton: {
        padding: 10
    },
    table: {
        minWidth: 650
    },
    tableContainer: {
        margin: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    activeButton: {
        margin: '0 5px',
        color: '#fff',
        border: 'none',
        backgroundColor: PRIMARY_COLOR(),
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
});

interface Props {
    giftCards: IGiftCard[];
    store?: number;
    searchData: (data: string) => void;
    fetchGiftCards: () => void
}

export default function GiftCardTable(props: Props) {
    const classes = useStyles();
    const { giftCards, store, searchData, fetchGiftCards } = props;
    const [search, setSearch] = React.useState('');
    const handleSearch = (event: any) => {
        setSearch(event.target.value);
    };


    return (
        <>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Paper className={classes.root}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search giftcards"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={search}
                            onChange={handleSearch}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    searchData(search);
                                }
                            }}
                        />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={() => {
                                searchData(search);
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Active/Inactive</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Buyer user</TableCell>
                            <TableCell>Code</TableCell>

                            {store > 0 && (
                                <>
                                    <TableCell>Actions</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {giftCards?.map((giftCard) => (
                            <TagsRow fetchGiftCards={() => fetchGiftCards()} giftCard={giftCard} store={store} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
