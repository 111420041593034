import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Container,
  Typography
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editMenu } from 'redux/ducks/menu';
import { Menu } from 'interfaces/menu';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { NETWORK } from 'settings'
// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  menu: Menu;
  categories: any[];
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: '200px'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      image: {
        display: 'none'
      },
      imageContainer: {
        maxWidth: 'auto',
        height: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function ModalEditMenu(props: SimpleDialogProps) {
  const { onClose, open, menu, categories } = props;
  const [bannerSource, setBannerSource] = React.useState<File>();
  const [name, setName] = React.useState('');
  const [maxOfItems, setMaxOfItems] = React.useState(0);
  const [maxOfSameItem, setMaxOfSameItem] = React.useState(0);
  const [active, setActive] = React.useState(true);
  const [sections, setSections] = React.useState([
    {
      category: '',
      template: '',
      nameEn: '',
      namePt: '',
      id: 0
    }
  ]);

  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setActive(true);
    setMaxOfItems(0);
    setMaxOfSameItem(0);
    onClose();
  };

  useEffect(() => {
    if (menu) {
      setName(menu.name);
      setMaxOfItems(menu.max_quantity_of_items);
      setMaxOfSameItem(menu.max_quantity_of_same_item);
      if (menu.sections) {
        setSections(menu.sections);
      }
    }
  }, [menu]);

  const handleName = (event: any) => {
    setName(event.target.value);
  };
  const handleChangeCategory = (index: any, value: any) => {
    const x = [...sections];
    x[index].category = value;
    setSections(x);
  };

  // const handleChangeEnSectionName = (index: any, value: any) => {
  //   setSections(
  //     sections.map((item) =>
  //       item.id === index
  //         ? {
  //             ...item,
  //             nameEn: value
  //           }
  //         : item
  //     )
  //   );
  // };
  // const handleChangePtSectionName = (index: any, value: any) => {
  //   setSections(
  //     sections.map((item) =>
  //       item.id === index
  //         ? {
  //             ...item,
  //             namePt: value
  //           }
  //         : item
  //     )
  //   );
  // };
  const handleDelete = (id: number) => {
    const values = [...sections];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setSections(values);
  };

  

  const handleMaxOfItems = (event: any) => {
    setMaxOfItems(event.target.value);
  };

  const handleMaxOfSameItem = (event: any) => {
    setMaxOfSameItem(event.target.value);
  };

  const submitEdit = async () => {
    const sectionsToSubmit = [];
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      sectionsToSubmit.push({
        category: element.category,
        template: 'product',
        nameEn: element.category,
        namePt: element.category
      });
    }
    dispatch(
      editMenu(menu.menu_id, {
        banner: bannerSource,
        name,
        type_of_menu: "Take Away",
        max_quantity_of_items: 100,
        max_quantity_of_same_item: maxOfSameItem,
        active,
        sections: JSON.stringify(sectionsToSubmit)
      })
    ).then((result: boolean) => {
      if (result) handleClose();
    });
  };
  const listCategories = categories.map((category, index) => (
    <MenuItem value={category.name} key={index}>
      {category.name}
    </MenuItem>
  ));
  const listSections = sections?.map((section, index) => (
    <>
      <div className="section-title-wrapper">
        <h3>Section {index + 1}</h3>
        {sections.length > 1 ? (
          <IconButton
            style={{ width: '55px' }}
            color="secondary"
            onClick={() => handleDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </div>
      <Grid key={index + section.id} item xs={12} spacing={5} sm={12}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="category-input">Category</InputLabel>
              <Select
                labelId="label"
                id="select"
                value={sections[index].category}
                onChange={(e) => handleChangeCategory(index, e.target.value)}
              >
                {listCategories}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={5}>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="ENName-input">
                (EN) Name of Section
              </InputLabel>
              <Input
                id="ENName-input"
                value={section.nameEn}
                onChange={(e) =>
                  handleChangeEnSectionName(index, e.target.value)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="PTName-input">(PT) Nome da Seção</InputLabel>
              <Input
                id="PTName-input"
                value={section.category}
                onChange={(e) =>
                  handleChangePtSectionName(index, e.target.value)
                }
                disabled
              />
            </FormControl>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  ));

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>

        <h2>Edit {NETWORK === "6" ? "Group" : "Menu"} {menu?.name}</h2>
        <Container maxWidth="md">
          <div className="image-input-wrapper">
            <Grid item xs={12} sm={4}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop an image here or click'}
                  onChange={(e) => {
                    console.log(e);
                    setBannerSource(e[0]);
                  }}
                  filesLimit={1}
                />
              </ThemeProvider>

              {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
            </Grid>
            {bannerSource ? (
              <img
                className="image-create-menu-wrapper"
                src={URL.createObjectURL(bannerSource)}
              />
            ) : null}{' '}
          </div>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={handleName}
              />
            </FormControl>
            {/* <Grid container spacing={5}>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth style={{ marginBottom: '15px' }}>
                  <InputLabel htmlFor="price-input">{NETWORK === "6" ? "Group" : "Menu"} Type</InputLabel>
                  <Select
                    labelId="label"
                    id="select"
                    value={typeOfMenu}
                    onChange={handleTypeOfMenu}
                  >
                    <MenuItem value="Take Away">Delivery & Pickup</MenuItem>
                    <MenuItem value="Mesa">Table or Tab</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}
            <Grid style={{ marginTop: '10px' }} container spacing={5}>
              <Grid item xs={5} sm={4}>
                <FormControl style={{ marginBottom: '15px' }}>
                  <InputLabel htmlFor="name-input">
                    Maximum {NETWORK === "6" ? "Group" : "Menu"} items
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="number"
                    value={maxOfItems}
                    onChange={handleMaxOfItems}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} sm={4}>
                <FormControl style={{ marginBottom: '15px' }}>
                  <InputLabel htmlFor="name-input">
                    Maximum of the same item
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="number"
                    value={maxOfSameItem}
                    onChange={handleMaxOfSameItem}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {listSections}
            <IconButton
              color="primary"
              onClick={() => {
                var data = {
                  category: '',
                  template: '',
                  nameEn: '',
                  namePt: '',
                  id: 0
                };
                if (sections.length >= 1) {
                  data.id = sections[sections.length - 1].id + 1;
                }
                setSections(sections.concat(data));
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 20
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitEdit}
                color="primary"
              >
                {' '}
                Save{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
