import { IAlertAction } from 'interfaces/alert';
import IBaseAction from 'interfaces/redux';

export interface IOwnerBase {
  id: number;
  email: string;
  stores: IStores[];
  profile: {
    id?: number;
    name?: string;
    // cnpj?: string;
    // is_stripe_owner?: boolean;
    // account_stripe_key?: string;
  };
}

export interface IOwnerState {
  fetch: boolean;
  next: string;
  previous: string;
  results: IOwnerBase[];
  error: string;
}

interface IStores {
  name: string;
  id: number;
}
/**
 * Redux types.
 */
export enum ETypesOwner {
  FETCH = 'OWNER/FETCH',
  SUCCESS = 'OWNER/SUCCESS',
  FAILURE = 'OWNER/FAILURE',
  RESET = 'OWNER/RESET'
}

export interface IFetchAction extends IBaseAction<ETypesOwner, null> {
  type: ETypesOwner.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesOwner, IOwnerState> {
  type: ETypesOwner.SUCCESS;
  payload: IOwnerState;
}

export interface IFailureAction extends IBaseAction<ETypesOwner, string> {
  type: ETypesOwner.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesOwner, null> {
  type: ETypesOwner.RESET;
}

export type IOwnerAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type PromiseAction = Promise<IOwnerAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IOwnerAction
    | ThunkAction
    | PromiseAction
    | Array<IOwnerAction>
    | IAlertAction
) => any;
