import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  receiptHeader: {
    width: '100%',
    margin: '50px 10px',
    padding: '3% 15%',
    border:"none",
  },
  receiptBody: {
    marginTop: 15,
    marginBottom: 10
  }
}));
