import React from 'react';
import { Grid } from '@material-ui/core';

export default function Billing(): JSX.Element {

    return (
        <>
            <Grid container spacing={3}>
                <Grid lg={8} md={6} sm={12}></Grid>
                <Grid lg={4} md={6} sm={12}></Grid>
            </Grid>
        </>
    );
}
