import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IKitchen } from 'interfaces/kitchen';
import { Button, DialogContentText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteOrder } from 'redux/ducks/kitchen';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void; 
  order: IKitchen;
  refresh: () => void
}

export default function DeleteOrderModal(props: SimpleDialogProps) {
  const { onClose, open, order, refresh } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const submitCancel = async () => {
    deleteOrder(order, dispatch).then(
      () => {
        refresh()
        dispatch(sendAlert("Order successfully deleted", EAlertVariant.SUCCESS))
        handleClose();
      }
    ).catch(e =>{
        dispatch(sendAlert("Something went wrong", EAlertVariant.ERROR))
        console.log("error deleting ", e)
    })
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete order: {order?.order_code}
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DialogContentText style={{ width: '90%' }} id="simple-dialog-title">
          Are you sure that you want to delete this order?
        </DialogContentText>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitCancel} color="primary">
          {' '}
          Yes
        </Button>
        <Button onClick={handleClose}> No </Button>
      </div>
    </Dialog>
  );
}
