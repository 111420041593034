import React from 'react';

import { IKitchen } from 'interfaces/kitchen';

import { v4 as uuidv4 } from 'uuid';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface IProps {
  order: IKitchen;
}

export default function Summary(props: IProps): JSX.Element {
  const { order } = props;
  return (
    <Grid>
      <Typography paragraph variant="overline" style={{ fontWeight: 'bold' }}>
        ORDER SUMMARY
      </Typography>
      {order?.items.length > 0 &&
        order.items.map((item) => (
          <>
            {item.quantity > 0 && (
              <Grid key={uuidv4()}>
                <Typography variant="overline">
                  {`${item?.quantity} x ${item?.product?.name}`}
                </Typography>
                {item.comment && (
                  <Typography
                    variant="overline"
                    style={{ fontStyle: 'italic', marginLeft: '12px' }}
                  >
                    "{item.comment}"
                  </Typography>
                )}
                {item?.extra_orders.map((extra) => (
                  <Grid key={extra.id}>
                    <Typography variant="overline" style={{ marginLeft: 15 }}>
                      {`${extra?.quantity} x ${extra?.measure_quantity}${extra?.measure} ${extra?.ingredient}`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        ))}
    </Grid>
  );
}
