import React from 'react';
import { Page, Text, View, Document, Image, } from '@react-pdf/renderer';
import bird from './../../../assets/img/petshop/bird.png'
import logo from './../../../assets/img/petshop/logo.png'
import giftMessage from './../../../assets/img/petshop/giftcard.png'

export default function Receipt({ code, value }): JSX.Element {

    return (
        <Document>
            <Page >
                <View style={{ position: 'relative', height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <View
                        style={{
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            width: "50%",
                            backgroundColor: "white",
                            paddingLeft: "0.5cm", paddingRight: "0.5cm",
                            border: "0.026cm solid #000"
                        }}
                    >
                        <View style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "-0.7cm", marginBottom: "0.6cm" }}>
                            <Image source={bird} style={{ width: "95%", height: "auto" }} />
                        </View>
                        <Image style={{ width: "90%", height: "auto" }} source={giftMessage} />
                        <Image style={{ width: "90%", height: "auto" }} source={logo} />

                        <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", borderBottom: "0.026cm solid #000", paddingBottom: "0.8cm" }}>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                                marginTop: '1.5cm',
                            }}>
                                Look what the cat’s dragged in!
                            </Text>
                            <Text
                                style={{
                                    color: '#000',
                                    fontSize: '0.39cm',
                                    textDecoration: 'none',
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >

                                You will receive the physical gift card in the mail shortly. If you can't wait any longer, you can already use it online or in store by using the code below.
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", borderBottom: "0.026cm solid #000", paddingBottom: "1cm", paddingTop: "0.2cm" }}>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                            }}>
                                Value: {value.toFixed(2)} €
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", borderBottom: "0.026cm solid #000", paddingBottom: "1cm", paddingTop: "0.2cm" }}>
                            <Text
                                style={{
                                    color: '#000',
                                    fontSize: '0.39cm',
                                    textDecoration: 'none',
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                Code: {code}
                            </Text>
                        </View>
                        <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "flex-start", borderBottom: "0.026cm solid #000", paddingBottom: "1.5cm", paddingTop: "0.2cm" }}>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                            }}>
                                If you have any questions, feel free to contact us here:{" "}<Text style={{
                                    color: '#000',
                                    fontSize: '0.39cm',
                                    textDecoration: "underline",
                                    width: '100%',
                                    textAlign: 'left',
                                }}>
                                    petshop@karhard.com
                                </Text>
                            </Text>

                        </View>
                        <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", paddingTop: "0.2cm" }}>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: "0.7cm"
                            }}>
                                Petshop by karhard®
                            </Text>

                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                            }}>
                                Oberbaumstrasse 7

                            </Text>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                            }}>
                                10997 Berlin
                            </Text>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: "0.7cm"
                            }}>
                                Deutschland
                            </Text>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                            }}>
                                petshop@karhard.com
                            </Text>
                            <Text style={{
                                color: '#000',
                                fontSize: '0.39cm',
                                textDecoration: 'none',
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: "0.7cm"
                            }}>
                                +49 30 61107848
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document >
    );
}

Receipt.defaultProps = {
    disabled: false
};
