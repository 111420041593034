import React from 'react';

import { ITable } from 'interfaces/table';

import formatDate from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';

import { getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PrintIcon from './../../assets/img/print-icon.svg';

import Print from 'components/Common/Print';

import { useStyles } from './styles';

interface IProps {
  table: ITable;
  disabled?: boolean;
}

export default function Receipt(props: IProps): JSX.Element {
  const { table, disabled } = props;
  const { order_code, name } = table;
  const classes = useStyles();

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <img src={PrintIcon} height="35px" width="50px" />
        </Button>
      }
    >
      <Card>
        <Grid
          container
          justifyContent="center"
          className={classes.receiptHeader}
        >
          <Grid>
            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
              {order_code?.toUpperCase()} {name?.toUpperCase()}
            </Typography>
            {table.orders.map((order) => (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  {`${order?.order_code}`}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  {formatDate(
                    new Date(order?.date_ordered),
                    'dd/MM/yyyy - hh:mm'
                  )}
                </Typography>
                {order?.date_delivery && (
                  <Typography style={{ fontSize: 20 }}>
                    {formatDate(
                      new Date(order?.date_delivery),
                      'dd/MM/yyyy - hh:mm'
                    )}
                  </Typography>
                )}
                {getDeliveryType(order?.type_of_order) === 'Mesa' &&
                order?.table ? (
                  <>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                      TABLE DATA
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Client's Name:{' '}
                      {order?.owner?.name
                        ? order?.client?.name
                        : 'Unidentified'}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Table Name: {order?.table?.name}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Table Number: {order?.table?.number}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Table Status:{' '}
                      {order?.table?.status === 'Available'
                        ? 'Available'
                        : order?.table?.status === 'Active'
                        ? 'Active'
                        : 'Needs Payment'}
                    </Typography>
                  </>
                ) : null}
                {order?.owner || order?.client ? (
                  <>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                      CLIENT'S DATA
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      {order?.owner?.name ?? order?.client?.name}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      {order?.owner?.phone_number ?? order?.client?.phone}
                    </Typography>
                  </>
                ) : null}

                {order?.delivery_address && (
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    DELIVERY ADDRESS
                  </Typography>
                )}
                {order?.delivery_address && (
                  <Typography style={{ maxWidth: 200, fontSize: 20 }}>
                    {`${order?.delivery_address}`}
                  </Typography>
                )}

                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  ORDER DETAILS
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Paid: {order?.is_paid ? 'Sim' : 'Não'}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Payment: {getPaymentType(order?.payment)}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Type: {getDeliveryType(order?.type_of_order)}
                </Typography>
                {order?.delivery_address && (
                  <>
                    <Typography style={{ fontSize: 20 }}>
                      Sub-total: £:{' '}
                      {(
                        Number(order?.total_order?.toFixed(2)) -
                        Number(order?.delivery_fee)
                      ).toFixed(2)}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Delivery Fee: £ {order?.delivery_fee?.toFixed(2)}
                    </Typography>
                    <Typography style={{ fontSize: 20 }}>
                      Deliveryman:{' '}
                      {`${
                        order?.gomotoboy_driver_name
                          ? order?.gomotoboy_driver_name
                          : 'Undefined'
                      }`}
                    </Typography>
                  </>
                )}
                <Typography style={{ fontSize: 20 }}>
                  Discount: £:{' '}
                  {order?.discount ? order?.discount?.toFixed(2) : '0.00'}
                </Typography>
                <Typography style={{ fontSize: 20 }}>
                  Total: £: {order?.total_order?.toFixed(2)}
                </Typography>
                {order?.cash_change && (
                  <Typography style={{ fontSize: 20 }}>
                    Change: £ {Number(order?.cash_change).toFixed(2)}
                  </Typography>
                )}

                <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                  ORDER SUMMARY
                </Typography>
                {order?.items.map((item) => (
                  <span key={uuidv4()}>
                    <Typography
                      variant="h6"
                      key={item?.product?.product_id}
                      style={{ fontSize: 20 }}
                    >
                      {`${item?.quantity}x ${item?.product?.name} - £ ${
                        item?.quantity * item?.item_price
                      }`}
                    </Typography>
                    {item?.extra_orders.map((extra) => (
                      <Typography
                        key={extra.id}
                        style={{ marginLeft: 15, fontSize: 20 }}
                      >
                        {`${extra?.quantity} x Extra ${extra?.ingredient}`}
                      </Typography>
                    ))}
                  </span>
                ))}
                {order.set_driver_qr &&
                  getDeliveryType(order?.type_of_order) === 'Entrega' && (
                    <img
                      alt="QR Code"
                      src={order.set_driver_qr}
                      width="150"
                      height="150"
                    />
                  )}

                {order.final_comment && (
                  <>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                      COMMENT
                    </Typography>
                    <Typography
                      style={{ fontSize: 20 }}
                    >{`${order.final_comment}`}</Typography>
                  </>
                )}
              </>
            ))}
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

Receipt.defaultProps = {
  disabled: false
};
