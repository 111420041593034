import React, { useState, useEffect } from 'react';
import { IKitchen } from 'interfaces/kitchen';
import formatDate from 'date-fns/format';
//import { enUS } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

import { getDateByStatus, hideButtonByDate } from 'helpers/kitchen';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import { getPaymentType } from 'helpers/common';
import { getDeliveryType, getWhastappUrl } from 'helpers/kitchen';
import { useStyles } from './styles';

import Motorcycle from '../../assets/img/motorcycle.png';
import LeftIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RightIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import QRCode from '../../assets/img/qrcode.png';

import Animate from 'components/Common/Animate';
import Receipt from './Receipt';
import { useDispatch } from 'react-redux';
import { setOrderIsPaid } from 'redux/ducks/kitchen';

interface IProps {
  bodyStyle: string;
  order: IKitchen;
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showExtraInfo?: boolean;
  showDriverBtn?: boolean;
  currentOrderID: number;
  headerTitle: string;
  setKitchenStatus: () => void;
  setRevertKitchenStatus: () => void;
  onClick: () => void;
}

const Order: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    bodyStyle,
    order,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showExtraInfo,
    showDriverBtn,
    headerTitle,
    // currentOrderID,
    setKitchenStatus,
    setRevertKitchenStatus,
    onClick
  } = props;
  const classes = useStyles();

  const [disableButtons, setDisableButtons] = useState(hideButtonByDate(order));

  const dispatch = useDispatch();
  const handlePaid = (order: IKitchen) => {
    dispatch(setOrderIsPaid(order, { infos: { is_paid: true } }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableButtons(hideButtonByDate(order));
    }, 35000);
    return () => clearTimeout(timer);
  }, [order]);

  const driverPhone = order?.driver?.phone_number.replace(/\D/g, '');
  const addressEncoded = !order?.delivery_address
    ? order.delivery_address
    : order.delivery_address;

  // this used to be a regular expression that wasn't working in safari, so i changed to this
  let found = null;
  const bairro = found != null ? found : null;
  // ---------------------------------------------

  const renderProductName = (item: any) => {
    if (item?.product?.half && !item.product?.double) {
      return `${item?.quantity + 'x' ?? ''} ${
        item.flavors.length > 0
          ? item.flavors
              .map((flavor: { name: string }) => flavor.name)
              .join('/')
          : item?.product?.name ?? 'Unavailable'
      }`;
    }
    return `${item?.quantity + 'x' ?? ''} ${item.product.name}`;
  };

  const background_type = (type: string) => {
    const values = {
      D: '#ccf6c8',
      C: '#f6d6ad',
      M: '#a6f6f1'
    };

    return values[type];
  };

  return (
    <Animate key={order.order_id}>
      <Card className={bodyStyle}>
        <CardContent>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: '20px' }}
          >
            <Typography // Order Code
              variant="overline"
              className={classes.overlineLineHeight}
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: 1,
                backgroundColor: background_type(
                  order.type_of_order.split('-')[1] || ''
                )
              }}
            >
              {order?.order_code}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography // Date
                className={`${classes.pos} ${classes.overlineLineHeight}`}
                variant="overline"
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginBottom: '5px'
                }}
              >
                {formatDate(
                  new Date(getDateByStatus(order.status, order)),
                  'EEE  dd/MMM HH:mm',
                  { locale: enGB }
                )}
              </Typography>
              {order.date_delivery && (
                <Typography // Date Delivery
                  className={`${classes.pos} ${classes.overlineLineHeight}`}
                  variant="overline"
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    marginBottom: '5px'
                  }}
                >
                  {formatDate(
                    new Date(order.date_delivery),
                    'EEE  dd/MMM HH:mm',
                    { locale: enGB }
                  )}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            <Grid>
              <Typography // Order Client
                style={{
                  lineHeight: 1,
                  fontWeight: 'bold'
                }}
              >
                {order?.client?.name
                  ? order?.client?.name
                  : order?.owner?.name
                  ? order?.owner?.name
                  : ''}
              </Typography>
              {showIsPaid && (
                <Typography
                  style={{
                    color: 'green'
                  }}
                >
                  {order?.is_paid && 'Paid'}
                </Typography>
              )}
              {order.type_of_order !== 'FC-M' && showIsPaid && !order?.is_paid && (
                <Button
                  onClick={() => {
                    handlePaid(order);
                  }}
                  style={{ backgroundColor: '#ff6961', marginTop: '1vw' }}
                >
                  Confirmar Pagamento
                </Button>
              )}
            </Grid>
            {order.type_of_order.search('-D') && headerTitle === 'DEPÓSITO' && (
              <Grid onClick={onClick} className={classes.clickableCard}>
                <img alt="QRCODE" src={QRCode} width="75" height="75" />
              </Grid>
            )}
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            {order?.items?.map((item) => (
              <Grid
                key={uuidv4()}
                style={{
                  backgroundColor: '#f5f5f5',
                  marginBottom: 10,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 10,
                  width: '100%'
                }}
              >
                <Typography // Product Name
                  variant="h6"
                  className={classes.productInfo}
                >
                  {renderProductName(item)}
                </Typography>
                {item?.product?.double &&
                  item?.flavors?.length > 0 &&
                  item?.flavors?.map((flavor, index) => (
                    <Typography
                      key={index}
                      variant="overline"
                      className={classes.overlineLineHeight}
                      paragraph
                      style={{ marginBottom: -5 }}
                    >
                      Stack {index + 1}: {flavor.name}
                    </Typography>
                  ))}
                {item.comment && (
                  <Grid className={classes.comment}>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{ fontWeight: 'bold' }}
                    >
                      Observação:
                    </Typography>
                    <Typography
                      paragraph
                      variant="overline"
                      className={classes.overlineLineHeight}
                    >
                      {item.comment}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>

          {order.delivery_address &&
            bairro &&
            getDeliveryType(order?.type_of_order) === 'Entrega' && (
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                >
                  BAIRRO:
                </Typography>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  {bairro}
                </Typography>
              </Grid>
            )}
          {order?.payment && (
            <Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                PAGAMENTO:
              </Typography>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
                style={{ fontWeight: 'bold' }}
              >
                {order?.payment && getPaymentType(order?.payment)}
              </Typography>
            </Grid>
          )}
          {order.final_comment && (
            <>
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  COMENTÁRIO
                </Typography>
              </Grid>
              <Typography
                variant="overline"
                className={classes.overlineLineHeight}
              >
                {`${order.final_comment}`}
              </Typography>
            </>
          )}
          {showExtraInfo && (
            <>
              <Grid>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                >
                  TIPO:{' '}
                </Typography>
                <Typography
                  variant="overline"
                  className={classes.overlineLineHeight}
                  style={{ fontWeight: 'bold' }}
                >
                  {`${getDeliveryType(order?.type_of_order)}`}
                </Typography>
              </Grid>

              {`${getDeliveryType(order?.type_of_order)}` === 'Delivery' && (
                <>
                  <Grid>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                    >
                      MOTORISTA:{' '}
                    </Typography>
                    <Typography
                      variant="overline"
                      className={classes.overlineLineHeight}
                      style={{ fontWeight: 'bold' }}
                    >
                      {`${
                        order?.gomotoboy_driver_name
                          ? order?.gomotoboy_driver_name
                          : 'Não selecionado'
                      }`}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </CardContent>
        {showDriverBtn && order.delivery_address && order.driver?.phone_number && (
          <>
            <CardActions>
              <Grid container justify="center">
                <Button size="small">
                  <a
                    target="blank"
                    href={getWhastappUrl(order, driverPhone, addressEncoded)}
                  >
                    <img src={Motorcycle} width="30" height="30" alt="Driver" />
                  </a>
                </Button>
              </Grid>
            </CardActions>
          </>
        )}

        <CardActions>
          <Grid container justify="space-between">
            {showBackBtn ? (
              disableButtons ? (
                <Button
                  size="small"
                  // disabled={currentOrderID === order.order_id}
                  onClick={setRevertKitchenStatus}
                >
                  <LeftIcon />
                </Button>
              ) : (
                <Button disabled>{''}</Button>
              )
            ) : (
              <Button disabled>{''}</Button>
            )}
            {showPrintBtn && (
              <Receipt
                order={order}
                // disabled={currentOrderID === order.order_id}
              />
            )}
            {showOKBtn ? (
              <Button
                size="small"
                // disabled={currentOrderID === order.order_id}
                onClick={setKitchenStatus}
              >
                <RightIcon />
              </Button>
            ) : (
              <Button disabled>{''}</Button>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Animate>
  );
};

export default Order;
