import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    withStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createClient } from 'redux/ducks/kitchen';
import { NETWORK } from 'settings';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    setNewClient: (client, clientData) => void
}
const GlobalCss = withStyles({
    '@global': {
        '.MuiDialog-paperWidthSm': {
            minWidth: '30vw'
        }
    }
})(() => null);

export default function ModalCreateClient(props: SimpleDialogProps) {
    const { onClose, open, setNewClient } = props;
    const [firstName, setFirstName] = React.useState('');
    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [phoneError, setPhoneError] = React.useState('');

    const dispatch = useDispatch();

    const handleClose = (result) => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        if (result) {
            setNewClient({ value: result.id, label: result.first_name + " " + result.last_name }, result)
        }
        onClose();
    };

    const checkForm = () => {
        let entered = false;
        if (firstName === '') {
            setFirstNameError('Please Enter first name');
            entered = true;
        }
        if (lastName === '') {
            setLastNameError('Please Enter last name');
            entered = true;
        }

        return entered;
    };

    const submitCreate = async () => {
        if (!checkForm()) {
            var payload = {

                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phone,
                network: NETWORK
            }
            if (payload.phone_number === "") delete payload.phone_number
            createClient(payload, dispatch).then((result) => {
                if (result.user) handleClose(result.user);
            }).catch(e => {
                console.log("error ", e)
            })
        }
    };

    return (
        <div>
            <GlobalCss />
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                style={{ minWidth: '30vw' }}
            >
                <DialogTitle id="simple-dialog-title">Create Client</DialogTitle>
                <Container maxWidth="md">
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                        error={firstNameError !== ''}
                        required
                    >
                        <InputLabel htmlFor="name-input">First Name</InputLabel>
                        <Input
                            id="name-input"
                            type="text"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setFirstNameError('');
                            }}
                        />
                        <FormHelperText>{firstNameError}</FormHelperText>
                    </FormControl>
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        error={lastNameError !== ''}
                        required
                        margin={'normal'}
                    >
                        <InputLabel htmlFor="name-input">Last Name</InputLabel>
                        <Input
                            id="name-input"
                            type="text"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                setLastNameError('');
                            }}
                        />
                        <FormHelperText>{lastNameError}</FormHelperText>
                    </FormControl>
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                        error={emailError !== ''}
                    >
                        <InputLabel htmlFor="name-input">Email</InputLabel>
                        <Input
                            id="name-input"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError('');
                            }}
                        />
                        <FormHelperText>{emailError}</FormHelperText>
                    </FormControl>
                    <FormControl
                        style={{
                            marginTop: '0px'
                        }}
                        fullWidth={true}
                        margin={'normal'}
                        error={phoneError !== ''}
                    >
                        <InputLabel htmlFor="name-input">Phone Number</InputLabel>
                        <Input
                            id="name-input"
                            type="text"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                setPhoneError('');
                            }}
                        />
                        <FormHelperText>{phoneError}</FormHelperText>
                    </FormControl>
                </Container>
                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <Button onClick={() => handleClose(0)}> Cancel </Button>
                    <Button onClick={submitCreate} color="primary">
                        Create
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
