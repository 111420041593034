import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { Product } from 'interfaces/stock';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Currency from 'utils/currency';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  menu_products?: Product[];
  products: Product[];
  selectedProducts?: Product[];
  setSelectedProducts?: any;
}

export default function EditProductsTable(props: IProps) {
  const classes = useStyles();
  const { products, selectedProducts, setSelectedProducts } = props;

  const handleProductOnPress = (selectedProduct: Product) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };
  const handleProductPressAll = () => {
    setSelectedProducts(products);
  };
  const handleProductUnPressAll = () => {
    setSelectedProducts([]);
  };
  const formatValue = (value) => {
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value)
  }
  const productPrice = (product) => {
    if (product.variation.length > 0) {
      return product.variation.map((el, idx) => (idx === 0 ? (<><Currency /> {formatValue(el.price)}</>) : (<>{"; "}<Currency /> {formatValue(el.price)}</>)))
    }
    return (<><Currency /> {formatValue(product.price)}</>)
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>

            <TableCell
              style={{
                padding: '5px',

              }}
              title="Select all"
            > <Tooltip title="Select All" arrow placement="top">
                {selectedProducts.length === products.length ? (
                  <CheckBoxIcon onClick={() => handleProductUnPressAll()} />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    onClick={() => handleProductPressAll()}
                  />
                )}
              </Tooltip>
            </TableCell>

            <TableCell style={{ padding: '5px' }}>Product Name</TableCell>
            <TableCell style={{ padding: '5px' }}>Category</TableCell>
            <TableCell style={{ padding: '5px' }}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((product) => (
            <TableRow key={product.product_id} hover>
              <TableCell
                component="th"
                scope="row"
                style={{ padding: '5px', width: '5%' }}
              >
                {selectedProducts.some(
                  (menu_product) =>
                    menu_product.product_id === product.product_id
                ) ? (
                  <CheckBoxIcon onClick={() => handleProductOnPress(product)} />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    onClick={() => handleProductOnPress(product)}
                  />
                )}
              </TableCell>
              <TableCell style={{ padding: '5px' }}>{product.name}</TableCell>
              <TableCell style={{ padding: '5px' }}>
                {product.category}
              </TableCell>
              <TableCell style={{ padding: '5px' }}>
                <Typography>

                  {productPrice(product)}
                </Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
