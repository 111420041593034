import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  checkAuthentication,
  checkAuthenticationWaiter,
} from 'redux/ducks/authentication';

import IStore from 'interfaces/store';
import {
  IAuthenticationState,
  ICredentials,
  ICredentialsWaiter
} from 'interfaces/authentication';
import axios from 'axios';
import {
  API_ENDPOINT,
  NETWORK,
  NETWORK_NAME
} from '../../settings';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@material-ui/core';

import { useStyles } from './styles';
import { sendAlert } from './../../redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';

import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import FieldText from '@react-form-fields/material-ui/components/Text';


import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PRIMARY_COLOR } from 'settings';
import Logo from 'helpers/logo';
export default function Authentication(): JSX.Element {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [network, setNetwork] = useState<any>("")
  const [success, setSuccess] = useState(false);
  const [networkError, setNetworkError] = useState(false)
  const listNetworks = ['2', '4', '5', '6', '8', '9', '10', '11', '15']
  const dispatch = useDispatch();
  const authenticationState = useSelector<IStore, IAuthenticationState>(
    (state) => state.authentication
  );
  const reduxNetworks = useSelector<any>(
    (state) => state["networks"]
  );
  const { fetch, authorized, account_stripe_KYC_status, stripe_profiles } =
    authenticationState;
  const store: string = localStorage.getItem('state');
  const state = JSON.parse(store);
  let new_user = false;
  if (state) {
    new_user = state.authentication.new_user;
  }

  const classes = useStyles();
  async function authenticate(isValid: boolean): Promise<void> {
    if (!network && NETWORK_NAME === "Network") {
      setNetworkError(true)
      dispatch(
        sendAlert(
          'Please insert the network you want to access',
          EAlertVariant.ERROR
        )
      );
      return
    }
    if (!email && !password) {
      dispatch(
        sendAlert(
          'Please insert your email and password to login',
          EAlertVariant.ERROR
        )
      );
    } else {
      if (
        /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
          email
        )
      ) {
        const credentials: ICredentials = { email: email.trim(), password };
        dispatch(checkAuthentication(credentials, network));
      } else {
        const credentials: ICredentialsWaiter = {
          username: email.trim(),
          password
        };
        dispatch(checkAuthenticationWaiter(credentials));
      }
    }
  }

  const forgetPasswordFN = async (e) => {
    e.preventDefault()
    setLoading(true);
    const request_url = `${API_ENDPOINT}users/api/clients/forgot_password/?network=${NETWORK}`;
    await axios.post(request_url, { email }).then(() => {
      setLoading(false);
      setMessage('We have sent you an email');
      setSuccess(true);
      setTimeout(() => {
        setForgotPassword(false);
      }, 1000);
    }).catch((e) => {
      console.log("error ", e)
      setLoading(false);
      setMessage("This email doesn't exist");
      setSuccess(false);
    })

  };
  console.log(`reduxNetworks["network"] -> ${reduxNetworks["network"]}`, reduxNetworks["network"].includes("6"), reduxNetworks["network"].includes("15"))
  if (authorized) {
    if ((reduxNetworks["network"].includes(6) || reduxNetworks["network"].includes(15))) {
      return <Redirect to="/networks" />;
    }
    if (new_user) {
      return <Redirect to="/on-boarding" />;
    } else {
      if (!account_stripe_KYC_status && NETWORK !== "6") {
        return <Redirect to="/stripe-kyc" />;
      }
      if (stripe_profiles?.account_connect_status === 'Deauthorized' && NETWORK !== "6") {
        return <Redirect to="/deauthorized" />;
      }
      return <Redirect to="/dashboard/order" />;
    }
  }
  /*
   const getEmailInfo = async () => {
     try {
       const response = await api({
         method: EMethod.GET,
         url: `/users/api/clients/owners/?email=${email}`,
       });
       console.log("response ", response)
     } catch (error) {
       console.log("error ", error)
     }
   }
  */
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {!forgotPassword ? (
        <div className={classes.paper}>
          <Logo styling={{ marginBottom: '50px', maxHeight: "120px", width: "400px" }} />
          <FormValidation onSubmit={(isValid) => authenticate(isValid)}>
            <FieldText
              inputProps={{ 'data-testid': 'email' }}
              variant="outlined"
              margin="normal"
              validation="required"
              type="email"
              fullWidth
              id="email"
              style={{ color: "black" }}

              color="primary"
              label="E-mail"
              name="email"
              value={email}
              onBlur={() => {
                if (email) {
                  setEmail(email.trim())
                  //getEmailInfo()
                };
              }}
              onChange={(email) => setEmail(email.trim())}
              autoFocus
            />

            <FieldText
              inputProps={{ 'data-testid': 'password' }}
              variant="outlined"
              margin="normal"
              validation="required"
              fullWidth
              style={{ color: "black" }}
              name="password"
              value={password}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(true)}
                      onMouseDown={() => setShowPassword(false)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={(password) => setPassword(password)}
              autoComplete="current-password"
            />
            {NETWORK_NAME === "Network" ?
              <FormControl error={networkError && !network} variant='outlined' style={{ marginTop: "10px" }} fullWidth>
                <InputLabel id="network-label" >Network</InputLabel>
                <Select label="Network" labelId="network-label" id="select" value={network} onChange={(e) => setNetwork(e.target.value)}>
                  {listNetworks.map(el => (
                    <MenuItem value={el}>{el}</MenuItem>

                  ))}
                </Select>
                {networkError && !network && <FormHelperText>The network field is required</FormHelperText>}
              </FormControl>
              : null}

            <Button
              data-testid="submit"
              type="submit"
              fullWidth
              //disabled={fetch}
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                backgroundColor:
                  email === null ||
                    email === '' ||
                    password === null ||
                    password === ''
                    ? '#BBBBBB'
                    : NETWORK_NAME === "Network" ? "black" : PRIMARY_COLOR()
              }}
            >
              {!fetch ? 'Sign in' : 'Loading...'}
            </Button>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={() => {
                setForgotPassword(true);
              }}
            >
              Forgot Password

            </div>
          </FormValidation>
        </div>
      ) : (
        <div className={classes.paper}>
          <Logo styling={{ width: "300px" }} />

          <FieldText
            inputProps={{ 'data-testid': 'email' }}
            variant="outlined"
            margin="normal"
            validation="required|email"
            type="email"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            value={email}
            onBlur={() => {
              if (email) setEmail(email.trim());
            }}
            onChange={(email) => setEmail(email)}
            autoFocus
          />
          <Button
            onClick={(e) => forgetPasswordFN(e)}
            data-testid="submit"
            type="submit"
            fullWidth
            disabled={fetch}
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              backgroundColor:
                email === null || email === '' ? '#BBBBBB' : PRIMARY_COLOR()
            }}
          >
            {loading ? 'Loading' : 'Send'}
          </Button>
          <div
            style={{
              color: success ? 'green' : 'red',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {message}
          </div>
        </div>
      )
      }
    </Container >
  );
}
