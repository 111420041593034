import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, CardContent } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  toolBarTitles: {
    color: 'rgb(0 0 0 / 54%) !important',
    fontWeight: 400,
    fontSize: 12
  },
  overlineLineHeight: {
    lineHeight: 1.6
  },
  button: {
    margin: '0 5px',
    color: '#124c4d',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
}));

export const CardWithValue = styled(Card)`
  background-color: #fdfdfd;
  min-width: 18%;
  display: flex;
  align-items: center;
  justifyContent-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardWithGraphic = styled(Card)`
  background-color: #fdfdfd;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justifyContent-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CardLabel = styled(Typography)`
  margin-bottom: 0;
`;

export const GraphicCardLabel = styled(Typography)`
  width: 100%;
  padding: 10px 20px;
  color: #4c4c4c;
  border-bottom: 1px solid #d1d1d1;
`;

export const CardBody = styled(CardContent)`
  padding-bottom: 16px !important;
`;

export const ColorLabel = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: ${(props) => (props.color ? props.color : '#124c4d')};
`;

export const PlusInfo = styled.span`
  color: #3fa336;
`;

export default useStyles;
