import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  ICepState,
  ETypesCep,
  ICepAction,
  ICepBase
} from 'interfaces/cep';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from '../../utils/query';

/* Authentication State. */
const initialState: ICepState = {
  fetch: false,
  next: '',
  previous: '',
  results: [],
  error: ''
};

/* Authentication Reducer. */
export default (
  state: ICepState = initialState,
  action: ICepAction
): ICepState => {
  switch (action.type) {
    case ETypesCep.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesCep.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesCep.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesCep.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchCep = (): IFetchAction => ({
  type: ETypesCep.FETCH
});

export const successCep = (payload: ICepState): ISuccessAction => ({
  type: ETypesCep.SUCCESS,
  payload
});

export const failureCep = (payload: string): IFailureAction => ({
  type: ETypesCep.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesCep.RESET
});

/* Authentication Side Effects Functions. */
export const getCep =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(fetchCep());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `/payment/api/cep/${query_url}`;
        let response;
        let results: ICepBase[] = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        response.results = results;
        dispatch(successCep(response));
      } catch (error) {
        dispatch(failureCep(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const createCep =
  (data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        var store;
        Object.keys(data).forEach((key) => {
          if (key === 'store')
            store = data[key]
        })
        await fetch({
          method: EMethod.POST,
          url: `payment/api/cep/`,
          data: data
        });
        dispatch(sendAlert('Cep criado com sucesso', EAlertVariant.SUCCESS));
        dispatch(getCep({ store: store }));
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const editCep =
  (id: number = undefined, data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        var store;
        Object.keys(data).forEach((key) => {
          if (key === 'store')
            store = data[key]
        })
        await fetch({
          method: EMethod.PATCH,
          url: `payment/api/cep/${id}/`,
          data: data
        });
        dispatch(sendAlert('Cep criado com sucesso', EAlertVariant.SUCCESS));
        dispatch(getCep({ store: store }));
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const editCepImport = (Cep_id: number = undefined, data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.PATCH,
      url: `products/api/Ceps/${Cep_id}/`,
      data: data
    })
    dispatch(sendAlert('Dados da loja selecionada importados', EAlertVariant.SUCCESS));
    dispatch(getCep())
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const editCepMenus = (Cep_id: number = undefined, data: {} = undefined): ThunkAction => async (
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.PATCH,
      url: `products/api/Ceps/${Cep_id}/`,
      data: data
    })
    dispatch(sendAlert('Menus da loja editados com sucesso', EAlertVariant.SUCCESS));
    dispatch(getCep())
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};

export const handleIsOwnCep =
  (Cep_id: number = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.POST,
          url: `products/api/Ceps/${Cep_id}/handle_is_own_Cep/`
        });
        dispatch(getCep());
        dispatch(
          sendAlert(
            'Loja usa stock matriz editado com sucesso',
            EAlertVariant.SUCCESS
          )
        );
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const deleteCep = (
  id: number = undefined, store_id: number = undefined
): ThunkAction => async (dispatch: Dispatch): Promise<boolean> => {
  try {
    await fetch({
      method: EMethod.DELETE,
      url: `payment/api/cep/${id}/`
    });
    dispatch(sendAlert('Bairro deletado com sucesso', EAlertVariant.SUCCESS));
    dispatch(getCep({ store: store_id }));
    return true;
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    return false;
  }
};
