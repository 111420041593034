import React, { useState } from 'react';
import {
    Button,
    InputLabel,
    Input,
    Container,
    Grid,
    FormControl,
    IconButton, Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import './../styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { createTag } from 'redux/ducks/stock';
export interface SimpleDialogProps {
    open: boolean;
    store?: number;
    onClose: () => void;
    fetchTags: () => void
}

export default function ModalCreateTag(props: SimpleDialogProps) {
    const { onClose, open, store, fetchTags } = props;
    const [name, setName] = useState('');
    const dispatch = useDispatch();

    const handleClose = () => {
        setName('');
        onClose();
    };

    const handleName = (event: any) => {
        setName(event.target.value);
    };
    const submitCreation = async () => {
        dispatch(
            createTag({
                name,
                color: "#fff",
                background: "#fff",
                store,
            })
        ).then((result: boolean) => {
            fetchTags()
            if (result) handleClose();
        });
    };
    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleClose()}
                >
                    <IconButton
                        style={{ width: '50px' }}
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography color="secondary">Close</Typography>
                </div>
                <h2>Add Tag</h2>
                <Container maxWidth="md">
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">Name</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={name}
                                onChange={handleName}
                            />
                        </FormControl>
                    </Grid>
                    {/* End of left column */}
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ marginBottom: '30px' }}
                                variant="outlined"
                                onClick={submitCreation}
                                color="primary"
                            >
                                {' '}
                                Create{' '}
                            </Button>
                        </div>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
}
