import {
  IKitchen,
  IKitchenState,
  IFetchAction,
  ISuccessAction,
  ICurrentOrderIDAction,
  ISetAction,
  IUnsetAction,
  IFailureAction,
  IKitchenAction,
  Dispatch,
  ThunkAction,
  ETypesKitchen,
  IUpdateAction,
  GetState
} from 'interfaces/kitchen';

import { EAlertVariant } from 'interfaces/alert';

import { EKitchenStatus } from 'enums/order';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { checkOrderStatus, unsetOldOrder, setNewOrder } from 'helpers/kitchen';

import UIfx from 'uifx';

import NotificationSound from 'assets/sounds/to-the-point.mp3';
import { mountQueryURL } from '../../utils/query';
import { NETWORK } from 'settings';

/* Kitchen State. */
const initialState: IKitchenState = {
  fetch: false,
  count: 0,
  currentOrderID: 0,
  results: [],
  todo: [],
  doing: [],
  done: [],
  baking: [],
  depot: [],
  transit: [],
  delivered: [],
  next: '',
  previous: '',
  error: ''
};

/* Kitchen Reducer. */
export default (
  state: IKitchenState = initialState,
  action: IKitchenAction
): IKitchenState => {
  switch (action.type) {
    case ETypesKitchen.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesKitchen.SUCCESS:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        todo: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.TODO
        ),
        doing: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DOING
        ),
        done: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DONE
        ),
        depot: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DEPOT
        ),
        baking: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.BAKING
        ),
        transit: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.TRANSIT
        ),
        delivered: action.payload.results.filter(
          (order) => order.status === EKitchenStatus.DELIVERED
        ),
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesKitchen.UPDATE_ORDER:
      const updatedResults = state.results.map((order) => {
        if (order.order_id === action.payload.order_id) {
          return action.payload;
        }
        return order;
      });
      return {
        ...state,
        results: updatedResults,
        todo: updatedResults.filter(
          (order) => order.status === EKitchenStatus.TODO
        ),
        doing: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DOING
        ),
        done: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DONE
        ),
        depot: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DEPOT
        ),
        baking: updatedResults.filter(
          (order) => order.status === EKitchenStatus.BAKING
        ),
        transit: updatedResults.filter(
          (order) => order.status === EKitchenStatus.TRANSIT
        ),
        delivered: updatedResults.filter(
          (order) => order.status === EKitchenStatus.DELIVERED
        )
      };
    case ETypesKitchen.CURRENT_ORDER_ID:
      return {
        ...state,
        currentOrderID: action.payload
      };
    case ETypesKitchen.SET:
      return {
        ...state,
        results: [...state.results, action.payload],
        currentOrderID: 0,
        [checkOrderStatus(action.payload.status)]: setNewOrder(
          action.payload,
          state[checkOrderStatus(action.payload.status)]
        )
      };
    case ETypesKitchen.UNSET:
      return {
        ...state,
        [checkOrderStatus(action.payload.last_status)]: unsetOldOrder(
          action.payload,
          state[checkOrderStatus(action.payload.last_status)]
        )
      };
    case ETypesKitchen.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
      case ETypesKitchen.RESET_FETCH:
      return {
        ...state,
        fetch: false,
      };
    default:
      return state;
  }
};

/* Kitchen Action Creators Functions. */
export const fetchKitchen = (): IFetchAction => ({
  type: ETypesKitchen.FETCH
});

export const resetFetchKitchen = (): IFetchAction => ({
  type: ETypesKitchen.FETCH
});

export const successKitchen = (payload: IKitchenState): ISuccessAction => ({
  type: ETypesKitchen.SUCCESS,
  payload
});

export const currentOrderIDKitchen = (
  payload: number
): ICurrentOrderIDAction => ({
  type: ETypesKitchen.CURRENT_ORDER_ID,
  payload
});

export const setKitchen = (payload: IKitchen): ISetAction => ({
  type: ETypesKitchen.SET,
  payload
});

export const unsetKitchen = (payload: IKitchen): IUnsetAction => ({
  type: ETypesKitchen.UNSET,
  payload
});

export const failureKitchen = (payload: string): IFailureAction => ({
  type: ETypesKitchen.FAILURE,
  payload
});

export const updateOrderOnKitchen = (payload: IKitchen): IUpdateAction => ({
  type: ETypesKitchen.UPDATE_ORDER,
  payload
});

/* Kitchen Side Effects Functions. */
export const getKitchen =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchKitchen());

      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';

      let url = `/orders/api/orders_resumed/${query_url}`;
      let response;
      let results: IKitchen[] = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      dispatch(successKitchen(response));
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const setOrderIsPaid =
  (order: IKitchen, data: {}): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/manual_payment/`,
        data
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setKitchenStatus =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/change_status/`
      });
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const setCanceledStatus =
  (order: IKitchen, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/cancel_order/`,
        data: data
      });
      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const refundOrder = async (order_id: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order_id}/refund_order/`
      });
      resolve(1);
    } catch (error) {
      console.log('error => ', error);
      reject(0);
    }
  });
};

export const setKitchenRevertStatus =
  (order: IKitchen): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(currentOrderIDKitchen(order.order_id));
      if ((order.status === 'Fazendo' || order.status === "A fazer") && order.payment === 'Invoice' && order.is_paid === true) await refundOrder(order.order_id);
      else {
        await fetch({
          method: EMethod.POST,
          url: `/orders/api/orders/${order.order_id}/revert_status/`
        });
      }
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const updateKitchen =
  (socket: any): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { kitchen } = getState();
      const { message } = socket;
      const newOrder: IKitchen = message;
      const checkOrders: IKitchen[] = kitchen.results.filter(
        (order: IKitchen) => {
          return order.order_id === newOrder.order_id;
        }
      );
      const status = ['todo', 'doing', 'done', 'depot'];
      const checkOrdersByStatus = status
        .map((stats) =>
          kitchen[stats].filter((order: IKitchen) => {
            return order.order_id === newOrder.order_id;
          })
        )
        .filter((ordersByStatus) => ordersByStatus.length > 0);
      if (checkOrders.length > 0 || checkOrdersByStatus.length > 0) {
        return dispatch(updateOrderOnKitchen(newOrder));
      }
      if (!newOrder.last_status && newOrder.status === EKitchenStatus.TODO) {
        const beep = new UIfx(NotificationSound);
        beep.play();
      }
      if (newOrder.last_status) dispatch(unsetKitchen(newOrder));
      if (newOrder.status === EKitchenStatus.COMPLETED) return null;
      dispatch(setKitchen(newOrder));
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const scheduleDeliveryDate =
  (orderId: number, date: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(orderId));
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${orderId}/create_or_update_date_delivery/`,
        data: { date_delivery: date }
      });
      await dispatch(
        sendAlert('The date was scheduled.', EAlertVariant.SUCCESS)
      );
      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
    //Should to improve the error response, in time of handlerError task
  };

export const updateDeliveryDate =
  (order_id: number, newOrder: any): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch(currentOrderIDKitchen(order_id));
      await fetch({
        method: EMethod.PATCH,
        url: `/orders/api/orders/${order_id}/`,
        data: newOrder
      });
      await dispatch(sendAlert('The date was changed.', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(failureKitchen(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
    //Should to improve the error response, in time of handlerError task
  };

export const sendPDF = async (file: any, id: number) => {
  try {
    var new_file = new File([file], 'invoice');
    const pdfFIle = new FormData();
    pdfFIle.append('file', new_file);
    var response = await fetch({
      method: EMethod.POST,
      url: `/orders/api/orders/${id}/add_invoice_pdf/`,
      data: pdfFIle
    });
    // dispatch(sendAlert('Invoice saved successfuly', EAlertVariant.SUCCESS));
    //window.location.reload();
    return response;
  } catch (error) {
    return null;
  }
};

export const createClient = (data: {}, dispatch): any => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      var response;
      if (data['email']) {
        var clientSearch = await fetch({
          method: EMethod.GET,
          url: `users/api/clients/?email=${data['email']}`
        });
        if (clientSearch.count < 1) {
          data['password'] = '12345678';
          response = await fetch({
            method: EMethod.POST,
            url: `/users/api/clients/register_by_email/`,
            data
          });
          dispatch(
            sendAlert('Client created successfuly', EAlertVariant.SUCCESS)
          );
          resolve(response);
        } else {
          dispatch(
            sendAlert(
              'This client already exists in the database',
              EAlertVariant.ERROR
            )
          );
          reject('This client already exists in the database');
        }
      } else {
        data['email'] = null;
        if (data['phone_number'] === '') delete data['phone_number'];
        response = await fetch({
          method: EMethod.POST,
          url: `users/api/clients/guest_user/`,
          data
        });
        dispatch(
          sendAlert('Client created successfuly', EAlertVariant.SUCCESS)
        );
        resolve(response);
      }
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      reject(error);
    }
  });
};
export const getOrdersToDo = async (store) => {
  var response = await fetch({
    method: EMethod.GET,
    url: `/waiter/api/waiter/get_orders_network/?network=${NETWORK}&store=${store}&status=A fazer`
  });
  return response;
};

export const sendGiftCards = async (data: { file: string; code: string }[]) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      data.map(async (el, idx) => {
        var new_file = new File([el.file], 'giftcard');
        const pdfFIle = new FormData();
        pdfFIle.append('file', new_file);
        pdfFIle.append('code', el.code);

        await fetch({
          method: EMethod.POST,
          url: `/orders/api/giftcard/add_giftcard_pdf/`,
          data: pdfFIle
        });
        if (idx === data.length - 1) resolve();
      });
    } catch (error) {
      reject();
    }
  });
};

export const addTrackingNumber = async (order: IKitchen, data:{delivery_tracking_number: string, delivery_company:string}) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/delivery_tracking_number/`,
        data
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteOrder = async (order: IKitchen, dispatch) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      dispatch(fetchKitchen());

      await fetch({
        method: EMethod.POST,
        url: `/orders/api/orders/${order.order_id}/cancel_delete/`,
      });
      resolve();
      dispatch(resetFetchKitchen());

    } catch (error) {
      dispatch(resetFetchKitchen());
      reject(error);
    }
  });
};

