import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Product, Ingredients, Tags, ICategory } from 'interfaces/stock';
import ModalCreateProduct from './ModalCreateProduct';
import ModalEditProduct from './ModalEditProduct';
import ModalDeleteProduct from './ModalDeleteProduct';
import ModalAddProduct from './ModalAddProduct';
import { Button, IconButton, InputBase, Modal, Fade, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
// import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import './styles.css';
import { NETWORK, PRIMARY_COLOR, WEB_URL } from 'settings';
import RelatedProducts from './AddRelatedProducts';
// import i18n from '../i18n/i18n';
import ShareIcon from '@material-ui/icons/Share';
import { sendAlert } from 'redux/ducks/alert';
import { useDispatch } from 'react-redux';
import { EAlertVariant } from 'interfaces/alert';
import qrCode from 'assets/img/qr-code.png'
import { fetch } from 'utils/request';
import ModalResevedProducts from './ModalReservedProducts';
import { fetchAllArtists } from 'redux/ducks/stock'
import { createProduct } from 'redux/ducks/stock';
import Currency from '../../utils/currency'
const useStyles = makeStyles({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: '15px'
  },
  input: {
    flex: 1,
    paddingLeft: '8px'
  },
  iconButton: {
    padding: 10
  },
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

interface IProps {
  products: Product[];
  ingredients: Ingredients[];
  tags: Tags[];
  categories: ICategory[];
  store: number;
  more: () => void;
  hasMore: boolean;
  reset: () => void;
  searchData: (data: string) => void;
  storeSlug: any;
  loading: boolean;
  currentPage: number
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();
  const { products, more, reset, searchData, storeSlug, store, tags, loading, currentPage } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(undefined);
  const [search, setSearch] = React.useState('');
  const [relatedModal, setRelatedModal] = React.useState(false);
  const [qrcode, setQrcode] = React.useState('');
  const [qrcodeOpen, setQrcodeOpen] = React.useState(false);
  const [modalReservedOpen, setModalReservedOpen] = React.useState(false)
  const [modalReservedSelected, setModalReservedSelected] = React.useState(undefined)
  const [artists, setArtists] = React.useState<{ artist_id: number, name: string }[]>([])
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };
  const dispatch = useDispatch();
  const handleHasMore = () => {
    if (((currentPage + 1) * 50) < products.length) return true
    return false
  }
  const handleShowProduct = (idx) => {

    if (((currentPage + 1) * 50) > idx) return true
    return false
  }

  const handleClose = () => {
    //if (product) updateProductList(product)
    setOpen(false);
    setOpenAddProduct(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setSelectedProduct(undefined);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  // const handleClickOpenAddProduct = (product: Product) => {
  //   setSelectedProduct(product);
  //   setOpenAddProduct(true);
  // };

  const handleClickOpenEdit = (product: Product) => {
    setOpenEdit(true);
    setSelectedProduct(product);
  };

  const handleClickOpenDelete = (product: Product) => {
    setOpenDelete(true);
    setSelectedProduct(product);
  };
  const getQRCode = async (product_id) => {
    const x = await fetch({
      url: `/products/api/products/${product_id}/generate_qr_to_product/?network_id=${NETWORK}&store_id=${props.store ? props.store : 0}`,
      method: 'POST'
    });
    setQrcode(x.link);
    setQrcodeOpen(true);
    // console.log(qrcode);
  };
  const getArtists = () => {
    fetchAllArtists().then((response: any) => {
      setArtists(response)
    })
  }
  useEffect(() => {
    getArtists()
  }, [])

  const copyURL = (slug) => {
    switch (NETWORK) {
      case "6":
        return WEB_URL() + `?product=${slug}`
      case "15":
        return WEB_URL() + `?product=${slug}`

      default:
        return `${WEB_URL()}/shop/${storeSlug}/${slug}`
        break;
    }
  }
  const generateNewSlug = (slug) => {
    var parts = slug.split("-");
    var newSlug = ""
    const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (nums.includes(parts[parts.length - 1])) {
      parts.map((part, idx) => {
        if (idx === parts.length - 1) {
          newSlug = newSlug + "-" + (Number(part) + 1)

        } else {
          newSlug = newSlug + "-" + part

        }
      })
    } else {
      newSlug = slug + '-1'
    }
    return newSlug;
  }

  const duplicateProduct = (order) => {

    var payload = {
      image: order.image,
      name: order.name,
      price: order.price,
      stock: order.stock,
      description: order.description,
      allergy_notes: order.allergy_notes,
      category: order.category,
      measure: order.measure,
      slug: generateNewSlug(order.slug),
      video: order.video,
      store: store,
      ingredients: order.ingredients,
      extras: order.extras,
      related_products: order.related_products,
      size: order.sizes,
      artist: order.artist,
      origin: order.origin,
      product_sheet: order.product_sheet,
      colour_hex: order.colour_hex,
      network: parseInt(NETWORK),
      product_images: order.product_images,
      material: order.material,
      cost: order.cost,
    }
    if (NETWORK !== "6") {
      delete payload.product_sheet
      delete payload.size
      delete payload.colour_hex
      delete payload.artist
      delete payload.material
      delete payload.cost
    }

    if (!payload.product_sheet) {
      delete payload.product_sheet
    }
    dispatch(
      createProduct(payload)
    ).then((result: boolean) => {
      if (result) {
        reset();
      }
    });
  }
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Button
          style={{
            fontWeight: 'bold',
            fontSize: 12,
            margin: '10px 10px',
            height: '35px'
          }}
          color="primary"
          onClick={handleClickOpen}
          className={classes.activeButton}
        >
          Add Product
        </Button>
        {/*props.store !== 0 && (
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: 12,
              margin: '10px 10px',
              height: '35px'
            }}
            color="primary"
            onClick={handleClickOpen}
            className={classes.activeButton}
          >
            Add Product
          </Button>
          )*/}
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search product"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={search}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                searchData(search);
              }
            }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => {
              searchData(search);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "15px 0px" }}><CircularProgress /></div> :
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '5px' }}>Name</TableCell>
              {/* {props.store !== 0 && (
              // <TableCell align="left" style={{ padding: '5px' }}>
              //   <div style={{ display: 'flex', flexDirection: 'column' }}>
              //     <span>Produce</span>
              //   </div>
              // </TableCell>
            )} */}
              {NETWORK === "6" || NETWORK === "15" ? <TableCell align="left" style={{ padding: '5px' }}>
                Pic
              </TableCell> : null}
              {NETWORK === "6" && <TableCell align="left" style={{ padding: '5px' }}>
                Color
              </TableCell>}
              {props.store !== 0 && (
                <TableCell align="left" style={{ padding: '5px' }}>
                  Related products
                </TableCell>
              )}
              <TableCell align="left" style={{ padding: '5px' }}>
                Sold today
              </TableCell>
              <TableCell
                className="hide-on-mobile-table"
                align="left"
                style={{ padding: '5px' }}
              >
                {NETWORK === "6" || NETWORK === "15" ? "Pre-ordered" : "Reserved (next. 6 days)"}
              </TableCell>

              <TableCell align="left" style={{ padding: '5px' }}>
                Stock
              </TableCell>
              <TableCell align="left" style={{ padding: '5px' }}>
                Price
              </TableCell>
              {/* <TableCell align="right" style={{ padding: '5px' }}>
              Cost
            </TableCell> */}
              {/* <TableCell align="right" style={{ padding: '5px' }}>
              Profit %
            </TableCell> */}
              {/* <TableCell align="right" style={{ padding: '5px' }}>
              CMV %
            </TableCell> */}
              {props.store !== 0 && <TableCell
                align="left"
                style={{
                  padding: '5px',
                }}
              >
                Actions
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{products?.map((product, idx) => (
            <>
              {handleShowProduct(idx) ? <TableRow key={product.product_id}>
                <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                  {product.name ? product.name : ''}
                </TableCell>
                {NETWORK === "6" || NETWORK === "15" ? <TableCell align="left" component="th" scope="row" style={{ padding: '5px' }}>
                  {product.image ? <img src={product.image} alt="" height={45} width={45} /> : <div style={{ width: 45, height: 45 }} />}
                </TableCell> : null}
                {NETWORK === "6" && <TableCell align="left" component="th" scope="row" style={{ padding: '5px' }}>
                  <div style={{ backgroundColor: product.colour_hex, borderRadius: "999px", height: "20px", width: "20px" }} />
                </TableCell>}
                {props.store !== 0 && (
                  <TableCell align="left" style={{ padding: '5px' }}>
                    <IconButton
                      onClick={() => {
                        setRelatedModal(true);
                        setSelectedProduct(product);
                      }}
                    >
                      <AddIcon style={{ cursor: 'pointer' }} />{' '}
                    </IconButton>
                  </TableCell>
                )}
                {/* {props.store !== 0 && (
                <TableCell align="left" style={{ padding: '5px' }}>
                  <div style={{ color: '#124c4d', display: 'flex' }}>
                    <ControlPointIcon
                      onClick={() => handleClickOpenAddProduct(product)}
                    />
                  </div>
                </TableCell>
              )} */}
                <TableCell align="left" style={{ padding: '5px' }}>
                  {product.sold ? product.sold : 0}
                </TableCell>
                <TableCell
                  className="hide-on-mobile-table"
                  align="left"
                  onClick={() => {
                    setModalReservedSelected(product)
                    setModalReservedOpen(true)
                  }}
                  style={{
                    padding: props.store === 0 ? '5px' : '19px 5px 19px 5px',
                    cursor: "pointer"
                  }}
                >
                  {product.reserved ? product.reserved - product.sold : 0}
                </TableCell>

                <TableCell align="left" style={{ padding: '5px' }}>
                  <div
                  >
                    <span>
                      {product.stock} {' '}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="left" style={{ padding: '5px' }}>
                  <Typography>
                    <Currency />
                    {new Intl.NumberFormat('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(product.variation.length > 0 ? product.variation[0].price : product.price)}
                  </Typography>
                </TableCell>
                {/* <TableCell align="right" style={{ padding: '5px' }}>
                {product.cost
                  ? product.cost.toFixed(2)
                    ? product.cost.toFixed(2)
                    : 0
                  : 0}
              </TableCell> */}
                {/* <TableCell align="right" style={{ padding: '5px' }}>
                {product.price && product.cost
                  ? (
                      ((product.price - product.cost) / product.price) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </TableCell> */}
                {/* <TableCell align="right" style={{ padding: '5px' }}>
                {product.price && product.cost
                  ? ((product.cost / product.price) * 100).toFixed(2)
                  : 0}
                %
              </TableCell> */}
                {props.store !== 0 && (
                  <TableCell align="left" style={{ padding: '5px' }}>

                    <IconButton style={{ marginLeft: "-14px" }} onClick={() => {
                      navigator.clipboard.writeText(copyURL(product.slug))
                      dispatch(
                        sendAlert(
                          'Product URL copied to clipboard',
                          EAlertVariant.INFO
                        )
                      );
                    }}>
                      <ShareIcon />
                    </IconButton>
                    <IconButton onClick={() => getQRCode(product.product_id)}><img style={{ height: "20px", width: "20px" }} src={qrCode} /></IconButton>
                    <IconButton onClick={() => handleClickOpenEdit(product)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleClickOpenDelete(product)}><DeleteIcon /></IconButton>
                    {NETWORK === "6" ? <Tooltip title="Duplicate title">
                      <IconButton onClick={() => duplicateProduct(product)}><FileCopyIcon /></IconButton>
                    </Tooltip> : null}

                  </TableCell>
                )}
              </TableRow> : null}

            </>

          ))}
          </TableBody>

        </Table>
      }
      <ModalAddProduct
        open={openAddProduct}
        onClose={handleClose}
        product={selectedProduct}
        store={props.store}
      />
      <ModalCreateProduct
        tags={tags}
        ingredients={props.ingredients}
        categories={props.categories}
        products={products}
        store={props.store}
        open={open}
        onClose={handleClose}
        reset={reset}
        storeSlug={storeSlug}
        artists={artists}
        getArtists={() => getArtists()}
      />
      <RelatedProducts
        open={relatedModal}
        onClose={() => {
          setSelectedProduct(null);
          setRelatedModal(false);
        }}
        product={selectedProduct}
        products={products}
        reset={() => reset()}
      />
      <ModalEditProduct
        open={openEdit}
        ingredients={props.ingredients}
        categories={props.categories}
        store={props.store}
        products={products}
        product={selectedProduct}
        onClose={handleClose}
        reset={() => reset()}
        storeSlug={storeSlug}
        artists={artists}
        tags={tags}
        getArtists={() => getArtists()}
      />
      <ModalDeleteProduct
        open={openDelete}
        product={selectedProduct}
        onClose={handleClose}
        reset={reset}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={qrcodeOpen}
        onClose={() => setQrcodeOpen(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={qrcodeOpen}>
          <div>
            <img alt="QR Code" src={qrcode} width="200" height="200" />
          </div>
        </Fade>
      </Modal>
      <ModalResevedProducts open={modalReservedOpen} onClose={() => {
        setModalReservedOpen(false)
        setModalReservedSelected(undefined)
      }}
        product={modalReservedSelected} />
      {handleHasMore() && products.length > 0 && !loading && (
        <div
          style={{
            color: 'grey',
            fontSize: '1.2vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            more();

          }}
        >
          Load more items
        </div>
      )}
    </TableContainer>
  );
}
