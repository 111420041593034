import { IAlertAction } from "./alert";
import IBaseAction from "./redux";


export interface ICepBase {
    id: number;
    neighborhood: string;
    price: number;
    store?: {
        id: number,
        name: string,
    };
}

export interface ICepState {
    fetch: boolean;
    next: string;
    previous: string;
    results: ICepBase[];
    error: string;
}

/**
 * Redux types.
 */
export enum ETypesCep {
    FETCH = 'Cep/FETCH',
    SUCCESS = 'Cep/SUCCESS',
    FAILURE = 'Cep/FAILURE',
    RESET = 'Cep/RESET'
}

export interface IFetchAction extends IBaseAction<ETypesCep, null> {
    type: ETypesCep.FETCH;
}

export interface ISuccessAction
    extends IBaseAction<ETypesCep, ICepState> {
    type: ETypesCep.SUCCESS;
    payload: ICepState;
}

export interface IFailureAction extends IBaseAction<ETypesCep, string> {
    type: ETypesCep.FAILURE;
    payload: string;
}


export interface IResetAction extends IBaseAction<ETypesCep, null> {
    type: ETypesCep.RESET;
}

export type ICepAction =
    | IFetchAction
    | ISuccessAction
    | IFailureAction
    | IResetAction;

export type GetState = () => any;

export type PromiseAction = Promise<ICepAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
    action:
        | ICepAction
        | ThunkAction
        | PromiseAction
        | Array<ICepAction>
        | IAlertAction
) => any;