import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { IHistory } from 'interfaces/history';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import moment from 'moment';
import { IconButton, TableContainer, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import Currency from 'utils/currency';
import formatDate from 'date-fns/format';
// import ModalCreateInvoice from './ModalCreateInvoice';
import { PRIMARY_COLOR } from 'settings';
import Receipt from 'helpers/Receipt';
import { Delete } from '@material-ui/icons';
import DeleteOrderModal from './DeleteOrder'
const useStyles = makeStyles({
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  table: {
    minWidth: 650
  },
  tableCell: {
    padding: '5px',
    textAlign: 'center',
    fontSize: 10.3
  },
  tableCellIdOrder: {
    width: '135px',
    textAlign: 'center',
    padding: '5px',
    fontSize: 10.3
  },
  tableCellNameCliente: {
    width: '120px',
    textAlign: 'center',
    padding: '5px',
    fontSize: 10.3
  },
  tableCellData: {
    width: '200px',
    textAlign: 'center',
    padding: '5px',
    fontSize: 10.3
  },
  tableCellStripe: {
    width: '30px',
    textAlign: 'center',
    padding: '5px',
    fontSize: 10.3
  },
  tableCellPaid: {
    width: '60px',
    textAlign: 'center',
    padding: '5px',
    fontSize: 10.3
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  dataGrid: {
    '& .MuiButton-label': {
      color: PRIMARY_COLOR()
    }
  },
  hideOnMobile: {
    '@media screen and (max-width: 600px)': {
      display: 'none'
    },
    '@media screen and (min-width: 600px)': {
      display: 'block'
    }
  }
});

interface IProps {
  orders: IHistory[];
  initialDate: Date;
  finalDate: Date;
  isFiltering: boolean;
  filter: any;
  setInitialDate: any;
  setFinalDate: any;
  message: string;
  getOrders: () => void;
}

export default function HistoryTable(props: IProps) {
  const classes = useStyles();
  const {
    orders,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    message,
    getOrders
  } = props;
  // const [createInvoiceOpen, setInvoiceOpen] = React.useState(false);
  const [deleteOrder, setDeleteOrder] = useState<IHistory | undefined>(undefined)
  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const filterMessage = () => {
    const style = {
      color: PRIMARY_COLOR(),
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };
  const translateStatus = (status) => {
    switch (status) {
      case "A fazer":
        return "To do"
      case "Fazendo":
        return "Doing"

      default:
        return status
    }
  }
  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return editOrderColumn(params.row.id);
      }
    },
    {
      field: 'id',
      headerName: 'Order ID',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return orderIdColumn(params.row.id);
      }
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return emailColumn(params.row.email);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'payment',
      headerName: 'Payment',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Agendado',
      headerName: 'Schedule',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Valor',
      headerName: 'Value',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return valueColumn(params.row.id)
      }
    },
    {
      field: 'Paid',
      headerName: 'Paid?',
      width: 120,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Solicitado',
      headerName: 'Doing',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Feito',
      headerName: 'Done',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'caminho',
      headerName: 'On the way',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Entregue',
      headerName: 'Delivered',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Completo',
      headerName: 'Complete',
      type: 'date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    }
  ];
  let rows: Array<any>;
  // useEffect(() => {
  if (orders) {
    rows = orders?.map((order) => ({
      id: order.order_id,
      client: order.client ? order.client?.name : order.owner?.name,
      email: order?.owner?.user ? order.owner.user.email : "",
      payment: order.payment === 'none' ? 'Incomplete' : order.payment,
      status: translateStatus(order.status),
      Agendado: order?.date_delivery
        ? formatDate(new Date(order?.date_delivery), 'dd/MM/yyyy - hh:mm:ss')
        : null,
      Valor: order.total_order.toFixed(2) ? order.total_order.toFixed(2) : '',
      Paid: order.is_paid ? 'Yes' : 'No',
      Solicitado: order?.date_ordered
        ? formatDate(new Date(order?.date_ordered), 'dd/MM/yyyy - hh:mm:ss')
        : null,
      Feito: order?.done_at
        ? formatDate(new Date(order?.done_at), 'dd/MM/yyyy - hh:mm:ss')
        : null,
      caminho: order?.transit_at
        ? formatDate(new Date(order?.transit_at), 'dd/MM/yyyy - hh:mm:ss')
        : null,
      Entregue: order?.delivered_at
        ? formatDate(new Date(order?.delivered_at), 'dd/MM/yyyy - hh:mm:ss')
        : null,
      Completo: order?.finished_at
        ? formatDate(new Date(order?.finished_at), 'dd/MM/yyyy - hh:mm:ss')
        : null
    }));
  } else {
    rows = [
      {
        id: '',
        client: '',
        payment: '',
        Agendado: '',
        Valor: '',
        Pago: '',
        Solicitado: '',
        Feito: '',
        caminho: '',
        Entregue: '',
        Completo: ''
      }
    ];
  }

  // }, [orders]);
  const orderIdColumn = (id) => {
    var order = orders.find(order => (order.order_id === id))
    return <Receipt trigger={<Typography>{order.order_code}</Typography>} order={order} />
  }
  const emailColumn = (email) => {
    return <a href={`mailto:${email}`}>{email}</a>
  }
  const valueColumn = (id) => {
    var order = orders.find(order => (order.order_id === id))
    return <Typography><Currency /> {order.total_order.toFixed(2)}</Typography>
  }
  const editOrderColumn = (id) => {
    var order = orders.find(order => (order.order_id === id))
    if (order.status === "A fazer") {
      return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <a href={`/dashboard/comercialInvoice/?edit=${id}`}>
            <Button variant='outlined'><Typography>Edit</Typography></Button>
          </a>
          <IconButton onClick={() => setDeleteOrder(order)} >
            <Delete />
          </IconButton>
        </div>)
    }
    else return (
      <IconButton onClick={() => setDeleteOrder(order)} >
        <Delete />
      </IconButton>
    )
  }
  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 15,
            padding: 15,
            backgroundColor: '#f5f5f5',
            borderRadius: 5
          }}
        >
          <div style={{
            display: 'inline-flex', alignItems: 'flex-end',
            width: "100%",
            flexWrap: "wrap"
          }}>
            <Grid
              style={{
                alignItems: 'center',
                display: 'inline-flex',
                flexWrap: "wrap"
              }}
              container
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item lg={5} md={5} sm={5}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Initial Date</span>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-initial"
                      value={initialDate}
                      placeholder="                ---------------"
                      onChange={handleInitalDateChange}
                      autoOk={true}
                      style={{ width: 189, marginRight: 5 }}
                    />
                  </div>
                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Final Date</span>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-final"
                      margin="none"
                      value={finalDate}
                      onChange={handleFinalDateChange}
                      autoOk={true}
                      style={{ width: 189 }}
                    />
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <div style={{ display: 'inline-flex', marginLeft: 5 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  marginLeft: 5,
                  fontSize: '0.8em',
                  padding: '5px 16px',
                  marginTop: "10px"
                }}
                onClick={filter}
              >
                Filter
              </Button>
            </div>
          </div>
          <div className={classes.hideOnMobile}>
            <PrintIcon
              onClick={() => {
                const _initialDate = initialDate
                  ? moment(initialDate).format('YYYY-MM-DD')
                  : null;
                const _finalDate = finalDate
                  ? moment(finalDate).format('YYYY-MM-DD')
                  : null;
                window.open(
                  `https://shnonco.gorealtime.io/waiter/generate_csv_of_orders_for_between_dates/?starts=${_initialDate}&ends=${_finalDate}`
                );
              }}
            />
          </div>

          {filterMessage()}
          <div style={{ height: 700, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableDensitySelector
              className={classes.dataGrid}
              components={{
                Toolbar: GridToolbar
              }}
            />
          </div>
          {/* <Button
            variant="outlined"
            color="primary"
            style={{ width: 'fit-content' }}
            onClick={() => {
              setInvoiceOpen(true);
            }}
          >
            Criar pedido
          </Button> */}
        </div>
      </TableContainer>
      <DeleteOrderModal open={deleteOrder ? true : false} onClose={() => setDeleteOrder(undefined)} order={deleteOrder} refresh={getOrders} />
    </>
  );
}
