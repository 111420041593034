import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NETWORK, SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';

import { getKitchen, updateKitchen } from 'redux/ducks/kitchen';
import { getStore } from 'redux/ducks/store';

import IStore from 'interfaces/store';
import { IStoreState, IStoreBase } from 'interfaces/store_test';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import Select from 'react-select';
import Card from '@material-ui/core/Card';
import './styles.css';
import Animate from 'components/Common/Animate';
import Badge from '@material-ui/core/Badge';
import { Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { editStore } from 'redux/ducks/store';
import { PRIMARY_COLOR } from 'settings';
import { setCurrency } from 'redux/ducks/currency';
import PdfFile from './../../helpers/Receipt/GiftCard/pdf'
import { pdf } from '@react-pdf/renderer';
import PetshopReceipt from 'helpers/Receipt/petshopReceitPDF';
import { sendGiftCards, sendPDF } from 'redux/ducks/kitchen';
//import MangelReceipt from 'helpers/Receipt/mangelReceipt';
var storeFilter: { id: number; name: string }[] = [];

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: PRIMARY_COLOR(),
    boxShadow: 'none',
    ':hover': {
      borderColor: PRIMARY_COLOR()
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
  })
};
export default function Kitchen() {
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = PRIMARY_COLOR();
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-order');
    if (d.item(0)) {
      d.item(0).style.color = '#ff5a23';

      d.item(1).style.color = '#ff5a23';
    }
  });

  const dispatch = useDispatch();
  const { fetch, todo, doing, done, depot, baking, transit, delivered } =
    useSelector<IStore, IKitchenState>((state) => state.kitchen);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  const { authentication } = useSelector<IStore, IStore>((state) => state);
  storeFilter = [];
  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  if (authentication.is_superuser) {
    storeFilter.push({ id: 0, name: 'ALL' });
  }

  const classes = useStyles();

  const [online, setOnline] = useState(false);
  const [filteredTodo, setFilteredTodo] = useState(todo);
  const [filteredDoing, setFilteredDoing] = useState(doing);
  const [filteredDone, setFilteredDone] = useState(done);
  //  const [filteredDepot, setFilteredDepot] = useState(depot);
  const [filteredTransit, setFilteredTransit] = useState(transit);
  const [filteredCompleted, setFilteredCompleted] = useState(delivered);
  const [firstRender, setFirstRender] = useState(true);
  //const [activeDateFilter, setActiveDateFilter] = useState('ALL');
  const [activeStoreFilter, setActiveStoreFilter] = useState(
    authentication.is_superuser ? 0 : results[0]
  );
  const [selectedStore, setSelectedStore] = useState<IStoreBase>({
    id: 0,
    name: '',
    menus: [],
    is_own_store: false,
    force_close: false,
    slug: 'string',
    categories: [],
    ingredients: [],
    own_delivery: false,
    currency: "",
    address: {
      city: "",
      country: "",
      neighborhood: '',
      street: "",
      street_number: "",
      zipcode: "",
    }
  });
  /* const handleActiveDateFilter = (value: string) => {
    setActiveDateFilter(value);
  }; */

  /**const getWeekDates = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const numDay = now.getDate();

    const start = new Date(now);
    start.setDate(numDay - dayOfWeek + 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(23, 59, 59, 59);

    return [start, end];
  }; */

  /**  const getTodayDates = () => {
    const now = new Date();

    const start = new Date(now);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setHours(23, 59, 59, 59);

    return [start, end];
  }; */
  useEffect(() => {
    if (firstRender && results.length > 0) {
      dispatch(setCurrency(results[0].currency))
      setActiveStoreFilter(results[0].id);
      setFirstRender(false);
    }
  }, [results]);
  useEffect(() => {
    dispatch(getStore());
  }, [dispatch, activeStoreFilter]);

  useEffect(() => {
    if (typeof activeStoreFilter === 'number') {
      var params = {
        date_delivery: 'ALL',
        a_fazer: true,
        fazendo: true,
        feito: true,
        depot: true,
        transit: true,
        delivered: true,
        baking: true,
        store: activeStoreFilter,
        network: NETWORK
      };
      if (activeStoreFilter !== 0 || !authentication.is_superuser) {
        delete params.network;
      }
      if (authentication.is_superuser) {
        params.network = NETWORK;
      }
      if (activeStoreFilter === 0) {
        delete params.store;
      }
      dispatch(getKitchen(params));
    }
  }, [dispatch, activeStoreFilter]);

  //Find a way to find the store_id in the storeFilter list
  const isStoreIncluded = (store) => {
    var included = false
    storeFilter.map((el) => {
      if (el.name === store) included = true
    })
    return included
  }

  const generateOrderPDF = async (order) => {
    const doc = (
      <PetshopReceipt order={order}
      />
    );
    await pdf(doc)
      .toBlob()
      .then((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          sendPDF(base64data, order.order_id)
        };
      })
      .catch((e) => {
        console.log(e);
      });



  }



  // const generateOrderPDF = async (order) => {
  //   switch (NETWORK) {
  //     case "6":
  //       const doc1 = <PetshopReceipt order={order} />;
  //       await pdf(doc1)
  //         .toBlob()
  //         .then((blob) => {
  //           var reader = new FileReader();
  //           reader.readAsDataURL(blob);
  //           reader.onloadend = function () {
  //             var base64data = reader.result;
  //             sendPDF(base64data, order.order_id);
  //           };
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //         });
  //       break; 
  
  //     case "15":
  //       const doc2 = <MangelReceipt order={order} />;
  //       await pdf(doc2)
  //         .toBlob()
  //         .then((blob) => {
  //           var reader = new FileReader();
  //           reader.readAsDataURL(blob);
  //           reader.onloadend = function () {
  //             var base64data = reader.result;
  //             sendPDF(base64data, order.order_id);
  //           };
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //         });
  //       break; 
  
  //     default:
        
  //       break;
  //   }
  // };
  

  const generategiftCardPdf = async (bought_giftcards) => {
    try {
      if (bought_giftcards) {
        const filesList = []
        await bought_giftcards.map(async (el, idx) => {
          if (!el.sent) {
            const doc = (
              <PdfFile value={el.value} code={el.code}
              />
            );
            await pdf(doc)
              .toBlob()
              .then((blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  var base64data = reader.result;
                  filesList.push({ code: el.code, file: base64data })
                  if (idx === bought_giftcards.length - 1) {
                    sendGiftCards(filesList)
                  }
                };
              })
              .catch((e) => {
                console.log(e);
              });
          }

        })
      }
    } catch (e) {
      console.log("error ", e)
    }
  }
  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => setOnline(true);

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data);
      if (new_data.message.order_id) {
        if (isStoreIncluded(new_data.message.store.name)) {
          if (activeStoreFilter !== 0) {
            if (new_data && new_data.message.store_id === activeStoreFilter) {
              dispatch(updateKitchen(JSON.parse(event.data)));
            }
          } else if (activeStoreFilter === 0) {
            if (!authentication.is_superuser) {
              try {
                dispatch(updateKitchen(JSON.parse(event.data)));
              } catch (err) { }
            } else dispatch(updateKitchen(JSON.parse(event.data)));
          }
          if (new_data.message.status === "Fazendo") {
            generategiftCardPdf(new_data.message.bought_giftcards)
            setTimeout(() => {
              if (!new_data.message.invoice_pdf)
                generateOrderPDF(new_data.message)
            }, 30000);
          }
        }
      }
    };

    kitchenSocket.onerror = (e) => {
      console.log("error on the websocket ", e)
      kitchenSocket.close()
    };

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, dispatch, activeStoreFilter]);

  useEffect(() => {
    const [start, end] = [null, null];
    /* const [start, end] =
      activeDateFilter === EDateFilter.TODAY
        ? getTodayDates()
        : activeDateFilter === EDateFilter.WEEK
        ? getWeekDates()
        : [null, null]; */
    function filterDates(order: IKitchen) {
      if (start && end) {
        return (
          (new Date(order.date_ordered) >= start &&
            new Date(order.date_ordered) <= end) ||
          (new Date(order.date_delivery) >= start &&
            new Date(order.date_delivery) <= end)
        );
      } else {
        return order;
      }
    }

    const toDofilter = todo.filter((order) => filterDates(order));
    setFilteredTodo(toDofilter);

    const doingfilter = doing.filter((order) => filterDates(order));
    setFilteredDoing(doingfilter);

    const donefilter = done.filter((order) => filterDates(order));
    setFilteredDone(donefilter);

    //   const depotfilter = depot.filter(
    //     (order) =>
    //       filterDates(order)
    //   );
    //    setFilteredDepot(depotfilter);

    const transitFilter = transit.filter((order) => filterDates(order));
    setFilteredTransit(transitFilter);

    const completedFilter = delivered.filter((order) => filterDates(order));
    setFilteredCompleted(completedFilter);
  }, [todo, doing, done, transit, delivered]);
  useEffect(() => {
    if (results.length > 0) {
      var filteredStore = results.filter(
        (store) => store.id === activeStoreFilter
      );
      if (filteredStore[0]) setSelectedStore(filteredStore[0]);
    }
  }, [activeStoreFilter]);
  const handleActiveStoreFilter = (value: any) => {
    setActiveStoreFilter(value);

    if (value === 0) {
      setFilteredTodo(todo);
      setFilteredDoing(doing);
      setFilteredDone(done);
      setFilteredTransit(baking);
      setFilteredCompleted(depot);
    } else {
      var _orders = {
        todo: todo,
        doing: doing,
        done: done,
        baking: baking,
        depot: depot
      };
      const keys = Object.keys(_orders);
      keys.forEach((prop: string) => {
        if (typeof _orders[prop] === 'object') {
          _orders[prop] = _orders[prop].filter(
            (_order) => _order.store_id === value
          );
        }
      });
      setFilteredTodo(_orders.todo);
      setFilteredDoing(_orders.doing);
      setFilteredDone(_orders.done);
      setFilteredTransit(_orders.baking);
      setFilteredCompleted(_orders.depot);
    }
  };
  const [sidebarTop, setSidebarTop] = useState(undefined);

  useEffect(() => {
    const orderHeader = document
      .querySelector('.ordersHeaderwrapper')
      .getBoundingClientRect();
    setSidebarTop(orderHeader.top + 10);
  }, []);

  useEffect(() => {
    if (sidebarTop) {
      window.addEventListener('scroll', isSticky);
      return () => {
        window.removeEventListener('scroll', isSticky);
      };
    } else {
      return undefined;
    }
  }, [sidebarTop]);

  const isSticky = (e) => {
    const ordersWrapperel = document.querySelector('.ordersHeaderwrapper');
    const ordersbodyel = document.querySelector('.orders-wrapper');
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      ordersWrapperel.classList.add('is-sticky');
      ordersbodyel.classList.add('ordersUnderSticky');
    } else {
      ordersWrapperel.classList.remove('is-sticky');
      ordersbodyel.classList.remove('ordersUnderSticky');
    }
  };

  //Yes, the attribute needs to be considered as a string, because that's how it comes from the backend
  const incomingOrders = () => {
    return filteredTodo.filter((el) => el.payment === 'none').length;
  };

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderTodo,
      headerTitle: 'NEW ORDERS',
      bodyStyle: classes.cardBodyTodo,
      orders: filteredTodo,
      showBackBtn: false,
      isFirstStage: true,
      showIsPaid: true,
      showPrintBtn: false,
      showScheduleBtn: false,
      showExtraInfo: true
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'DOING',
      bodyStyle: classes.cardBodyDoing,
      orders: filteredDoing,
      showIsPaid: true,
      showBackBtn: false,
      showPrintBtn: true,
      showScheduleBtn: true
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'DONE',
      bodyStyle: classes.cardBodyDone,
      orders: filteredDone,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showPrintBtn: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showScheduleBtn: true,
      showDeliveryTrackBtn: true
    },
    {
      key: 4,
      headerStyle: classes.cardHeaderDepot,
      headerTitle: 'ON THE WAY',
      bodyStyle: classes.cardBodyDepot,
      orders: filteredTransit,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showPrintBtn: true,
      showScheduleBtn: false,
      showDeliveryTrackBtn: true
    },
    {
      key: 5,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'DELIVERED',
      bodyStyle: classes.cardBodyDone,
      orders: filteredCompleted,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showPrintBtn: true,
      showScheduleBtn: false
    }
  ];
  const handleForceClose = (id: number, action: boolean) => {
    setSelectedStore({
      ...selectedStore,
      force_close: !selectedStore.force_close
    });
    dispatch(
      editStore(
        {
          force_close: action
        },
        id
      )
    );

    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  // const toDoOrdersToDisplay = (orders) => {
  //   orders.filter(order => {
  //     if (NETWORK === "4" && order.payment === 'none') return false
  //     if (order.owner) {
  //       // if (order.payment === "none" && (order?.owner?.phone_number === "" || order?.owner?.phone_number.includes(".")) && order?.owner?.email === "") return false
        
  //     }
  //     if (order.payment === "none") return false
  //     return true
  //   })
  //   return orders.length
  // }
  const displayNoOrdersMessage = (orders) => {
    var filteredOrders = orders.filter(order => {
      if (NETWORK === "4" && order.payment === 'none') return false;
      if (order.owner) {
        if (
          order.payment === "none" &&
          (
            (order?.owner?.phone_number === "" || (order?.owner?.phone_number && order.owner.phone_number.includes("."))) &&
            (order?.owner?.email === "")
          )
        ) {
          return false;
        }
      }
      if (order.payment === "none") return false;
      return true;
    }); 
    const show = !fetch && filteredOrders.length === 0 && 
    doing.length === 0 && 
    done.length === 0 && 
    depot.length === 0 && 
    baking.length === 0;
   
    return  show
  }
  
  const showStores = () => {
    var options = [];
    for (let index = 0; index < storeFilter.length; index++) {
      const element = storeFilter[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeStoreFilter;
    });

    return (
      <>
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeStoreFilter === 0 ? 0 : activeStoreFilter,
            label:
              activeStoreFilter === 0
                ? 'All'
                : storeFilter[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => handleActiveStoreFilter(e.value)}
          styles={customStyles}
        />

        {activeStoreFilter !== 0 &&
          (selectedStore.id !== 0 || selectedStore !== undefined) ? (
          <div
            className="switch-force-close"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '10px'
            }}
          >
            <Tooltip title="If you activate the force close your store will be unavailable to receive new orders in the website">
              <Switch
                checked={selectedStore.force_close}
                onChange={() =>
                  handleForceClose(selectedStore.id, !selectedStore.force_close)
                }
                id={`switch-force-close`}
                name={`switch`}
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Tooltip>
            <Typography>Close store</Typography>
          </div>
        ) : (
          <div
            className="switch-force-close"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '10px',
              opacity: 0,
              cursor: 'default'
            }}
          >
            <Tooltip title="">
              <Switch
                checked={false}
                id={`switch-force-close`}
                name={`switch`}
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Tooltip>
            <Typography>Close store</Typography>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="orders-scroll-bar-wrapper">
      <Loading show={fetch} />
      <div
        className='store-filter-wrapper'
      >
        {storeFilter.length > 2 && <Grid
          style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>{' '}
          {showStores()}
        </Grid>}
        <br />
        {/*<Grid style={{ marginBottom: 10 }}>
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`For: `}</Typography>
          {dateFilter.map((elmnt) => (
            <Button
              key={uuidv4()}
              onClick={() => {
                handleActiveDateFilter(elmnt);
              }}
              variant="outlined"
              size="small"
              className={
                activeDateFilter === elmnt
                  ? classes.activeButton
                  : classes.inactiveButton
              }
            >
              {elmnt}
            </Button>
          ))}
        </Grid> */}
      </div>
      <div className="orders-page-content-wrapper">
        <div className="sidebar">
          <div >
            <Grid className={'ordersHeaderwrapper'} id="ordersHeaderwrapper" container spacing={3}>
              {panels.map((panel) => (
                <div className='ordersHeaderElement' >
                  <Animate container={false}>
                    <Badge
                      style={{ cursor: 'pointer' }}
                      title=""
                      invisible={
                        panel.headerTitle !== 'NEW ORDERS' || NETWORK === "6"
                          ? true
                          : incomingOrders() <= 0
                            ? true
                            : false
                      }
                      badgeContent={incomingOrders()}
                      color="error"
                    >
                      <Card className={panel.headerStyle}>
                        <Typography
                          variant="subtitle2"
                          align="center"
                          style={{ fontWeight: 300, fontSize: 16 }}
                        >
                          {panel.headerTitle}
                        </Typography>
                      </Card>
                    </Badge>
                  </Animate>
                </div>
              ))}
            </Grid>
          </div>
        </div>
        <Grid
          className="orders-wrapper"
          style={{ flexWrap: 'nowrap' }}
          container
          spacing={3}
        >
          {panels.map((panel) => (
            <Panel
              key={panel.key}
              headerStyle={panel.headerStyle}
              headerTitle={panel.headerTitle}
              bodyStyle={panel.bodyStyle}
              orders={panel.orders}
              showOKBtn={panel.showOKBtn}
              showBackBtn={panel.showBackBtn}
              showIsPaid={panel.showIsPaid}
              showPrintBtn={panel.showPrintBtn}
              showScheduleBtn={panel.showScheduleBtn}
              showDriverBtn={panel.showDriverBtn}
              isFirstStage={panel.isFirstStage}
              showExtraInfo={panel.showExtraInfo}
              showDeliveryTrackBtn={panel.showDeliveryTrackBtn}
            />
          ))}
        </Grid>
      </div>
      {/* {!fetch &&
        toDoOrdersToDisplay(todo) &&
        doing.length === 0 &&
        done.length === 0 &&
        depot.length === 0 &&
        baking.length === 0 && (
          <div style={{width:"100%", height:"30vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Typography
              variant="h6"
              align="center"
              style={{ marginBottom: 10 }}
            >There are no open orders</Typography>
          </div>
        )} */
        }

{displayNoOrdersMessage(todo) && (
  <div style={{ width: "100%", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Typography
      variant="h6"
      align="center"
      style={{ marginBottom: 10 }}
    >There are no open orders</Typography>
  </div>
)}

    </div>
  );
}
