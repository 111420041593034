import React from 'react';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Print from 'components/Common/Print';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import thankYou from './../../assets/img/petshop/thank-you.svg'
//import logo from './../../assets/img/mangel_logo.svg'
import { IKitchen } from 'interfaces/kitchen';

//import Print from 'components/Common/Print';

import { useStyles } from './receiptStyles';
import moment from "moment"
import { NETWORK } from 'settings';

interface IProps {
    order: IKitchen;
    trigger: any;
    storeName?: string;
}

export default function MangelReceipt(props: IProps): JSX.Element {
    const { order, trigger, storeName } = props;
    const classes = useStyles();
    const returnLast4 = () => {
        if (order.owner) {
            if (order.owner["user"]) {
                var profile = order.owner["user"]['profile']["stripe_profiles"].find(el => el.network_id == NETWORK)
                if (profile) {
                    return profile.last4
                }
            }
        }
        return ""
    }
    const last4 = returnLast4()
    return (
        <>
            <Print
                title={`${storeName}_${moment(new Date()).format("YYYY-MM-DD")}_${order.order_id}`}
                trigger={
                    <Button size="medium" title="Print">
                        {trigger}
                    </Button>
                }
            >
                <Card style={{ border: "none", borderBottom: "0p solid transparent" }}>
                    <Grid container justify="center" className={classes.receiptHeader}>
                        <Grid style={{ width: "100%", position: "relative" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "85%" }}>
                                <img
                                    src={thankYou}
                                />
                                <img
                                    style={{ marginTop: "15px" }}
                                    src={"https://res.cloudinary.com/arbid/image/upload/v1705000266/Petshop/M%C3%A4ngelquartett%20by%20karhard.png"}
                                />
                            </div>
                            <div style={{ marginTop: "70px", borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "40px" }}>
                                <Typography>Date: {moment(order.date_ordered).format('DD.MM.YYYY')}</Typography>
                                <Typography>Time: {moment(order.date_ordered).format('HH:mm')}</Typography>
                                <Typography>Receipt Nr.: {order.order_id}</Typography>
                                {order.owner && <Typography>Customer: {order.owner.name}</Typography>}
                                {order.billing_address && <Typography>Billing address: {order.billing_address.replaceAll("None,", "")}</Typography>}
                                {order.delivery_address && order.type_of_order.includes("D") && <Typography>Delivery address: {order.delivery_address.replaceAll("None,", "")}</Typography>}
                                {order.delivery_tracking_number && <Typography>Tracking number: {order.delivery_tracking_number}</Typography>}
                                {order.delivery_company && <Typography>Delivery company: {order.delivery_company}</Typography>}
                            </div>
                            {order.items.map((item) => {
                                if (item.quantity > 0) {
                                    return (
                                        <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "40px" }}>
                                            <Typography>{item?.product?.name}</Typography>
                                            <Typography>{item?.product?.artist}</Typography>
                                            <Typography>{item?.variation?.name ? item?.variation?.name + ", " : ""} {item?.product?.colour_hex && item?.product?.colour_hex !== "null" ? item?.product?.colour_hex : ""}</Typography>
                                            <Typography>{item?.quantity} x {new Intl.NumberFormat('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(item?.variation ? item?.variation?.price : item?.product?.price)} EUR</Typography>
                                        </div>
                                    )
                                }
                                return null
                            })}
                            <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "10px" }}>
                                <div style={{ gap: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                                    <Typography>Subtotal:</Typography>
                                    <Typography>{new Intl.NumberFormat('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(order.sub_total)} EUR</Typography>
                                </div>
                            </div>
                            {order.vat_total > 0 ?
                                <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "10px" }}>
                                    <div style={{ gap: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                                        <Typography>MwSt. 19%:</Typography>
                                        <Typography>{new Intl.NumberFormat('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(order.vat_total)} EUR</Typography>
                                    </div>
                                </div> : null}
                            {order.delivery_fee > 0 && order.type_of_order.includes("-D") ?
                                <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "10px" }}>
                                    <div style={{ gap: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                                        <Typography>Delivery fee:</Typography>
                                        <Typography>{new Intl.NumberFormat('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(order.delivery_fee)} EUR</Typography>
                                    </div>
                                </div>
                                : null}
                            {order.discount > 0 ?
                                <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "10px" }}>
                                    <div style={{ gap: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                                        <Typography>Discount:</Typography>
                                        <Typography>{new Intl.NumberFormat('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(order.discount)} EUR</Typography>
                                    </div>
                                </div>
                                : null}

                            <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "40px" }}>
                                <div style={{ gap: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                                    <Typography>Total:</Typography>
                                    <Typography>{new Intl.NumberFormat('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(order.total_order)} EUR</Typography>
                                </div>
                            </div>
                            {order.payment === "Card" ?
                                <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "15px" }}>
                                    <Typography>Payment method: {order.payment === "Card" ? "Card via Stripe" : "Invoice"}</Typography>
                                    <Typography>Date: {moment(order.date_ordered).format('DD.MM.YYYY')}</Typography>
                                    {last4 ? <Typography>Cardnr: ******{last4}</Typography> : null}
                                </div> : null}
                            <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "40px" }}>
                                <Typography>VAT-ID: DE 261 413 525</Typography>
                                <Typography>EORI: DE918636845613880</Typography>
                                <Typography>Name: karhard design GbR</Typography>
                                <Typography>Thomas Karsten, Alexandra Erhard</Typography>
                                <Typography>IBAN: DE22 1001 0010 0552 8211 01</Typography>
                                <Typography>BIC: PBNKDEFF (Postbank)</Typography>
                            </div>
                            <div style={{ borderTop: "1px solid black", paddingTop: "5px", paddingBottom: "40px" }}>
                                <Typography>Mängelquartett by karhard®</Typography>
                                <br />
                                <Typography>
                                    Bevernstrasse 5<br />
                                    10997 Berlin<br />
                                    Deutschland
                                </Typography>
                                <br />
                                <Typography>
                                    shop@maengelquartett.de<br />
                                    + 49 30 61107848
                                </Typography>
                                <br />
                            </div>
                        </Grid>
                    </Grid>
                </Card >
            </Print>
        </>
    );
}

MangelReceipt.defaultProps = {
    disabled: false
};
