import { createTheme } from '@material-ui/core';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  Tooltip
} from '@material-ui/core';
import { toNumber } from 'lodash';
import { PRIMARY_COLOR } from 'settings';

import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';

interface IProps {
  handleSave: (data: {
    name: string;
    price: number;
    description: string;
    store: number;
    image: File;
    category: string;
    cost: number;
    measure: string;
  }) => void;
  store: number;
  categoryName: string;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        width: '75%',
        marginBottom: '1vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function CreateProduct(props: IProps) {
  const { handleSave, store, categoryName } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [priceError, setPriceError] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [descError, setDescError] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [categoryError, setCategoryError] = React.useState('');
  const [imageSource, setImageSource] = React.useState<File>();
  const [measure, setMeasure] = React.useState('');
  const [measureError, setMeasureError] = React.useState('');

  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
  };
  const handleCategory = (event: any) => {
    setCategory(event.target.value);
    setCategoryError('');
  };

  const handlePrice = (event: any) => {
    const value = event.target.value;
    if (/^[.0-9]+$/.test(value)) {
      setPrice(value);
    }
  };
  const handleDesc = (event: any) => {
    setDescription(event.target.value);
    setDescError('');
  };

  const handleMeasure = (event: any) => {
    setMeasure(event.target.value);
    setMeasureError('');
  };

  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
    }
  };
  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert product name');
      entered = true;
    }
    if (price === '') {
      setPriceError('Please insert product price');
      entered = true;
    }
    if (description === '') {
      setDescError('Please insert product description');
      entered = true;
    }
    if (category === '') {
      setCategoryError('Please insert product category');
      entered = true;
    }
    if (measure === '' || measure === null) {
      setMeasureError('Please insert measure');
      entered = true;
    }

    return entered;
  };
  const submit = () => {
    if (!checkForm()) {
      handleSave({
        name,
        price: toNumber(price),
        description,
        store,
        image: imageSource,
        category,
        cost: 0.0,
        measure
      });
    }
  };
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <Typography
        style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
      >
        Create your first product
      </Typography>
      <FormControl
        style={{ marginBottom: '1rem', width: '75%' }}
        required
        error={nameError !== ''}
      >
        <InputLabel htmlFor="name-input">Product name</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={name}
          onChange={handleName}
        />
        <FormHelperText>{nameError}</FormHelperText>
      </FormControl>
      <Tooltip title="The product price is the price that customers will see in the website">
        <FormControl
          style={{ marginBottom: '1rem', width: '75%' }}
          required
          error={priceError !== ''}
        >
          <InputLabel htmlFor="name-input">Product Price</InputLabel>
          <Input
            id="name-input"
            type="number"
            value={price}
            onChange={handlePrice}
          />
          <FormHelperText>{priceError}</FormHelperText>
        </FormControl>
      </Tooltip>
      <FormControl
        style={{ marginBottom: '1rem', width: '75%' }}
        required
        error={descError !== ''}
      >
        <InputLabel htmlFor="name-input">Product Description</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={description}
          onChange={handleDesc}
          multiline
          rows={4}
        />

        <FormHelperText>{descError}</FormHelperText>
      </FormControl>
      <FormControl
        style={{ marginBottom: '1rem', width: '75%' }}
        required
        error={measureError !== ''}
      >
        <InputLabel htmlFor="measure-input">Measurement</InputLabel>
        <Select id="measure-input" value={measure} onChange={handleMeasure}>
          <MenuItem value={'kg'}>Kilogram, kg</MenuItem>
          <MenuItem value={'l'}>Litre, l</MenuItem>
          <MenuItem value={'Unit'}>Unit, u</MenuItem>
        </Select>
        <FormHelperText>{measureError}</FormHelperText>
      </FormControl>
      <FormControl
        style={{ marginBottom: '1rem', width: '75%' }}
        required
        error={categoryError !== ''}
      >
        <InputLabel htmlFor="name-input">Product Category</InputLabel>
        <Select id="type-input" value={category} onChange={handleCategory}>
          <MenuItem value={categoryName}>{categoryName}</MenuItem>
        </Select>
        <FormHelperText>{categoryError}</FormHelperText>
      </FormControl>
      <ThemeProvider theme={theme}>
        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneText={'Drag and drop here or click to upload product image'}
          onChange={(files) => handleImage(files)}
          filesLimit={1}
        />
      </ThemeProvider>
      <Button
        style={{
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={() => submit()}
      >
        Save and continue
      </Button>
    </Grid>
  );
}
