import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export enum EFilters {
  PRODUCTS = 'products',
  INGREDIENTS = 'ingredients'
}

export enum ETypesStock {
  FETCH = 'STOCK/FETCH',
  SUCCESS = 'STOCK/SUCCESS',
  CREATE_PRODUCT = 'STOCK/CREATE_PRODUCT',
  UPDATE_PRODUCT = 'STOCK/UPDATE_PRODUCT',
  DELETE_PRODUCT = 'STOCK/DELETE_PRODUCT',
  FAILURE = 'STOCK/FAILURE',
  RESET = 'STOCK/RESET'
}

export interface IStockState {
  fetch?: boolean;
  ingredients: Ingredients[];
  products: Product[];
  productsForStockPage: Product[];
  providers: Provider[];
  combos: ICombos[];
  categories: ICategory[];
  hasMore: boolean;
}

export interface Ingredients {
  name: string;
  ingredient_id: number;
  stock_id: number;
  measure: string;
  quantity: number;
  stock: number;
  cost: number;
  id: number;
  is_sauce: boolean;
}

export interface ICombos {
  id: number;
  name: string;
  image: string;
  price: number;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface Tags {
  id: number;
  name: string;
  background: string;
  color: string;
}

export interface Product {
  active: boolean;
  stock_id: number;
  new:boolean
  product_sheet: string;
  product_size: string;
  fiscal_category:string;
  colour_hex: string;
  artist: number;
  origin: string;
  slug: string;
  id: number;
  bad_stock: number;
  category: string;
  category_2: string;
  cost: number;
  define_category: string;
  description: string;
  measure: string;
  extras: any[];
  product_images: any[];
  image: string | null;
  ingredients: Ingredients[];
  name: string;
  price: number;
  product_id: number;
  sold: number;
  reserved: number;
  allergy_notes: string;
  stock: number;
  time: number;
  video: string;
  material: string;
  variation: {
    id: number;
    name: string;
    barcode: string;
    sku: string;
    price: number;
    stock: number;
    cost: number;
  }[];
  sizes: {
    id: number;
    portion: string;
    price: number;
    serves: number;
  }[];
  gift_card: boolean;
  related_products: [
    {
      id: number;
      base_product: {
        product_id: number;
        name: string;
        price: number;
        ingredients: [];
        sizes: [];
        extras: [];
        half: boolean;
        double: boolean;
        image: string;
      };
      related_product: {
        product_id: number;
        name: string;
        price: number;
        ingredients: [];
        sizes: [];
        extras: [];
        half: boolean;
        double: boolean;
        image: string;
      };
    }
  ];
  tags: any[];
  next_days: [
    {
      date: string;
      weekday: string;
      value: number;
    }
  ];
  day_history?: [
    {
      quantity: number;
      created_at: string;
      description: string;
      item_id: number;
      type: string;
      before: number;
      after: number;
    }
  ];
}

export interface Provider {
  id: number;
  name: string;
  provider_pricing: number;
}

export interface IFetchAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesStock, IStockState> {
  type: ETypesStock.SUCCESS;
  payload: IStockState;
}

export interface ICreateAction extends IBaseAction<ETypesStock, Product> {
  type: ETypesStock.CREATE_PRODUCT;
  payload: Product;
}

export interface IUpdateAction extends IBaseAction<ETypesStock, Product> {
  type: ETypesStock.UPDATE_PRODUCT;
  payload: Product;
}

export interface IDeleteAction extends IBaseAction<ETypesStock, number> {
  type: ETypesStock.DELETE_PRODUCT;
  payload: number;
}

export interface IFailureAction extends IBaseAction<ETypesStock, string> {
  type: ETypesStock.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesStock, null> {
  type: ETypesStock.RESET;
}

export type IManagerAction =
  | IFetchAction
  | ISuccessAction
  | ICreateAction
  | IUpdateAction
  | IDeleteAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action: IManagerAction | ThunkAction | Array<IManagerAction> | IAlertAction
) => any;
