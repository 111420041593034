import React, { useEffect, } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import store from './redux/';


import { APP_NAME } from 'settings';

import Routes from './components/Router';
import PrivateRoute from './components/Router/PrivateRoute';
import Authentication from './components/Authentication';
import StripeKYC from 'components/StripeKYC';
import Alert from './components/Global/Alert';
import SetPassword from './components/Authentication/ResetPassword';
import Onboarding from 'components/Onboarding';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Reauth from './components/Reauth';
import FinishOnboarding from './components/OnboardingFinish';
import Deauthorized from './components/Deauthorized';
import Networks from 'components/Networks';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'settings';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR()
    },
    secondary: {
      main: SECONDARY_COLOR()
    }
  }
});

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);
// import { LanguageProvider } from './utils/hooks/useLanguage';

const App: React.FC = () => {
  useEffect(() => {
    document.title = APP_NAME;
  }, []);
  // Sentry.init({
  //   dsn: 'https://6ece745965f84777a06ef726f04b1e8a@o911239.ingest.sentry.io/5902544',
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0
  // });

  useEffect(() => {
    if (!store.getState().authentication.authorized && (window.location.pathname.includes("dashboard") || window.location.pathname.includes("on-boarding"))) {
      window.location.pathname = "/"
    }
  }, [store.getState().authentication.authorized])
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Alert />
        <Router>
          <Switch>
            {/* <LanguageProvider > */}
            <Route path="/set-password" exact component={SetPassword} />
            <Route path="/deauthorized" exact component={Deauthorized} />
            <Route path="/" exact component={Authentication} />
            <Route path="/on-boarding" exact component={Onboarding} />
            <Route path="/stripe-kyc" exact component={StripeKYC} />
            <Route path="/reauth" exact component={Reauth} />
            <Route path="/networks" exact component={Networks} />
            <Route
              path="/finish-onboarding"
              exact
              component={FinishOnboarding}
            />

            <PrivateRoute path="/dashboard" component={Routes} />
            <Route
              render={() => (
                <>
                  <h3 className="text-center">Page Not Found</h3>
                </>
              )}
            />
            {/* </LanguageProvider> */}
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
