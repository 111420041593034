import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ModalCreateIngredient from './ModalCreateIngredient';
import ModalEditIngredient from './ModalEditIngredient';
import ModalAddIngredient from './ModalAddIngredient';
import { Button, IconButton, InputBase } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Ingredients } from 'interfaces/stock';
import ModalDeleteIngredient from './ModalDeleteIngredient';
import SearchIcon from '@material-ui/icons/Search';
import { PRIMARY_COLOR } from 'settings';

const useStyles = makeStyles({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: '15px'
  },
  input: {
    flex: 1,
    paddingLeft: '8px'
  },
  iconButton: {
    padding: 10
  },
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  ingredients: Ingredients[];
  store?: number;
  searchData: (data: string) => void;
  ingredientsToFilterBy: any;
}

export default function IngredientsTable(props: Props) {
  const classes = useStyles();
  const { ingredients, store, searchData, ingredientsToFilterBy } = props;
  const [open, setOpen] = React.useState(false);
  const [openAddIngredient, setOpenAddIngredient] = React.useState(false);
  const [openEditIngredient, setOpenEditIngredient] = React.useState(false);
  const [openDeleteIngredient, setOpenDeleteIngredient] = React.useState(false);
  const [selectedIngredient, setSelectedIngredient] = React.useState(undefined);
  const [search, setSearch] = React.useState('');

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAddIngredient(false);
    setOpenEditIngredient(false);
    setOpenDeleteIngredient(false);
    setSelectedIngredient(undefined);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEditIngredient = (ingredient: Ingredients) => {
    setOpenEditIngredient(true);
    setSelectedIngredient(ingredient);
  };
  const handleClickOpenDeleteIngredient = (ingredient: Ingredients) => {
    setOpenDeleteIngredient(true);
    setSelectedIngredient(ingredient);
  };

  // const handleClickOpenAddIngredient = (ingredient: Ingredients) => {
  //   setOpenAddIngredient(true);
  //   setSelectedIngredient(ingredient);
  // };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {store !== 0 ? (
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: 12,
              margin: '10px 10px',
              height: '35px'
            }}
            color="primary"
            onClick={handleClickOpen}
            className={classes.activeButton}
          >
            Add Extra
          </Button>
        ) : null}

        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search extra"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={search}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                searchData(search);
              }
            }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => {
              searchData(search);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {/* {props.store !== 0 && (
              <TableCell align="left" style={{ padding: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Buy</span>
                </div>
              </TableCell>
            )} */}
            <TableCell align="right">Stock</TableCell>
            <TableCell align="right">Unit</TableCell>
            {/* <TableCell align="right">Cost (£)</TableCell> */}
            {props.store !== 0 && <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients?.map((ingredient) => (
            <TableRow key={ingredient.ingredient_id}>
              <TableCell component="th" scope="row">
                {ingredient.name}
              </TableCell>
              {/* {props.store !== 0 && (
                <TableCell align="left" style={{ padding: '5px' }}>
                  <div style={{ color: '#124c4d', display: 'flex' }}>
                    <ControlPointIcon
                      onClick={() => handleClickOpenAddIngredient(ingredient)}
                    />
                  </div>
                </TableCell>
              )} */}
              <TableCell align="right">{ingredient.stock.toFixed(2)}</TableCell>
              <TableCell align="right">{ingredient.measure}</TableCell>
              {/* <TableCell align="right">{ingredient.cost.toFixed(2)}</TableCell> */}
              {props.store !== 0 && (
                <TableCell align="right">
                  <EditIcon
                    onClick={() => handleClickOpenEditIngredient(ingredient)}
                  />
                </TableCell>
              )}
              {props.store !== 0 && (
                <TableCell>
                  <DeleteIcon
                    onClick={() => handleClickOpenDeleteIngredient(ingredient)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateIngredient
        ingredientsToFilterBy={ingredientsToFilterBy}
        open={open}
        store={store}
        onClose={handleClose}
      />
      <ModalEditIngredient
        open={openEditIngredient}
        onClose={handleClose}
        ingredient={selectedIngredient}
        store={store}
        ingredientsToFilterBy={ingredientsToFilterBy}
      />
      <ModalAddIngredient
        open={openAddIngredient}
        onClose={handleClose}
        ingredient={selectedIngredient}
        store={props.store}
        ingredientsToFilterBy={ingredientsToFilterBy}
      />
      <ModalDeleteIngredient
        onClose={handleClose}
        open={openDeleteIngredient}
        ingredient={selectedIngredient}
        ingredientsToFilterBy={ingredientsToFilterBy}
      />
    </TableContainer>
  );
}
