import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import {
  fetchDashboard,
  fetchDashboardWithDateRange
} from 'redux/ducks/manager';
import formatDate from 'date-fns/format';

import { EFilters } from '../../enums/managerDashboard';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import DateFilter from './DateFilter';
import ExpensesCard from './ExpensesCard';
import PieChart from './PieChart';
// import BarChart from './BarChart';
import LineChart from './LineChart';
import NumberLessCard from './numberLessCard';
//import NumberCard from './NumberCard';
import TableChart from './TableChart';
import { useStyles } from './styles';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';
// import SellersCommissionTable from './SellersCommisionTable';
import { fetchSellersCommissions } from 'redux/ducks/customersAndSellers';
import Select from 'react-select';
import { NETWORK, PRIMARY_COLOR } from 'settings';
import './styles.css';
import { setCurrency } from 'redux/ducks/currency';

const filters = [
  {
    label: 'Today',
    value: EFilters.TODAY
  },
  {
    label: 'Tomorrow',
    value: EFilters.YESTERDAY
  },
  {
    label: 'Last week',
    value: EFilters.LAST_WEEK
  },
  {
    label: 'Last month',
    value: EFilters.LAST_MONTH
  }
];

var storeFilter: { id: number; name: string }[] = [];

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: PRIMARY_COLOR(),
    boxShadow: 'none',
    ':hover': {
      borderColor: PRIMARY_COLOR()
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
  })
};
export default function ManagerDashboard(): JSX.Element {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<EFilters>(
    EFilters.LAST_MONTH
  );
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { manager, authentication } = useSelector<IStore, IStore>(
    (state) => state
  );
  // const { customersAndSellers } = useSelector<IStore, IStore>((state) => state);

  const cost =
    Object.values(manager.balance.cost).reduce((value, sum) => value + sum, 0) -
    manager.balance.cost.discount;

  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  if (authentication.is_superuser) {
    storeFilter.push({ id: 0, name: 'All' });
  }
  useEffect(() => {
    dispatch(getStore());
  }, [dispatch, activeFilter, activeStoreFilter]);

  useEffect(() => {
    var params = {
      period: activeFilter,
      network: NETWORK,
      store: activeStoreFilter
    };
    if (results[0]) dispatch(setCurrency(results[0].currency))
    dispatch(fetchDashboard(params));
  }, [dispatch, activeFilter, activeStoreFilter]);

  useEffect(() => {
    dispatch(
      fetchSellersCommissions(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd')
      )
    );
  }, [dispatch, activeFilter]);

  const classes = useStyles();

  const handleDateRange = () => {
    dispatch(
      fetchDashboardWithDateRange(
        formatDate(initialDate, 'yyyy-MM-dd'),
        formatDate(endDate, 'yyyy-MM-dd'),
        activeStoreFilter
      )
    );
  };

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  const showStores = () => {
    var options = [];
    for (let index = 0; index < storeFilter.length; index++) {
      const element = storeFilter[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeStoreFilter;
    });
    return (
      <>
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeStoreFilter === 0 ? 0 : activeStoreFilter,
            label:
              activeStoreFilter === 0
                ? 'All'
                : storeFilter[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => handleActiveStoreFilter(e.value)}
          styles={customStyles}
        />
      </>
    );
  };
  return (
    <>
      <Loading show={manager.fetch} />

      <Grid container spacing={3}>
        {storeFilter.length > 2 && <Grid
          style={{
            marginBottom: 10,
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px'
          }}
        >
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>{' '}
          {showStores()}
        </Grid>}
        <DateFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
          dateRange={{
            initialDate,
            endDate,
            setInitialDate,
            setEndDate
          }}
          handleDateRange={handleDateRange}
        />
        <div className={`${classes.cardroot}`}>
          {/* <Grid container>
            <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
              Sellers
            </Typography>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              marginLeft: '10px',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <SellersCommissionTable
              sellers={customersAndSellers.sellersWithCommission}
            />
          </Grid> */}
          <Grid container>
            <Typography
              variant="subtitle1"
              style={{ paddingLeft: '10px', marginTop: '10px' }}
            >
              Orders
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            style={{ marginRight: '100px' }}
          >
            <ExpensesCard
              header={manager.balance.revenue_count.card}
              label="Online"
              percentage={0}
              value={manager.balance.revenue.card}
            />{' '}
            <ExpensesCard
              label="Collection"
              header={manager.balance.where_go_count.collection}
              percentage={0}
              value={manager.balance.where_go.collection}
            />
            <ExpensesCard
              label="Delivery"
              header={manager.balance.where_go_count.delivery}
              percentage={0}
              value={manager.balance.where_go.delivery}
            />
          </Grid>

          <Grid container>
            <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
              Costs
            </Typography>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start">
            <NumberLessCard label="Total Cost" value={cost} />
            <NumberLessCard
              label="Delivery Costs"
              value={manager.balance.cost.drivers}
            />
            {/*<NumberLessCard
              label="Discounts Values"
              value={manager.balance.cost.discount}
            /> */}
          </Grid>
        </div>

        <Grid
          item
          container
          className="column-to-row"
          justifyContent="space-evenly"
        >
          <LineChart title="New orders" data={manager.orders.news} />
          <PieChart open={manager.orders.open} closed={manager.orders.closed} />
          <LineChart
            title="Avarage value per order"
            data={manager.orders.average}
            floatNumbers
            total_average={manager.orders.total_average}
          />
        </Grid>
        <Grid container>
          <Typography variant="subtitle1" style={{ paddingLeft: '10px' }}>
            Average time
          </Typography>
        </Grid>
        {/*<Grid item container direction="row" justifyContent="space-between">
          <DateCard
            label="Preparing - Done"
            time={manager.balance.timings.doing_done}
          />
          <DateCard
            label="Done - Deposit"
            time={manager.balance.timings.doing_depot}
          />
          <DateCard
            label="Deposit - On The Way"
            time={manager.balance.timings.depot_transit}
          />
          <DateCard
            label="On The Way - Delivered"
            time={manager.balance.timings.transit_delivered}
          />
          <DateCard
            label="Preparing - Complete"
            time={manager.balance.timings.doing_completed}
          />
        </Grid> */}
        <Grid item container direction="row" justifyContent="space-between">
          <TableChart
            label1="Product"
            label2="Quant."
            data={manager.ranks.products}
            total_count={manager.ranks.total_products}
            total_value={manager.ranks.total_products_value}
          />
          {/* <BarChart
            title="Produtos mais vendidos"
            size="medium"
            data={manager.ranks.products}
          /> */}
          {/*  <TableChart
            label1="Neighborhood"
            label2="Quant."
            data={manager.ranks.neighborhood}
            total_count={manager.ranks.total_neighborhood}
            total_value={manager.ranks.total_neighborhood_value}
          /> */}
          {/* <BarChart
            title="ranking dos bairros"
            size="medium"
            data={manager.ranks.neighborhood}
          /> */}
        </Grid>
      </Grid>
    </>
  );
}
