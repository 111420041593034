/* eslint-disable no-var */
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  Container,
  FormControl,
  Button,
  InputLabel,
  Input,
  Tabs,
  Tab,
  AppBar,
  Typography,
  Box,
  MenuItem,
  Select,
  IconButton,
  FormHelperText,
  TextField,
  makeStyles,
  createStyles,
  Fade,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  editProduct,
  // deleteIngredientStock,
  deleteExtra
} from 'redux/ducks/stock';
import { Product, Ingredients, ICategory } from 'interfaces/stock';
import './styles.css';
import Modal from '@material-ui/core/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetch } from 'utils/request';
import { NETWORK } from 'settings'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toInteger } from 'lodash';
import { Itags } from 'interfaces/store';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ModalCreateArtist from './../Artists/ModalAddArtist'
//import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

//Overrides theme of Dropzone component
const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: (product) => void;
  product: Product;
  ingredients: Ingredients[];
  categories?: ICategory[];
  store: number;
  reset: () => void;
  products?: Product[];
  storeSlug: any;
  artists: { artist_id: number, name: string }[],
  tags: Itags[];
  getArtists: () => void;

}

export default function ModalEditProduct(props: SimpleDialogProps) {
  const classes = useStyles();
  const { onClose, open, product, categories, products, artists, store, tags, getArtists } = props;
  const [imageSource, setImageSource] = useState<File>();
  const [imageFromBackend, setImageFromBackend] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [slugError, setSlugError] = useState('');
  const [measure, setMeasure] = useState('');
  const [measureError, setMeasureError] = useState('');
  const [price, setPrice] = useState(0);
  const [slug, setSlug] = useState('');
  const [cost, setCost] = useState(0);
  // const [costError, setCostError] = useState('');
  const [stock, setStock] = useState(0);
  const [category, setCategory] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [productImages, setProductImages] = React.useState<any>([])
  const [newProductImages, setNewProductImages] = React.useState<any>([])
  const [description, setDescription] = useState('');
  const [allergyNotes, setAllergyNotes] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [hexCode, setHexCode] = useState("")
  const [material, setMaterial] = useState("")
  const [extras, setExtras] = useState([]);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [inputValueCategory, setInputValueCategory] = useState('');
  const [qrcode, setQrcode] = useState('');
  const [qrcodeOpen, setQrcodeOpen] = useState(false);
  const [video, setVideo] = useState<File>();
  const [videoFromBackend, setVideoFromBackend] = useState('');
  const [sizes, setSizes] = React.useState([])
  const [sizesError, setSizesError] = React.useState(false)
  const [artistName, setArtistName] = React.useState<any>({ artist_id: 0, name: "" })
  const [artistQuery, setArtistQuery] = useState("")
  const [origin, setOrigin] = React.useState("")
  const [productSheet, setProductSheet] = React.useState(null)
  const [selectedTags, setSelectedTags] = React.useState([])
  const [tagsError, setTagsError] = React.useState([])
  const [giftCard, setGiftCard] = React.useState(false);
  const [productImagesError, setProductImagesError] = useState(false)
  const [openCreateArtist, setOpenCreateArtist] = React.useState(false)
  const [newProduct, setNewProduct] = useState(false);
  const [productSize, setProductSize] = useState<any>("SMALL")
  const [productSizeError, setProductSizeError] = useState(false)
  const [fiscalCategory, setFiscalCategory] = useState<any>("PRODUCT")
  const [fiscalCategoryError, setFiscalCategoryError] = useState(false)

  const productSizes = ["SMALL", "MEDIUM", "LARGE&LIGHT", "LARGE&HEAVY", "HUGE"]

  const ckEditorToolbarConfig = [
    'Heading',
    '|',
    'bold',
    'italic',
    '|',
    'BulletedList',
    'NumberedList',
    '|',
    'Indent',
    '|',
    'BlockQuote',
    'undo',
    'redo'
  ];
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getQRCode = async () => {
    const x = await fetch({
      url: `/products/api/products/${product.product_id}/generate_qr_to_product/`,
      method: 'POST'
    });
    setQrcode(x.link);
    setQrcodeOpen(true);
  };

  const handleClose = (product) => {
    setName('');
    setNewProduct(false)
    setSlug('');
    setNameError('');
    setSlugError('');
    setCategoryError('');
    setNewProductImages([])
    setProductImages([])
    setSlug("")
    setPrice(0);
    setStock(0);
    // setCost(0);
    setMeasureError('');
    // setCostError('');
    setIngredients([]);
    setExtras([]);
    setValue(0);
    setSizes([])
    setProductSheet(null)
    setOrigin("")
    setHexCode("")
    setArtistName("")
    setSelectedTags([])
    setProductImagesError(false)
    setProductSize("SMALL")
    setProductSizeError(false)
    setFiscalCategory("PRODUCT")
    onClose(product);

  };

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      // setCost(product.cost);
      setStock(product.stock);
      setDescription(product.description);
      setAllergyNotes(product.allergy_notes);
      setMeasure(product.measure);
      setCategory(product.category);
      setImageFromBackend(product.image);
      setVideoFromBackend(product.video);
      setSlug(product.slug);
      setProductSize(product.product_size)
      setCost(product.cost);
      if (product.new) {
        setNewProduct(product.new)
      }
      setGiftCard(product.gift_card)
      var filledTags = product["tags"].map((tag => tag.id))
      setSelectedTags(filledTags)
      if (product.product_images) {
        var images = []
        product.product_images.map((image) => {
          images.push({
            deleted: false,
            image: image,
            oldImage: true
          })
        })
        setProductImages(images)
      }
      setExtras(product.extras);
      if (NETWORK === "6") {
        setFiscalCategory(product.fiscal_category)
        setMaterial(product.material)
        setProductSheet(product.product_sheet)
        setHexCode(product.colour_hex)
        setOrigin(product.origin)
        const artist = artists.find((artist) => artist.artist_id === product.artist)
        if (artist?.name) {
          setArtistName({ artist_id: artist.artist_id, name: artist.name })
        }
        if (product["sizes"]) {
          var _sizes = product["variation"].map((_size) => ({ ..._size, changed: false }))
          setSizes(_sizes)
        }
      }
      handleMaps();
    }
  }, [product]);
  const handleMaps = () => {
    const newIngredients = [];
    const newExtras = [];
    for (let index = 0; index < product.ingredients.length; index++) {
      const element = product.ingredients[index];
      newIngredients.push({
        id: index,
        ingredient_id: element.ingredient_id,
        measure: element.measure,
        name: element.name,
        quantity: element.quantity,
        isOld: true,
        stock_id: element.id
      });
    }
    for (let index = 0; index < product.extras.length; index++) {
      const element = product.extras[index];
      newExtras.push({
        extra_id: index,
        id: element.id,
        is_sauce: element.is_sauce === false ? 0 : 1,
        limit: element.limit,
        price: element.price,
        ingredient: element.ingredient_id,
        quantity: element.quantity,
        isOld: true
      });
    }
    setIngredients(newIngredients);
    setExtras(newExtras);
  };
  const generateURL = (file) => {
    if (file) {
      if (file.oldImage) {

        return file.image.image
      }

      var url = URL.createObjectURL(file)
      return url
    }
    return ""
  }
  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
    }
  };
  const handleVideo = (event: any) => {
    if (event.length > 0) {
      setVideo(event[0]);
    }
  };
  const handleProductSheet = (event: any) => {
    if (event.length > 0) {
      setProductSheet(event[0]);
    }
  };
  const handleName = (event: any) => {
    setName(event.target.value);
    setSlug(slugify(event.target.value));
    setNameError('');
  };

  const handleSlug = (event: any) => {
    setSlug(event);
    setSlugError('');
  };

  const handleMeasure = (event: any) => {
    setMeasure(event.target.value);
    setMeasureError('');
  };

  const handleCategory = (event: any) => {
    setCategory(event);
    setCategoryError('');
  };

  const handlePrice = (event: any) => {
    setPrice(event.target.value);
  };

  const handleStock = (event: any) => {
    setStock(event.target.value);
  };

  const handleDescription = (value: string) => {
    setDescription(value);
  };
  const handleAllergyNotes = (event: any) => {
    setAllergyNotes(event.target.value);
  };

  const checkDuplicateSlug = () => {
    for (let index = 0; index < products.length; index++) {
      const product_el = products[index];
      if (
        product_el.slug === slug &&
        product_el.product_id !== product.product_id
      ) {
        return true;
      }
    }
    return false;
  };

  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert the name');
      entered = true;
    }
    if (checkDuplicateSlug()) {
      setSlugError('You already have a product with this friendly URL');
      entered = true;
    }
    if (slug === '') {
      setSlugError('Please insert the friendly URL');
      entered = true;
    }

    if (category === '' || category === null || !category) {
      setCategoryError('Please insert the category');
      entered = true;
    }
    if (measure === '' || measure === null) {
      setMeasureError('Please insert the measure');
      entered = true;
    }
    if (NETWORK === "6" && productSize === "") {
      setProductSizeError(true)
      entered = true
    }
    if (["2", "6"].includes(NETWORK) && fiscalCategory === "") {
      setFiscalCategoryError(true)
      entered = true
    }
    var _tagsError = [...tagsError]
    selectedTags.map((tag, idx) => {
      if (tag === "") {
        _tagsError[idx] = true
        entered = true
      }
    })
    if (productImages.filter((el) => el.deleted === false).length < 1 && newProductImages.length < 1) {
      setProductImagesError(true)
      entered = true
    }
    setTagsError(_tagsError)
    if (NETWORK === "6") {
      sizes.map((element) => {
        if (element.name === "") {

          setSizesError(true)
          entered = true;
        }
      });
    }
    // if (cost === null) {
    //   setCostError('Please insert the cost');
    //   entered = true;
    // }

    if (entered) {
      dispatch(sendAlert('Please insert all the names', EAlertVariant.ERROR));
    }
    return entered;
  };

  const submitEdit = async () => {
    if (!checkForm()) {
      var oldExtras = [];
      var oldIngredients = [];
      var extrasNew = [];
      var ingredientsNew = [];
      for (let index = 0; index < ingredients.length; index++) {
        const element = ingredients[index];
        if (element.isOld === true) {
          oldIngredients.push({
            quantity: element.quantity,
            measure: element.measure,
            ingredient_id: element.ingredient_id,
            stock: element.stock_id
          });
        } else {
          ingredientsNew.push({
            ingredient_id: element.ingredient_id,
            ingredient_stock: {
              measure: showMeasure(element.ingredient_id),
              quantity: element.quantity * 1
            }
          });
        }
      }

      for (let index = 0; index < extras.length; index++) {
        const element = extras[index];
        if (element.isOld === true) {
          oldExtras.push({
            ingredient_id_new: element.ingredient,
            quantity: element.quantity,
            limit: element.limit,
            price: element.price,
            id: element.id,
            is_sauce: element.is_sauce === 0 ? false : true
          });
        } else {
          extrasNew.push({
            ingredient_id: element.ingredient,
            extra: {
              limit: element.limit,
              price: element.price,
              is_sauce: element.is_sauce === 0 ? false : true,
              measure: showMeasure(element.ingredient),
              quantity: element.quantity
            }
          });
        }
      }
      var imagesToSubmit = [...productImages, ...newProductImages]
      var payload = {
        image: imageSource,
        video: video,
        name: name,
        price: Number.isNaN(price) ? 0 : price,
        stock: toInteger(stock),
        // cost: cost,
        description: description,
        category: category,
        allergy_notes: allergyNotes,
        measure: measure,
        oldExtras: oldExtras,
        oldIngredients: oldIngredients,
        newIngredients: ingredientsNew,
        newExtras: extrasNew,
        store: props.store,
        slug: slug,
        size: sizes,
        artist: artistName ? artistName.artist_id : null,
        origin: origin,
        product_sheet: productSheet,
        colour_hex: hexCode,
        network: NETWORK,
        product_images: imagesToSubmit,
        material: material,
        cost: Number.isNaN(cost) ? 0 : cost,
        tags: selectedTags,
        gift_card: giftCard,
        new: newProduct,
        product_size: productSize,
        fiscal_category: fiscalCategory

      }
      if (NETWORK !== "6") {
        delete payload.product_sheet
        delete payload.size
        delete payload.colour_hex
        delete payload.origin
        delete payload.artist
        delete payload.material
        delete payload.cost
        delete payload.product_size
        delete payload.fiscal_category
      }
      if (!productSheet || typeof productSheet === "string") {
        delete payload.product_sheet
      }
      dispatch(
        editProduct(product.product_id, payload, store)
      ).then((result) => {
        //reset()
        handleClose(result);
      });
    }
  };

  const listIngredientsItemsForExtras = props.ingredients.map(
    (ingredient, index) => (
      <MenuItem key={ingredient.name + index} value={ingredient.ingredient_id}>
        {ingredient.name}
      </MenuItem>
    )
  );

  const showMeasure = (id: number) => {
    var measure = '';
    for (let index = 0; index < props.ingredients.length; index++) {
      const element = props.ingredients[index];
      if (element.ingredient_id === id) {
        measure = element.measure;
      }
    }
    return measure;
  };

  const handleChangeingredientIdExtras = (index: any, value: any) => {
    setExtras(
      extras.map((item) =>
        item.extra_id === index
          ? {
            ...item,
            ingredient: value
          }
          : item
      )
    );
  };

  const handleChangeIsSauceExtras = (index: any, value: any) => {
    setExtras(
      extras.map((item) =>
        item.extra_id === index
          ? {
            ...item,
            is_sauce: value
          }
          : item
      )
    );
  };

  const sanitizeVideoUrl = (video: string) => {
    if (typeof video !== 'string') return video;
    if (video.includes('https://res.cloudinary.com')) {
      const formated = video.split('/').slice(4).join('/');
      return formated;
    }

    return 'https://res.cloudinary.com/arbid/' + video;
  };

  const handleChangeLimitExtras = (index: any, value: any) => {
    setExtras(
      extras.map((item) =>
        item.extra_id === index
          ? {
            ...item,
            limit: value
          }
          : item
      )
    );
  };

  const handleChangePriceExtras = (index: any, value: any) => {
    setExtras(
      extras.map((item) =>
        item.extra_id === index
          ? {
            ...item,
            price: value
          }
          : item
      )
    );
  };

  const handleChangeSize = (prop, newValue, idx) => {
    var _sizes = [...sizes]
    _sizes[idx][prop] = newValue
    _sizes[idx]["changed"] = true
    setSizes(_sizes)
  }
  const handleDeleteSize = (idx: number) => {
    var _sizes = [...sizes]
    _sizes[idx]["deleted"] = true
    setSizes(_sizes)
  }

  const handleAddSize = () => {
    var _sizes = [...sizes]
    _sizes.push({
      name: "",
      price: 0,
      stock: 0,
      cost: 0,
      sku: "",
      barcode: "",
      deleted: false
    })
    setSizes(_sizes)
  }
  const handleAddtag = () => {
    var _tags = [...selectedTags]
    var _tagsError = [...tagsError]

    _tags.push('')
    _tagsError.push(
      false
    )
    setTagsError(_tagsError)
    setSelectedTags(_tags)
  }
  const handleDeleteTag = (idx: number) => {
    var _tags = [...selectedTags]
    var _tagsError = [...tagsError]
    _tagsError.splice(idx, 1)
    _tags.splice(idx, 1)
    setSelectedTags(_tags)
    setTagsError(_tagsError)
  }
  const handleChangeTag = (newValue, idx) => {
    var _tags = [...selectedTags]
    _tags[idx] = newValue
    // _tags[idx].name = tags.find((tag) => tag.id === newValue).name
    setSelectedTags(_tags)
  }
  const handleChangeQuantityExtras = (index: any, value: any) => {
    setExtras(
      extras.map((item) =>
        item.extra_id === index
          ? {
            ...item,
            quantity: value
          }
          : item
      )
    );
  };

  function slugify(text) {
    if (text) {
      return text
        .toString()
        .normalize('NFD')
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    } else {
      return '';
    }
  }

  const handleDeleteExtras = (id: number) => {
    const extrasWithoutDeleted = extras.filter((e) => e.extra_id !== id);
    const extraToBeDeleted = extras.find((e) => e.extra_id === id);

    setExtras(extrasWithoutDeleted);
    if (extraToBeDeleted.id) {
      dispatch(deleteExtra(extraToBeDeleted.id, false));
    }
  };

  const listExtras = extras.map((item, index) => (
    <tr key={index}>
      <td style={{ marginTop: 10, marginLeft: 15 }}>
        <Grid item xs={12} sm={9}>
          <FormControl
            style={{
              marginTop: '0px'
            }}
            fullWidth={true}
            margin={'normal'}
          >
            <Select
              id="ingredient-input"
              value={item.ingredient}
              onChange={(e) => {
                handleChangeingredientIdExtras(item.extra_id, e.target.value);
              }}
            >
              {listIngredientsItemsForExtras}
            </Select>
          </FormControl>
        </Grid>
      </td>
      <td style={{ marginTop: 10, marginLeft: 15 }}>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth={true} required={true}>
            <Input
              id="price-input"
              type="number"
              value={item.quantity}
              onChange={(e) => {
                handleChangeQuantityExtras(item.extra_id, e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </td>
      <td style={{ marginTop: 10, marginLeft: 15 }}>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth={true} required={true}>
            <Input
              id="price-input"
              type="number"
              value={item.limit}
              onChange={(e) => {
                handleChangeLimitExtras(item.extra_id, e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </td>
      <td>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth={true} required={true}>
            <Input
              id="price-input"
              type="number"
              value={item.price}
              onChange={(e) => {
                handleChangePriceExtras(item.extra_id, e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </td>

      <td>
        <Grid item xs={12} sm={10}>
          <FormControl fullWidth={true} required={true}>
            <Select
              id="ingredient-input"
              value={item.is_sauce}
              onChange={(e) => {
                handleChangeIsSauceExtras(item.extra_id, e.target.value);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </td>
      <td>
        <>
          <IconButton
            style={{ height: '50px' }}
            onClick={() => handleDeleteExtras(index)}
            color="secondary"
          >
            <DeleteIcon />
          </IconButton>
        </>
      </td>
    </tr>
  ));

  // const showTotalCost = () => {
  //   var cost = 0;
  //   for (let index = 0; index < ingredients.length; index++) {
  //     const element = ingredients[index];
  //     cost = cost + showCost(element.ingredient_id) * element.quantity;
  //   }
  //   return <p>£ {cost}</p>;
  // };
  const getTabs = () => {
    switch (NETWORK) {
      case "2":
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Add Product"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Product" {...a11yProps(0)} />
            <Tab label="Product images" {...a11yProps(1)} />
            <Tab label={"Extras"}{...a11yProps(2)} />
          </Tabs>
        )
      case "4":
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Add Product"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Product" {...a11yProps(0)} />
            <Tab label="Product images" {...a11yProps(1)} />
            <Tab label={"Extras"}{...a11yProps(2)} />
          </Tabs>
        )
      case "6":
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Add Product"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Product" {...a11yProps(0)} />
            <Tab label="Product images" {...a11yProps(1)} />
            <Tab label="Variations"{...a11yProps(2)} />
            <Tab label="Tags"{...a11yProps(3)} />

          </Tabs>
        )
      default:
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Add Product"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Product" {...a11yProps(0)} />
            <Tab label="Product images" {...a11yProps(1)} />
            <Tab label={"Extras"}{...a11yProps(2)} />
          </Tabs>
        )
    }
  }
  const getTabTitle = () => {
    switch (NETWORK) {
      case "2":
        return (
          <>
            {value === 0
              && 'Edit product'
            }
            {value === 1
              && 'Edit product images'
            }
            {value === 2
              && 'Edit extras'
            }
          </>
        )
      case "6":
        return (
          <>
            {value === 0
              && 'Edit product'
            }
            {value === 1
              && 'Edit product images'
            }
            {value === 2
              && 'Edit variations'
            }
            {value === 3
              && 'Edit tags'
            }

          </>
        )
      default:
        return (
          <>
            {value === 0
              && 'Edit product'
            }
            {value === 1
              && 'Edit product images'
            }
            {value === 2
              && 'Edit extras'
            }
          </>
        )
    }
  }
  const removeOldImage = (idx) => {
    try {
      var _images = [...productImages]
      _images[idx].deleted = true
      setProductImages(_images)
    } catch (error) {
      console.log("error")
    }
  }
  const openNewTab = (image) => {
    if (image.oldImage) {
      window.open(image.image.image)
    } else {
      var url = URL.createObjectURL(image)
      window.open(url)
    }
  }
  const removeImage = (idx) => {
    var _images = [...newProductImages]
    _images.splice(idx, 1)

    setNewProductImages(_images)
  }
  const returnTabs = () => {
    switch (NETWORK) {
      case "2":
        return (
          <>
            < TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    filesLimit={20}
                    dropzoneText={'Click or drag and drop the product images here'}
                    onChange={(files) => {
                      if (files.length > 0) {
                        setNewProductImages(files)
                      }
                    }}
                  />
                </ThemeProvider>

                <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>
                    {productImagesError && productImages.length < 1 && newProductImages.length < 1 && <Typography style={{ color: "red" }}>At least one product image is required</Typography>}
                    {
                      productImages.map((image, idx) => (
                        <>
                          {!image.deleted ? <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                            <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                            <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeOldImage(idx)}>
                              <CloseIcon />
                            </IconButton>
                            <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                              <OpenInNewIcon />
                            </IconButton>
                          </div> : null}
                        </>
                      ))
                    }
                    {
                      newProductImages.map((image, idx) => (
                        <>
                          {!image.deleted ? <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                            <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                            <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeImage(idx)}>
                              <CloseIcon />
                            </IconButton>
                            <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                              <OpenInNewIcon />
                            </IconButton>
                          </div> : null}
                        </>
                      ))
                    }
                  </div>
                </div>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Container maxWidth="md">
                <table className="ingredients-table-container">
                  <thead>
                    <th className="extra-th">Extra</th>
                    <th>Quantity per serving</th>
                    <th>Limit of serving</th>
                    <th>Price per serving</th>
                    <th className="sauce-th">Is it a sauce?</th>
                    <th></th>
                  </thead>
                  <tbody>{listExtras}</tbody>
                </table>
                <IconButton
                  color="primary"
                  onClick={() => {
                    var data = {
                      extra_id: 0,
                      ingredient: '',
                      limit: 0,
                      price: '',
                      // is_sauce: 0,
                      quantity: 0,
                      isOld: false
                    };
                    if (extras.length >= 1) {
                      data.extra_id = extras[extras.length - 1].extra_id + 1;
                    }
                    setExtras(extras.concat(data));
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Container>
            </TabPanel>


          </>
        )
      case "4":
        return (
          <>
            < TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    filesLimit={20}
                    dropzoneText={'Click or drag and drop the product images here'}
                    onChange={(files) => {
                      if (files.length > 0) {
                        setNewProductImages(files)
                      }
                    }}
                  />
                </ThemeProvider>

                <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>                    {productImagesError && <Typography style={{ color: "red" }}>At least one product image is required</Typography>}
                    {productImagesError && productImages.length < 1 && newProductImages.length < 1 && <Typography style={{ color: "red" }}>At least one product image is required</Typography>}
                    {
                      productImages.map((image, idx) => (
                        <>
                          {!image.deleted ?
                            <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                              <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                              <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeOldImage(idx)}>
                                <CloseIcon />
                              </IconButton>
                              <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                                <OpenInNewIcon />
                              </IconButton>
                            </div>
                            : null}
                        </>
                      ))
                    }
                    {
                      newProductImages.map((image, idx) => (
                        <>
                          {!image.deleted ? <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                            <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                            <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeImage(idx)}>
                              <CloseIcon />
                            </IconButton>
                            <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                              <OpenInNewIcon />
                            </IconButton>
                          </div> : null}
                        </>
                      ))
                    }
                  </div>
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container maxWidth="md">
                <table className="ingredients-table-container">
                  <thead>
                    <th className="extra-th">Extra</th>
                    <th>Quantity per serving</th>
                    <th>Limit of serving</th>
                    <th>Price per serving</th>
                    <th className="sauce-th">Is it a sauce?</th>
                    <th></th>
                  </thead>
                  <tbody>{listExtras}</tbody>
                </table>
                <IconButton
                  color="primary"
                  onClick={() => {
                    var data = {
                      extra_id: 0,
                      ingredient: '',
                      limit: 0,
                      price: '',
                      // is_sauce: 0,
                      quantity: 0,
                      isOld: false
                    };
                    if (extras.length >= 1) {
                      data.extra_id = extras[extras.length - 1].extra_id + 1;
                    }
                    setExtras(extras.concat(data));
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Container>
            </TabPanel>

          </>
        )
      case "6":
        return (
          <>
            < TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    filesLimit={20}
                    dropzoneText={'Click or drag and drop the product images here'}
                    onChange={(files) => {
                      setNewProductImages(files)

                    }}
                  />
                </ThemeProvider>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>
                  {productImagesError && productImages.length < 1 && newProductImages.length < 1 && <Typography style={{ color: "red" }}>At least one product image is required</Typography>}
                  {
                    productImages.map((image, idx) => (
                      <>
                        {!image.deleted ? <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                          <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                          <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeOldImage(idx)}>
                            <CloseIcon />
                          </IconButton>
                          <IconButton style={{ position: "absolute", right: 0, bottom: -10 }} onClick={() => openNewTab(image)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </div> : null}
                      </>
                    ))
                  }
                  {
                    newProductImages.map((image, idx) => (
                      <>
                        {!image.deleted ? <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", position: "relative" }}>
                          <img src={generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />
                          <IconButton style={{ position: "absolute", right: 0, top: 0 }} onClick={() => removeImage(idx)}>
                            <CloseIcon />
                          </IconButton>
                          <IconButton style={{ position: "absolute", right: 0, bottom: -10 }} onClick={() => openNewTab(image)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </div> : null}
                      </>
                    ))
                  }
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container maxWidth="md">
                <>
                  {sizes.map((size, idx) => (
                    <>
                      {!size.deleted && (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderTop: "2px solid #eeeeee",
                            padding: "10px",
                            marginBottom: "15px",
                            marginTop: "15px",
                            gap: "10px"
                          }}
                          key={idx}
                        >
                          <Grid className="!ml-[0px]" container spacing={2} xs={12} md={11}>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                error={sizesError && size.name === ""}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="price-input">Name</InputLabel>
                                <Input
                                  id="price-input"
                                  type="string"
                                  value={size.name}
                                  onChange={(e) => {
                                    handleChangeSize("name", e.target.value, idx);
                                  }}
                                />
                                {sizesError && size.name === "" ? (
                                  <FormHelperText>
                                    Please fill all fields in sizes
                                  </FormHelperText>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                error={sizesError && !size.price}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="stock-input">Price</InputLabel>
                                <Input
                                  id="stock-input"
                                  type="number"
                                  value={size.price}
                                  onChange={(e) =>
                                    handleChangeSize("price", e.target.value, idx)
                                  }
                                />
                                {sizesError && !size.price ? (
                                  <FormHelperText>
                                    Please fill all fields in sizes
                                  </FormHelperText>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                // error={sizesError && !size.price}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="stock-input">Cost</InputLabel>
                                <Input
                                  id="stock-input"
                                  type="number"
                                  value={size.cost}
                                  onChange={(e) =>
                                    handleChangeSize("cost", e.target.value, idx)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                // error={sizesError && !size.price}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="stock-input">Stock</InputLabel>
                                <Input
                                  id="stock-input"
                                  type="number"
                                  value={size.stock}
                                  onChange={(e) =>
                                    handleChangeSize("stock", e.target.value, idx)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                //error={sizesError && !size.price}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="stock-input">SKU</InputLabel>
                                <Input
                                  id="stock-input"
                                  type="string"
                                  value={size.sku}
                                  onChange={(e) =>
                                    handleChangeSize("sku", e.target.value, idx)
                                  }
                                />
                                {/*sizesError && !size.price ? (                <FormHelperText>Please fill all fields in sizes</FormHelperText>
                        ) : (
                          ""
                        )*/}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl
                                className="fix-label"
                                //error={sizesError && !size.price}
                                fullWidth={true}
                                margin={"normal"}
                              >
                                <InputLabel htmlFor="stock-input">Bar code</InputLabel>
                                <Input
                                  id="stock-input"
                                  type="string"
                                  value={size.barcode}
                                  onChange={(e) =>
                                    handleChangeSize("barcode", e.target.value, idx)
                                  }
                                />
                                {/*sizesError && !size.price ? (
                          <FormHelperText>Please fill all fields in sizes</FormHelperText>
                        ) : (
                          ""
                        )*/}
                              </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={3}>
                      <FormControl
                        error={sizesError && size.stock === 0}
                        fullWidth={true}
                        margin={"normal"}
                      >
                        <InputLabel htmlFor="stock-input">Stock</InputLabel>
                        <Input
                          id="stock-input"
                          type="number"
                          value={size.stock}
                          onChange={(e) => handleChangeSize("stock", e.target.value, idx)}
                        />
                        {sizesError && size.stock === 0 ? (
                          <FormHelperText>Please fill all fields in sizes</FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid> */}
                          </Grid>
                          <Grid
                            xs={12}
                            sm={1}
                          >
                            <IconButton
                              onClick={() => handleDeleteSize(idx)}
                              style={{
                                backgroundColor: "#fff",
                                fill: "#fff",

                              }}
                            >
                              <DeleteIcon />{" "}
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                  <IconButton onClick={() => handleAddSize()}>
                    <AddIcon />
                  </IconButton>
                </>
              </Container>
            </TabPanel>
            < TabPanel value={value} index={3}>
              <Container maxWidth="md">
                <>
                  {selectedTags.map((tag, idx) => (
                    <Grid container style={{ alignItems: "end" }}>
                      <Grid item xs={11} sm={11}>
                        <FormControl
                          className="fix-label"
                          error={tagsError[idx] && !tag}
                          fullWidth={true}
                          margin={"normal"}
                        >
                          <InputLabel htmlFor="stock-input">Tag</InputLabel>
                          <Select
                            id="stock-input"
                            type="string"
                            value={tag}
                            onChange={(e) =>
                              handleChangeTag(e.target.value, idx)
                            }
                          >
                            {tags.map((_tag) => (
                              <MenuItem value={_tag.id}>{_tag.name}</MenuItem>
                            ))}
                          </Select>
                          {tagsError[idx] && !tag ? (
                            <FormHelperText>
                              Please select a tag
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        xs={11}
                        sm={1}
                      >
                        <IconButton
                          onClick={() => handleDeleteTag(idx)}
                          style={{
                            backgroundColor: "#fff",
                            fill: "#fff",

                          }}
                        >
                          <DeleteIcon />{" "}
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <IconButton onClick={() => handleAddtag()}>
                    <AddIcon />
                  </IconButton>
                </>
              </Container>
            </TabPanel>
          </>
        )
      default:
        return (
          <>
            < TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    filesLimit={20}
                    dropzoneText={'Click or drag and drop the product images here'}
                    onChange={(files) => {
                      if (files.length > 0) {
                        setNewProductImages(files)
                      }
                    }}
                  />
                </ThemeProvider>

                <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px", flexWrap: "wrap" }}>
                  {productImagesError && <Typography style={{ color: "red" }}>At least one product image is required</Typography>}
                  {
                    productImages.map((image, idx) => (
                      <>

                        {!image.deleted &&
                          <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", display: "flex", flexDirection: "column", alignItems: "end", marginBottom: "35px" }}>
                            <IconButton onClick={() => removeOldImage(idx)}>
                              <CloseIcon />
                            </IconButton>
                            <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                              <OpenInNewIcon />
                            </IconButton>
                            <img src={typeof image.image === "string" ? image.image : generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />

                          </div>}</>
                    ))
                  }
                  {
                    newProductImages.map((image, idx) => (
                      <div key={idx} style={{ height: "100px", width: "100px", marginRight: "10px", display: "flex", flexDirection: "column", alignItems: "end", marginBottom: "35px" }}>
                        <IconButton onClick={() => removeImage(idx)}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton style={{ position: "absolute", right: 0, bottom: 0 }} onClick={() => openNewTab(image)}>
                          <OpenInNewIcon />
                        </IconButton>
                        <img src={typeof image === "string" ? image : generateURL(image)} alt="" style={{ height: "100px", width: "100px", objectFit: "cover", }} />

                      </div>
                    ))
                  }
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container maxWidth="md">
                <table className="ingredients-table-container">
                  <thead>
                    <th className="extra-th">Extra</th>
                    <th>Quantity per serving</th>
                    <th>Limit of serving</th>
                    <th>Price per serving</th>
                    <th className="sauce-th">Is it a sauce?</th>
                    <th></th>
                  </thead>
                  <tbody>{listExtras}</tbody>
                </table>
                <IconButton
                  color="primary"
                  onClick={() => {
                    var data = {
                      extra_id: 0,
                      ingredient: '',
                      limit: 0,
                      price: '',
                      // is_sauce: 0,
                      quantity: 0,
                      isOld: false
                    };
                    if (extras.length >= 1) {
                      data.extra_id = extras[extras.length - 1].extra_id + 1;
                    }
                    setExtras(extras.concat(data));
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Container>
            </TabPanel>
          </>
        )
    }
  }
  const firstStep = () => {
    return (
      <TabPanel value={value} index={0}>
        <Container maxWidth="md">
          <Grid container spacing={7}>
            {/* Start of left column */}
            <Grid item xs={12} sm={9}>
              {NETWORK === "6" ? <Grid style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox checked={newProduct} onChange={() => setNewProduct(!newProduct)} />
                  <Typography>Is this a new product?</Typography>
                </div>
              </Grid> : null}
              {NETWORK === "6" ? <Grid style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox checked={giftCard} onChange={() => setGiftCard(!giftCard)} />
                  <Typography>Is this product a giftcard?</Typography>
                </div>
              </Grid> : null}
              <FormControl
                fullWidth={true}
                required={true}
                error={nameError !== ''}
              >
                <InputLabel htmlFor="name-input">Name</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={name}
                  onChange={handleName}
                />
                <FormHelperText>{nameError}</FormHelperText>
              </FormControl>
              <br />
              <Tooltip title="The friendly URL is the url that will appear when the client open the product page">
                <FormControl
                  margin={'normal'}
                  fullWidth={true}
                  required={true}
                  error={slugError !== ''}
                >
                  <InputLabel disabled htmlFor="name-input">
                    Friendly URL
                  </InputLabel>
                  <Input
                    id="name-input"
                    onChange={(e) => handleSlug(e.target.value)}
                    type="string"
                    value={slug}
                    onBlur={() => handleSlug(slugify(slug))}
                  />
                  <FormHelperText>{slugError}</FormHelperText>
                </FormControl>
              </Tooltip>
              <FormControl
                fullWidth={true}
                margin={'normal'}
                required
                error={measureError !== ''}
              >
                <InputLabel htmlFor="measure-input">Measure</InputLabel>
                <Select
                  id="measure-input"
                  value={measure}
                  onChange={handleMeasure}
                >
                  <MenuItem value={'Unit'}>Unit</MenuItem>
                  <MenuItem value={'kg'}>Kilogram, kg</MenuItem>
                  <MenuItem value={'l'}>Litre, l</MenuItem>
                  <MenuItem value={'m'}>Metre, m</MenuItem>
                </Select>
                <FormHelperText>{measureError}</FormHelperText>
              </FormControl>
              {NETWORK == '6' ?
                <>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ display: "flex", alignItems: "center", flexDirection: "row" }} fullWidth={true} margin={'normal'}>
                        <Autocomplete
                          style={{ width: "inherit" }}
                          value={artistName}
                          onChange={(event: any, newValue: string | null, reason, details) => {
                            setArtistName(newValue);
                          }}
                          inputValue={artistQuery}
                          onInputChange={(event, newInputValue) => {
                            setArtistQuery(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={artists}
                          getOptionLabel={(option) => {
                            if (option !== undefined) {
                              return option["name"]
                            } else return '';
                          }}
                          renderOption={(option) =>
                            option !== undefined ? option.name : ''
                          }
                          loading={artists.length === 0}
                          loadingText={'Loading artists'}
                          renderInput={(params) => (
                            <FormControl
                              style={{
                                marginTop: '0px'
                                // width: '17.5vw'
                              }}
                              fullWidth={true}
                              margin={'normal'}
                              required
                            >
                              <TextField {...params} label="Artist *" />
                            </FormControl>
                          )}
                        />
                        <IconButton onClick={() => setOpenCreateArtist(true)}>
                          <AddIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} margin={'normal'}>
                        <InputLabel htmlFor="stock-input">Specifics</InputLabel>
                        <Input
                          id="stock-input"
                          type="text"
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Grid style={{ marginTop: "-35px" }} container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} margin={'normal'}>
                        <InputLabel htmlFor="stock-input">Color</InputLabel>
                        <Input
                          id="stock-input"
                          type="text"
                          value={hexCode}
                          onChange={(e) => setHexCode(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} margin={'normal'}>
                        <InputLabel htmlFor="stock-input">Material</InputLabel>
                        <Input
                          id="stock-input"
                          type="text"
                          value={material}
                          onChange={(e) => setMaterial(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
                : null}
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  {categories && (
                    <FormControl
                      fullWidth={true}
                      margin={'normal'}
                      error={categoryError !== ''}
                      required
                    >
                      <Autocomplete
                        value={category}
                        onChange={(event: any, newValue: string | null) => {
                          handleCategory(newValue);
                        }}
                        inputValue={inputValueCategory}
                        onInputChange={(event, newInputValue) => {
                          setInputValueCategory(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={categories.map((element) => {
                          return element.name;
                        })}
                        getOptionLabel={(option) => {
                          if (option !== undefined) {
                            return option;
                          } else return '';
                        }}
                        renderOption={(option) =>
                          option !== undefined ? option : ''
                        }
                        loading={categories.length === 0}
                        loadingText={'Loading categories'}
                        renderInput={(params) => (
                          <FormControl
                            style={{
                              marginTop: '0px'
                              // width: '17.5vw'
                            }}
                            fullWidth={true}
                            margin={'normal'}
                            error={categoryError !== ''}
                            required
                          >
                            <TextField {...params} label="Category *" />
                            <FormHelperText>{categoryError}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
                {NETWORK === "6" && <Grid item xs={12} sm={6}>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="stock-input">Product size</InputLabel>
                    <Select
                      required
                      labelId="label"
                      id="select-template"
                      value={productSize}
                      onChange={(e) => setProductSize(e.target.value)}
                    >
                      {productSizes.map((element) => {
                        return (
                          <MenuItem key={element} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    {productSizeError && productSize === "" ? (
                      <FormHelperText>
                        Please select the product size
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>}
              </Grid>
              <Grid container spacing={5}>
                {["2", '6'].includes(NETWORK) && <Grid item xs={12} sm={6}>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="stock-input">Fiscal category</InputLabel>
                    <Select
                      required
                      labelId="label"
                      id="select-template"
                      value={fiscalCategory}
                      onChange={(e) => setFiscalCategory(e.target.value)}
                    >
                      {["PRODUCT", "SERVICE"].map((element) => {
                        return (
                          <MenuItem key={element} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    {fiscalCategoryError && fiscalCategory === "" ? (
                      <FormHelperText>
                        Please select the fiscal category
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>}
              </Grid>
              <Grid container spacing={5}>


                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="price-input">{NETWORK === '6' ? "Retail price" : "Price"}</InputLabel>
                    <Input
                      id="price-input"
                      disabled={sizes.length > 0}
                      type="number"
                      value={price}
                      onChange={handlePrice}
                    />
                  </FormControl>
                </Grid>
                {NETWORK === '6' ? <Grid item xs={12} sm={4}>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="cost-input">Cost</InputLabel>
                    <Input
                      id="price-input"
                      type="number"
                      value={cost}
                      onChange={(e) => setCost(parseFloat(e.target.value))}
                    />
                  </FormControl>
                </Grid> : null}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <InputLabel htmlFor="stock-input">Stock</InputLabel>
                    <Input
                      id="stock-input"
                      type="number"
                      disabled={sizes.length > 0}

                      value={stock}
                      onChange={handleStock}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <FormControl
                      fullWidth={true}
                      margin={'normal'}
                      required
                      error={costError !== ''}
                    >
                      <InputLabel htmlFor="stock-input">Cost</InputLabel>
                      <Input
                        id="stock-input"
                        type="number"
                        value={cost}
                        disabled={ingredients.length >= 1 ? true : false}
                        onChange={handleCost}
                      />
                      <FormHelperText>{costError}</FormHelperText>
                    </FormControl>
                  </Grid> */}
              </Grid>
              <FormControl fullWidth={true} margin={'normal'}>
                <Typography >
                  Description
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ckEditorToolbarConfig
                  }}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleDescription(data);
                  }}
                />
                {/* <TextField
                  id="outlined-secondary"
                  label="Description"
                  variant="outlined"
                  color="primary"
                  value={description}
                  onChange={handleDescription}
                /> */}
              </FormControl>
              {NETWORK !== '6' &&
                <FormControl fullWidth={true} margin={'normal'}>
                  <InputLabel htmlFor="alergy-notes-input">
                    Allergy Notes
                  </InputLabel>
                  <Input
                    id="alergy-notes-input"
                    type="string"
                    value={allergyNotes}
                    onChange={handleAllergyNotes}
                  />
                </FormControl>}

              <Button
                onClick={() => {
                  getQRCode();
                }}
                variant="outlined"
                color="primary"
              >
                Generate QR Code
              </Button>
            </Grid>
            {/* End of left column */}
            <Grid item xs={12} sm={3}>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={'Drag and drop an image here or click'}
                    onChange={(files) => handleImage(files)}
                    filesLimit={1}
                  />
                </ThemeProvider>
                {imageSource === undefined && imageFromBackend !== '' ? (
                  <img
                    src={imageFromBackend}
                    style={{
                      width: '3vw',
                      height: '3vw',
                      marginTop: '1vw',
                      marginLeft: '3vw'
                    }}
                  />
                ) : (
                  ''
                )}
                {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['video/*']}
                    dropzoneText={'Drag and drop a video here or click'}
                    onChange={(files) => handleVideo(files)}
                    filesLimit={1}
                  />
                </ThemeProvider>

                {videoFromBackend && !videoFromBackend.includes("undefined") && (
                  <video
                    id="video"
                    src={sanitizeVideoUrl(videoFromBackend)}
                    controls
                    style={{ width: '100%', marginTop: '10px' }}
                  ></video>
                )}
              </Grid>
              {NETWORK === "6" ? <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                <ThemeProvider theme={theme}>
                  <DropzoneArea
                    acceptedFiles={['image/*', 'application/pdf']}
                    dropzoneText={'Click or drag and drop the product sheet here'}
                    onChange={(files) => handleProductSheet(files)}
                    filesLimit={1}
                  />
                </ThemeProvider>

                <div>
                  {productSheet ? (
                    productSheet instanceof File ? (
                      <a href={generateURL(productSheet)} target="_blank">{productSheet.name}</a>
                    ) : (
                      <>
                        {typeof productSheet === "string" ? <a href={productSheet} target="_blank">See attached file</a> :
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <div>{productSheet}</div>
                            <IconButton onClick={() => setProductSheet(undefined)}>
                              <CloseIcon />
                            </IconButton>
                          </div>}
                      </>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </Grid> : null}
            </Grid>
          </Grid>
        </Container>
      </TabPanel>
    )
  }
  const handleSave = () => {
    switch (NETWORK) {
      case "2":
        if (value === 2) {
          submitEdit()
        } else {
          setValue(value + 1)
        }
        break;
      case "4":
        if (value === 2) {
          submitEdit()
        } else {
          setValue(value + 1)
        }
        break;
      case "6":
        if (value === 3) {
          submitEdit()
        } else {
          setValue(value + 1)
        }
        break;
      default:
        if (value === 2) {
          submitEdit()
        } else {
          setValue(value + 1)
        }
        break;
    }
  }
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={qrcodeOpen}
          onClose={() => setQrcodeOpen(false)}
          closeAfterTransition
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={qrcodeOpen}>
            <div>
              <img alt="QR Code" src={qrcode} width="200" height="200" />
            </div>
          </Fade>
        </Modal>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose(undefined)}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose(undefined)}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {getTabTitle()}
            <Button
              onClick={() => { handleSave() }}
              variant="outlined"
              color="primary"
            >
              Save
            </Button>
          </div>
        </DialogTitle>
        <AppBar position="static" color="default">
          {getTabs()}
        </AppBar>
        {firstStep()}
        {returnTabs()}
        <ModalCreateArtist open={openCreateArtist} onClose={(newArtist) => {
          setArtistName(newArtist)
          setOpenCreateArtist(false)
        }} refresh={() => getArtists()} />
      </div>
    </Modal >
  );
}
