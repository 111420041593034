import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Grid,
    FormControl,
    IconButton,
    Typography,
    Switch,
    Input,
    InputLabel
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import './../styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { editGiftCard } from 'redux/ducks/stock';
import { IGiftCard } from 'interfaces/store';
export interface SimpleDialogProps {
    open: boolean;
    store?: number;
    onClose: () => void;
    fetchGiftCards: () => void;
    giftCard: IGiftCard
}

export default function ModalEditGiftCard(props: SimpleDialogProps) {
    const { onClose, open, store, fetchGiftCards, giftCard } = props;
    const [active, setActive] = useState(true)
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const dispatch = useDispatch();
    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        if (giftCard) {
            setActive(giftCard.active)
            setEmail(giftCard.gifted_email)
            setCode(giftCard.code)
        }
    }, [])
    const submitCreation = async () => {
        dispatch(
            editGiftCard({
                active,
                gifted_email: email,
                store,
                code: code
            }, giftCard.id)
        ).then((result: boolean) => {
            fetchGiftCards()
            if (result) handleClose();
        });
    };
    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleClose()}
                >
                    <IconButton
                        style={{ width: '50px' }}
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography color="secondary">Close</Typography>
                </div>
                <h2>Add giftcard</h2>
                <Container maxWidth="md">
                    <Grid item xs={12} spacing={5} sm={12}>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <Typography >Active/Inactive</Typography>
                            <Switch checked={active} onChange={() => setActive(!active)} />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">Email</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: '15px' }}>
                            <InputLabel htmlFor="name-input">Code</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    {/* End of left column */}
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ marginBottom: '30px' }}
                                variant="outlined"
                                onClick={submitCreation}
                                color="primary"
                            >
                                {' '}
                                Create{' '}
                            </Button>
                        </div>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
}
