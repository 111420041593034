import React from 'react';

import { IKitchen } from 'interfaces/kitchen';

import formatDate from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';

import { getPaymentType } from 'helpers/common';
import { getDeliveryType } from 'helpers/kitchen';
import Logo from 'helpers/logo';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PrintIcon from '@material-ui/icons/PrintOutlined';

import Print from 'components/Common/Print';

import { useStyles } from './styles';

interface IProps {
  order: IKitchen;
  disabled?: boolean;
}

export default function Receipt(props: IProps): JSX.Element {
  const { order, disabled } = props;
  const { order_code, date_ordered, items, date_delivery } = order;
  const classes = useStyles();

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <PrintIcon />
        </Button>
      }
    >
      <Card>
        <Grid container justify="center" className={classes.receiptHeader}>
          <Grid>
            <Logo styling={{ width: "150px", height: "150px" }} />
            <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>
              {order_code?.toUpperCase()}
            </Typography>
            {!date_delivery ? (
              <Typography style={{ marginBottom: 10 }}>
                {formatDate(new Date(date_ordered), 'dd/MM/yyyy - hh:mm')}
              </Typography>
            ) : (
              <Typography>
                {formatDate(new Date(date_ordered), 'dd/MM/yyyy - hh:mm')}
              </Typography>
            )}
            {date_delivery && (
              <Typography style={{ marginBottom: 10 }}>
                {formatDate(new Date(date_delivery), 'dd/MM/yyyy - hh:mm')}
              </Typography>
            )}

            <Typography style={{ fontWeight: 'bold' }}>CLIENT DATA</Typography>
            <Typography>{order?.owner?.name ?? order?.client?.name}</Typography>
            <Typography style={{ marginBottom: 10 }}>
              {order?.owner?.phone_number ?? order?.client?.phone}
            </Typography>

            {order?.delivery_address && (
              <Typography style={{ fontWeight: 'bold' }}>
                DELIVERY ADDRESS
              </Typography>
            )}
            {order?.delivery_address && (
              <Typography style={{ marginBottom: 10, maxWidth: 200 }}>
                {`${order?.delivery_address}`}
              </Typography>
            )}

            <Typography style={{ fontWeight: 'bold' }}>
              PAYMENT DETAILS
            </Typography>
            <Typography>Paid: {order?.is_paid ? 'Yes' : 'No'}</Typography>
            <Typography>Payment: {getPaymentType(order?.payment)}</Typography>
            <Typography>
              Type: {getDeliveryType(order?.type_of_order)}
            </Typography>
            {order?.delivery_address && (
              <>
                <Typography>
                  Subtotal: £:{' '}
                  {(
                    Number(order?.total_order?.toFixed(2)) -
                    Number(order?.delivery_fee)
                  ).toFixed(2)}
                </Typography>
                <Typography>
                  Delivery Fee: £ {order?.delivery_fee?.toFixed(2)}
                </Typography>
                <Typography>
                  Discount: £{' '}
                  {order?.discount ? order?.discount?.toFixed(2) : '0.00'}
                </Typography>
                <Typography>
                  Deliveryman:{' '}
                  {`${order?.gomotoboy_driver_name
                      ? order?.gomotoboy_driver_name
                      : 'Not selected'
                    }`}
                </Typography>
              </>
            )}
            {order?.store_to_collect && (
              <>
                <Typography>
                  Pickup Address: {order?.store_to_collect}
                </Typography>
              </>
            )}
            <Typography style={{ marginBottom: '10px' }}>
              Total: £: {order?.total_order?.toFixed(2)}
            </Typography>
            {order?.cash_change && (
              <Typography>
                Change: £ {Number(order?.cash_change).toFixed(2)}
              </Typography>
            )}

            <Typography style={{ fontWeight: 'bold' }}>
              Order Summary
            </Typography>
            {items.map((item) => (
              <span key={uuidv4()}>
                <Typography variant="h6" key={item?.product?.product_id}>
                  {`${item?.quantity}x ${item?.product?.name}`}
                </Typography>
                {item?.extra_orders.map((extra) => (
                  <Typography key={extra.id} style={{ marginLeft: 15 }}>
                    {`${extra?.quantity} x Extra ${extra?.ingredient}`}
                  </Typography>
                ))}
              </span>
            ))}
            {order.final_comment && (
              <>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>
                  Comments
                </Typography>
                <Typography
                  style={{ marginBottom: 10 }}
                >{`${order.final_comment}`}</Typography>
              </>
            )}
            {order.set_driver_qr &&
              getDeliveryType(order?.type_of_order) === 'Entrega' && (
                <img
                  alt="QR Code"
                  src={order.set_driver_qr}
                  width="200"
                  height="200"
                />
              )}
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

Receipt.defaultProps = {
  disabled: false
};
