import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { editSeller, fetchBestSellers } from 'redux/ducks/customersAndSellers';
import { fetch } from 'utils/request';
import { useEffect } from 'react';
import { EMethod } from 'enums/method';
import { Seller } from 'interfaces/customersAndSellers';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  seller: Seller;
}

export default function ModalEditVendedor(props: SimpleDialogProps) {
  const { open, onClose, seller } = props;
  const handleClose = () => {
    onClose();
  };
  const dispatch = useDispatch();
  const [commission, setCommission] = React.useState(0.0);
  const [username, setUsername] = React.useState('');
  const [usernameError, setUsernameError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [isManager, setIsManager] = React.useState(false);
  const [store, setStore] = React.useState<number>();
  const [storeError, setStoreError] = React.useState('');
  const [stores, setStores] = React.useState([]);

  const _getStores = async () => {
    const response = await fetch({
      method: EMethod.GET,
      url: '/products/api/stores/'
    });
    setStores(response.results);
  };

  useEffect(() => {
    _getStores();
  }, []);

  useEffect(() => {
    if (seller) {
      setUsername(seller.username);
      setPassword(seller.password);
      setIsManager(seller.is_manager);
      setStore(seller.store);
      setCommission(seller.commission);
    }
  }, [seller]);

  const handleUsername = (value: any) => {
    setUsername(value);
    setUsernameError('');
  };
  const handlePassword = (value: any) => {
    setPassword(value);
    setPasswordError('');
  };

  const handleStore = (value: any) => {
    setStore(value);
    setStoreError('');
  };

  const handleCommission = (value: any) => {
    setCommission(value);
  };
  const checkForm = () => {
    let entered = false;
    if (store === null || store === undefined || store === 0) {
      setStoreError('Please insert store name');
      entered = true;
    }
    if (username === '') {
      entered = true;
      setUsernameError('Please insert user name');
    }
    if (password === '') {
      entered = true;
      setPasswordError('Please insert password');
    }
    return entered;
  };
  const submitEdit = async () => {
    if (!checkForm()) {
      const x = await dispatch(
        editSeller(seller?.id, {
          username,
          password,
          is_manager: isManager,
          store
        })
      );
      if (x === 1) {
        dispatch(fetchBestSellers());
        handleClose();
      }
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={handleClose}
    >
      <div className="modal-create-menu-content">
      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <h2>Edit Seller</h2>
        <Container maxWidth="md">
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={usernameError !== ''}
            >
              <InputLabel htmlFor="name-input">Username</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={username}
                onChange={(e) => handleUsername(e.target.value)}
              />
              <FormHelperText>{usernameError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={passwordError !== ''}
            >
              <InputLabel htmlFor="name-input">Password</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={password}
                onChange={(e) => handlePassword(e.target.value)}
              />
              <FormHelperText>{usernameError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Commission</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={commission}
                onChange={(e) => handleCommission(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isManager}
                    onChange={() => {
                      setIsManager(!isManager);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Manager"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={storeError !== ''}
            >
              <InputLabel htmlFor="name-input">Store</InputLabel>
              <Select
                labelId="label"
                id="select-template"
                value={store}
                onChange={(e) => handleStore(e.target.value)}
              >
                {stores.map((element) => {
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{storeError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitEdit}
                color="primary"
              >
                Edit
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
