import React from 'react';
import {
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Container,
  Grid,
  FormControl,
  IconButton,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createMenu } from 'redux/ducks/menu';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { NETWORK } from 'settings'
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  store?: number;
  categories: any[];
}
const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: '200px'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      image: {
        display: 'none'
      },
      imageContainer: {
        maxWidth: 'auto',
        height: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open, store, categories } = props;
  const [bannerSource, setBannerSource] = React.useState<File>();
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  //const [typeOfMenu, setTypeOfMenu] = React.useState('Take Away');
  const [maxOfItems, setMaxOfItems] = React.useState(0);
  const [maxOfSameItem, setMaxOfSameItem] = React.useState(0);
  const [active, setActive] = React.useState(true);
  const [sections, setSections] = React.useState([
    {
      category: '',
      template: '',
      nameEn: '',
      namePt: '',
      id: 0
    }
  ]);
  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setActive(true);
    setMaxOfItems(0);
    setMaxOfSameItem(0);
    setSections([
      {
        category: '',
        template: '',
        nameEn: '',
        namePt: '',
        id: 0
      }
    ]);
    onClose();
  };

  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
  };


  const handleMaxOfItems = (event: any) => {
    setMaxOfItems(event.target.value);
  };

  const handleMaxOfSameItem = (event: any) => {
    setMaxOfSameItem(event.target.value);
  };

  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert name');
      entered = true;
    }
    return entered;
  };

  const submitCreation = async () => {
    if (!checkForm()) {
      const sectionsToSubmit = [];
      for (let index = 0; index < sections.length; index++) {
        const element = sections[index];
        sectionsToSubmit.push({
          category: element.category,
          template: 'product',
          nameEn: element.category,
          namePt: element.category
        });
      }
      dispatch(
        createMenu({
          banner: bannerSource,
          name,
          type_of_menu: "Take Away",
          max_quantity_of_items: 100,
          max_quantity_of_same_item: maxOfSameItem,
          store,
          active,
          sections: JSON.stringify(sectionsToSubmit)
        })
      ).then((result: boolean) => {
        if (result) handleClose();
      });
    }
  };

  const listCategories = categories.map((category, index) => (
    <MenuItem key={index} value={category.name}>
      {category.name}
    </MenuItem>
  ));

  const handleChangeCategory = (index: any, value: any) => {
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
            ...item,
            category: value
          }
          : item
      )
    );
  };

  const handleChangeTemplate = (index: any, value: any) => {
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
            ...item,
            template: value
          }
          : item
      )
    );
  };
  // const handleChangeEnSectionName = (index: any, value: any) => {
  //   setSections(
  //     sections.map((item) =>
  //       item.id === index
  //         ? {
  //             ...item,
  //             nameEn: value
  //           }
  //         : item
  //     )
  //   );
  // };
  // const handleChangePtSectionName = (index: any, value: any) => {
  //   setSections(
  //     sections.map((item) =>
  //       item.id === index
  //         ? {
  //             ...item,
  //             namePt: value
  //           }
  //         : item
  //     )
  //   );
  // };

  const handleDelete = (id: number) => {
    const values = [...sections];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setSections(values);
  };

  const listSections = sections.map((section, index) => (
    <>
      <div className="section-title-wrapper">
        <h3>Section {index + 1}</h3>
        {sections.length > 1 ? (
          <IconButton
            style={{ width: '55px' }}
            color="secondary"
            onClick={() => handleDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </div>
      <Grid key={index + section.id} item xs={12} spacing={5} sm={6}>
        <FormControl fullWidth style={{ marginBottom: '15px' }}>
          <InputLabel htmlFor="category-input">Category</InputLabel>
          <Select
            labelId="label"
            id="select"
            value={section.category}
            onChange={(e) => handleChangeCategory(index, e.target.value)}
          >
            {listCategories}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: '15px' }}>
          <InputLabel htmlFor="select-template">Template</InputLabel>
          <Select
            labelId="label"
            id="select-template"
            value={section.template}
            onChange={(e) => handleChangeTemplate(index, e.target.value)}
          >
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="drink">Drink</MenuItem>
          </Select>
        </FormControl>

        {/* <Grid container spacing={5}>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="ENName-input">
                (EN) Name of Section
              </InputLabel>
              <Input
                id="ENName-input"
                value={section.nameEn}
                onChange={(e) =>
                  handleChangeEnSectionName(index, e.target.value)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="PTName-input">(PT) Nome da Seção</InputLabel>
              <Input
                id="PTName-input"
                value={section.namePt}
                onChange={(e) =>
                  handleChangePtSectionName(index, e.target.value)
                }
              />
            </FormControl>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  ));
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <h2>Add {NETWORK === "6" ? "Group" : "Menu"}</h2>
        <Container maxWidth="md">
          {/* Start of left column */}
          <div className="image-input-wrapper">
            <Grid item xs={12} sm={6}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Drag and drop an image here or click'}
                  onChange={(e) => {
                    setBannerSource(e[0]);
                  }}
                  filesLimit={1}
                />
              </ThemeProvider>

              {/* <FormControl>
                <InputLabel htmlFor="file-input">Image</InputLabel>
                <Input
                  id="file-input"
                  type="file"
                  value={image}
                  onChange={(e) => handleImage(e)}
                />
              </FormControl> */}
            </Grid>
            {bannerSource ? (
              <img
                className="image-create-menu-wrapper"
                src={URL.createObjectURL(bannerSource)}
              />
            ) : null}{' '}
          </div>
          <Grid item xs={12} spacing={5} sm={6}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required
              error={nameError !== ''}
            >
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={handleName}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
           {/* <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="price-input">{NETWORK === "6" ? "Group" : "Menu"} Type</InputLabel>
              <Select
                labelId="label"
                id="select"
                value={typeOfMenu}
                onChange={handleTypeOfMenu}
              >
                <MenuItem value="Take Away">Delivery & Pickup</MenuItem>
                <MenuItem value="Mesa">Table or Tab</MenuItem>
              </Select>
            </FormControl> */}
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <FormControl style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">
                  Maximum for {NETWORK === "6" ? "group" : "menu"} items
                </InputLabel>
                <Input
                  id="name-input"
                  type="number"
                  value={maxOfItems}
                  onChange={handleMaxOfItems}
                />
              </FormControl>
              <FormControl style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">
                  Maximum for the same item
                </InputLabel>
                <Input
                  id="name-input"
                  type="number"
                  value={maxOfSameItem}
                  onChange={handleMaxOfSameItem}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* End of left column */}
          <div>
            {listSections}
            <IconButton
              color="primary"
              onClick={() => {
                var data = {
                  category: '',
                  template: '',
                  nameEn: '',
                  namePt: '',
                  id: 0
                };
                if (sections.length >= 1) {
                  data.id = sections[sections.length - 1].id + 1;
                }
                setSections(sections.concat(data));
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                {' '}
                Create{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
