import React from 'react';
import { IKitchen } from 'interfaces/kitchen';
import DefaultReceipt from './receipt';
import PetshopReceipt from './petshopReceipt';
import MangelReceipt from './mangelReceipt';
import { NETWORK } from 'settings';
import Invoice from './NSreceipt';
interface IProps {
  order: IKitchen;
  trigger: any;
  storeName?: string
}

export default function Receipt(props: IProps): JSX.Element {
  const { order, trigger, storeName } = props;

  switch (NETWORK) {
    case "6":
      return <PetshopReceipt storeName={storeName} trigger={trigger} order={order} />
    case "15":
      return <MangelReceipt storeName={storeName} trigger={trigger} order={order} />
    default:
      return order.payment === "Invoice" ? <Invoice trigger={trigger} order={order} />
        : <DefaultReceipt trigger={trigger} order={order} />
  }

}

Receipt.defaultProps = {
  disabled: false
};
