import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteCustomer } from 'redux/ducks/customersAndSellers';
import { Customer } from 'interfaces/customersAndSellers';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    client: Customer;
    reset: () => void;
}

export default function ModalDeleteCustomer(props: SimpleDialogProps) {
    const { onClose, open, client, reset } = props;

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const submitDelete = async () => {
        deleteCustomer(dispatch, client?.id).then(() => {
            reset();
            handleClose();
        });
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Delete customer {client?.name}?
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitDelete} color="primary">
                    {' '}
                    Delete{' '}
                </Button>
                <Button onClick={handleClose}> Cancel </Button>
            </div>
        </Dialog>
    );
}
