import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IStore from 'interfaces/store';
import { Product } from 'interfaces/stock';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitProductProductionOrder,
  fetchProviders
} from 'redux/ducks/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (product_id) => void;
  product: Product;
  store?: number;
}

export default function ModalAddProduct(props: SimpleDialogProps) {
  const { onClose, open, product, store } = props;
  const [quantity, setQuantity] = React.useState(0);
  const { stock } = useSelector<IStore, IStore>((state) => state);
  const [pacote, setPacote] = React.useState(1);
  const [provider, setProvider] = React.useState(1);
  const [selectedProvider, setSelectedProvider] = React.useState(undefined);
  const [price, setPrice] = React.useState(0);

  const dispatch = useDispatch();

  const handleClose = (product) => {
    setPacote(1);
    setQuantity(0);
    setProvider(1);
    onClose(product);
  };

  const handlePacote = (event: any) => {
    setPacote(event.target.value);
  };

  const handleProvider = (event: any) => {
    setProvider(event.target.value);
    setSelectedProvider(
      stock.providers?.find((el) => el.id === event.target.value)
    );
    setPrice(
      stock.providers?.find((el) => el.id === event.target.value)
        .provider_pricing
    );
  };

  const handleQuantity = (event: any) => {
    setQuantity(event.target.value);
  };
  const handlePrice = (event: any) => {
    setPrice(event.target.value);
  };

  useEffect(() => {
    if (product) {
      dispatch(fetchProviders({ product: product.product_id }));
      console.log(selectedProvider);
    }
  }, [product]);

  const submitCreation = async () => {
    dispatch(
      submitProductProductionOrder({
        package: pacote,
        quantity: quantity,
        product: product.product_id,
        provider: provider,
        price: price,
        store
      })
    ).then((result: boolean) => {
      if (result) handleClose(result);
    });
  };

  if (!product) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Create production order for {product.name}
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="my-input">Quantity</InputLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          type="number"
          value={quantity}
          onChange={handleQuantity}
        />
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Batch</InputLabel>
        <Select
          labelId="label"
          id="select"
          value={pacote}
          onChange={handlePacote}
        >
          <MenuItem value="1">Batch 1</MenuItem>
          <MenuItem value="2">Batch 2</MenuItem>
          <MenuItem value="3">Batch 3</MenuItem>
          <MenuItem value="4">Batch 4</MenuItem>
          <MenuItem value="5">Batch 5</MenuItem>
          <MenuItem value="6">Batch 6</MenuItem>
          <MenuItem value="7">Batch 7</MenuItem>
          <MenuItem value="8">Batch 8</MenuItem>
          <MenuItem value="9">Batch 9</MenuItem>
          <MenuItem value="10">Batch 10</MenuItem>
          <MenuItem value="11">Batch 11</MenuItem>
          <MenuItem value="12">Batch 12</MenuItem>
          <MenuItem value="13">Batch 13</MenuItem>
          <MenuItem value="14">Batch 14</MenuItem>
          <MenuItem value="15">Batch 15</MenuItem>
          <MenuItem value="16">Batch 16</MenuItem>
          <MenuItem value="17">Batch 17</MenuItem>
          <MenuItem value="18">Batch 18</MenuItem>
          <MenuItem value="19">Batch 19</MenuItem>
          <MenuItem value="20">Batch 20</MenuItem>
          <MenuItem value="21">Batch 21</MenuItem>
          <MenuItem value="22">Batch 22</MenuItem>
          <MenuItem value="23">Batch 23</MenuItem>
          <MenuItem value="24">Batch 24</MenuItem>
          <MenuItem value="25">Batch 25</MenuItem>
          <MenuItem value="26">Batch 26</MenuItem>
          <MenuItem value="27">Batch 27</MenuItem>
          <MenuItem value="28">Batch 28</MenuItem>
          <MenuItem value="29">Batch 29</MenuItem>
          <MenuItem value="30">Batch 30</MenuItem>
          <MenuItem value="31">Batch 31</MenuItem>
          <MenuItem value="32">Batch 32</MenuItem>
          <MenuItem value="33">Batch 33</MenuItem>
          <MenuItem value="34">Batch 34</MenuItem>
          <MenuItem value="35">Batch 35</MenuItem>
          <MenuItem value="36">Batch 36</MenuItem>
          <MenuItem value="37">Batch 37</MenuItem>
          <MenuItem value="38">Batch 38</MenuItem>
          <MenuItem value="39">Batch 39</MenuItem>
          <MenuItem value="40">Batch 40</MenuItem>
          <MenuItem value="41">Batch 41</MenuItem>
          <MenuItem value="42">Batch 42</MenuItem>
          <MenuItem value="43">Batch 43</MenuItem>
          <MenuItem value="44">Batch 44</MenuItem>
          <MenuItem value="45">Batch 45</MenuItem>
          <MenuItem value="46">Batch 46</MenuItem>
          <MenuItem value="47">Batch 47</MenuItem>
          <MenuItem value="48">Batch 48</MenuItem>
          <MenuItem value="49">Batch 49</MenuItem>
          <MenuItem value="50">Batch 50</MenuItem>
          <MenuItem value="51">Batch 51</MenuItem>
          <MenuItem value="52">Batch 52</MenuItem>
          <MenuItem value="53">Batch 53</MenuItem>
          <MenuItem value="54">Batch 54</MenuItem>
          <MenuItem value="55">Batch 55</MenuItem>
          <MenuItem value="56">Batch 56</MenuItem>
          <MenuItem value="57">Batch 57</MenuItem>
          <MenuItem value="58">Batch 58</MenuItem>
          <MenuItem value="59">Batch 59</MenuItem>
          <MenuItem value="60">Batch 60</MenuItem>
          <MenuItem value="61">Batch 61</MenuItem>
          <MenuItem value="62">Batch 62</MenuItem>
          <MenuItem value="63">Batch 63</MenuItem>
          <MenuItem value="64">Batch 64</MenuItem>
          <MenuItem value="65">Batch 65</MenuItem>
          <MenuItem value="66">Batch 66</MenuItem>
          <MenuItem value="67">Batch 67</MenuItem>
          <MenuItem value="68">Batch 68</MenuItem>
          <MenuItem value="69">Batch 69</MenuItem>
          <MenuItem value="70">Batch 70</MenuItem>
          <MenuItem value="71">Batch 71</MenuItem>
          <MenuItem value="72">Batch 72</MenuItem>
          <MenuItem value="73">Batch 73</MenuItem>
          <MenuItem value="74">Batch 74</MenuItem>
          <MenuItem value="75">Batch 75</MenuItem>
          <MenuItem value="76">Batch 76</MenuItem>
          <MenuItem value="77">Batch 77</MenuItem>
          <MenuItem value="78">Batch 78</MenuItem>
          <MenuItem value="79">Batch 79</MenuItem>
          <MenuItem value="80">Batch 80</MenuItem>
          <MenuItem value="81">Batch 81</MenuItem>
          <MenuItem value="82">Batch 82</MenuItem>
          <MenuItem value="83">Batch 83</MenuItem>
          <MenuItem value="84">Batch 84</MenuItem>
          <MenuItem value="85">Batch 85</MenuItem>
          <MenuItem value="86">Batch 86</MenuItem>
          <MenuItem value="87">Batch 87</MenuItem>
          <MenuItem value="88">Batch 88</MenuItem>
          <MenuItem value="89">Batch 89</MenuItem>
          <MenuItem value="90">Batch 90</MenuItem>
          <MenuItem value="91">Batch 91</MenuItem>
          <MenuItem value="92">Batch 92</MenuItem>
          <MenuItem value="93">Batch 93</MenuItem>
          <MenuItem value="94">Batch 94</MenuItem>
          <MenuItem value="95">Batch 95</MenuItem>
          <MenuItem value="96">Batch 96</MenuItem>
          <MenuItem value="97">Batch 97</MenuItem>
          <MenuItem value="98">Batch 98</MenuItem>
          <MenuItem value="99">Batch 99</MenuItem>
          <MenuItem value="100">Batch 100</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Provider</InputLabel>
        <Select
          labelId="label"
          id="select"
          value={provider}
          onChange={handleProvider}
        >
          {stock.providers?.map((provider) => (
            <MenuItem value={provider?.id}>
              {provider.name} - £ {provider.provider_pricing.toFixed(2)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="price-input">Provider's Price</InputLabel>
        <Input
          id="price-input"
          aria-describedby="my-helper-text"
          type="number"
          value={price}
          onChange={handlePrice}
        />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={submitCreation}
          color="primary"
          disabled={quantity <= 0}
        >
          {' '}
          Create{' '}
        </Button>
        <Button onClick={handleClose}> Cancel </Button>
      </div>
    </Dialog>
  );
}
