import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    networks: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
    },
    network_wrapper: {
        width: "35%",
        alignItems: "start",
        '@media screen and (max-width: 650px)': {
            width: "90%",
        },
    }
}));
