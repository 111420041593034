import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCount } from 'redux/ducks/home';

import IStore from 'interfaces/store';
import { IChartState } from 'interfaces/charts';
import { Bar } from 'react-chartjs-2';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { orders, production, ingredients } = useSelector<IStore, IChartState>(
    (state) => state.home
  );

  const encomenda_data = {
    labels: ['Open', 'Closed', 'Canceled'],
    datasets: [{
      label: 'Orders',
      data: [orders.opened, orders.closed, orders.canceled],
      borderColor: [
        'rgba(163,197,248,1)',
        'rgba(96,211,122,1)',
        'rgba(243,130,130,1)',
      ],
      borderWidth: 2
    }]
  }

  const encomenda_status_data = {
    labels: ['A Fazer', 'Fazendo', 'Feito', 'Depósito'],
    datasets: [{
      label: 'Orders',
      data: [orders.afazer, orders.fazendo, orders.feito, orders.depot],
      borderColor: [
        'rgb(158, 2, 6)',
        'rgb(253, 120, 23)',
        'rgb(1, 165, 4)',
        'rgb(100,82,87)',
      ],
      borderWidth: 2
    }]
  }

  const producao_data = {
    labels: ['Open', 'Closed'],
    datasets: [{
      label: 'Orders',
      data: [production.opened, production.closed],
      borderColor: [
        'rgba(163,197,248,1)',
        'rgba(96,211,122,1)',
      ],
      borderWidth: 2
    }]
  }

  const producao_status_data = {
    labels: ['A Fazer', 'Fazendo', 'Feito'],
    datasets: [{
      label: 'Orders',
      data: [production.afazer, production.fazendo, production.feito],
      borderColor: [
        'rgb(158, 2, 6)',
        'rgb(253, 120, 23)',
        'rgb(1, 165, 4)',
      ],
      borderWidth: 2
    }]
  }

  const compras_data = {
    labels: ['Open', 'Closed'],
    datasets: [{
      label: 'Orders',
      data: [ingredients.opened, ingredients.closed],
      borderColor: [
        'rgba(163,197,248,1)',
        'rgba(96,211,122,1)',
      ],
      borderWidth: 2
    }]
  }

  const compras_status_data = {
    labels: ['A Fazer', 'Fazendo', 'Feito'],
    datasets: [{
      label: 'Orders',
      data: [ingredients.afazer, ingredients.fazendo, ingredients.feito],
      borderColor: [
        'rgb(158, 2, 6)',
        'rgb(253, 120, 23)',
        'rgb(1, 165, 4)',
      ],
      borderWidth: 2
    }]
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          suggestedMax: 100,
          beginAtZero: true
        }
      }]
    }
  }

  useEffect(() => {
    dispatch(getCount());
  }, [dispatch]);

  return (
    <>
      <Grid container direction='column'>
        <Grid>
          <Grid style={{ backgroundColor: 'rgba(197,197,197,0.84)' }}>
            <Typography style={{ marginBottom: 15 }} variant="h6" align="center">Orders</Typography>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid style={{ width: '35%' }}>
              <Bar
                data={encomenda_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
            <Grid style={{ width: '55%' }}>
              <Bar
                data={encomenda_status_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ backgroundColor: 'rgba(197,197,197,0.84)' }}>
            <Typography style={{ marginBottom: 15 }} variant="h6" align="center">Production</Typography>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid style={{ width: '35%' }}>
              <Bar
                data={producao_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
            <Grid style={{ width: '55%' }}>
              <Bar
                data={producao_status_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ backgroundColor: 'rgba(197,197,197,0.84)' }}>
            <Typography style={{ marginBottom: 15 }} variant="h6" align="center">Purchases</Typography>
          </Grid>
          <Grid container justifyContent='space-between'>
            <Grid style={{ width: '35%' }}>
              <Bar
                data={compras_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
            <Grid style={{ width: '55%' }}>
              <Bar
                data={compras_status_data}
                legend={{ display: false }}
                height={200}
                options={options}
              />
            </Grid>
          </Grid>
        </Grid>



      </Grid>
    </>
  )
}
