import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getCep } from 'redux/ducks/cep';
import { getStore } from 'redux/ducks/store';

import { IStoreState } from 'interfaces/store_test';

import IStore from 'interfaces/store';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import useStyles from './styles';
// import Loading from 'components/Common/Loading';
// import CepsTable from './CepsTable';

var storeFilter: { id: number; name: string }[] = [];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const { cep } = useSelector<IStore, IStore>((state) => state);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });

  useEffect(() => {
    setActiveStoreFilter(storeFilter[0].id);
  }, []);

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-config');
    d.item(0).style.color = '#ff5a23';

    d.item(1).style.color = '#ff5a23';
  });

  useEffect(() => {
    if (activeStoreFilter !== 0 && activeStoreFilter !== undefined) {
      dispatch(getCep({ store: activeStoreFilter }));
    }
    dispatch(getStore());
  }, [dispatch, activeStoreFilter]);
  return (
    <>
      {/* <Loading show={cep.fetch} /> */}
      <Grid style={{ marginBottom: 10, marginLeft: 15 }}>
        {storeFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveStoreFilter(elmnt.id);
            }}
            variant="outlined"
            size="small"
            className={
              activeStoreFilter === elmnt.id
                ? classes.activeButton
                : classes.button
            }
          >
            {elmnt.name}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={3}>
        {/* <CepsTable ceps={cep.results} store={activeStoreFilter} /> */}
      </Grid>
    </>
  );
}
