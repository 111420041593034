import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import IStore, { Itags } from 'interfaces/store';

import Loading from 'components/Common/Loading';
import StoreTable from './storeTable';
import { fetch } from './../../utils/request';
import { getStore } from 'redux/ducks/store';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import { PRIMARY_COLOR } from 'settings';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  inactiveButton: {
    margin: '0 5px',
    color: '#124c4d !important',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  selectInput: {
    width: '195px',
    marginTop: '-3px',
    marginLeft: '10px'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  toolBarTitles: {
    color: 'rgb(0 0 0 / 54%) !important',
    fontWeight: 400,
    fontSize: 12
  }
});

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: PRIMARY_COLOR(),
    boxShadow: 'none',
    ':hover': {
      borderColor: PRIMARY_COLOR()
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
  })
};
export default function Home(): JSX.Element {
  const dispatch = useDispatch();

  const { store, authentication } = useSelector<IStore, IStore>(
    (state) => state
  );
  const [fetchedMenus, setFetchedMenus] = useState<Itags[]>([]);
  const [activeFilter, setactiveFilter] = React.useState(0);
  const [filteredData, setFilteredData] = React.useState(store.results);
  const classes = useStyles();

  useEffect(() => {
    if (activeFilter === 0) {
      setFilteredData(store.results);
    } else {
      setFilteredData(store.results.filter((data) => data.id === activeFilter));
    }
  }, [activeFilter, store.results]);
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = PRIMARY_COLOR();
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-stores');
    if (d.item(0)) {
      d.item(0).style.color = '#ff5a23';

      //d.item(1).style.color = '#ff5a23';
    }
  });

  const _getMenus = async () => {
    try {
      let response;
      let results = [];
      let url = `/products/api/menus/?realtime=${true}`;
      do {
        response = await fetch({
          method: 'GET',
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      setFetchedMenus(response.results);
    } catch (error) { }
  };
  const control = 0;
  useEffect(() => {
    _getMenus();
    dispatch(getStore());
  }, [control, dispatch]);

  const showFilter = () => {
    var options = [];
    for (let index = 0; index < store.results.length; index++) {
      const element = store.results[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    if (authentication.is_superuser) {
      options.push({ value: 0, label: 'ALL' });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeFilter;
    });
    return (
      <>
        {' '}
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeFilter === 0 ? 0 : activeFilter,
            label:
              activeFilter === 0
                ? 'All'
                : store.results[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => setactiveFilter(e.value)}
          styles={customStyles}
        />
      </>
    );
  };

  return (
    <>
      <Loading show={store.fetch} />

      {store.results.length > 2 && <Grid
        style={{
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          marginTop: '20px'
        }}
      >
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`Store: `}</Typography>{' '}
        {showFilter()}
      </Grid>}
      <Grid container spacing={3}>
        <StoreTable fetchedMenus={fetchedMenus} stores={filteredData} activeFilter={activeFilter} />
      </Grid>
    </>
  );
}
