import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface IPackage {
  batch: number;
  products: [{
    product: string;
    quantity: number;
    provider: string;
    cost: number;
  }],
  ingredients: [{
    ingredient: string;
    quantity: number;
    measure: string;
  }]
}

export interface IPackageState {
  fetch: boolean;
  results: IPackage[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesPackage {
  FETCH = 'PACKAGE/FETCH',
  SUCCESS = 'PACKAGE/SUCCESS',
  FAILURE = 'PACKAGE/FAILURE',
}

export interface IFetchAction extends IBaseAction<ETypesPackage, null> {
  type: ETypesPackage.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesPackage, IPackageState> {
  type: ETypesPackage.SUCCESS;
  payload: IPackageState;
}

export interface IFailureAction extends IBaseAction<ETypesPackage, string> {
  type: ETypesPackage.FAILURE;
  payload: string;
}

export type IPackageAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IPackageAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IPackageAction
    | ThunkAction
    | PromiseAction
    | Array<IPackageAction>
    | IAlertAction
) => any;
