import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Content from 'components/Dashboard/Content';
import Logout from 'components/Authentication/Logout';

import Home from 'components/Home';
import Kitchen from 'components/Kitchen';
import Balcony from 'components/Balcony';
import Production from 'components/Production';
import Package from 'components/Package';
import Providers from 'components/Providers';
import IngredientProduction from 'components/IngredientProduction';
import IngredientPackage from 'components/IngredientPackage';
import Delivery from 'components/Delivery';
import ManagerDashboard from 'components/ManagerDashboard';
import Stock from 'components/Stock';
import Customers from 'components/Customers';
import Sellers from 'components/Sellers';
import Product from 'components/Product';
import Menus from 'components/Menus';
import Table from 'components/Table';
import History from 'components/History';
import ControlPanel from 'components/ControlPanel';
import Stores from 'components/Stores';
import Owner from 'components/Owner';
import Cep from 'components/Cep';
import ComercialInvoice from 'components/ComercialInvoice'
import Billing from 'components/Billing'
import Artists from 'components/Artists';
export default function Routes() {
  const store: string = localStorage.getItem('state');
  const state = JSON.parse(store);
  const {
    is_superuser,
    is_manager,
    is_waiter_manager,
    is_waiter,
    is_store_owner
  } = state.authentication;

  return (
    <Content>
      {(is_superuser || is_store_owner) ? (
        <Switch>
          <Route path="/dashboard/home" component={Home} />
          <Route path="/dashboard/manager" component={ManagerDashboard} />
          <Route path="/dashboard/stock" component={Stock} />
          <Route path="/dashboard/customers" component={Customers} />
          <Route path="/dashboard/sellers" component={Sellers} />
          <Route path="/dashboard/order" component={Kitchen} />
          <Route path="/dashboard/production/product" component={Production} />
          <Route
            path="/dashboard/production/ingredient"
            component={IngredientProduction}
          />
          <Route
            path="/dashboard/ingredientpackage"
            component={IngredientPackage}
          />
          <Route path="/dashboard/package" component={Package} />
          <Route path="/dashboard/providers" component={Providers} />
          <Route path="/dashboard/completed" component={Balcony} />
          <Route path="/dashboard/logout" component={Logout} />
          <Route path="/dashboard/billing" component={Billing} />
          <Route path="/dashboard/commercialInvoice" component={ComercialInvoice} />
          <Route path="/dashboard/delivery" component={Delivery} />
          <Route path="/dashboard/products" component={Product} />
          <Route path="/dashboard/menus" component={Menus} />
          <Route path="/dashboard/ceps" component={Cep} />
          <Route path="/dashboard/tables" component={Table} />
          <Route path="/dashboard/invoices" component={History} />
          <Route path="/dashboard/notifications" component={ControlPanel} />
          <Route path="/dashboard/stores" component={Stores} />
          <Route path="/dashboard/owner" component={Owner} />
          <Route path="/dashboard/artists" component={Artists} />
        </Switch>
      ) :
        <>
          {is_manager && (
            <Switch>
              <Route path="/dashboard/order" component={Kitchen} />
              <Route path="/dashboard/delivery" component={Delivery} />
              <Route path="/dashboard/completed" component={Balcony} />
              <Route path="/dashboard/stock" component={Stock} />
              <Route path="/dashboard/stores" component={Stores} />
              <Route path="/dashboard/home" component={Home} />
              <Route path="/dashboard/logout" component={Logout} />
            </Switch>
          )}
          {is_waiter_manager && (
            <Switch>
              <Route path="/dashboard/home" component={Home} />
              <Route path="/dashboard/order" component={Kitchen} />
              <Route path="/dashboard/delivery" component={Delivery} />
              <Route path="/dashboard/tables" component={Table} />
              <Route path="/dashboard/completed" component={Balcony} />
              <Route path="/dashboard/manager" component={ManagerDashboard} />
              <Route path="/dashboard/stock" component={Stock} />
              <Route path="/dashboard/customers" component={Customers} />
              <Route path="/dashboard/history" component={History} />
              <Route path="/dashboard/logout" component={Logout} />
            </Switch>
          )}
          {is_waiter && (
            <Switch>
              <Route path="/dashboard/home" component={Home} />
              <Route path="/dashboard/order" component={Kitchen} />
              <Route path="/dashboard/delivery" component={Delivery} />
              <Route path="/dashboard/tables" component={Table} />
              <Route path="/dashboard/history" component={History} />
              <Route path="/dashboard/logout" component={Logout} />
            </Switch>
          )}
        </>}

    </Content>
  );
}
