import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Grid, Typography, FormControl, Input, InputLabel, TextField, Radio,
    RadioGroup, FormLabel, FormControlLabel, Select as MuiSelect, MenuItem, IconButton, Button, CircularProgress, FormHelperText
} from '@material-ui/core';
import Select from 'react-select';
import IStore from 'interfaces/store';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'interfaces/store_test';
import { PRIMARY_COLOR, NETWORK } from 'settings';
import { getStore } from 'redux/ducks/store';
import { getOrdersToDo } from 'redux/ducks/kitchen';
import useStyles from './styles'
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import {
    fetchProductsGeneral,
} from 'redux/ducks/stock';
import allCountries from 'components/Onboarding/countryList';
import { EMethod } from 'enums/method';
import { fetch } from 'utils/request';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import AddIcon from '@material-ui/icons/Add';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import NewClientModal from './NewClientModal'
import DeliveryForm from './comps/DeliveryForm';
import OrderSummary from './comps/OrderSummary';
import Product from './ProductCard'
import { setCurrency } from 'redux/ducks/currency';

const customStyles = {
    control: (base) => ({
        ...base,
        borderColor: PRIMARY_COLOR(),
        boxShadow: 'none',
        ':hover': {
            borderColor: PRIMARY_COLOR()
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
    })
};


export default function Billing(): JSX.Element {
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const [editId, setEditId] = useState(query.get("edit"))
    const [search, setSearch] = useState("")
    const [deliveryFeeCalculated, setDeliveryFeeCalculated] = useState(false)
    const [firstRender, setFirstRender] = useState(true);
    const [selectedProduct, setSelectedproduct] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [categories, setCategories] = useState([])
    const [filteredCategories, setFilteredCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [editOrderView, setEditOrderView] = useState(false)
    const [ordersToDo, setOrdersToDo] = useState([])
    const [selectedOrderToEdit, setSelectedOrderToEdit] = useState({ value: "", label: "" })
    const [loading, setLoading] = useState(false)
    // const [editOrder, setEditOrder] = useState({})
    const [deliveryFee, setDeliveryFee] = useState({
        service_price: 0,
        delivery_price: 0,
        checkout_price: 0,
        discount: 0,
        stuart_price: 0,
        distance: "",
        duration: "",
        message: ""
    })
    const [clients, setClients] = useState([])
    const [billing, setBilling] = useState({
        street: "",
        street_number: "",
        neighborhood: '',
        city: "",
        state: "",
        country: "",
        zipcode: "",
    })
    const [deliveryCountryQuery, setDeliveryCountryQuery] = useState("")
    const [delivery, setDelivery] = useState({
        street: "",
        street_number: "",
        neighborhood: '',
        city: "",
        state: "",
        country: "",
        zipcode: "",
    })
    const [deliveryChanged, setDeliveryChanged] = useState(false)
    const [deliveryError, setDeliveryError] = useState({
        street: "",
        street_number: "",
        neighborhood: '',
        city: "",
        state: "",
        country: "",
        zipcode: "",
    })
    const [selectedCustomerError, setSelectedCustomerError] = useState(false)
    const [selectedProductsError, setSelectedProductsError] = useState(false)
    const { results } = useSelector<IStore, IStoreState>((state) => state.store);
    const [selectedCustomerData, setSelectedCustomerData] = useState<any>({})
    const [activeStoreFilter, setActiveStoreFilter] = useState(undefined);
    const [openProductDetails, setOpenProductDetails] = useState(false)
    const [payment, setPayment] = useState<any>("Card")
    const [productDetails, setProductDetails] = useState({})
    const [typeOfOrder, setTypeOfOrder] = useState("Delivery")
    const [productSearch, setProductSearch] = useState("")
    const [deliveryDate, setDeliveryDate] = useState<string>(moment(new Date()).format("YYYY-MM-DD"))
    const [deliveryTime, setDeliveryTime] = useState<any>(new Date())
    const [collectionDate, setCollectionDate] = useState<string>(moment(new Date()).format("YYYY-MM-DD"))
    const [collectionTime, setCollectionTime] = useState<any>(new Date())
    const [newClientOpen, setNewClientOpen] = useState(false)
    const [orderSuccessfull, setOrderSuccessfull] = useState(null)
    const [paid, setPaid] = useState<any>("Not paid")
    const [giftCardCode, setGiftCardCode] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(false)
    const [orderToEditObj, setOrderToEditObj] = useState(undefined)
    const [giftCardError, setGiftCardError] = useState("")
    const [giftCardValue, setGiftCardValue] = useState(0)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)

    var storeFilter: { id: number; name: string }[] = [];
    const { authentication, stock } = useSelector<IStore, IStore>(
        (state) => state
    );

    storeFilter = [];
    results.map((store) => {
        storeFilter.push({ id: store.id, name: store.name });
    });

    const getStoreAddress = () => {
        const store = results.find((_store) => _store.id === activeStoreFilter)
        return store.address
    }
    const handleActiveStoreFilter = (value: number) => {
        setActiveStoreFilter(value);
    };

    const handleUpdateNumber = async (uid) => {
        return new Promise<void>(async (resolve, reject) => {
            if (phone === selectedCustomerData.phone_number && email === selectedCustomerData.email) {
                resolve()
            } else {
                var payload = {
                    email,
                    profile: {
                        phone_number: phone
                    }
                }
                if (phone === selectedCustomerData.phone_number || phone === "") delete payload.profile
                if (email === selectedCustomerData.email || email === "") delete payload.email
                if (Object.keys(payload).length < 1) resolve()
                try {
                    await fetch({
                        method: 'PATCH',
                        url: `/users/api/clients/${uid}/`,
                        data: payload
                    });
                    resolve()
                } catch (error) {
                    console.log("edit error ", error)
                    if (error?.profile?.phone_number) {
                        dispatch(sendAlert("This phone number is already in use", EAlertVariant.ERROR))
                    }
                    reject(error)
                }
            }
        })
    }
    function returnAddressAsString(addressObject) {
        const { street_number, street, zipcode } = addressObject
        return (street_number ? street_number + ", " : "") + (street ? street + ", " : "") + (zipcode ? zipcode : "")
    }

    const handleChangeBillingAddress = async (value) => {
        setBilling(value)
        try {
            delete value.address_id
            if (value.state === "") {
                value.state = null
            }
            if (value.neighborhood === "") {
                value.neighborhood = null
            }
            await fetch({
                method: 'POST',
                url: `users/api/address/waiter_create_address/`,
                data: {
                    ...value,
                    country: value.country.code,
                    street_number: null,
                    active: true,
                    update_address: true,
                    user_id: selectedCustomerData.id
                }
            });
        } catch (error) {
            console.log("error", error)
        }
    }
    const translateErrorMessage = (error) => {
        if (error === "No Amount was returned by Delivery Parter.") {
            dispatch(sendAlert("This delivery address is out of range", EAlertVariant.ERROR))
            return
        }
        if (error.includes("ZERO_RESULTS")) {
            dispatch(sendAlert("This delivery address is out of range", EAlertVariant.ERROR))
            return
        }
        dispatch(sendAlert("Something went wrong", EAlertVariant.ERROR))
        return
    }
    const orderTotal = () => {
        var total = 0;
        selectedProduct.map((product) => {
            var totalExtras = 0
            product.extras.map((extra) => {
                if (extra.quantity > 1) {
                    totalExtras += extra.quantity - 1 * extra.price
                }
            })
            total += productPrice(product) + totalExtras
        })
        if (typeOfOrder === "Delivery") {
            total += deliveryFee.delivery_price
        }
        return total
    }
    const getdeliveryFee = async (_pickup, _delivery, ignoreCheckForm) => {
        try {
            if (!checkAddressForm(ignoreCheckForm, _delivery) && typeOfOrder === "Delivery") {
                var order_items_value = orderTotal()
                setIsloading(true)
                const response = await fetch({
                    method: EMethod.POST,
                    url: `/orders/api/orders/get_service_delivery_estimate/?network=${NETWORK}`,
                    data: {
                        store: activeStoreFilter,
                        order_items_value,
                        pickup_address: returnAddressAsString(getStoreAddress()),
                        delivery_address: returnAddressAsString(_delivery),
                        network: Number(NETWORK)
                    }
                });
                setDeliveryFee(JSON.parse(response))
                setIsloading(false)
                setDeliveryFeeCalculated(true)
            }
        } catch (error) {
            translateErrorMessage(error)
            setDeliveryFee({ ...deliveryFee, delivery_price: 0 })
            setIsloading(false)
        }
    }
    const getClients = async () => {
        const response = await fetch({
            method: EMethod.GET,
            url: `/users/api/clients/network_customers_only/?network=${NETWORK}&store=${activeStoreFilter}`
        });
        setClients(response)
    }
    const selectCategory = (id) => {
        var selected = [...selectedCategories]
        if (selectedCategories.includes(id)) {
            selected.splice(selected.indexOf(id), 1)
        } else {
            selected.push(id)
        }
        setSelectedCategories(selected)
    }

    const isSelectedCategory = (id) => {
        return selectedCategories.includes(id)
    }
    const isCategoryIncluded = (category: string) => {
        const categoryNames = filteredCategories.map(_filteredCategory => {

            if (selectedCategories.includes(_filteredCategory.id)) {
                return _filteredCategory.name
            }
        })
        if (selectedCategories.length > 0) {
            return categoryNames.includes(category)
        } else {
            return true
        }
    }
    const isSearchIncluded = (product_name: string) => {
        var included = true
        if (productSearch) {
            included = product_name.toLowerCase().includes(productSearch.toLowerCase())
        }
        return included
    }
    const getCategories = async () => {
        return new Promise<any[]>(async (resolve, reject) => {
            if (NETWORK === "6") {
                await fetch({
                    method: EMethod.GET,
                    url: nextURL ? nextURL : `/products/api/menus/?search=petshop&network=${NETWORK}`
                }).then(async (_response) => {
                    const filteredData = _response.results.find(el => el.name === "Species")
                    var payload = []
                    var nextURL = ""
                    const categorynames = filteredData.sections.map(el => (el.category))
                    while (true) {
                        const response = await fetch({
                            method: EMethod.GET,
                            url: nextURL ? nextURL : `/products/api/categories/?network=${NETWORK}&store=${activeStoreFilter}`
                        });
                        payload.push(...response.results)
                        if (!response.next) {
                            break
                        } else {
                            nextURL = response.next
                        }
                    }
                    resolve(payload.filter(el => categorynames.includes(el.name)))
                })

            } else {
                var payload = []
                var nextURL = ""
                while (true) {
                    const response = await fetch({
                        method: EMethod.GET,
                        url: nextURL ? nextURL : `/products/api/categories/?network=${NETWORK}&store=${activeStoreFilter}`
                    });
                    payload.push(...response.results)
                    if (!response.next) {
                        break
                    } else {
                        nextURL = response.next
                    }
                }
                resolve(payload)
            }
        })
    }
    const getProducts = () => {
        setLoading(true)
        var params = {
            store: activeStoreFilter,
            network: NETWORK,
            search
        };

        if (!authentication.is_superuser) {
            delete params.network;
        }
        if (activeStoreFilter === 0 && authentication.is_superuser) {
            delete params.store;
        }
        dispatch(fetchProductsGeneral(params)).then(() => {
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }

    const selectProduct = (id, product) => {
        var selected = [...selectedProduct]
        if (selected.find(product => product.product_id === id)) {
            selected = selected.filter((product) => product.product_id != id)
        } else {
            if (product.variation.length > 0) {
                product.selected_variation = [{ ...product.variation[0], amount: 1 }]
            } else {
                product.selected_variation = []
            }
            selected.push({ ...product, amount: 1 })
        }
        if (selected.length > 0) {
            setSelectedProductsError(false)
        }
        setSelectedproduct(selected)
    }
    const deselectProduct = (id: number) => {
        var selected = [...selectedProduct]
        selected = selected.filter((product) => product.product_id != id)
        if (productDetails['product_id'] === id) {
            setProductDetails({})
            setOpenProductDetails(false)
        }
        setSelectedproduct(selected)

    }
    const isProductSelected = (id: number) => {
        return selectedProduct.find(product => product.product_id === id) ? true : false
    }
    const filterCategories = (_categories) => {
        var storeData = results.find(store => store.id === activeStoreFilter)
        var _filteredCategories = _categories.filter(category => storeData.categories.includes(category.id))
        setFilteredCategories(_filteredCategories)
    }

    const fillEditOrderData = (items, order) => {
        var formatedProducts = []
        items.map((item, idx) => {
            var foundIdx = -1
            formatedProducts.forEach((el, index) => {
                if (item.product.product_id === el.product_id) {
                    foundIdx = index
                }

            })
            if (foundIdx > -1) {
                var foundVariation = item.product.variation.find((el => el.id === item?.variation?.id))
                if (foundVariation) {
                    formatedProducts[foundIdx].selected_variation.push({
                        "id": foundVariation.id,
                        "name": foundVariation.name,
                        "barcode": foundVariation.barCode,
                        "sku": foundVariation.sku,
                        "price": foundVariation.price,
                        "stock": foundVariation.id.stock,
                        "cost": foundVariation.cost,
                        "amount": item.quantity
                    })
                }
            } else {

                if (item.variation) {
                    var foundVariation = item.product.variation.find((el => el.id === item.variation.id))
                    formatedProducts.push({ ...item.product, position: item.position, amount: 1, selected_variation: [{ ...foundVariation, amount: item.quantity }] })
                } else {
                    formatedProducts.push({ ...item.product, position: item.position, amount: item.quantity, selected_variation: [] })

                }
            }
        })
        setTypeOfOrder(order.type_of_order.includes("-D") ? "Delivery" : "Collection")
        setDeliveryDate(moment(order.date_delivery).format("YYYY-MM-DD"))
        setDeliveryTime(new Date(order.date_delivery))
        setPayment(order.payment)
        setSelectedproduct(formatedProducts)
    }
    const fetchOrderstoDo = async () => {
        var fetchedorders = await getOrdersToDo(activeStoreFilter)
        setOrdersToDo(fetchedorders)
    }

    useEffect(() => {
        if (activeStoreFilter) {
            getClients()
            fetchOrderstoDo()
            getCategories().then((response) => {
                setCategories(response)
                filterCategories(response)
            }).catch((e) => {
                console.log("fetching categories ", e)
            })
        }
    }, [dispatch, activeStoreFilter]);
    useEffect(() => {
        if (firstRender && results.length > 0) {
            setActiveStoreFilter(results[0].id);
            dispatch(setCurrency(results[0].currency))
            setFirstRender(false);
        }
    }, [results]);

    useEffect(() => {
        if (
            activeStoreFilter !== undefined &&
            !firstRender
        ) {
            getProducts()
        }
        if (activeStoreFilter !== undefined && categories.length > 0) {
            filterCategories(categories)
        }
        dispatch(getStore());

    }, [activeStoreFilter, firstRender])
    useEffect(() => {
        setSelectedCategories([])
        if (!editId) setSelectedproduct([])
    }, [activeStoreFilter,])
    const getOrder = async (id) => {
        setLoading(true)
        const res = ordersToDo.find((el) => (el.order_id === id))
        setOrderToEditObj(res)
        fillEditOrderData(res.items, res)
        selectUserEditOrder(res)
        setLoading(false)
    }
    const selectUserEditOrder = (order) => {
        if (order["owner"]) handleChangeClient({ value: order['owner']['user']['id'], label: order['owner']['user']['first_name'] + " " + order['owner']['user']['last_name'] }, order['owner']['user'])
        setDeliveryFee({ ...deliveryFee, delivery_price: 0 })
        setSelectedCustomerError(false)
    }
    useEffect(() => {
        if (editId) {
            getOrder(editId)
        }
    }, [selectedOrderToEdit.value])
    useEffect(() => {
        const categoryNames = filteredCategories.map(_filteredCategory => {
            if (selectedCategories.includes(_filteredCategory.id)) {
                return _filteredCategory.name
            }
        })
        var _products = [...selectedProduct]
        selectedProduct.map((product, idx) => {
            if (!categoryNames.includes(product.category)) {
                _products.splice(_products.findIndex(x => x.product_id === product.product_id), 1)
            }
        })
        if (_products.length > 0)
            setSelectedproduct(_products)
    }, [selectedCategories])

    const showStores = () => {
        var options = [];
        for (let index = 0; index < storeFilter.length; index++) {
            const element = storeFilter[index];
            options.push({
                value: element.id,
                label: element.name
            });
        }
        var activeFilterIndex = options.findIndex((object) => {
            return object.value === activeStoreFilter;
        });

        return (
            <>
                <Select
                    className={classes.selectInput}
                    classNamePrefix="select"
                    value={{
                        value: activeStoreFilter,
                        label: storeFilter[activeFilterIndex]?.name
                    }}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={options}
                    onChange={(e: any) => handleActiveStoreFilter(e.value)}
                    styles={customStyles}
                />
            </>
        );
    };
    const showOrderToEdit = () => {
        var options = [];
        for (let index = 0; index < ordersToDo.length; index++) {
            const element = ordersToDo[index];
            options.push({
                value: element['order_id'],
                label: element['order_code'] + (element?.owner?.name ? ", " + element?.owner?.name : "")
            });
        }
        return (
            <>
                <Select
                    className={classes.selectInput}
                    classNamePrefix="select"
                    value={selectedOrderToEdit}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={options}
                    onChange={(e: any) => {
                        setEditId(e.value)
                        setSelectedOrderToEdit(e)
                    }}
                    styles={customStyles}
                />
            </>
        );
    };
    const handleChangeClient = async (e: { value: number, label: string }, userData) => {
        setSelectedCustomer(e)
        setSelectedCustomerError(false)
        var customerData
        if (editId || Object.keys(userData).length > 0) {
            customerData = userData
        } else {
            clients.map((client, index) => {
                if (client.id === e.value) {
                    customerData = client
                }
            })
        }
        setEmail(customerData.email)
        if (customerData.phone_number && !customerData.phone_number.includes("."))
            setPhone(customerData.phone_number)
        setSelectedCustomerData(customerData)
        if (customerData.billing_address) {
            if (typeof customerData.billing_address.country === "string") {
                customerData.billing_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData.billing_address.country.toLocaleLowerCase())
            }
            if (typeof customerData.billing_address.country === "object") {
                customerData.billing_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData.billing_address.country.code.toLocaleLowerCase())
            }
            //customerData[0].billing_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData[0].billing_address.country)
            setBilling(customerData.billing_address)
        }
        if (customerData.shipping_address) {

            if (typeof customerData.shipping_address.country === "string") {
                customerData.shipping_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData.shipping_address.country.toLocaleLowerCase())
            }
            if (typeof customerData.shipping_address.country === "object") {
                customerData.shipping_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData.shipping_address.country.code.toLocaleLowerCase())
            }
            // customerData[0].shipping_address.country = allCountries.find(country => country.code.toLocaleLowerCase() === customerData[0].shipping_address.country)
            setDelivery(customerData.shipping_address)
            if (selectedProduct.length > 0 && NETWORK !== "6") getdeliveryFee(getStoreAddress(), customerData.shipping_address, true)
            setDeliveryFee({
                service_price: 0,
                delivery_price: 0,
                checkout_price: 0,
                discount: 0,
                stuart_price: 0,
                distance: "",
                duration: "",
                message: ""
            })
            setDeliveryFeeCalculated(false)
        }

    }
    const increaseAmount = (id) => {
        var _products = [...selectedProduct]
        _products.map((product, idx) => {
            if (product.product_id === id) {
                product.amount += 1
            }
        })
        setSelectedproduct(_products)
    }

    const decreaseAmount = (id) => {
        var _products = [...selectedProduct]
        _products.map((product, idx) => {
            if (product.product_id === id) {
                if (product.amount <= 1) {
                    //_products = _products.splice(idx, 1)
                    deselectProduct(product.product_id)
                } else {
                    product.amount -= 1
                }
            }
        })
        setSelectedproduct(_products)

    }
    function between(min, max) {
        return Math.floor(
            Math.random() * (max - min) + min
        )
    }


    const getPosition = (item, variation) => {
        var position = between(1000, 9999)
        if (orderToEditObj) {
            var oldIOrderItem
            if (variation) {
                oldIOrderItem = orderToEditObj.items.find((el => {
                    return el.product.product_id === item.product_id && el.variation.id === variation
                }))
            } else {
                oldIOrderItem = orderToEditObj.items.find((el => {
                    return el.product.product_id === item.product_id
                }))
            }
            if (oldIOrderItem && oldIOrderItem.position) {
                position = oldIOrderItem.position
            }
        }
        return position
    }
    const handleRemovedItems = (cartItems, oldOrderItems) => {
        var removedItems = oldOrderItems.filter((el) => {
            if (!cartItems.find(item => {
                if (item.product_id === el.product.product_id) {
                    if (el.variation) {
                        if (el.variation.id === item.variation.id) {
                            return true
                        }
                        return false
                    }
                    return true
                }
                return false
            })) return true
            else return false
        })
        const payload = removedItems.map((item) => (
            {
                product_id: item.product.product_id,
                quantity: 0,
                extras: [],
                comment: "",
                position: item.position,
                variation: item.variation ?? null
            }
        ))
        return payload
    }
    const formatProducts = (cartItems) => {
        let formatedProducts = []
        cartItems.forEach(item => {
            if (item.selected_variation.length > 0) {
                item.selected_variation.map(el => {

                    formatedProducts.push({
                        product_id: item.product_id,
                        quantity: el.amount,
                        extras: [],
                        comment: "",
                        position: getPosition(item, el.id),
                        variation: {
                            id: el.id,
                            price: el.price,
                        }
                    })
                })

            } else {
                formatedProducts.push({
                    product_id: item.product_id,
                    quantity: item.amount,
                    extras: [],
                    comment: "",
                    position: getPosition(item, null),

                })
            }
        })

        if (editId) formatedProducts.push(...handleRemovedItems(formatedProducts, orderToEditObj.items))
        return formatedProducts
    }
    var checkAddressForm = (ignoreCheckForm, address) => {
        var entered = false
        var _deliveryErrors = { ...deliveryError }
        if (typeOfOrder === "Delivery") {
            _deliveryErrors.state = ""
            _deliveryErrors.neighborhood = ""
            if (address.street === "" || address.street === null) {
                entered = true
                _deliveryErrors.street = "This field cannot be empty"
            }
            if (address.zipcode === "" || address.zipcode === null) {
                entered = true
                _deliveryErrors.zipcode = "This field cannot be empty"

            }
            if (address.city === "" || address.city === null) {
                entered = true
                _deliveryErrors.city = "This field cannot be empty"

            }
            /* if (address.state === "" || address.state === null) {
                 entered = true
                 _deliveryErrors.state = "This field cannot be empty"
 
             }
             if (address.neighborhood === "" || address.neighborhood === null) {
                 entered = true
                 _deliveryErrors.neighborhood = "This field cannot be empty"
 
             } */
            if (address.country === "" || address.country === null || !address.country) {
                entered = true
                _deliveryErrors.country = "This field cannot be empty"
            }
        }

        setDeliveryError(_deliveryErrors)
        if (entered && !ignoreCheckForm) {
            if (Object.keys(selectedCustomerData).length < 1) {
                dispatch(sendAlert("Please select a customer", EAlertVariant.ERROR))
                setSelectedCustomerError(true)
            } else {
                dispatch(sendAlert("Please check the errors in the fields below", EAlertVariant.ERROR))
            }
        }
        return entered
    }
    var checkForm = () => {
        var entered = false
        var _deliveryErrors = { ...deliveryError }
        if (selectedProduct.length < 1) {
            entered = true
            setSelectedProductsError(true)
        }
        if (Object.keys(selectedCustomerData).length < 1) {
            entered = true
            setSelectedCustomerError(true)
        }

        if (payment === "Card" && phone === "") {
            setPhoneError(true)
            entered = true
        }

        if (email === "") {
            setEmailError(true)
            entered = true
        }
        if (typeOfOrder === "Delivery") {
            _deliveryErrors.state = ""
            _deliveryErrors.neighborhood = ""
            if (delivery.street === "" || delivery.street === undefined) {
                _deliveryErrors.street = "This fiel cannot be empty"
                entered = true
            }
            if (delivery.city === "" || delivery.city === undefined) {
                _deliveryErrors.city = "This fiel cannot be empty"
                entered = true
            }
            /* if (delivery.state === "" || delivery.state === undefined) {
                 _deliveryErrors.state = "This fiel cannot be empty"
                 entered = true
             }
             if (delivery.neighborhood === "" || delivery.neighborhood === undefined) {
                 _deliveryErrors.neighborhood = "This fiel cannot be empty"
                 entered = true
             } */
            if (delivery.zipcode === "" || delivery.zipcode === undefined) {
                _deliveryErrors.zipcode = "This fiel cannot be empty"
                entered = true
            }

            if (delivery.country === "" || delivery.country === undefined || !delivery.country) {
                _deliveryErrors.country = "This fiel cannot be empty"
                entered = true
            }
        }
        setDeliveryError(_deliveryErrors)
        if (entered) {
            if (!selectedCustomerData) {
                dispatch(sendAlert("Please select a customer", EAlertVariant.ERROR))
                setSelectedCustomerError(true)
            } else {
                dispatch(sendAlert("Please check the errors in the fields below", EAlertVariant.ERROR))
            }

        }
        return entered
    }
    const checkGiftCard = async () => {
        return new Promise<boolean>(async (resolve, reject) => {

            try {
                if (giftCardCode === "") {
                    resolve(false)
                } else {
                    var response = await fetch({
                        method: 'POST',
                        url: `/orders/api/giftcard/get_gift_card_info/`,
                        data: {
                            code: giftCardCode
                        }
                    });
                    if (response.active && !response["in use"]) {
                        setGiftCardValue(response.balance)
                        setGiftCardError("")
                        resolve(false)
                    } else {
                        setGiftCardValue(0)
                        const errorMessage = !response.active ? "This gift card is not active" : response['in use'] ? "This gift card is already in use" : "This gift card is not valid"
                        setGiftCardError(errorMessage)
                        dispatch(sendAlert(errorMessage, EAlertVariant.ERROR))
                        resolve(true)
                    }
                }

            } catch (error) {
                setGiftCardValue(0)
                setGiftCardError("This giftcard does not exist")
                dispatch(sendAlert("This giftcard does not exist", EAlertVariant.ERROR))
                resolve(true)
            }

        })
    }
    const dateTime = (date, time: Date) => {
        var timeString = time.getHours() + ':' + time.getMinutes() + ':00';
        var dateObj = new Date(date + ' ' + timeString);
        return dateObj
    }
    const updateDelivery_address = async () => {
        await fetch({
            method: 'POST',
            url: `/users/api/address/waiter_create_shipping_address/`,
            data: {
                ...delivery,
                country: delivery.country["code"],
                active: true,
                update_address: true,
                user_id: selectedCustomerData.id
            }
        });
    }
    const submit = async () => {
        checkGiftCard().then((result) => {
            if (!result) {
                if (!checkForm()) {
                    setIsloading(true)
                    handleUpdateNumber(selectedCustomerData.id).then(async () => {
                        if (deliveryChanged) await updateDelivery_address()
                        const _delivery = delivery
                        delete _delivery["address_id"]
                        let orderObjectToSend = {
                            infos: {
                                store_id: activeStoreFilter,
                                type_of_order: typeOfOrder,
                                payment: payment,
                                date_delivery: typeOfOrder === "Delivery" ? dateTime(deliveryDate, deliveryTime).toISOString() : dateTime(collectionDate, collectionTime).toISOString(),
                                delivery_fee: typeOfOrder === "Delivery" ? deliveryFee.delivery_price : 0,
                                network_id: Number(NETWORK),
                                is_paid: paid === "Paid" ? true : false,
                                billing_address: returnAddressAsString(billing),
                                giftcard_code: giftCardCode ? giftCardCode : null
                            },
                            client: selectedCustomerData.id,
                            address: typeOfOrder === "Delivery" ? { ..._delivery, country: delivery?.country ? delivery.country["code"] : "" } : null,
                            products: formatProducts(selectedProduct),
                        };
                        if (typeOfOrder === "Collection") {
                            delete orderObjectToSend.address
                        }
                        await fetch({
                            method: editId ? EMethod.PATCH : EMethod.POST,
                            url: editId ? `orders/api/orders/${editId}/` : `orders/api/orders/`,
                            data: {
                                ...orderObjectToSend
                            }
                        }).then((response) => {
                            setOrderSuccessfull(response)
                            setIsloading(false)
                            dispatch(sendAlert("Order created successfully", EAlertVariant.SUCCESS))
                            // generatePDF(response)

                        }).catch((e) => {
                            setIsloading(false)
                            dispatch(sendAlert("Something went wrong", EAlertVariant.ERROR))

                        })
                    }).catch((e) => {
                        setIsloading(false)
                        console.log("submit error ", e)
                    })
                }
            }
        })

    }

    const toggleProductDetailsModal = (product) => {
        setProductDetails(product)
        setOpenProductDetails(true)
    }

    const addExtra = (product_id, extra_idx) => {
        var _products = [...selectedProduct]
        _products.map((product, idx) => {
            if (product.product_id === product_id && _products[idx].extras[extra_idx].quantity <= _products[idx].extras[extra_idx].limit - 1) {
                _products[idx].extras[extra_idx].quantity += 1
            }
        })
    }
    const removeExtra = (product_id, extra_idx) => {
        var _products = [...selectedProduct]
        _products.map((product, idx) => {
            if (product.product_id === product_id && _products[idx].extras[extra_idx].quantity > 0) {
                _products[idx].extras[extra_idx].quantity -= 1
            }
        })
    }


    useEffect(() => {
        if (typeOfOrder === "Collection") {
            setDeliveryFee({
                service_price: 0,
                delivery_price: 0,
                checkout_price: 0,
                discount: 0,
                stuart_price: 0,
                distance: "",
                duration: "",
                message: ""
            })
        }
    }, [typeOfOrder])

    const getSelectedVariations = (product_id) => {
        const _product = selectedProduct.find((el) => el.product_id === product_id)
        if (!_product) return []
        return _product['selected_variation'] ? _product['selected_variation'] : []
    }
    const pickVariation = (selected_variation, product_id: number) => {
        var _products = [...selectedProduct]
        var variationIncluded = false
        _products.map((_product, idx) => {
            if (_product.product_id === product_id) {
                _products[idx].selected_variation = _products[idx].selected_variation.filter(el => {
                    if (el.id !== selected_variation.id) return true
                    else {
                        variationIncluded = true
                        return false
                    }
                })
                if (!variationIncluded) {
                    _products[idx].selected_variation.push({ ...selected_variation, amount: 1 })
                }
                if (_products[idx].selected_variation.length === 0) {
                    setTimeout(() => {
                        deselectProduct(_products[idx].product_id)
                    }, 200);
                }
            }
        })
        setSelectedproduct(_products)
    }

    const increaseVariationAmount = (variation_id, product_id) => {
        var _products = [...selectedProduct]
        _products.map((product, productIdx) => {
            if (product_id === product.product_id) {
                product.selected_variation.map((variation, variationIdx) => {
                    if (variation.id === variation_id) {
                        _products[productIdx].selected_variation[variationIdx].amount += 1
                    }
                })
            }
        })
        setSelectedproduct(_products)
    }
    const decreaseVariationAmount = (variation_id, product_id) => {
        var _products = [...selectedProduct]
        _products.map((product, productIdx) => {
            if (product_id === product.product_id) {
                product.selected_variation.map((variation, variationIdx) => {
                    if (variation.id === variation_id) {
                        _products[productIdx].selected_variation[variationIdx].amount -= 1
                        if (_products[productIdx].selected_variation[variationIdx].amount === 0) {
                            _products[productIdx].selected_variation.splice(variationIdx, 1)
                        }
                    }
                })
            }
        })
        setSelectedproduct(_products)
    }
    const productPrice = (product): number => {
        if (product.variation[0]) {
            return product.variation[0].price
        }
        return product.price
    }

    const handleCloseOrderSuccess = () => {
        setOrderSuccessfull(null)
        setSelectedproduct([])
        setSelectedCustomerData({})
        setSelectedCategories([])
        setSelectedCustomer({})
        setPaid("Not paid")
        setPayment("Card")
        if (query.get("edit"))
            window.location.assign("/dashboard/comercialInvoice")
        setDeliveryFee({
            service_price: 0,
            delivery_price: 0,
            checkout_price: 0,
            discount: 0,
            stuart_price: 0,
            distance: "",
            duration: "",
            message: ""
        })
        setEditId("")
        setDeliveryFeeCalculated(false)
        setOrderToEditObj(undefined)
        setEditOrderView(false)
        setSelectedOrderToEdit({ label: "", value: "" })
        setGiftCardCode("")
        setGiftCardError("")
        setPhone("")
        setEmail("")
        setGiftCardValue(0)
    }
    const checkBilling = () => {
        var entered = false
        var keys = ['street',
            'city',
            'country',
            'zipcode']
        keys.map(key => {
            if (billing[key] === "") {
                entered = true
            }
        })
        console.log("returning illing", entered, billing)
        return entered
    }
    return (
        <>
            {loading && <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "rgba(255, 255, 255, 0.32)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 99999 }}>
                <CircularProgress />
            </div>}
            <Grid container spacing={3} style={{ height: "100%", overflowY: "hidden", overflowX: "hidden" }}>
                <Grid lg={8} md={8} sm={7} xs={12}>
                    <Grid spacing={3} style={{ marginLeft: "25px", marginTop: "15px" }} container>
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <Button variant='contained' onClick={() => {
                                if (editOrderView) {
                                    handleCloseOrderSuccess()
                                }
                                setEditOrderView(!editOrderView)
                            }}>
                                <Typography>Edit an order</Typography>
                            </Button>
                        </div>
                        {editOrderView && <Grid
                            xs={12} sm={12} md={5} lg={4}
                        >
                            <Typography
                                onClick={() => setSearch("")}
                                color="primary"
                                variant="overline"
                                className={`${classes.toolBarTitles}`}
                            >{`Order to edit: `}</Typography>{' '}
                            <div style={{ marginTop: "7px" }}>
                                {showOrderToEdit()}
                            </div>
                        </Grid>}
                    </Grid>
                    <Grid container xs={10} lg={12} md={12} sm={11} style={{ marginLeft: "15px", marginTop: "15px", gap: "7px" }} spacing={3} >
                        {
                            storeFilter.length < 2 ? null :
                                <Grid
                                    xs={12} sm={12} md={5} lg={4}

                                >
                                    <Typography
                                        onClick={() => setSearch("")}
                                        color="primary"
                                        variant="overline"
                                        className={`${classes.toolBarTitles}`}
                                    >{`Store: `}</Typography>{' '}
                                    <div style={{ marginTop: "7px" }}>
                                        {showStores()}
                                    </div>
                                </Grid>
                        }
                        <Grid xs={12} sm={12} md={7} lg={5} >
                            <Typography
                                onClick={() => setSearch("")}
                                color="primary"
                                variant="overline"
                                className={`${classes.toolBarTitles}`}
                            >{`Customer: `}</Typography>{' '}
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Select
                                    className={selectedCustomerError ? classes.selectInputError : classes.selectInput}
                                    classNamePrefix="select"
                                    value={selectedCustomer}
                                    isDisabled={editId ? true : false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    placeholder="Customer"
                                    name="color"
                                    options={clients.map((customer) => ({ value: customer.id, label: customer.name + ", " + customer.email }))}
                                    onChange={(e: any) => {
                                        handleChangeClient(e, {})
                                        setDeliveryFee({ ...deliveryFee, delivery_price: 0 })
                                        setSelectedCustomerError(false)

                                    }}
                                    styles={customStyles}
                                />
                                {!editId && <IconButton onClick={() => setNewClientOpen(true)}><AddIcon /></IconButton>}
                            </div>

                            {
                                selectedCustomerError ?
                                    <Typography
                                        variant="overline"
                                        className={`${classes.toolBarTitlesError}`}
                                    >Please select a customer</Typography>
                                    : null
                            }
                        </Grid>
                    </Grid>

                    <Grid container lg={12} md={12} sm={11} xs={10} style={{ marginLeft: "15px", marginTop: "15px", gap: "7px" }} spacing={3} >

                        <Grid
                            sm={12} md={12} lg={12} xs={10}

                        >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    <Typography
                                        color="primary"
                                        variant="overline"
                                        className={`${classes.toolBarTitles}`}
                                    >{`Type of order: `}</Typography>
                                </FormLabel>
                                <RadioGroup style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }} value={typeOfOrder} onChange={(e) => { setTypeOfOrder(e.target.value) }}>
                                    <FormControlLabel value="Delivery" control={<Radio />} label="Delivery" />
                                    <FormControlLabel value="Collection" control={<Radio />} label="Collection" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid
                            sm={12} md={12} lg={12} xs={12}
                            container
                            style={{ marginLeft: "15px", gap: "5px" }}
                        >
                            {typeOfOrder === "Delivery" ?
                                <>
                                    <Grid
                                        sm={5} md={5} lg={4} xs={5}
                                    >
                                        <FormControl
                                            variant="standard"
                                            style={{
                                                width: '100%',
                                            }}
                                            fullWidth={true}
                                            margin={'normal'}
                                        >
                                            <TextField
                                                value={deliveryDate}
                                                onChange={(e) => setDeliveryDate(e.target.value)}
                                                error={false}
                                                variant="standard"
                                                type='date'
                                                label="Delivery Date     "
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        sm={5} md={5} lg={4} xs={5}
                                    >
                                        <FormControl
                                            variant="standard"
                                            style={{
                                                width: '100%',
                                                marginTop: '0px',
                                            }}
                                            fullWidth={true}
                                            margin={'normal'}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Delivery time"
                                                    value={deliveryTime}
                                                    onChange={(e) => setDeliveryTime(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>

                                </>
                                :
                                <>
                                    <Grid
                                        sm={5} md={5} lg={5} xs={5}
                                    >
                                        <FormControl
                                            variant="standard"
                                            style={{
                                                width: '100%',
                                                marginTop: '16px',
                                            }}
                                            fullWidth={true}
                                            margin={'normal'}
                                        >
                                            <TextField
                                                value={collectionDate}
                                                onChange={(e) => setCollectionDate(e.target.value)}
                                                error={false}
                                                variant="standard"
                                                type='date'
                                                label="Collection Date     "
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        sm={5} md={5} lg={5} xs={5}
                                    >
                                        <FormControl
                                            variant="standard"
                                            style={{
                                                width: '100%',
                                                marginTop: '0px',
                                            }}
                                            fullWidth={true}
                                            margin={'normal'}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Collection time"
                                                    value={collectionTime}
                                                    onChange={(e) => {

                                                        setCollectionTime(e)
                                                        dateTime(collectionDate, e)
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>


                                </>
                            }
                            <Grid lg={3} md={4} sm={12} xs={10}>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        width: '100%',
                                    }}
                                    fullWidth={true}
                                    margin={'normal'}
                                >
                                    <InputLabel>Payment method</InputLabel>
                                    <MuiSelect
                                        value={payment}
                                        placeholder={"Payment method"}
                                        onChange={(e) => {
                                            if (e.target.value === "Card") setPaid("Not paid")
                                            setPayment(e.target.value)
                                        }}
                                        error={false}
                                        variant="standard"
                                        label="Payment method"
                                    >
                                        <MenuItem value={"Card"}>Stripe</MenuItem>
                                        <MenuItem value={"Invoice"}>Invoice / Cash</MenuItem>
                                    </MuiSelect>
                                </FormControl>
                            </Grid>
                            <Grid lg={3} md={4} sm={12} xs={10}>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        width: '100%',
                                    }}
                                    fullWidth={true}
                                    margin={'normal'}
                                >
                                    <InputLabel>Payment status</InputLabel>
                                    <MuiSelect
                                        disabled={payment === "Card"}
                                        value={paid}
                                        placeholder={"Payment status"}
                                        onChange={(e) => {
                                            setPaid(e.target.value)
                                        }}
                                        error={false}
                                        variant="standard"
                                        label="Payment status"
                                    >
                                        <MenuItem value={"Paid"}>Paid</MenuItem>
                                        <MenuItem value={"Not paid"}>Not paid</MenuItem>
                                    </MuiSelect>
                                </FormControl>
                            </Grid>
                            <Grid lg={3} md={4} sm={12} xs={10}>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        width: '100%',
                                    }}
                                    fullWidth={true}
                                    margin={'normal'}
                                    error={phoneError === true && phone === ""}

                                >
                                    <InputLabel>Phone number</InputLabel>
                                    <Input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {phoneError === true && phone === "" ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                                </FormControl>
                            </Grid>
                            <Grid lg={3} md={4} sm={12} xs={10}>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        width: '100%',
                                    }}
                                    fullWidth={true}
                                    margin={'normal'}
                                    error={emailError === true && email === ""}

                                >
                                    <InputLabel>Email</InputLabel>
                                    <Input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {emailError === true && email === "" ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ marginLeft: "25px", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "20px" }} sm={11} md={12} lg={12} xs={11}>
                        {typeOfOrder === "Delivery" && selectedCustomer["value"] &&
                            < DeliveryForm
                                delivery={delivery}
                                deliveryCountryQuery={deliveryCountryQuery}
                                deliveryError={deliveryError}
                                setDelivery={(e) => {
                                    setDeliveryFee({
                                        service_price: 0,
                                        delivery_price: 0,
                                        checkout_price: 0,
                                        discount: 0,
                                        stuart_price: 0,
                                        distance: "",
                                        duration: "",
                                        message: ""
                                    })
                                    setDeliveryFeeCalculated(false)
                                    setDelivery(e)
                                    setDeliveryChanged(true)
                                }}
                                setDeliveryCountryQuery={(e) => setDeliveryCountryQuery(e)}
                            />
                        }
                    </Grid>
                    <Grid container sm={11} md={12} lg={12} spacing={3} style={{ marginLeft: "25px", marginTop: '25px', gap: "5px" }}>
                        {filteredCategories.map((category) => (
                            <>
                                <Grid onClick={() => selectCategory(category.id)} key={category.id} sm={3} md={3} lg={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: !isSelectedCategory(category.id) ? "1px solid lightgray" : `1px solid ${PRIMARY_COLOR()}`,
                                        padding: "8px 5px 8px 5px",
                                        borderRadius: "12px",
                                        cursor: "pointer"
                                    }}>
                                    <Typography style={{
                                        whiteSpace: "normal",
                                        width: " 100%",
                                        overflow: "hidden",
                                        textAlign: "center",
                                        color: !isSelectedCategory(category.id) ? "black" : PRIMARY_COLOR()
                                    }}>{category.name}</Typography>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    <Grid style={{ marginLeft: "25px", marginTop: "20px" }} item xs={10} sm={8} md={7} lg={4}>
                        <FormControl
                            style={{ marginBottom: '1rem', width: '90%', }}
                        >
                            <InputLabel htmlFor="name-input">Filter products</InputLabel>
                            <Input
                                id="name-input"
                                type="string"
                                value={productSearch}
                                onChange={(e) => {
                                    setProductSearch(e.target.value)
                                }} />
                        </FormControl>
                    </Grid>
                    {
                        selectedProductsError ?
                            <div style={{ marginTop: "15px", marginLeft: "15px" }}
                            >
                                <Typography
                                    variant="overline"
                                    className={`${classes.toolBarTitlesError}`}
                                >Please select a product</Typography>
                            </div>
                            : null
                    }
                    <Grid container sm={12} md={12} lg={12} style={{ marginLeft: "15px", marginTop: "30px", paddingLeft: "10px", paddingRight: "30px" }} spacing={3}>
                        {stock?.products?.map((product, index) => (
                            <>
                                {
                                    isCategoryIncluded(product.category) && isSearchIncluded(product.name) ?
                                        <Product
                                            product={product}
                                            amount={selectedProduct.length > 0 ? selectedProduct.find(_product => _product.product_id === product.product_id)?.amount : 0}
                                            key={product.product_id}
                                            price={productPrice(product)}
                                            isSelected={isProductSelected(product.product_id)}
                                            select={() => {
                                                selectProduct(product.product_id, product)
                                                if ((product.extras.length > 0 || product.sizes.length > 0) && !isProductSelected(product.product_id)) {
                                                    selectProduct(product.product_id, product)
                                                    toggleProductDetailsModal(product)
                                                } else {
                                                    setProductDetails({})
                                                    setOpenProductDetails(false)
                                                }
                                            }}
                                            increaseAmount={() => increaseAmount(product.product_id)}
                                            decreaseAmount={() => decreaseAmount(product.product_id)}
                                            openProductDetails={() => {
                                                if (!isProductSelected(product.product_id)) {
                                                    selectProduct(product.product_id, product)
                                                }
                                                toggleProductDetailsModal(product)
                                            }}
                                            pickVariation={(selected_variaiton) => pickVariation(selected_variaiton, product.product_id)}
                                            deselectProduct={() => deselectProduct(product.product_id)}
                                            addExtra={(extra_id) => addExtra(product.product_id, extra_id)}
                                            removeExtra={(extra_id) => removeExtra(product.product_id, extra_id)}
                                            selectedVariation={getSelectedVariations(product.product_id)}
                                            increaseVariationAmount={(variation_id) => increaseVariationAmount(variation_id, product.product_id)}
                                            decreaseVariationAmount={(variation_id) => decreaseVariationAmount(variation_id, product.product_id)}
                                        /> : null
                                }
                            </>
                        ))}
                    </Grid>

                </Grid>
                <Grid container className={classes.left_side_wrapper} lg={4} md={4} sm={5} xs={12} >
                    <OrderSummary
                        openProductDetails={openProductDetails}
                        setProductDetails={(e) => setProductDetails(e)}
                        setOpenProductDetails={(e) => setOpenProductDetails(e)}
                        addExtra={(id, extra_id) => addExtra(id, extra_id)}
                        removeExtra={(id, extra_id) => removeExtra(id, extra_id)}
                        productDetails={productDetails}
                        selectedProduct={selectedProduct}
                        billing={billing}
                        typeOfOrder={typeOfOrder}
                        returnAddressAsString={(billing) => returnAddressAsString(billing)}
                        deliveryFee={deliveryFee}
                        submit={() => submit()}
                        getdeliveryFee={(storeAddress, deliveryAddress, ignoreCheckForm) => getdeliveryFee(storeAddress, deliveryAddress, ignoreCheckForm)}
                        delivery={delivery}
                        getStoreAddress={() => getStoreAddress()}
                        pickSize={(selected_variaiton, product_id) => pickVariation(selected_variaiton, product_id)}
                        deselectProduct={(id) => deselectProduct(id)}
                        deliveryFeeCalculated={deliveryFeeCalculated}
                        orderSuccessfull={orderSuccessfull}
                        handleCloseOrderSuccess={() => handleCloseOrderSuccess()}
                        loading={isLoading}
                        handleChangeBillingAddress={(value) => handleChangeBillingAddress(value)}
                        checkBilling={() => checkBilling()}
                        giftCardCode={giftCardCode}
                        setGiftCardCode={(e) => setGiftCardCode(e)}
                        isPaid={paid === "Paid" ? true : false}
                        editId={editId}
                        selecteduser={selectedCustomer}
                        checkGiftCard={() => {
                            checkGiftCard()
                        }}
                        giftCardError={giftCardError}
                        giftCardValue={giftCardValue}
                    />
                </Grid>
            </Grid>
            <NewClientModal open={newClientOpen} onClose={() => {
                setNewClientOpen(false)
                getClients()
            }} setNewClient={(clientValue, clientData) => {
                clientData.phone_number = clientData.profile.phone_number
                handleChangeClient(clientValue, clientData)
            }} />
        </>
    );
}
