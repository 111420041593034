import React from 'react';
import {
    Button,
    InputLabel,
    Container,
    Grid,
    FormControl,
    IconButton,
    Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editStoreImport } from 'redux/ducks/store';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IStoreBase } from 'interfaces/store_test';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    stores?: any[];
    selectedStore?: IStoreBase;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
    const { onClose, open, stores } = props;
    const [store, setStore] = React.useState(0);

    const dispatch = useDispatch();

    const handleClose = () => {
        setStore(0)
        onClose();
    };
    const handleStore = (event: any) => {
        setStore(event.target.value);
    };
    const submitCreation = async () => {
        dispatch(
            editStoreImport(props.selectedStore?.id, {
                store,
            })
        ).then((result: boolean) => {
            if (result) handleClose();
        });
    };
    return (
        <Modal
            className="create-menu-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-create-menu-content">
            <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>

                <h2>Select Store to Import to {props.selectedStore?.name}</h2>
                <Container maxWidth="md">
                    {stores && (
                        <Grid item xs={12} spacing={5} sm={12}>
                            <FormControl
                                fullWidth={true}
                                margin={'normal'}
                            >
                                <InputLabel htmlFor="category-input">Store to import</InputLabel>
                                <Select
                                    id="category-input"
                                    value={store}
                                    onChange={handleStore}
                                >
                                    {stores.map((store) => (
                                        <MenuItem value={store.id}>
                                            {store.name}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {/* End of left column */}
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ marginBottom: '30px' }}
                                variant="outlined"
                                onClick={submitCreation}
                                color="primary"
                            >
                                {' '}
                                Create{' '}
                            </Button>
                        </div>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
}
