import React, { useState } from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  // TextField,
  // ThemeProvider,
  Typography
} from '@material-ui/core';
// import { DropzoneArea } from 'material-ui-dropzone';
// import { createTheme } from '@material-ui/core';
// import PhoneInput from 'react-phone-input-2';
// import allCountries from './countryList';
import { PRIMARY_COLOR } from 'settings';

import 'react-phone-input-2/lib/material.css';
import './styles.css';
import { useSelector } from 'react-redux';
import IStore from 'interfaces/store';
// import { NETWORK } from 'settings';
interface IProps {
  handleSave?: (data: {
    active: boolean;
    name: string;
    street: string;
    neighborhood: string;
    zipcode: string;
    city: string;
    country: string;
  }) => void;
}
interface IFormData {
  street: string;
  neighborhood: string;
  postalCode: string;
  city: string;
  // country: string;
}

interface IErrors {
  street: boolean;
  neighborhood: boolean;
  postalCode: boolean;
  city: boolean;
  // country: boolean;
}

const errorMensages = {
  required: 'This field is required'
};

export default function CreateUserAddress(props: IProps) {
  const { handleSave } = props;

  const { authentication } = useSelector<IStore, IStore>((state) => state);
  const [hasSubmitedOnce, setHasSubmitedOnce] = useState<boolean>(false);

  const [formData, setFormData] = useState<IFormData>({
    street: '',
    neighborhood: '',
    postalCode: '',
    city: ''
    // country: ''
  });

  const [formDataErrors, setFormDataErrors] = useState<IErrors>({
    street: false,
    neighborhood: false,
    postalCode: false,
    city: false
    // country: false
  });

  // const [countryInput, setCountryInput] = useState('');

  const handleFormData = (event: any) => {
    const input = event.target.id;
    const value = event.target.value;

    switch (input) {
      case 'street':
        setFormData((currentData) => ({
          ...currentData,
          street: value
        }));
        break;
      case 'neighborhood':
        setFormData((currentData) => ({
          ...currentData,
          neighborhood: value
        }));
        break;
      case 'postal-code':
        setFormData((currentData) => ({
          ...currentData,
          postalCode: value
        }));
        break;
      case 'city':
        setFormData((currentData) => ({
          ...currentData,
          city: value
        }));
        break;
      default:
        break;
    }
  };

  // const handleCountryData = (countryName: string) => {
  //   setFormData((currentData) => ({
  //     ...currentData,
  //     country: countryName
  //   }));
  // };

  const validadeFields = () => {
    if (!hasSubmitedOnce) return;

    for (const [key, value] of Object.entries(formData)) {
      setFormDataErrors((currentData) => ({
        ...currentData,
        [key]: value ? false : true
      }));
    }
  };

  const formatPhone = (phone_number: string) => {
    return `+${phone_number.substring(0, 2)} ${phone_number.substring(
      2,
      5
    )} ${phone_number.substring(
      5,
      8
    )} ${phone_number.substring(8, 14)} `;
  };

  const submit = () => {
    let proceed = true;
    setHasSubmitedOnce(true);

    validadeFields();

    for (const [_key, value] of Object.entries(formData)) {
      if (value === '') {
        proceed = false;
      }
    }

    if (proceed) {
      handleSave({
        active: true,
        name: authentication.name,
        street: formData.street,
        neighborhood: formData.neighborhood,
        zipcode: formData.postalCode,
        city: formData.city,
        country: 'GB'
      });
    }
  };

  return (
    <>
      <Grid
        style={{
          borderRadius: '0.5vw',
          width: '40vw',
          padding: '1vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2vw'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '22px', marginBottom: '10px' }}>
            Complete your profile
          </Typography>
        </div>
        <div style={{ width: '75%', marginBottom: '20px' }}>
          {' '}
          <Typography>
            Once you have completed the address form, we will create an account
            for you in Stripe and send you an email. This will enable your store
            to start taking orders and getting paid.
          </Typography>
        </div>

        <div style={{ width: '75%' }}>
          <Typography>Name: {authentication.name}</Typography>
          <Typography>Email: {authentication.email}</Typography>
          <Typography>Phone: {formatPhone(authentication.phone)}</Typography>
        </div>
      </Grid>
      <Grid
        style={{
          // border: '1px solid gray',
          borderRadius: '0.5vw',
          width: '40vw',
          padding: '1vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2vw'
        }}
      >
        <Typography
          style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
        >
          Add address
        </Typography>
        <FormControl
          style={{ marginBottom: '1.5rem', width: '75%' }}
          required={true}
          error={formDataErrors.street}
        >
          <InputLabel htmlFor="street">First line of the address</InputLabel>
          <Input
            id="street"
            type="string"
            value={formData.street}
            onChange={handleFormData}
            onBlur={validadeFields}
          />
          <FormHelperText>
            {formDataErrors.street && errorMensages.required}
          </FormHelperText>
        </FormControl>{' '}
        <FormControl
          style={{ marginBottom: '1.5rem', width: '75%' }}
          required={true}
          error={formDataErrors.neighborhood}
        >
          <InputLabel htmlFor="neighborhood">Town</InputLabel>
          <Input
            id="neighborhood"
            type="string"
            value={formData.neighborhood}
            onChange={handleFormData}
            onBlur={validadeFields}
          />
          <FormHelperText>
            {formDataErrors.neighborhood && errorMensages.required}
          </FormHelperText>
        </FormControl>{' '}
        <FormControl
          style={{ marginBottom: '1.5rem', width: '75%' }}
          required={true}
          error={formDataErrors.city}
        >
          <InputLabel htmlFor="city">City</InputLabel>
          <Input
            id="city"
            type="string"
            value={formData.city}
            onChange={handleFormData}
            onBlur={validadeFields}
          />
          <FormHelperText>
            {formDataErrors.city && errorMensages.required}
          </FormHelperText>
        </FormControl>{' '}
        <FormControl
          style={{ marginBottom: '1.5rem', width: '75%' }}
          required={true}
          error={formDataErrors.postalCode}
        >
          <InputLabel htmlFor="postal-code">Postal Code</InputLabel>
          <Input
            id="postal-code"
            type="string"
            value={formData.postalCode}
            onChange={handleFormData}
            onBlur={validadeFields}
          />
          <FormHelperText>
            {formDataErrors.postalCode && errorMensages.required}
          </FormHelperText>
        </FormControl>{' '}
        {/* <FormControl
          style={{ marginBottom: '1rem', width: '75%' }}
          required={true}
        >
          <Autocomplete
            value={formData.country}
            onChange={(event: any, newValue: string | null) => {
              handleCountryData(newValue);
            }}
            inputValue={countryInput}
            onInputChange={(event, newInputValue) => {
              setCountryInput(newInputValue);
            }}
            id="country"
            options={allCountries.map((element) => {
              return element.name;
            })}
            getOptionLabel={(option) => {
              if (option !== undefined) {
                return option;
              } else return '';
            }}
            renderOption={(option) => (option !== undefined ? option : '')}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        </FormControl> */}
        <Button
          style={{
            backgroundColor: PRIMARY_COLOR(),
            color: 'white',
            borderRadius: '0.7vw',
            height: '2.5vw',
            paddingLeft: '0.5vw',
            paddingRight: '0.5vw',
            fontFamily: 'Work Sans, sans-serif'
          }}
          onClick={() => submit()}
        >
          Save and continue
        </Button>
      </Grid>
    </>
  );
}
