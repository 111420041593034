import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { ITableState, ETypesTable, ITableAction, IFetchAction, ISuccessAction, IFailureAction, ThunkAction, Dispatch, GetState } from 'interfaces/table';

/* Production State. */
const initialState: ITableState = {
  fetch: false,
  results: [],
  error: ''
};

/* Production Reducer. */
export default (
  state: ITableState = initialState,
  action: ITableAction
): ITableState => {
  switch (action.type) {
    case ETypesTable.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesTable.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        error: ''
      };
    case ETypesTable.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Production Action Creators Functions. */
export const fetchTable = (): IFetchAction => ({
  type: ETypesTable.FETCH
});

export const successTable = (
  payload: ITableState
): ISuccessAction => ({
  type: ETypesTable.SUCCESS,
  payload
});

export const failureTable = (payload: string): IFailureAction => ({
  type: ETypesTable.FAILURE,
  payload
});

/* Production Side Effects Functions. */
export const getTable = (): ThunkAction => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(fetchTable());
    let response;
    let url = `/orders/api/tables/?realtime=${true}`
    response = await fetch({
      method: EMethod.GET,
      url: url
    });
    dispatch(successTable(response));
  } catch (error) {
    dispatch(failureTable(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};

export const getTableOrder = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { table } = getState();
    dispatch(fetchTable());
    let response;
    let url = `/orders/api/tables/table_orders/`
    response = await fetch({
      method: EMethod.GET,
      url: url
    });
    dispatch(successTable({ ...table, results: response }));
  } catch (error) {
    dispatch(failureTable(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};
