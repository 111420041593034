import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  InputBase,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { Customer } from 'interfaces/customersAndSellers';
import { formatCellPhone } from '../../helpers/common';
import CustomerModal from './CustomerOrdersModal';
import { CustomerOrders } from 'interfaces/customersAndSellers';
import { Search } from '@material-ui/icons';
import ModalDeleteCustomer from './CustomerDeleteModal';
import DeleteIcon from '@material-ui/icons/Delete';
import { NETWORK } from 'settings';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  customers: Customer[];
  handleLastOrder: (orderId: number) => void;
  query: string;
  setQuery: (e) => void;
  getCustomers: () => void;
}

export default function ClientsTable(props: Props) {
  const classes = useStyles();
  const { customers, query, setQuery, getCustomers } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<CustomerOrders[]>([]);
  const [customerToDelete, setCustomerToDelete] = useState(null)
  const [sortBy, setSortBy] = useState<any>("orders")
  const [customer, setCustomer] = useState('');
  console.log("customers -> ", customers)

  const sortCustomers = () => {
    if (NETWORK === "6" || NETWORK === "15") {
      if (sortBy === "orders") {
        return customers.sort(function (a, b) {
          return b.orders.length - a.orders.length;
        });
      }
      return customers.sort(function (a, b) {
        return b.id - a.id;
      });
    }
    return customers
  }
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: "0px 10px 0px 20px", alignItems: "center" }} >
        {NETWORK === "6" || NETWORK === "15" ? <FormControl
          variant="standard"
          style={{
            width: '250px',
          }}
          fullWidth={true}
          margin={'normal'}
        >
          <InputLabel>Sort by</InputLabel>
          <Select
            value={sortBy}
            placeholder={"Payment method"}
            onChange={(e) => {
              setSortBy(e.target.value)
            }}
            error={false}
            variant="standard"
            label="Sort by"
          >
            <MenuItem value={"orders"}>Number of orders</MenuItem>
            <MenuItem value={"date"}>Date created</MenuItem>
          </Select>
        </FormControl> : <div />}
        <Paper style={{ margin: "15px" }}>
          <InputBase
            style={{ paddingLeft: "5px" }}
            placeholder="Search customer"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <IconButton
            style={{ padding: 10 }}
            aria-label="search"

          >
            <Search />
          </IconButton>
        </Paper></div>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Stripe customer ID</TableCell>
            <TableCell align="right">Orders</TableCell>
            <TableCell ></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {sortCustomers() ? (
            <>
              {sortCustomers().map((customer) => (
                <TableRow key={customer.email + customer.phone_number}>
                  <TableCell component="th" scope="row">
                    {customer.name ? customer.name : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {customer.email ? <Button style={{ textTransform: "inherit" }}><a style={{ textDecoration: "none", fontWeight: 600, color: "black" }} href={`mailto:${customer.email}`}>{customer.email}</a></Button> : ''}
                  </TableCell>
                  <TableCell>
                    {customer.phone_number
                      ? formatCellPhone(customer.phone_number)
                      : ''}
                  </TableCell>

                  <TableCell align="left">
                    {customer.stripe_account ? customer.stripe_account : ''}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setOpen(true);
                      setData(customer.orders);
                      setCustomer(customer.name);
                    }}
                    align="right"
                  >
                    {' '}
                    <Button>{customer.orders.length}</Button>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => setCustomerToDelete(customer)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
      <CustomerModal
        open={open}
        handleClose={() => {
          setCustomer('');
          setData([]);
          setOpen(false);
        }}
        customer={customer}
        data={data}
      />
      <ModalDeleteCustomer client={customerToDelete} open={customerToDelete !== null} reset={() => getCustomers()} onClose={() => setCustomerToDelete(null)} />
    </TableContainer >
  );
}
