import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchBestSellers } from 'redux/ducks/customersAndSellers';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import SellersTable from './SellersTable';

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { customersAndSellers } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    })
  
    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    })
  
    const d = document.querySelectorAll<HTMLElement>('.d-config');
    d.item(0).style.color = "#ff5a23";
  
    d.item(1).style.color = "#ff5a23";
  })

  useEffect(() => {
    dispatch(fetchBestSellers());
  }, [dispatch]);

  return (
    <>
      <Loading show={customersAndSellers.fetch} />
      <Grid container spacing={3}>
        <SellersTable sellers={customersAndSellers.sellers} />
      </Grid>
    </>
  );
}
