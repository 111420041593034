import React from 'react';
import {
  Button,
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  ThemeProvider,
  createTheme,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createStore } from 'redux/ducks/store';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NETWORK } from 'settings';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  stores?: any[];
}
const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        maxWidth: '25vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open, stores } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [image, setImage] = React.useState('');
  const [darkLogo, setDarkLogo] = React.useState('');
  const [lightLogo, setLightLogo] = React.useState('');
  const [currency, setCurrency] = React.useState('£');
  const [store, setStore] = React.useState(0);
  const [stripeKey, setStripeKey] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [ownerEmail, setOwnerEmail] = React.useState('');
  const [ownerEmailError, setOwnerEmailError] = React.useState('');
  const [orderPrefix, setOrderPrefix] = React.useState('');
  const [addressUrl, setAddressUrl] = React.useState('');
  const [merchantOrderId, setMerchantOrderId] = React.useState('');
  const [sameDayDelivery, setSameDayDelivery] = React.useState(false);
  const [promote, setPromote] = React.useState(false);
  const [onlyDelivery, setOnlyDelivery] = React.useState(false);
  const [description, setDescription] = React.useState('')
  const ckEditorToolbarConfig = [
    'Heading',
    '|',
    'bold',
    'italic',
    '|',
    'BulletedList',
    'NumberedList',
    '|',
    'Indent',
    '|',
    'BlockQuote',
    'undo',
    'redo'
  ];
  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setNameError('');
    setImage('');
    setLightLogo('')
    setDarkLogo('')
    setStore(0);
    setPhoneNumber('');
    setPhoneNumberError('');
    setSlug('');
    setStripeKey('');
    setSlugError('');
    setOwnerEmail('');
    setOwnerEmailError('');
    setOrderPrefix('');
    setAddressUrl('');
    setMerchantOrderId('');
    setSameDayDelivery(false);
    setPromote(false);
    setOnlyDelivery(false);
    onClose();
  };

  const handleName = (event: any) => {
    if (
      /^[^\W_]*$/.test(event.target.value) ||
      event.target.value.includes(' ')
    ) {
      setName(event.target.value);
      setNameError('');
    }
  };
  const handleStore = (event: any) => {
    setStore(event.target.value);
  };
  const handleSameDayDelivery = (event: boolean) => {
    setSameDayDelivery(event);
  };
  const handlePromote = (event: boolean) => {
    setPromote(event);
  };
  const handleOnlyDelivery = (event: boolean) => {
    setOnlyDelivery(event);
  };
  const handleCurrency = (event: any) => {
    setCurrency(event.target.value);
  };
  const handleMerchantOrderId = (event: any) => {
    setMerchantOrderId(event.target.value);
  };
  const handlePhoneNumber = (event: any) => {
    if (/(^[0-9]*[-]*[0-9]*$)/.test(event.target.value)) {
      setPhoneNumber(event.target.value);
      setPhoneNumberError('');
    }
  };
  const handleSlug = (event: any) => {
    setSlug(event.target.value);
    setSlugError('');
  };
  const handleAddressUrl = (event: any) => {
    setAddressUrl(event.target.value);
  };
  const handlePrefix = (event: any) => {
    if (event.target.value.length <= 6 && /^[^\W_]*$/.test(event.target.value))
      setOrderPrefix(event.target.value);
  };
  const handleOwnerEmail = (event: any) => {
    setOwnerEmail(event.target.value.trim());
    setOwnerEmailError('');
  };
  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImage(event[0]);
    }
  };
  const handleDarkLogo = (event: any) => {
    if (event.length > 0) {
      setDarkLogo(event[0]);
    }
  };
  const handleLightLogo = (event: any) => {
    if (event.length > 0) {
      setLightLogo(event[0]);
    }
  };
  const handleStripeKey = (event: any) => {
    setStripeKey(event.target.value);
  };
  const checkForm = () => {
    let entered = false;
    if (name === '') {
      entered = true;
      setNameError('Please insert the name');
    }
    if (phoneNumber === '') {
      entered = true;
      setPhoneNumberError('Please insert phone');
    }
    if (ownerEmail === '') {
      entered = true;
      setOwnerEmailError('Please insert email');
    }
    if (slug === '') {
      entered = true;
      setSlugError('Please insert slug');
    }
    return entered;
  };

  const submitCreation = async () => {
    if (!checkForm()) {
      dispatch(
        createStore({
          name,
          order_prefix: orderPrefix,
          currency,
          store,
          stripe_key: stripeKey,
          phone_number: phoneNumber,
          owner_email: ownerEmail,
          image,
          light_logo: lightLogo,
          dark_logo: darkLogo,
          slug,
          address_url: addressUrl,
          merchant_ifood_id: merchantOrderId,
          sameday_delivery: sameDayDelivery,
          description: description,
          networks: [NETWORK]
        })
      ).then((result: boolean) => {
        if (result) handleClose();
      });
    }
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <h2>Add Store</h2>
        <Container maxWidth="md">
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={nameError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={handleName}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={ownerEmailError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Email</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={ownerEmail}
                onChange={handleOwnerEmail}
              />
              <FormHelperText>{ownerEmailError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={phoneNumberError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Phone</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={phoneNumber}
                onChange={handlePhoneNumber}
              />
              <FormHelperText>{phoneNumberError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={slugError !== ''}
            >
              <InputLabel htmlFor="name-input">Slug</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={slug}
                onChange={handleSlug}
              />
              <FormHelperText>{slugError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">
                Establishment Currency
              </InputLabel>
              <Select
                id="category-input"
                value={currency}
                onChange={handleCurrency}
              >
                <MenuItem value={'$'}>{'$'}</MenuItem>
                <MenuItem value={'€'}>{'€'}</MenuItem>
                <MenuItem value={'£'}>{'£'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
            <Grid item xs={12} sm={12}>
              <ThemeProvider theme={theme}>
                <Typography>Store banner</Typography>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Store banner'}
                  showPreviewsInDropzone={true}
                  onChange={(files) => handleImage(files)}
                  filesLimit={1}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemeProvider theme={theme}>
                <Typography>Dark store logo</Typography>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Dark store logo'}
                  showPreviewsInDropzone={true}
                  onChange={(files) => handleDarkLogo(files)}
                  filesLimit={1}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <ThemeProvider theme={theme}>
                <Typography>Light store logo</Typography>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={'Light store logo'}
                  showPreviewsInDropzone={true}
                  onChange={(files) => handleLightLogo(files)}
                  filesLimit={1}
                />
              </ThemeProvider>
            </Grid> </div>

          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">URL of the address</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={addressUrl}
                onChange={handleAddressUrl}
              />
            </FormControl>
          </Grid>{' '}
          {stores && (
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor="category-input">
                  Import a establishment
                </InputLabel>
                <Select
                  id="category-input"
                  value={store}
                  onChange={handleStore}
                >
                  {stores.map((store, index) => (
                    <MenuItem key={index} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Stripe Key</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={stripeKey}
                onChange={handleStripeKey}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Merchant Ifood id</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={merchantOrderId}
                onChange={handleMerchantOrderId}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Order of sale prefix</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={orderPrefix}
                onChange={handlePrefix}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <Typography>Store description</Typography>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ckEditorToolbarConfig
              }}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameDayDelivery}
                    onChange={(e) => handleSameDayDelivery(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Same day delivery"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyDelivery}
                    onChange={(e) => handleOnlyDelivery(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Only delivery"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={promote}
                    onChange={(e) => handlePromote(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Promote"
              />
            </FormControl>
          </Grid>
          {/* End of left column */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                {' '}
                Create{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
