import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { getPackage } from 'redux/ducks/package';
import { IPackageState } from 'interfaces/package';
import { v4 as uuidv4 } from 'uuid';

export default function Package() {
  const dispatch = useDispatch();
  const { fetch, results } = useSelector<IStore, IPackageState>(
    (state) => state.package
  );


  useEffect(() => {
    dispatch(getPackage());

  }, [dispatch]);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-ingredient-package');
   d.item(0).style.color = '#ff5a23';

   // d.item(1).style.color = '#ff5a23';

  });

  return (
    <>
      <Loading show={fetch} />
      {!fetch && results.length === 0 && (
        <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
          Currently there's no batch.
        </Typography>
      )}
      <Grid container spacing={3}>
        {results.map((batch) => (
          <Panel
            key={uuidv4()}
            batch={batch}
          />
        ))}
      </Grid>
    </>
  );
}
