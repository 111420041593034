export const ENV = process.env.REACT_APP_ENV || 'production';
export const APP_NAME = process.env.REACT_APP_NAME || 'Flogi Portal';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
export const AUTH_ENDPOINT_WAITERS =
  process.env.REACT_APP_AUTH_ENDPOINT_WAITERS;

export const SOCKET_PRODUCTION = process.env.REACT_APP_SOCKET_PRODUCTION;
export const SOCKET_NOTIFICATION = process.env.REACT_APP_SOCKET_NOTIFICATION;
export const MANAGER_PASSWORD = process.env.REACT_APP_MANAGER_PASSWORD;
export const NETWORK = localStorage.getItem('network')
  ? localStorage.getItem('network')
  : process.env.REACT_APP_NETWORK;
export const EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
export const NETWORK_NAME = process.env.REACT_APP_NAME;
export const APP_URL = process.env.REACT_APP_URL;
export const CLASSIFICATION_CHOICES =
  process.env.REACT_APP_CLASSIFICATION_CHOICES.split(',');
export const PRIMARY_COLOR = () => {
  if (NETWORK_NAME === 'Network') return '#000';
  switch (NETWORK) {
    case '2':
      return '#3114A0';
    case '4':
      return '#124c4d';
    default:
      return '#000';
  }
};
export const SECONDARY_COLOR = () => {
  if (NETWORK_NAME === 'Network' && window.location.pathname === '/') {
    return '#000';
  }
  switch (NETWORK) {
    case '2':
      return '#FF931E';
    case '4':
      return '#ff5a23';
    default:
      return '#000';
  }
};

export const WEB_URL = () => {
  switch (NETWORK) {
    case '6':
      return 'petshop.karhard.com/';
    case '15':
      return 'https://www.maengelquartett.de/';
    default:
      return APP_URL;
  }
};

export const NETWORKS_LIST = [
  { id: 1, name: "Default" },
  { id: 2, name: "Flogi Test Mode" },
  { id: 3, name: "Doois" },
  { id: 4, name: "Yalla Halal" },
  { id: 5, name: "Arbid" },
  { id: 6, name: "Petshop" },
  { id: 8, name: "Oliver Charles" },
  { id: 9, name: "SOB Coffee" },
  { id: 10, name: "Sweet & Kind" },
  { id: 11, name: "Eredi Conconi" },
  { id: 12, name: "Petshop new" },
  { id: 13, name: "Vino Couture" },
  { id: 14, name: "Federico Rella" },
  { id: 15, name: "Mängelquartett" }
];

export const GET_NETWORK_NAME = (network) => {
  var name = NETWORKS_LIST.find(el => el.id == network)
  if (name) return name.name
  return ""
}
