import React from 'react';

import { IProduction } from 'interfaces/production';

import formatDate from 'date-fns/format';

import Logo from 'helpers/logo';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PrintIcon from '@material-ui/icons/PrintOutlined';

import Print from 'components/Common/Print';

import { useStyles } from './styles';

interface IProps {
  order: IProduction;
  disabled?: boolean;
}

export default function Receipt(props: IProps): JSX.Element {
  const { order, disabled } = props;
  const {
    order_code,
    order_type,
    status,
    quantity,
    date_ordered,
    item,
    measure
  } = order;
  const classes = useStyles();

  return (
    <Print
      trigger={
        <Button size="medium" disabled={disabled}>
          <PrintIcon />
        </Button>
      }
    >
      <Card>
        <Grid
          container
          justifyContent="center"
          className={classes.receiptHeader}
        >
          <Grid>
            <Logo styling={{ width: "150px", height: "150px" }} />
            <Typography variant="h6" style={{ marginTop: 10 }}>
              {order_code?.toUpperCase()}
            </Typography>
            <Typography style={{ marginBottom: 10 }}>
              {formatDate(new Date(date_ordered), 'dd/MM/yyyy - hh:mm')}
            </Typography>

            <Typography variant="h6">TYPE</Typography>
            <Typography style={{ marginBottom: 10 }}>
              {order_type === 'Product' ? 'Product' : 'Supply'}
            </Typography>
            {order.store && (
              <>
                <Typography variant="h6">STORE</Typography>
                <Typography style={{ marginBottom: 10 }}>
                  {order.store.name}
                </Typography>
              </>
            )}
            <Typography variant="h6">STATUS</Typography>
            <Typography style={{ marginBottom: 10 }}>{status}</Typography>
            {order.provider && (
              <>
                <Typography variant="h6">PROVIDER</Typography>
                <Typography style={{ marginBottom: 10 }}>
                  {order.provider.name}
                </Typography>
              </>
            )}

            <Typography variant="h6">
              {order_type === 'Product' ? 'PRODUCT' : 'SUPPLY'}
            </Typography>
            <Typography>{`${quantity}${measure || 'x'} ${item.name
              }`}</Typography>
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

Receipt.defaultProps = {
  disabled: false
};
