import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';

interface IProps {
  trigger: React.ReactElement;
  children: React.ReactElement;
  onBeforeGetContent?: () => void | Promise<any>;
  title?: string; 
  size?:string;
}

export default function Print(props: IProps): JSX.Element {
  const { trigger, children, onBeforeGetContent, title, size } = props;
  const componentRef = useRef();
  const contentFunction = () =>
    componentRef.current as unknown as React.ReactInstance;
  const triggerFunction = () => trigger;
  return (
    <>
      <ReactToPrint
        trigger={triggerFunction}
        pageStyle={size}
        content={contentFunction}
        onBeforeGetContent={onBeforeGetContent}
        documentTitle={title ?? 'Receipt'}
      />
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>{children}</div>
      </div>
    </>
  );
}
