import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AddArtist } from 'redux/ducks/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: (newArtist) => void;
    refresh: () => void;

}

export default function ModalDeleteIngredient(props: SimpleDialogProps) {
    const { onClose, open, refresh } = props;

    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const handleClose = (newValue) => {
        onClose(newValue ? newValue : null);
    };

    const submitCreate = async () => {
        AddArtist(name, dispatch).then((result) => {
            if (result) {
                refresh()
                setName("")
                handleClose(result);
            }
        }).catch(e => {
            console.log("error while creating artist")
        })
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Add new artist
            </DialogTitle>
            <DialogContent style={{ minWidth: "400px" }}
            >
                <TextField
                    style={{ width: "100%" }}
                    placeholder='Artist'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'center', gap: "5px" }}>

                    <Button onClick={handleClose}> Cancel </Button>
                    <Button variant='outlined' disabled={name === ""} onClick={submitCreate} color="primary">
                        {' '}
                        Create{' '}
                    </Button>
                </div>
            </DialogActions>

        </Dialog>
    );
}
