import IBaseAction from 'interfaces/redux';

interface INetwork {
    network: string[];
}
export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
export enum ETypesNetwork {
    SET = 'Network/SET'
}
export type INetworkAction = ISetAction;

export interface ISetAction extends IBaseAction<ETypesNetwork, null> {
    type: ETypesNetwork.SET;
}
export type PromiseAction = Promise<INetworkAction>;

type Dispatch = (action: ISetAction | ThunkAction | PromiseAction) => any;
const initialState: INetwork = {
    network: []
};

/* Authentication Reducer. */
export default (
    state: INetwork = initialState,
    action: {
        payload: [];
        type: ETypesNetwork;
    }
): INetwork => {
    if (action.type === ETypesNetwork.SET) {
        console.log("setting net ", action.payload)
        return {
            ...state,
            network: action.payload
        };
    }
    return state;
};

export const setNetworks =
    (networks: any[]) =>
        async (dispatch: Dispatch): Promise<void> => {
            dispatch(setNetworkAction(networks));
        };

export const setNetworkAction = (payload): ISetAction => ({
    type: ETypesNetwork.SET,
    payload
});
