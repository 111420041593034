import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import { useStyles } from './styles';
import { IconButton } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  url: string;
}

export default function CancelOrderModal(props: SimpleDialogProps) {
  const { url, open, onClose } = props;

  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
      // className="stuart-modal-dialog"
      open={open}
      className={classes.muiModal}
    >
      <IconButton
        style={{
          width: 'fit-content',
          position: 'absolute',
          right: '1rem',
          top: '3rem',
          fontSize: '12pt'
        }}
        color="secondary"
        onClick={() => onClose()}
      >
        Close Viewer <CloseIcon />
      </IconButton>
      <iframe style={{ height: '80vh', width: '80vw' }} src={url} />
    </Dialog>
  );
}
