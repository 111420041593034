import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
    '.MuiGrid-grid-lg-2': {
      maxWidth: '20%',
      flexBasis: '20%'
    }
  },
  muiModal: {
    '& .MuiDialog-paperFullScreen': {
      width: '80vw',
      height: '80vh'
    }
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  cardHeaderTodo: {
    width: '18vw',
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(158, 2, 6)',
    color: '#000',
    '@media screen and (max-width: 650px)': {
      width: '195px'
    }
  },
  cardHeaderDoing: {
    width: '18vw',
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(253, 120, 23)',
    color: '#000',
    '@media screen and (max-width: 650px)': {
      width: '195px'
    }
  },
  cardHeaderDone: {
    width: '18vw',
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(1, 165, 4)',
    color: '#000',
    '@media screen and (max-width: 650px)': {
      width: '195px'
    }
  },
  cardHeaderDepot: {
    width: '18vw',
    marginBottom: 10,
    padding: 5,
    border: '1px solid rgb(100,82,87)',
    color: '#000',
    '@media screen and (max-width: 650px)': {
      width: '195px'
    }
  },
  cardBodyTodo: {
    alignItems: 'stretch',
    width: '19vw',
    minHeight: 250,
    marginBottom: 10,
    border: '1px solid rgb(221, 23, 23)',
    color: '#000',
    marginLeft: '-5px',
    '@media screen and (max-width: 650px)': {
      width: '200px'
    }
  },
  cardBodyDoing: {
    alignItems: 'stretch',
    width: '19vw',
    minHeight: 250,
    marginBottom: 10,
    border: '1px solid rgb(255, 145, 36)',
    color: '#000',
    marginLeft: '-5px',
    '@media screen and (max-width: 650px)': {
      width: '200px'
    }
  },
  cardBodyDone: {
    alignItems: 'stretch',
    width: '19vw',
    minHeight: 250,
    marginBottom: 10,
    border: '1px solid rgb(15, 197, 19)',
    color: '#000',
    marginLeft: '-5px',
    '@media screen and (max-width: 650px)': {
      width: '200px'
    }
  },
  cardBodyDepot: {
    alignItems: 'stretch',
    width: '19vw',
    minHeight: 250,
    marginBottom: 10,
    border: '1px solid rgb(100,72,77)',
    color: '#000',
    marginLeft: '-5px',
    '@media screen and (max-width: 650px)': {
      width: '200px'
    }
  },
  pos: {
    marginBottom: 12
  },
  productInfo: {
    margin: 0,
    fontSize: '1em',
    fontFamily: 'Roboto',
    lineHeight: 1.5,
    letterSpacing: '0.05em'
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  comment: {
    marginTop: 10
  },
  orderIdText: {
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  itemContainer: {
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  itemText: {
    color: 'black',
    fontFamily: 'Roboto'
  },
  commentContainer: {
    padding: '2.5%'
  },
  messageContainer: {
    padding: '2.5% 2.5% 5% 2.5%',
    backgroundColor: '#d7d7d77f',
    borderRadius: 5
  },
  commentTitle: {
    fontWeight: 'bold',
    marginBottom: 10
  },
  orderInfoContainer: {
    padding: '5%'
  },
  button: {
    width: 100,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 0.5 black',
    borderRadius: 10,
    fontWeight: 'bold'
  },
  orderContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  receiptHeader: {
    width: 350,
    margin: '0 auto',
    marginTop: 10
  },
  receiptBody: {
    marginTop: 15,
    marginBottom: 10
  },
  active: {
    backgroundColor: 'rgb(1, 165, 4) !important'
  },
  inactive: {
    backgroundColor: 'rgb(0 0 0 / 54%) !important'
  },
  toolBarTitles: {
    color: 'rgb(0 0 0 / 54%) !important',
    fontWeight: 400,
    fontSize: 12
  },
  overlineLineHeight: {
    lineHeight: 1.6
  },
  inactiveButton: {
    margin: '0 5px',
    color: '#124c4d !important',
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff !important',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  selectInput: {
    width: '195px',
    marginTop: '-3px',
    marginLeft: '10px',
    '@media screen and (max-width: 600px)': {
      width: '110px'
    }
  }
}));
