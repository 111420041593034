import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { fetch } from 'utils/request';
import { NETWORK } from 'settings';
import { EMethod } from 'enums/method';

import './styles.css';

export default function Reauth() {
  const localStore = localStorage.getItem('state');
  const state = JSON.parse(localStore);
  const [loading, setLoading] = useState(false);
  const [stripeURL, setStripeURL] = useState('');
  const getLink = async () => {
    setLoading(true);
    if (!state) {
      window.location.href = '/';
      return;
    }
    if (!state.authentication.token) {
      window.location.href = '/';
      return;
    }
    try {
      const { id } = state?.authentication;

      var response = await fetch({
        method: EMethod.GET,
        url: `users/api/clients/${id}/refresh_account_link/?network=${NETWORK}`
      });
      setStripeURL(getStripeURL(response.user.profile.stripe_profiles));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getStripeURL = (profiles) => {
    var url = '';
    profiles.forEach((profile) => {
      if (profile.network_id == NETWORK) {
        url = profile.account_stripe_link;
      }
    });
    return url;
  };
  useEffect(() => {
    getLink();
  }, []);
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '65vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '8rem',
        marginLeft: '10rem'
      }}
    >
      <div>
        <Typography style={{ fontSize: '22px', marginBottom: '10px' }}>
          The Stripe link has expired.
        </Typography>
      </div>
      <div style={{ width: '75%', marginBottom: '20px' }}>
        {loading && (
          <Typography style={{ fontSize: '15px' }}>
            Loading new Stripe link...
          </Typography>
        )}
      </div>
      <div style={{ width: '75%', marginTop: '20px' }}>
        {' '}
        {!loading && (
          <a
            style={{
              width: '5rem',
              padding: '10px 30px 10px 30px',
              backgroundColor: '#124c4d',
              color: 'white',
              textDecoration: 'none',
              fontFamily: 'Work Sans, sans-serif'
            }}
            href={stripeURL}
          >
            New Stripe link
          </a>
        )}
      </div>
    </Grid>
  );
}
