import React from 'react';
import {
  Button,
  Container,
  Grid,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editOpenHours } from 'redux/ducks/store';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { FormControlLabel, Checkbox } from '@material-ui/core';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  store: any;
  stores: any[];
}

export default function ModalOpenHours(props: SimpleDialogProps) {
  const { onClose, open, store } = props;
  const [days, setDays] = React.useState([]);

  const dispatch = useDispatch();
  const [id, setId] = React.useState(0);
  const store_local: string = localStorage.getItem('state');
  const state = JSON.parse(store_local);
  //ad is_store_owner etc to line above
  // const [owner, setOwner] = React.useState(is_store_owner);
  // const [waiter, setWaiter] = React.useState(is_waiter);
  // const [manager, setManager] = React.useState(is_manager);

  useEffect(() => {
    setId(store.id);
  }, [state.authentication]);

  useEffect(() => {
    fillOpenHours();
  }, [store]);
  const fillOpenHours = () => {
    var openHoursDays = [];
    if (store?.open_hours) {
      for (
        let index = 0;
        index < Object.keys(store.open_hours).length;
        index++
      ) {
        const day = Object.keys(store.open_hours)[index];
        if (day !== 'id') {
          openHoursDays.push({
            day: day,
            opened: moment(store.open_hours[day].opened, 'HH:mm'),
            closed: moment(store.open_hours[day].closed, 'HH:mm'),
            maxDateError: false,
            closedAllDAy:
              store.open_hours[day].opened === '00:00' &&
              store.open_hours[day].closed === '00:00'
                ? true
                : false
          });
        }
      }
    } else {
      openHoursDays = [
        {
          day: 'monday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'tuesday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'wednesday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'thursday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'friday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'saturday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        },
        {
          day: 'sunday',
          opened: moment('08:00', 'HH:mm'),
          closed: moment('18:00', 'HH:mm'),
          maxDateError: false,
          closedAllDAy: false
        }
      ];
    }
    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];
    openHoursDays.sort(
      (a, b) => weekDays.indexOf(a.day) - weekDays.indexOf(b.day)
    );

    setDays(openHoursDays);
  };
  const handleClose = () => {
    // setAddressUrl('');
    // setMerchantOrderId('');
    onClose();
  };

  const submitCreation = async () => {
    var daysOfTheWeek = {
      id: id,
      monday: {
        opened: days[0].closedAllDAy
          ? '00:00'
          : moment(days[0].opened).format('HH:mm'),
        closed: days[0].closedAllDAy
          ? '00:00'
          : moment(days[0].closed).format('HH:mm'),
        closed_all_day: days[0].closed_all_day
      },
      tuesday: {
        opened: days[1].closedAllDAy
          ? '00:00'
          : moment(days[1].opened).format('HH:mm'),
        closed: days[1].closedAllDAy
          ? '00:00'
          : moment(days[1].closed).format('HH:mm'),
        closed_all_day: days[1].closed_all_day
      },
      wednesday: {
        opened: days[2].closedAllDAy
          ? '00:00'
          : moment(days[2].opened).format('HH:mm'),
        closed: days[2].closedAllDAy
          ? '00:00'
          : moment(days[2].closed).format('HH:mm'),
        closed_all_day: days[2].closed_all_day
      },
      thursday: {
        opened: days[3].closedAllDAy
          ? '00:00'
          : moment(days[3].opened).format('HH:mm'),
        closed: days[3].closedAllDAy
          ? '00:00'
          : moment(days[3].closed).format('HH:mm'),
        closed_all_day: days[3].closed_all_day
      },
      friday: {
        opened: days[4].closedAllDAy
          ? '00:00'
          : moment(days[4].opened).format('HH:mm'),
        closed: days[4].closedAllDAy
          ? '00:00'
          : moment(days[4].closed).format('HH:mm'),
        closed_all_day: days[4].closed_all_day
      },
      saturday: {
        opened: days[5].closedAllDAy
          ? '00:00'
          : moment(days[5].opened).format('HH:mm'),
        closed: days[5].closedAllDAy
          ? '00:00'
          : moment(days[5].closed).format('HH:mm'),
        closed_all_day: days[5].closed_all_day
      },
      sunday: {
        opened: days[6].closedAllDAy
          ? '00:00'
          : moment(days[6].opened).format('HH:mm'),
        closed: days[6].closedAllDAy
          ? '00:00'
          : moment(days[6].closed).format('HH:mm'),
        closed_all_day: days[6].closed_all_day
      }
    };
    await dispatch(editOpenHours(daysOfTheWeek, id)).then((result: any) => {
      handleClose();
    });
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleChange = (_day, prop, time) => {
    setDays(
      days.map((item) => (item.day === _day ? { ...item, [prop]: time } : item))
    );
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
          <h2>Edit Open Hours</h2>
          <Container maxWidth="md">
            {days.map((element, idx) => (
              <>
                <Typography variant="h6">
                  {capitalizeFirstLetter(element.day)}
                </Typography>

                <Grid
                  style={{ display: 'flex', alignItems: 'end' }}
                  container
                  spacing={2}
                >
                  {' '}
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker-opened"
                        label="Opened"
                        value={element.opened}
                        disabled={element.closedAllDAy}
                        onChange={(e) => {
                          handleChange(element.day, 'opened', e);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker-closed"
                        label="Closed"
                        disabled={element.closedAllDAy}
                        value={element.closed}
                        maxDateMessage={
                          element.maxDateError
                            ? 'The closed time must be after the opened time'
                            : ''
                        }
                        onChange={(e) => {
                          handleChange(element.day, 'closed', e);
                          /**                          
                           const minTime = moment(element.opened, 'HH:mm');
                          if (minTime.isBefore(e)) {
                            handleChange(element.day, 'closed', e);
                            handleChange(element.day, 'maxDateError', false);
                          } else {
                            handleChange(element.day, 'maxDateError', true);
                          } */
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={element.closedAllDAy}
                            onChange={(e) =>
                              handleChange(
                                element.day,
                                'closedAllDAy',
                                e.target.checked
                              )
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Closed all day"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ))}

            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  style={{ marginBottom: '30px' }}
                  variant="outlined"
                  onClick={submitCreation}
                  color="primary"
                >
                  Update & Save
                </Button>
              </div>
            </Grid>
          </Container>
        </MuiPickersUtilsProvider>
      </div>
    </Modal>
  );
}
