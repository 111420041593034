import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IOwnerState,
  ETypesOwner,
  IOwnerAction,
  IOwnerBase
} from 'interfaces/owner';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { NETWORK } from 'settings';

/* Authentication State. */
const initialState: IOwnerState = {
  fetch: false,
  next: '',
  previous: '',
  results: [],
  error: ''
};

/* Authentication Reducer. */
export default (
  state: IOwnerState = initialState,
  action: IOwnerAction
): IOwnerState => {
  switch (action.type) {
    case ETypesOwner.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesOwner.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesOwner.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesOwner.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchOwner = (): IFetchAction => ({
  type: ETypesOwner.FETCH
});

export const successOwner = (payload: IOwnerState): ISuccessAction => ({
  type: ETypesOwner.SUCCESS,
  payload
});

export const failureOwner = (payload: string): IFailureAction => ({
  type: ETypesOwner.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesOwner.RESET
});

/* Authentication Side Effects Functions. */
export const getOwner =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchOwner());

      let url = `/users/api/clients/owners_network/?network=${NETWORK}`;
      let response;
      let results: IOwnerBase[] = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url: url
        });
        url = response.next;
        console.log(response);
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      dispatch(successOwner(response));
    } catch (error) {
      dispatch(failureOwner(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createOwner =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: `/users/api/clients/`,
        data: data
      });
      dispatch(
        sendAlert('Store Owner created successfully', EAlertVariant.SUCCESS)
      );
      dispatch(getOwner());
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
