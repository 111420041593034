import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IKitchen } from 'interfaces/kitchen';
import { Button, DialogContentText } from '@material-ui/core';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    order: IKitchen;
    confirm: () => void;
}

export default function ConfirmTracking(props: SimpleDialogProps) {
    const { onClose, open, order, confirm } = props;
    const handleClose = () => {
        onClose();
    };



    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Order: {order?.order_code}
            </DialogTitle>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <DialogContentText style={{ width: '90%' }} id="simple-dialog-title">
                Are you sure you want to proceed without a tracking number?
                </DialogContentText>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClose} color="primary">
                    {' '}
                    Cancel
                </Button>
                <Button onClick={confirm}> Yes </Button>
            </div>
        </Dialog>
    );
}
