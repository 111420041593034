import React from 'react'
import { Product } from 'interfaces/stock';
import { Grid, Typography, IconButton } from '@material-ui/core';
import useStyles from './styles'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'settings';
import Radio from '@material-ui/core/Radio';
import EditIcon from '@material-ui/icons/Edit';
import ProductDetails from './comps/productDetails';
import { NETWORK } from 'settings'
import Currency from './../../utils/currency'
interface SimpleProps {
    product: Product,
    increaseAmount: () => void,
    decreaseAmount: () => void,
    isSelected: boolean,
    select: () => void,
    amount: number
    openProductDetails: () => void
    price: number;
    pickVariation: (selected_size) => void,
    deselectProduct: () => void;
    addExtra: (extra_id: number) => void,
    removeExtra: (extra_id: number) => void,
    selectedVariation: { id: number, name: string, price: number; }[],
    increaseVariationAmount: (variation_id) => void,
    decreaseVariationAmount: (variation_id) => void
}
export default function ProductCard(props: SimpleProps) {
    const { product, increaseAmount, decreaseAmount, isSelected, select, amount, openProductDetails, price, pickVariation, deselectProduct, addExtra, removeExtra, selectedVariation, increaseVariationAmount, decreaseVariationAmount } = props
    const classes = useStyles()
    return (
        <Grid className={classes.productCardWrapper} item xs={10} lg={3} md={4} sm={6} onBlur={() => {
            if (NETWORK === "6" && (selectedVariation.length < 1 && product.variation.length > 0)) {
                deselectProduct()
            }
        }}>
            <div style={{ border: isSelected ? `1px solid ${PRIMARY_COLOR()}` : "1px solid lightgrey", borderRadius: "10px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "5px", justifyContent: "space-between", position: "absolute", right: "10px", top: "10px", backgroundColor: isSelected ? PRIMARY_COLOR() : SECONDARY_COLOR() }}>
                    <Typography style={{ color: "white", padding: "2px 5px 2px 5px" }}><Currency /> {new Intl.NumberFormat('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(price)}</Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", position: "absolute", left: "10px", top: "3px", }}>
                    <Radio color="primary" checked={isSelected} onClick={() => select()} />
                </div>

                <div onClick={() => select()} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} className={classes.productCardImage}>
                    <img src={product.image} style={{ borderTopLeftRadius: "9px", borderTopRightRadius: "9px" }} alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                    <Typography style={{ fontWeight: 500, marginLeft: "5px", marginTop: "8px", marginBottom: "8px" }}>{product.name}</Typography>
                    {
                        product.extras.length > 0 || product.sizes.length > 0 &&
                        <IconButton onClick={() => openProductDetails()}>
                            <EditIcon />
                        </IconButton>
                    }
                </div>

                {
                    isSelected &&
                    <div style={{ paddingRight: "12px", paddingLeft: "12px" }}>
                        <ProductDetails
                            pickVariation={(selected_size) => pickVariation(selected_size)}
                            deselectProduct={() => deselectProduct()}
                            addextra={(extra_id) => addExtra(extra_id)}
                            removeExtra={(extra_id) => removeExtra(extra_id)}
                            product={product}
                            selectedVariation={selectedVariation}
                            increaseVariationAmount={(variation_id) => increaseVariationAmount(variation_id)}
                            decreaseVariationAmount={(variation_id) => decreaseVariationAmount(variation_id)}
                        />
                    </div>
                }
                {product.variation.length < 1 && <div className={classes.productCardActionsWrapper} style={{ height: isSelected ? "45px" : "0px", opacity: isSelected ? 1 : 0 }}>

                    <IconButton onClick={() => decreaseAmount()}><RemoveIcon /> </IconButton>
                    <Typography>{amount}</Typography>
                    <IconButton onClick={() => increaseAmount()}><AddIcon /> </IconButton>

                </div>}
            </div>
        </Grid>
    )
}