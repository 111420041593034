import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ModalEditMenu from './ModalEditMenu';
import ModalEditStore from './ModalEditStore';
import ModalCreateStore from './ModalCreateStore';
import SelectStoreToImport from './SelectStoreToImport';
import { IStoreBase } from 'interfaces/store_test';
import Switch from '@material-ui/core/Switch';
// import { useDispatch } from 'react-redux';
// import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch } from 'react-redux';
import { editStore } from 'redux/ducks/store';
import { Tooltip } from '@material-ui/core';
import AccessTimeFilledIcon from '@material-ui/icons/AccessTime';
import ModalOpenHours from './ModalOpenHours';
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  stores: IStoreBase[];
  fetchedMenus: any[];
  activeFilter: number;
}

export default function ProductsTable(props: IProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { stores } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditStore, setOpenEditStore] = React.useState(false);
  const [openImport, setOpenImport] = React.useState(false);
  const [selectedStore, setSelectedStore] = React.useState(undefined);
  const [openHoursModal, setOpenHoursModal] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenImport(false);
    setSelectedStore(undefined);
    setOpenEditStore(false);
    setOpenHoursModal(false);
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClickOpenEditStore = (store: any) => {
    setOpenEditStore(true);
    setSelectedStore(store);
  };

  const handleClickOpenHoursModal = (store: any) => {
    setOpenHoursModal(true);
    setSelectedStore(store);
  };

  // const handleClickOpenImport = (store: any) => {
  //   setOpenImport(true);
  //   setSelectedStore(store);
  // };

  // const [state, setState] = React.useState({
  //   checkedA: true
  // });

  const [forceCloseStores, setForceCloseStores] = React.useState([]);

  useEffect(() => {
    const tempData = [];
    if (stores) {
      stores.map((store) => {
        tempData.push({
          storeId: store.id,
          forceClose: store.force_close
        });
      });
      setForceCloseStores(tempData);
    }
  }, [stores]);

  const handleForceClose = (id: number, action: boolean, index: number) => {
    const tempData = forceCloseStores;
    tempData[index].forceClose = action;
    setForceCloseStores(tempData);

    dispatch(
      editStore(
        {
          force_close: action
        },
        id
      )
    );
    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      {/* <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpen}
        className={classes.activeButton}
      >
        Add Store
      </Button> */}
      <Table className={classes.table} aria-label="simple table">
        <Button
          style={{
            fontWeight: 'bold',
            fontSize: 12,
            margin: '10px 10px',
            height: '35px'
          }}
          color="primary"
          variant='contained'
          onClick={() => setOpen(true)}
        >
          Add store
        </Button>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '5px' }}>Name</TableCell>
            {/* <TableCell align="center" style={{ padding: '5px' }}>
              Import
            </TableCell> */}
            <TableCell align="center" style={{ padding: '5px' }}>
              Force Close
            </TableCell>
            <TableCell align="center" style={{ padding: '5px' }}>
              Edit Establishment
            </TableCell>
            <TableCell align="center" style={{ padding: '5px' }}>
              Edit open hours
            </TableCell>
            {/* <TableCell align="right" style={{ padding: '5px' }}>
              Stock Management
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {stores?.map((store, index) => (
            <TableRow key={store.id}>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {store.name}
              </TableCell>
              {/* <TableCell align="center" style={{ padding: '5px' }}>
                <GetAppIcon onClick={() => handleClickOpenImport(store)} />{' '}
              </TableCell> */}
              <TableCell align="center" style={{ padding: '5px' }}>
                <Tooltip title="If you activate the force close your store will be unavailable to receive new orders in the website">
                  <Switch
                    checked={
                      forceCloseStores.length > 0
                        ? forceCloseStores[index]?.forceClose
                        : false
                    }
                    onChange={() =>
                      handleForceClose(store.id, !store.force_close, index)
                    }
                    id={`switch${store.id}`}
                    name={`switch${store.id}`}
                    inputProps={{ 'aria-label': 'checkbox' }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                align="center"
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                <EditIcon onClick={() => handleClickOpenEditStore(store)} />{' '}
              </TableCell>
              <TableCell align="center" style={{ padding: '5px' }}>
                <AccessTimeFilledIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClickOpenHoursModal(store)}
                />{' '}
              </TableCell>
              {/* <TableCell align="right" style={{ padding: '5px' }}>
                <Switch
                  checked={store.is_own_store}
                  onChange={() => {
                    dispatch(handleIsOwnStore(store.id));
                  }}
                  color="primary"
                />
                {' Filial'}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SelectStoreToImport
        selectedStore={selectedStore}
        open={openImport}
        stores={stores}
        onClose={handleClose}
      />
      <ModalEditStore
        open={openEditStore}
        onClose={handleClose}
        store={selectedStore ? selectedStore : ''}
        stores={stores}
      />
      <ModalEditMenu
        menus={selectedStore ? selectedStore.menus : []}
        store={selectedStore}
        open={openEdit}
        fetchedMenus={props.fetchedMenus}
        onClose={handleClose}
      />
      <ModalOpenHours
        open={openHoursModal}
        onClose={handleClose}
        store={selectedStore ? selectedStore : ''}
        stores={stores}
      />
      <ModalCreateStore open={open} onClose={handleClose} stores={stores} />
    </TableContainer>
  );
}
