import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IOwnerBase } from 'interfaces/owner';
import ModalCreateOwner from './ModalCreateOwner';
import { Button } from '@material-ui/core';
import { PRIMARY_COLOR } from 'settings';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height: 'fit-content'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  owners: IOwnerBase[];
}

export default function OwnersTable(props: IProps) {
  const classes = useStyles();
  const { owners } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const mapStores = (stores: any) => {
    return stores.map((store, index) => (
      <div
        style={{ marginRight: '5px' }}
        key={'store ' + store.id + index}
      >
        {store.name}
        {stores[index + 1] ? ',' : ''}
      </div>
    ));
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
        color="primary"
        onClick={handleClickOpen}
        className={classes.activeButton}
      >
        Add Store Owner
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '5px' }}>Name</TableCell>
            <TableCell align="center" style={{ padding: '5px' }}>
              Email
            </TableCell>
            <TableCell align="right" style={{ padding: '5px' }}>
              Stores
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {owners?.map((owner) => (
            <TableRow key={owner.id}>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {owner.profile?.name}
              </TableCell>
              <TableCell align="center" style={{ padding: '5px' }}>
                {owner.email}
              </TableCell>
              <TableCell align="right" style={{ padding: '5px' }}>
                {mapStores(owner.stores)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateOwner open={open} onClose={handleClose} />
    </TableContainer>
  );
}
