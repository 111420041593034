import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  successfully: {
    color: 'green'
  },
  error: {
    color: 'red'
  },
  loading: {
    textAlign: 'center'
  },
  forgotPasswordLink: {
    display: 'flex',
    color: '#000 !important',
    '& .MuiTypography-colorPrimary': {
      color: 'black',
      margin: '0 auto'
    },
    '& .MuiLink-underlineHover': {
      '&:hover': {
        opacity: 0.8,
        color: '#fe309c',
        cursor: 'pointer'
      }
    }
  },

  formControl: {
    marginTop: '15px',
    width: '100%',
    border: 'none',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black !important'
    }
  },

  passwordTextField: {
    marginTop: '10px',
    width: '100%'
  },

  brand: {
    zIndex: 10,
    position: 'sticky',
    fontFamily: 'Archivo, sans-serif',
    fontStretch: '103%',
    fontWeight: 700,
    letterSpacing: '0.15em',
    color: '#222',
    fontSize: '12.5px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    width: '100%',
    textAlign: 'center',
    '&:hover': {
      opacity: 0.8,
      color: '#fe309c',
      cursor: 'pointer'
    }
  },

}));
