import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import ModalCreateCategory from './ModalCreateCategory';
import ModalEditCategory from './ModalEditCategory';
import ModalDeleteCategory from './ModalDeleteCategory';
import { Button, IconButton, InputBase } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Delete, Edit } from "@material-ui/icons";
import { ICategory } from 'interfaces/stock';
import SearchIcon from '@material-ui/icons/Search';
import { PRIMARY_COLOR } from 'settings';
const useStyles = makeStyles({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: '15px'
  },
  input: {
    flex: 1,
    paddingLeft: '8px'
  },
  iconButton: {
    padding: 10
  },
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface Props {
  categories: ICategory[];
  store?: number;
  searchData: (data: string) => void;
  ingredientsToFilterBy: any;
}

export default function CategoriesTable(props: Props) {
  const classes = useStyles();
  const { categories, store, searchData, ingredientsToFilterBy } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(undefined);
  const [search, setSearch] = React.useState('');

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setSelectedCategory(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDelete = (category: ICategory) => {
    setOpenDelete(true);
    setSelectedCategory(category);
  };

  const handleClickOpenEdit = (category: ICategory) => {
    setOpenEdit(true);
    setSelectedCategory(category);
  };
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {store !== 0 && <Button
          style={{
            fontWeight: 'bold',
            fontSize: 12,
            margin: '10px 10px',
            height: '35px'
          }}
          color="primary"
          onClick={handleClickOpen}
          className={classes.activeButton}
        >
          Add Category
        </Button>}
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search category"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={search}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                searchData(search);
              }
            }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => {
              searchData(search);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            {store > 0 && (
              <>
                <TableCell>Delete</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.map((category) => (
            <TableRow key={category.id}>
              <TableCell component="th" scope="row">
                {category.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {category.name}
              </TableCell>

              {store > 0 && (
                <>
                  <TableCell component="th" scope="row">
                    <IconButton onClick={() => handleClickOpenEdit(category)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleClickOpenDelete(category)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateCategory
        ingredientsToFilterBy={ingredientsToFilterBy}
        open={open}
        onClose={handleClose}
        store={store}
      />
      <ModalEditCategory
        open={openEdit}
        ingredientsToFilterBy={ingredientsToFilterBy}
        onClose={handleClose}
        category={selectedCategory}
        store={store}
      />
      <ModalDeleteCategory
        open={openDelete}
        ingredientsToFilterBy={ingredientsToFilterBy}
        onClose={handleClose}
        category={selectedCategory}
      />
    </TableContainer>
  );
}
