import React, { useEffect } from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { IconButton, Badge } from '@material-ui/core';
import useStyles from './../styles'
interface IProps {
    items: number
}
export default function Cart(props: IProps) {
    const classes = useStyles()
    const { items } = props
    const [visible, setVisible] = React.useState(false)
    const handleScroll = () => {

        const summary = document.getElementById("summary")

        if (summary) {
            var rect = summary.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            setVisible((rect.bottom < 0 || rect.top - viewHeight >= 0));
        }
    };

    useEffect(() => {
        var div = document.getElementById('main');
        if (div) {
            div.addEventListener("scroll", handleScroll);
            return () => {
                div.removeEventListener("scroll", handleScroll);
            };
        }
        return undefined
    }, []);
    return (
        <div className={classes.cart_icon_wrapper} style={{ visibility: visible ? "visible" : "hidden", opacity: visible ? "1" : "0" }} >
            <div className={classes.cart_icon} >
                <IconButton onClick={() => {
                    document.getElementById("summary").scrollIntoView()
                }} >
                    <Badge
                        style={{ cursor: 'pointer' }}
                        title=""
                        badgeContent={items}
                        color="error"
                    >
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
            </div >
        </div>
    )
}