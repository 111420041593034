import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Backdrop,
  Fade,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { LastOrder } from 'interfaces/customersAndSellers';
import { getPaymentType } from 'helpers/common';
import Text from './Text';
import formatDate from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import useStyles from './styles';
interface IProps {
  open: boolean;
  handleClose: () => void;
  orderToShow: LastOrder;
  loading: boolean;
}

export default function TransitionsModal(props: IProps) {
  const { open, handleClose, orderToShow, loading } = props;
  const classes = useStyles();
  const dateOrdered = orderToShow?.date_ordered
    ? new Date(orderToShow?.date_ordered)
    : new Date();
  const dateDelivery = orderToShow?.date_delivery
    ? new Date(orderToShow?.date_delivery)
    : new Date();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h4">{orderToShow?.order_code}</Typography>
              <Typography variant="h6">Payment</Typography>
              <Text paragraph>{getPaymentType(orderToShow?.payment)}</Text>
              <Text paragraph>
                Total: £ {orderToShow?.total_order.toFixed(2)}
              </Text>
              <Typography variant="h6">Order</Typography>
              {orderToShow?.items.map((item) => (
                <Text paragraph key={item.product.name}>
                  {item.quantity}x - {item.product.name}
                </Text>
              ))}
              <Typography variant="h6">Information</Typography>
              <Text paragraph>
                Order date:{' '}
                {formatDate(dateOrdered, 'dd/MM/yy - HH:mm', { locale: enUS })}
              </Text>
              {orderToShow?.date_delivery && (
                <Text paragraph>
                  Delivery Date:{' '}
                  {formatDate(dateDelivery, 'dd/MM/yy - HH:mm', {
                    locale: enUS
                  })}
                </Text>
              )}
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
