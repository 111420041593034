import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteCategory } from 'redux/ducks/stock';
import { ICategory } from 'interfaces/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  category: ICategory;
  ingredientsToFilterBy: any;
}

export default function ModalCreateProduct(props: SimpleDialogProps) {
  const { onClose, open, category, ingredientsToFilterBy } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const submitDelete = async () => {
    dispatch(deleteCategory(category?.id, ingredientsToFilterBy)).then(
      (result: boolean) => {
        if (result) handleClose();
      }
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Delete Category {category?.name}?
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitDelete} color="primary">
          {' '}
          Delete{' '}
        </Button>
        <Button onClick={handleClose}> Cancel </Button>
      </div>
    </Dialog>
  );
}
