import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  ICustomersAndSellersState,
  ETypesCustomersAndSellers,
  IManagerAction,
  GetState
} from 'interfaces/customersAndSellers';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { NETWORK } from 'settings';
import { mountQueryURL } from 'utils/query';
import moment from 'moment';

/* Authentication State. */
const initialState: ICustomersAndSellersState = {
  fetch: false,
  customers: [],
  sellers: [],
  sellersWithCommission: [],
  last_order: {
    order_code: '',
    payment: '',
    total_order: 0,
    delivery_fee: 0,
    cash_change: 0,
    cash_paid: 0,
    discount: 0,
    date_delivery: '',
    date_ordered: '',
    final_comment: '',
    items: []
  }
};

/* Authentication Reducer. */
export default (
  state: ICustomersAndSellersState = initialState,
  action: IManagerAction
): ICustomersAndSellersState => {
  switch (action.type) {
    case ETypesCustomersAndSellers.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesCustomersAndSellers.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesCustomersAndSellers.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesCustomersAndSellers.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesCustomersAndSellers.FETCH
});

export const successFetch = (
  payload: ICustomersAndSellersState
): ISuccessAction => ({
  type: ETypesCustomersAndSellers.SUCCESS,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesCustomersAndSellers.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesCustomersAndSellers.RESET
});

/* Authentication Side Effects Functions. */
export const fetchBestBuyers =
  (params: any): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const query = mountQueryURL(params);
      var response = await fetch({
        method: EMethod.GET,
        url: `users/api/clients/network_customers/${query}`
      });
      if (params.period === 'Today') {
        response.map((customer, idx) => {
          var orders = customer.orders.filter(
            (order) =>
              moment(order.date_ordered).format('DD/MM/YYYY') ===
              moment(new Date()).format('DD/MM/YYYY')
          );
          response[idx].orders = orders;
        });
      }
      dispatch(successFetch({ ...customersAndSellers, customers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchBestBuyersWithDateRange =
  (initial: string, end: string): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: `users/api/clients/network_customers/?starts=${initial}&ends=${end}&network=${NETWORK}`
      });
      dispatch(successFetch({ ...customersAndSellers, customers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const fetchBestSellers =
  (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: 'users/api/clients/top_5_sellers/'
      });
      dispatch(successFetch({ ...customersAndSellers, sellers: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const fetchSellersCommissions =
  (
    starts = new Date().toISOString().split('T')[0],
    ends = new Date().toISOString().split('T')[0]
  ): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: `waiter/api/waiter/get_order_values_for_waiters_between_dates/?starts=${starts}&ends=${ends}&network=${NETWORK}`
      });
      dispatch(
        successFetch({
          ...customersAndSellers,
          sellersWithCommission: response
        })
      );
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };
export const addSeller =
  (data: {}): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: 'waiter/api/waiter/',
        data
      });
      dispatch(sendAlert('Vendedor criado com sucesso', EAlertVariant.SUCCESS));
      return 1;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const editSeller =
  (id: number, data: {}): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      await fetch({
        method: EMethod.PATCH,
        url: 'waiter/api/waiter/' + id + '/',
        data
      });
      dispatch(
        sendAlert('Vendedor editada com sucesso', EAlertVariant.SUCCESS)
      );
      return 1;
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const fetchLastOrder =
  (orderId: number): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { customersAndSellers } = getState();
      dispatch(fetchStock());
      const response = await fetch({
        method: EMethod.GET,
        url: `/orders/api/orders/${orderId}/get_order/`
      });
      dispatch(successFetch({ ...customersAndSellers, last_order: response }));
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const deleteCustomer = async (
  dispatch: Dispatch,
  id: number
): Promise<void> => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await fetch({
        method: EMethod.DELETE,
        url: `/users/api/clients/${id}/`
      });
      dispatch(
        sendAlert('Customer successfully deleted', EAlertVariant.SUCCESS)
      );
      resolve();
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      reject(error);
    }
  });
};
