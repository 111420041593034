import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { IKitchen } from 'interfaces/kitchen';

export type IHistory = IKitchen;

export interface IHistoryState {
  fetch: boolean;
  count: number;
  next?: string;
  previous?: string;
  results: IHistory[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesHistory {
  FETCH = 'HISTORY/FETCH',
  SUCCESS = 'HISTORY/SUCCESS',
  FIRSTLOAD = 'HISTORY/FIRSTLOAD',
  FAILURE = 'HISTORY/FAILURE',
  SEARCH = 'HISTORY/SEARCH',
  RESET_FETCH = 'HISTORY/RESET_FETCHS'
}

export interface IFetchAction extends IBaseAction<ETypesHistory, null> {
  type: ETypesHistory.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesHistory, IHistoryState> {
  type: ETypesHistory.SUCCESS;
  payload: IHistoryState;
}

export interface IFirstLoadAction
  extends IBaseAction<ETypesHistory, IHistoryState> {
  type: ETypesHistory.FIRSTLOAD;
  payload: IHistoryState;
}

export interface IFailureAction extends IBaseAction<ETypesHistory, string> {
  type: ETypesHistory.FAILURE;
  payload: string;
}

export interface ISearchAction extends IBaseAction<ETypesHistory, IHistory> {
  type: ETypesHistory.SEARCH;
  payload: IHistory;
}

export type IHistoryAction =
  | IFetchAction
  | ISuccessAction
  | IFirstLoadAction
  | IFailureAction
  | ISearchAction;

export type GetState = () => any;

export type PromiseAction = Promise<IHistoryAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IHistoryAction
    | ThunkAction
    | PromiseAction
    | Array<IHistoryAction>
    | IAlertAction
) => any;
