import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React from 'react';
//import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { PRIMARY_COLOR } from 'settings';

interface IProps {
  handleSave: (data: {
    name: string;
    type_of_menu: string;
    store: number;
    active: boolean;
    sections: string;
    max_quantity_of_items: number;
    max_quantity_of_same_item: number;
  }) => void;
  store: number;
  categoryName: string;
}

export default function CreateMenu(props: IProps) {
  const { handleSave, store, categoryName } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [maxOfSameItem, setMaxOfSameItem] = React.useState(1);
  const [active, setActive] = React.useState(true);
  const [sections, setSections] = React.useState([
    {
      category: '',
      template: 'Product',
      nameEn: '',
      namePt: '',
      id: 0
    }
  ]);
  const [sectionsError, setSectionsError] = React.useState(['']);
  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
  };

  // const handleMaxOfItems = (event: any) => {
  //    setMaxOfItems(event.target.value);
  // };

  const handleMaxOfSameItem = (event: any) => {
    const value = event.target.value;
    if (/^[0-9]+$/.test(value)) {
      setMaxOfSameItem(value);
    }
  };
  const handleActive = () => {
    setActive(!active);
  };
  const handleChangeCategory = (index: any, value: any) => {
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
              ...item,
              category: value
            }
          : item
      )
    );
    const x = [...sectionsError];
    x[index] = '';
    setSectionsError(x);
  };

  const handleDelete = (id: number) => {
    const values = [...sections];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setSections(values);
  };

  const listSections = sections.map((section, index) => (
    <>
      <Typography style={{ fontFamily: 'Work Sans, sans-serif' }}>
        Section {index + 1}
      </Typography>
      {sections.length > 1 ? (
        <IconButton
          style={{ width: '55px' }}
          color="secondary"
          onClick={() => handleDelete(index)}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}

      <FormControl
        fullWidth
        style={{ marginBottom: '15px', width: '100%' }}
        required
      >
        <InputLabel htmlFor="category-input">Category</InputLabel>
        <Select
          labelId="label"
          id="select"
          value={section.category}
          onChange={(e) => handleChangeCategory(index, e.target.value)}
        >
          <MenuItem value={categoryName}>{categoryName}</MenuItem>
        </Select>
      </FormControl>

      <div style={{ color: 'red' }}>{sectionsError[index]}</div>
    </>
  ));
  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert name');
      entered = true;
    }
    sections.map((element, index) => {
      if (element.category === '' || element.template === '') {
        const x = [...sectionsError];
        x[index] = 'Please enter all fields for this section';
        setSectionsError(x);
        entered = true;
      }
    });
    return entered;
  };
  const submit = () => {
    if (!checkForm()) {
      const sectionsToSubmit = [];
      for (let index = 0; index < sections.length; index++) {
        const element = sections[index];
        sectionsToSubmit.push({
          category: element.category,
          template: element.template,
          nameEn: element.category,
          namePt: element.category
        });
      }
      handleSave({
        name,
        type_of_menu: 'Take Away',
        store,
        active,
        max_quantity_of_items: 100,
        max_quantity_of_same_item: maxOfSameItem,
        sections: JSON.stringify(sectionsToSubmit)
      });
    }
  };
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <Typography
        style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
      >
        Create a menu
      </Typography>
      <FormControl
        style={{ marginBottom: '1rem', width: '100%' }}
        required={true}
        error={nameError !== ''}
      >
        <InputLabel htmlFor="name-input">Name</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={name}
          onChange={handleName}
        />
        <FormHelperText>{nameError}</FormHelperText>
      </FormControl>

      <Grid
        style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={() => {
                handleActive();
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Active"
        />
      </Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <FormControl
          style={{ marginBottom: '1rem' }}
          required={true}
          fullWidth
          error={nameError !== ''}
        >
          <InputLabel style={{ width: '130%' }} htmlFor="name-input">
            Maximum quantity a customer can purchase one produce at time
          </InputLabel>
          <Input
            id="name-input"
            type="number"
            value={maxOfSameItem}
            onChange={handleMaxOfSameItem}
          />
        </FormControl>
      </Grid>
      <div style={{ width: '100%' }}>
        {listSections}
        {/*  <IconButton
          color="primary"
          onClick={() => {
            const data = {
              category: '',
              template: '',
              nameEn: '',
              namePt: '',
              id: 0
            };
            if (sections.length >= 1) {
              data.id = sections[sections.length - 1].id + 1;
            }
            setSections(sections.concat(data));
            const x = [...sectionsError];
            x.push('');
            setSectionsError(x);
          }}
        >
          <AddIcon />
        </IconButton> */}
      </div>
      <Button
        style={{
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={() => submit()}
      >
        Save and continue
      </Button>
    </Grid>
  );
}
