import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { Product } from 'interfaces/stock';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { EMethod } from 'enums/method';
import { fetch } from 'utils/request';
import { sendAlert } from './../../redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { useDispatch } from 'react-redux';
import Currency from 'utils/currency';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product;
  store?: number;
  products: Product[];
  reset: () => void;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});
export default function ModalEditMenu(props: SimpleDialogProps) {
  const dispatch = useDispatch();
  const { onClose, open, product, products, reset } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([])
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();
  const handleProductPressAll = () => {
    setRemovedProducts([])
    setSelectedProducts(
      products.filter((_product) => _product.product_id !== product.product_id)
    );
  };
  const pushRemove = (product_id) => {
    var removed = []
    removedProducts.map((el) => {
      if (!removed.includes(el)) {
        removed.push(el)
      }
    })
    if (!removed.includes(product_id)) {
      removed.push(product_id)
    }
    setRemovedProducts(removed);

  }
  const deleteRemove = (product_id) => {
    var removed = []
    removedProducts.map((el) => {
      if (el !== product_id) {
        removed.push(el)
      }
    })
    setRemovedProducts(removed)

  }
  const handleProductUnPressAll = () => {
    setRemovedProducts(selectedProducts.map((_product) => { return _product.product_id }))
    setSelectedProducts([]);
  };
  const handleProductOnPress = (selectedProduct: Product) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };
  const submit = async () => {
    removedProducts.map(async (el, idx) => {
      await fetch({
        method: EMethod.DELETE,
        headers: {
          'content-type': 'application/json'
        },
        url: `/products/api/products/${product.product_id}/remove_related_product_from_product/`,
        data: {
          related_product_id: el
        }
      });
      if (idx === removedProducts.length - 1) {
        reset();
        handleClose();
        dispatch(
          sendAlert('Related products successfully edited', EAlertVariant.SUCCESS)
        );
      }
    });
    if (selectedProducts.length > 0) {
      selectedProducts.map(async (el, idx) => {
        await fetch({
          method: EMethod.POST,
          headers: {
            'content-type': 'application/json'
          },
          url: `products/api/products/${product.product_id}/add_related_product_to_product/`,
          data: {
            product_id: el.product_id
          }
        });
        if (idx === selectedProducts.length - 1) {
          reset();
          handleClose();
          dispatch(
            sendAlert('Related products successfully edited', EAlertVariant.SUCCESS)
          );
        }
      });

    } else {
      handleClose();
    }
  };
  useEffect(() => {
    if (product?.product_id) {
      var filteredProducts = [];
      product.related_products.forEach((related) => {
        var selectedRelated = products.filter(
          (element) => element.product_id === related.related_product.product_id
        );
        filteredProducts.push(selectedRelated[0]);
      });
      setSelectedProducts(filteredProducts);
    }
  }, [product]);
  const formatValue = (value) => {
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value)
  }
  const productPrice = (product) => {
    if (product.variation.length > 0) {
      return product.variation.map((el, idx) => (idx === 0 ? (<><Currency /> {formatValue(el.price)}</>) : (<>{"; "}<Currency /> {formatValue(el.price)}</>)))
    }
    return (<><Currency /> {formatValue(product.price)}</>)
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">
        Related products of {product?.product_id ? product.name : null}:
      </DialogTitle>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <Tooltip title="Select All" arrow placement="top">
                <TableCell
                  style={{
                    padding: '5px',

                  }}
                  title="Select all"
                >
                  {selectedProducts?.length === products?.length - 1 ? (
                    <CheckBoxIcon onClick={() => handleProductUnPressAll()} />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      onClick={() => handleProductPressAll()}
                    />
                  )}
                </TableCell>
              </Tooltip>
              <TableCell style={{ padding: '5px' }}>Product Name</TableCell>
              <TableCell style={{ padding: '5px' }}>Category</TableCell>
              <TableCell style={{ padding: '5px' }}>Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product?.product_id && products?.length > 0 ? (
              <>
                {products?.map(
                  (_product) =>
                    _product.product_id !== product.product_id && (
                      <TableRow key={_product.product_id} hover>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: '5px', width: '5%' }}
                        >
                          {selectedProducts.some((related) => {
                            return related?.product_id === _product?.product_id;
                          }) ? (
                            <CheckBoxIcon
                              onClick={() => {
                                pushRemove(_product.product_id)
                                handleProductOnPress(_product)
                              }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              onClick={() => {
                                if (removedProducts.includes(_product.product_id)) {
                                  deleteRemove(_product.product_id)
                                }
                                handleProductOnPress(_product)
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ padding: '5px' }}>
                          {_product.name}
                        </TableCell>
                        <TableCell style={{ padding: '5px' }}>
                          {_product.category}
                        </TableCell>
                        <TableCell style={{ padding: '5px' }}>
                          {productPrice(_product)}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
      >
        <Button color="primary" onClick={() => submit()}>
          Save
        </Button>
        <Button onClick={handleClose}> Close </Button>
      </div>
    </Dialog>
  );
}
