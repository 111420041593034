import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toolBarTitles: {
        color: 'rgb(0 0 0 / 54%) !important',
        fontWeight: 400,
        fontSize: 12,
        marginLeft: "10px"
    },
    toolBarTitlesError: {
        color: 'red',
        fontWeight: 500,
        fontSize: 12,
        marginLeft: "10px",
        textTransform: "none"
    },
    selectInput: {
        width: '100%',
        marginTop: '-3px',
        marginLeft: '10px'
    },
    selectInputError: {
        width: '100%',
        marginTop: '-3px',
        marginLeft: '10px',
        "& div": {
            borderColor: "red"
        }
    },
    productCardWrapper: {
        cursor: "pointer",
        position: "relative",
        padding: "5px !important"
    },
    productCardImage: {
        height: "150px",
        width: "100%",
        backgroundColor: "lightgrey",
        "& img": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
        }
    },
    productCardCheckbox: {

    },
    productCardActionsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        transition: "height 0.3s",
        overflow: "hidden"
    },
    submitButton: {
        width: "230px",
        marginTop: "15px",
        padding: "10px 2px 10px 2px",
        border: `solid 2px black`,
        color: "black",
        borderRadius: "7px",
        fontWeight: 500,
        cursor: "pointer",
        transition: "opacity 0.3ms",
        backgroundColor: "white !important",
        "&:hover": {
            border: `solid 2px green`,
            color: "green",
            transition: "border 400ms"
        },
        "&:disabled": {
            opacity: 0.5
        },
        '@media screen and (max-width: 850px)': {
            width: "90%",

        },
    },
    disabledSubmitButton: {
        width: "60%",
        marginTop: "15px",
        padding: "10px 2px 10px 2px",
        border: `solid 2px black`,
        color: "black",
        borderRadius: "7px",
        fontWeight: 500,
        cursor: "pointer",
        opacity: 0.5,
        transition: "opacity 0.3ms",
        backgroundColor: "white !important",
        "&:hover": {
            border: `solid 2px green`,
            color: "green",
            transition: "border 400ms"
        },
        '@media screen and (max-width: 850px)': {
            width: "90%",

        },
    },
    customer_modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '15px'
    },
    edit_customer_modal: {
        display: 'flex',
        justifyContent: 'end',
        height: '100vh'
    },

    customer_paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '60vw',
        paddingTop: '30px',
        paddingBottom: '30px',
        overflowY: 'auto',
        maxHeight: '99vh'
    },
    customer_modal_wrapper: {
        marginTop: '5px'
    },
    customer_modal_body: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    },
    customer_modal_field_wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%'
    },
    left_side_wrapper: {
        display: "flex", flexDirection: "column", marginTop: "50px", position: "relative",
        '@media screen and (max-width: 850px)': {
            padding: "0rem 2rem"
        },
    },
    left_side_content: {
        position: "fixed", width: "27%",
        overflowY: "auto",
        '@media screen and (max-width: 850px)': {
            position: "relative", width: "100%",
        },
    },
    cart_icon: {
        display: "none",
        '@media screen and (max-width: 600px)': {
            display: "block"
        },
        position: "fixed",
        bottom: "50px",
        right: "10px",
        backgroundColor: "slategray",
        borderRadius: "50%"
    },
    cart_icon_wrapper: {
        transition: "visibility 0s, opacity 0.3s linear"
    },
    product_details_wrapper: {
        display: "block",
        '@media screen and (max-width: 600px)': {
            display: "none"
        },
    },
    hide_desktop: {
        display: "none",
        '@media screen and (max-width: 600px)': {
            display: "block"
        },
    }
}));

export default useStyles;
