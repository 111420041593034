import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Backdrop, Fade, Typography, IconButton, Button } from '@material-ui/core';
import { CustomerOrders } from 'interfaces/customersAndSellers';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import OrderDetails from "./orderDetails"
import Currency from 'utils/currency';

interface IProps {
  open: boolean;
  handleClose: () => void;
  data: CustomerOrders[];
  customer: string;
}

export default function CustomerModal(props: IProps) {
  const { open, handleClose, data, customer } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState("")
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.customer_modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.customer_paper}>
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                cursor: 'pointer'
              }}
              onClick={() => handleClose()}
            >
              <IconButton
                style={{ width: '50px' }}
                color="secondary"
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
              <Typography color="secondary">Close</Typography>
            </div>
            <Typography variant="h5">{customer}</Typography>
            {data.map((order, idx) => (
              <>
                {order && (
                  <div
                    key={order?.order_id + idx}
                    className={classes.customer_modal_wrapper}
                  >
                    {/*                    <Typography variant={'h6'}>{order?.order_code}</Typography>
                     */}{' '}
                    <div className={classes.customer_modal_body}>
                      <div className={classes.customer_modal_field_wrapper}>
                        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                          Information
                        </Typography>
                        {/*<Typography>
                          Date ordered:{' '}
                          {moment(new Date(order?.date_ordered))
                            .utcOffset(3, false)
                            .format('DD/MM/yyyy - HH:mm')}
                        </Typography> */}
                        <Typography>
                          <b>Order ID:</b>{' '}
                          #{order.order_id}
                        </Typography>
                        <Typography>
                          <b>Date:</b> {moment(order.date_ordered).format("DD/MM/YYYY HH:mm")}
                        </Typography>
                        {order.type_of_order.includes("-D") ? <Typography>
                          <b>Order address:</b> {order?.order_address?.replace("None,", "")}{' '}
                        </Typography> :
                          <Typography>
                            <b>Billing address:</b> {order?.billing_address?.replace("None,", "")}{' '}
                          </Typography>}
                        <Button onClick={() => setSelected(order.order_id)} variant='text' style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "5px" }}>
                          <Typography style={{ fontWeight: 500 }}>Order details </Typography>
                          <OpenInBrowserIcon style={{ marginLeft: "7px" }} />
                        </Button>
                      </div>
                      <div className={classes.customer_modal_field_wrapper}>
                        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                          Payment
                        </Typography>
                        {/*                        <Typography>{order?.payment}</Typography>
                         */}
                        <Typography>
                          <b>Total:</b>{' '}
                          <Currency />
                          {order?.total_order?.toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginBottom: '10px',
                        marginTop: '5px'
                      }}
                    >
                      <div
                        style={{
                          width: '90%',
                          height: '1px',
                          backgroundColor: 'lightgrey'
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
          <OrderDetails open={!(selected === '')} handleClose={() => setSelected("")} order_id={selected} />
        </div>
      </Fade>
    </Modal>
  );
}
