import React from 'react';

import { IKitchen } from 'interfaces/kitchen';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Receipt from 'helpers/Receipt';
import { useDispatch } from 'react-redux';
import { setOrderIsPaid } from 'redux/ducks/kitchen';
import PrintIcon from '@material-ui/icons/Print';

interface IProps {
  order: IKitchen;
}

export default function Payment(props: IProps): JSX.Element {
  const { order } = props;
  const dispatch = useDispatch();
  const handleIsPaid = (order: IKitchen) => {
    dispatch(setOrderIsPaid(order, { infos: { is_paid: true } }));
  };
  return (
    <Grid>
      <Grid container justifyContent="space-between">
        <Receipt trigger={<PrintIcon />} order={order} />
        {order?.is_paid && (
          <Button
            style={{
              cursor: 'default',
              border: order.is_paid ? '1px solid green' : '',
              color: order.is_paid ? 'green' : '#ff6961',
              fontWeight: 400,
              borderRadius: 50
            }}
            onClick={() => {
              if (!order.is_paid) {
                handleIsPaid(order);
              }
            }}
            size="small"
          >
            {order?.is_paid ? 'PAID' : 'Confirmar Pagamento'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
