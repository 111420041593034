import React, { useState } from "react";
import { Itags } from "interfaces/store";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import ModalDeleteTag from "./ModalDeleteTag";
import ModalEditTag from "./ModalEditTag";
interface Iprops {
    tag: Itags,
    store: number,
    fetchTags: () => void
}
export default function TagsRow(props: Iprops) {
    const { tag, store, fetchTags } = props
    const [edit, setEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    return (
        <>
            <TableRow key={tag.id}>
                <TableCell component="th" scope="row">
                    {tag.name}
                </TableCell>
                {store > 0 && (
                    <>
                        <TableCell component="th" scope="row">
                            {tag["related_to_catagory"] ? null : <IconButton onClick={() => setEdit(true)}><Edit /></IconButton>}
                            <IconButton onClick={() => setDeleteModal(true)}><Delete /></IconButton>
                        </TableCell>
                    </>
                )}
            </TableRow>
            <ModalDeleteTag tag={tag} open={deleteModal} onClose={() => setDeleteModal(false)} fetchTags={() => fetchTags()} />
            <ModalEditTag tag={tag} open={edit} onClose={() => setEdit(false)} fetchTags={() => fetchTags()} />
        </>
    )
}