import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { StatusNotification } from 'interfaces/notification';
import { editNotificationStatus, fetchNotificationsStatus } from 'redux/ducks/notification';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    notification: StatusNotification;
}

export default function ModalEditNotificationStatus(props: SimpleDialogProps) {
    const { onClose, open, notification } = props;
    const [message, setMessage] = React.useState('');
    const [status, setStatus] = React.useState('A fazer');

    const dispatch = useDispatch();

    const handleClose = () => {
        setMessage('');
        setStatus('A fazer');
        onClose();
    };

    const handleMessage = (event: any) => {
        setMessage(event.target.value)
    }

    const handleStatus = (event: any) => {
        setStatus(event.target.value)
    }

    useEffect(() => {
        if (notification) {
            setMessage(notification.message);
            setStatus(notification.status);
        }
    }, [notification]);

    const submitEdit = async () => {
        dispatch(editNotificationStatus(notification.id, {
            message: message,
            status: status,
        }))
            .then((result: boolean) => {
                dispatch(fetchNotificationsStatus())
                if (result) handleClose()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="simple-dialog-title">
                Edit status notification {notification?.status}
            </DialogTitle>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel htmlFor="message-input">Message</InputLabel>
                <Input id="message-input" type="string" value={message} onChange={handleMessage} />
            </FormControl>
            <FormControl style={{ margin: '0px 10px' }}>
                <InputLabel id="label">Status</InputLabel>
                <Select labelId="label" id="select" value={status} onChange={handleStatus}>
                    <MenuItem value="A fazer">To do</MenuItem>
                    <MenuItem value="Fazendo">Accepted</MenuItem>
                    <MenuItem value="Feito">Preparing</MenuItem>
                    <MenuItem value="Depot">Ready</MenuItem>
                    <MenuItem value="Transit">On the way</MenuItem>
                    <MenuItem value="Delivered">Delivered</MenuItem>
                    <MenuItem value="Completed">Complete</MenuItem>
                    <MenuItem value="Canceled">Canceled</MenuItem>
                    <MenuItem value="Canceled and Refunded">Canceled & Refunded</MenuItem>
                </Select>
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitEdit} color="primary"> Save </Button>
                <Button onClick={handleClose}> Cancel </Button>
            </div>
        </Dialog>
    );
}