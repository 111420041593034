import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KitchenIcon from '@material-ui/icons/KitchenOutlined';
import HistoryIcon from '@material-ui/icons/History';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import StoreIconOutlined from '@material-ui/icons/StoreOutlined';
import { ItemMenu, StyleButton, StyleButtonHover } from './../styles';
import { Collapse, Grid, List } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import './../styles.css';
import stripeLogo from './../../../../assets/img/stripe.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NETWORK } from 'settings';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import posIcon from './../../../../assets/img/posIcon.svg'

interface IProps {
  openDrawer: () => void;
  drawerOpen: boolean;
}
export default function PetshopMenu(props: IProps) {
  const { openDrawer, drawerOpen } = props;
  const [openControlPanel, setOpenControlPanel] = React.useState(false);
  const handleClick = () => {
    setOpenControlPanel(!openControlPanel);
    openDrawer();
  };

  const store: string = localStorage.getItem('state');
  const state = JSON.parse(store);
  const {
    is_superuser,
    is_manager,
    is_waiter,
    is_store_owner,
    name,
    email,
    stripe_profiles
  } = state.authentication;

  const [superUser, setSuperUser] = React.useState(is_superuser);
  const [owner, setOwner] = React.useState(is_store_owner);
  const [waiter, setWaiter] = React.useState(is_waiter);
  const [manager, setManager] = React.useState(is_manager);
  const [logoutModal, setLogoutModal] = React.useState(false)


  useEffect(() => {
    const { is_superuser, is_manager, is_waiter, is_store_owner } =
      state.authentication;
    setSuperUser(is_superuser);
    setOwner(is_store_owner);
    setWaiter(is_waiter);
    setManager(is_manager);
  }, [state.authentication]);
  const returnEmphasisColor = () => {
    switch (NETWORK) {
      case '2':
        return '#FF931E';
      case '4':
        return '#ff5a23';
      default:
        return 'black';
    }
  };
  const returnColor = () => {
    switch (NETWORK) {
      case '2':
        return '#3114A0';
      case '4':
        return '#124c4d';
      default:
        return 'black';
    }
  };
  const handleCloseLogoutModal = (e) => {
    e.preventDefault()
    setLogoutModal(false)
  }
  const handleClickLogout = (e) => {
    e.preventDefault()
    setLogoutModal(true)
  };
  const UserProfilePanel = () => {
    return (
      <ListItem onClick={(e) => handleClickLogout(e)} className="d-config-chefs d-control">
        <div
        >
          {drawerOpen ? <ListItem
            style={{
              width: '98%',
              cursor: "pointer", textDecoration: "underline",
            }}
          >
            <ListItemIcon>
              Hello {name}
              <br />
              {email}
            </ListItemIcon>
          </ListItem> : <ListItemIcon style={{
            width: '98%',
            cursor: "pointer", textDecoration: "underline",
          }}><AccountCircleIcon /></ListItemIcon>}
        </div>
      </ListItem>
    );
  };

  return (
    <>
      <Dialog
        open={logoutModal}
        onClose={handleCloseLogoutModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to logout?</DialogTitle>

        <DialogActions>
          <Button onClick={handleCloseLogoutModal} color="secondary">
            No
          </Button>
          <Button onClick={() => window.location.pathname = "/dashboard/logout"} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <StyleButton>
        {(superUser || owner) ? (
          <Grid
            style={{ marginTop: drawerOpen ? '0px' : '70px' }}
            container
            direction="column"
          >
            <ItemMenu to="/dashboard/order">
              <StyleButtonHover>
                <ListItem button className="d-order d-order-item d-control">
                  <ListItemIcon>
                    <KitchenIcon
                      className="d-order d-control-icon"
                      style={{
                        fill:
                          window.location.pathname === '/dashboard/order'
                            ? returnEmphasisColor()
                            : returnColor(),
                        width: 24,
                        height: 24
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        window.location.pathname === '/dashboard/order'
                          ? returnEmphasisColor()
                          : returnColor()
                    }}
                    primary="Orders"
                  />
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <ItemMenu to="/dashboard/invoices">
              <StyleButtonHover>
                <ListItem button className="d-history d-control">
                  <ListItemIcon>
                    <HistoryIcon
                      className="d-history d-control-icon"
                      style={{
                        fill:
                          window.location.pathname === '/dashboard/invoices'
                            ? returnEmphasisColor()
                            : returnColor(),
                        width: 24,
                        height: 24
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        window.location.pathname === '/dashboard/invoices'
                          ? returnEmphasisColor()
                          : returnColor()
                    }}
                    primary="Invoices"
                  />
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            <ItemMenu to="/dashboard/customers">
              <StyleButtonHover>
                <ListItem
                  button
                  className="d-customers d-customers-item d-control"
                >
                  <ListItemIcon>
                    <PeopleAltOutlinedIcon
                      className="d-customers d-control-icon"
                      style={{
                        fill:
                          window.location.pathname === '/dashboard/customers'
                            ? returnEmphasisColor()
                            : returnColor(),
                        width: 24,
                        height: 24
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        window.location.pathname === '/dashboard/customers'
                          ? returnEmphasisColor()
                          : returnColor()
                    }}
                    primary="Clients"
                  />
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            {NETWORK !== "4" ?
              <ItemMenu to="/dashboard/commercialInvoice">
                <StyleButtonHover>
                  <ListItem button className="d-order d-order-item d-control">
                    <ListItemIcon>
                      <img className="d-order d-control-icon" src={posIcon} style={{
                        fill:
                          window.location.pathname === '/dashboard/commercialInvoice'
                            ? returnEmphasisColor()
                            : returnColor(),
                        width: 24,
                        height: 24
                      }} />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color:
                          window.location.pathname === '/dashboard/commercialInvoice'
                            ? returnEmphasisColor()
                            : returnColor()
                      }}
                      primary="Commercial invoice"
                    />
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>
              : null}
            <ItemMenu
              className="stock-drawer-img-wrapper"
              to="/dashboard/stock"
            >
              <StyleButtonHover>
                <ListItem button className="d-menus d-menus-item d-control">
                  <ListItemIcon>
                    <svg
                      height="900"
                      viewBox="0 -200 900 900"
                      xmlns="http://www.w3.org/2000/svg"
                      className="d-menus d-control-icon stock-drawer-img"
                      fill={
                        window.location.pathname === '/dashboard/stock'
                          ? returnEmphasisColor()
                          : returnColor()
                      }
                    >
                      <g id="Outline">
                        <path d="m484.25 239.741a24 24 0 0 0 -29.871-16.114l-214.6 64.207a8.016 8.016 0 0 0 -3.185 1.835l-26.072 24.5a8 8 0 0 0 4.1 13.711l35.243 6.156a8.019 8.019 0 0 0 3.67-.216l174.128-52.1.754 16.475-55.33 16.554 4.586 15.33 61.314-18.346a8 8 0 0 0 5.7-8.029l-1.223-26.704 24.673-7.382a24 24 0 0 0 16.113-29.872zm-233.49 78.21-17.622-3.078 13.036-12.251 36.512-10.922 4.586 15.328zm51.84-15.511-4.586-15.328 128.142-38.341.754 16.476zm165.7-52.023a7.952 7.952 0 0 1 -4.748 3.867l-20.841 6.235-.754-16.475 17.009-5.089a8 8 0 0 1 9.334 11.462z" />
                        <path d="m320 352h-136v-120a8 8 0 0 0 -8-8h-144a8 8 0 0 0 -8 8v256a8 8 0 0 0 8 8h288a8 8 0 0 0 8-8v-128a8 8 0 0 0 -8-8zm-88 16h32v32h-32zm-144-128h32v32h-32zm-48 0h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32v112h-128zm48 128h32v32h-32zm-48 0h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32v112h-128zm272 112h-128v-112h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32z" />
                        <path d="m280 448h16v16h-16z" />
                        <path d="m136 448h16v16h-16z" />
                        <path d="m136 320h16v16h-16z" />
                        <path d="m152 80a16.019 16.019 0 0 1 16-16h48v16h-40a8 8 0 0 0 -8 8v120h16v-112h192v128h16v-136a8 8 0 0 0 -8-8h-40v-16h48a16.019 16.019 0 0 1 16 16v136h16v-136a32.036 32.036 0 0 0 -32-32h-77.754l5.515-22.06a8 8 0 0 0 -7.761-9.94h-64a8 8 0 0 0 -7.761 9.94l5.515 22.06h-77.754a32.036 32.036 0 0 0 -32 32v128h16zm80-16h24a8 8 0 0 0 7.761-9.94l-5.515-22.06h43.508l-5.515 22.06a8 8 0 0 0 7.761 9.94h24v16h-96z" />
                        <path d="m408 408a16.019 16.019 0 0 1 -16 16h-48v16h48a32.036 32.036 0 0 0 32-32v-72h-16z" />
                        <path d="m392 400v-56h-16v48h-32v16h40a8 8 0 0 0 8-8z" />
                        <path d="m248 120a8 8 0 0 0 -8-8h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8zm-16 24h-16v-16h16z" />
                        <path d="m240 184h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z" />
                        <path d="m264 112h16v16h-16z" />
                        <path d="m264 144h96v16h-96z" />
                        <path d="m296 112h64v16h-64z" />
                        <path d="m264 184h16v16h-16z" />
                        <path d="m264 216h96v16h-96z" />
                        <path d="m296 184h64v16h-64z" />
                      </g>
                    </svg>
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        window.location.pathname === '/dashboard/stock'
                          ? returnEmphasisColor()
                          : returnColor()
                    }}
                    primary="Inventory"
                  />
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            
            {NETWORK === "6" &&
              <ItemMenu to="/dashboard/artists">
                <StyleButtonHover>
                  <ListItem
                    button
                    className="d-artists d-artists-item d-control"
                  >
                    <ListItemIcon>
                      <PersonOutlinedIcon
                        className="d-artists d-control-icon"
                        style={{
                          fill:
                            window.location.pathname === '/dashboard/artists'
                              ? returnEmphasisColor()
                              : returnColor(),
                          width: 24,
                          height: 24
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color:
                          window.location.pathname === '/dashboard/artists'
                            ? returnEmphasisColor()
                            : returnColor()
                      }}
                      primary="Artists"
                    />
                  </ListItem>
                </StyleButtonHover>
              </ItemMenu>
            }

            {NETWORK === "6" ? null : <> <ListItem
              button
              onClick={handleClick}
              className="d-config d-control"
            >
              <ListItemIcon>
                <StoreIconOutlined
                  className="d-config d-control-icon"
                  style={{ fill: returnColor(), width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Configurations"
                style={{ color: returnColor() }}
              />
              <div className="config-collape-button">
                {openControlPanel ? (
                  <ExpandLess style={{ color: returnColor() }} />
                ) : (
                  <ExpandMore style={{ color: returnColor() }} />
                )}
              </div>
            </ListItem>
              <Collapse
                in={openControlPanel && drawerOpen}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ItemMenu to="/dashboard/owner">
                    <StyleButtonHover>
                      <ListItem
                        button
                        className="d-config-chefs d-control"
                        style={{ paddingLeft: '3vw' }}
                      >
                        <ListItemText
                          style={{
                            color:
                              window.location.pathname === '/dashboard/owner'
                                ? returnEmphasisColor()
                                : returnColor()
                          }}
                          primary="Store Owner"
                        />
                      </ListItem>
                    </StyleButtonHover>
                  </ItemMenu>
                  <ItemMenu to="/dashboard/stores">
                    <StyleButtonHover>
                      <ListItem
                        button
                        className="d-stores d-control"
                        style={{ paddingLeft: '3vw' }}
                      >
                        <ListItemText
                          style={{
                            color:
                              window.location.pathname === '/dashboard/stores'
                                ? returnEmphasisColor()
                                : returnColor()
                          }}
                          primary="Stores"
                        />
                      </ListItem>
                    </StyleButtonHover>
                  </ItemMenu>
                </List>
              </Collapse></>}
            

            <ItemMenu to="/dashboard/manager">
              <StyleButtonHover>
                <ListItem button className="d-manager d-control">
                  <ListItemIcon>
                    <BarChartIcon
                      className="d-manager d-control-icon"
                      style={{
                        fill:
                          window.location.pathname === '/dashboard/manager'
                            ? returnEmphasisColor()
                            : returnColor(),
                        width: 24,
                        height: 24
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        window.location.pathname === '/dashboard/manager'
                          ? returnEmphasisColor()
                          : returnColor()
                    }}
                    primary="Statistics"
                  />
                </ListItem>
              </StyleButtonHover>
            </ItemMenu>
            {stripe_profiles?.stripe_login_link ? (
              <a
                target="_blank"
                href={stripe_profiles.stripe_login_link}
                style={{ textDecoration: 'none' }}
              >
                <StyleButtonHover>
                  <ListItem button className="d-config-chefs d-control">
                    <div style={{ height: 24, width: 24 }}>
                      <img
                        style={{ height: '100%', width: '100%' }}
                        src={stripeLogo}
                        alt=""
                      />
                    </div>
                    <ListItemText
                      style={{ marginLeft: '32px', color: returnColor() }}
                      primary="Stripe Login"
                    />
                  </ListItem>
                </StyleButtonHover>
              </a>
            ) : null}
            <UserProfilePanel />

          </Grid>
        ) :
          <>
            {manager && (
              <Grid
                style={{ marginTop: drawerOpen ? '0px' : '70px' }}
                container
                direction="column"
              >
                <ItemMenu to="/dashboard/order">
                  <StyleButtonHover>
                    <ListItem button className="d-order d-order-item d-control">
                      <ListItemIcon>
                        <KitchenIcon
                          className="d-order d-control-icon"
                          style={{
                            fill:
                              window.location.pathname === '/dashboard/order'
                                ? returnEmphasisColor()
                                : returnColor(),
                            width: 24,
                            height: 24
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color:
                            window.location.pathname === '/dashboard/order'
                              ? returnEmphasisColor()
                              : returnColor()
                        }}
                        primary="Orders"
                      />
                    </ListItem>
                  </StyleButtonHover>
                </ItemMenu>
                <ItemMenu
                  className="stock-drawer-img-wrapper"
                  to="/dashboard/stock"
                >
                  <StyleButtonHover>
                    <ListItem button className="d-menus d-menus-item d-control">
                      <ListItemIcon>
                        <svg
                          height="900"
                          viewBox="0 -200 900 900"
                          xmlns="http://www.w3.org/2000/svg"
                          className="d-menus d-control-icon stock-drawer-img"
                          fill={
                            window.location.pathname === '/dashboard/stock'
                              ? returnEmphasisColor()
                              : returnColor()
                          }
                        >
                          <g id="Outline">
                            <path d="m484.25 239.741a24 24 0 0 0 -29.871-16.114l-214.6 64.207a8.016 8.016 0 0 0 -3.185 1.835l-26.072 24.5a8 8 0 0 0 4.1 13.711l35.243 6.156a8.019 8.019 0 0 0 3.67-.216l174.128-52.1.754 16.475-55.33 16.554 4.586 15.33 61.314-18.346a8 8 0 0 0 5.7-8.029l-1.223-26.704 24.673-7.382a24 24 0 0 0 16.113-29.872zm-233.49 78.21-17.622-3.078 13.036-12.251 36.512-10.922 4.586 15.328zm51.84-15.511-4.586-15.328 128.142-38.341.754 16.476zm165.7-52.023a7.952 7.952 0 0 1 -4.748 3.867l-20.841 6.235-.754-16.475 17.009-5.089a8 8 0 0 1 9.334 11.462z" />
                            <path d="m320 352h-136v-120a8 8 0 0 0 -8-8h-144a8 8 0 0 0 -8 8v256a8 8 0 0 0 8 8h288a8 8 0 0 0 8-8v-128a8 8 0 0 0 -8-8zm-88 16h32v32h-32zm-144-128h32v32h-32zm-48 0h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32v112h-128zm48 128h32v32h-32zm-48 0h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32v112h-128zm272 112h-128v-112h32v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-40h32z" />
                            <path d="m280 448h16v16h-16z" />
                            <path d="m136 448h16v16h-16z" />
                            <path d="m136 320h16v16h-16z" />
                            <path d="m152 80a16.019 16.019 0 0 1 16-16h48v16h-40a8 8 0 0 0 -8 8v120h16v-112h192v128h16v-136a8 8 0 0 0 -8-8h-40v-16h48a16.019 16.019 0 0 1 16 16v136h16v-136a32.036 32.036 0 0 0 -32-32h-77.754l5.515-22.06a8 8 0 0 0 -7.761-9.94h-64a8 8 0 0 0 -7.761 9.94l5.515 22.06h-77.754a32.036 32.036 0 0 0 -32 32v128h16zm80-16h24a8 8 0 0 0 7.761-9.94l-5.515-22.06h43.508l-5.515 22.06a8 8 0 0 0 7.761 9.94h24v16h-96z" />
                            <path d="m408 408a16.019 16.019 0 0 1 -16 16h-48v16h48a32.036 32.036 0 0 0 32-32v-72h-16z" />
                            <path d="m392 400v-56h-16v48h-32v16h40a8 8 0 0 0 8-8z" />
                            <path d="m248 120a8 8 0 0 0 -8-8h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8zm-16 24h-16v-16h16z" />
                            <path d="m240 184h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z" />
                            <path d="m264 112h16v16h-16z" />
                            <path d="m264 144h96v16h-96z" />
                            <path d="m296 112h64v16h-64z" />
                            <path d="m264 184h16v16h-16z" />
                            <path d="m264 216h96v16h-96z" />
                            <path d="m296 184h64v16h-64z" />
                          </g>
                        </svg>
                      </ListItemIcon>
                      <ListItemText
                        primary="Products"
                        style={{
                          color:
                            window.location.pathname === '/dashboard/stock'
                              ? returnEmphasisColor()
                              : returnColor()
                        }}
                      />
                    </ListItem>
                  </StyleButtonHover>
                </ItemMenu>
                <ItemMenu to="/dashboard/stores">
                  <StyleButtonHover>
                    <ListItem button className="d-stores d-manager-item d-control">
                      <ListItemIcon>
                        <StoreIconOutlined
                          className="d-stores d-control-icon"
                          style={{
                            fill:
                              window.location.pathname === '/dashboard/stores'
                                ? returnEmphasisColor()
                                : returnColor(),
                            width: 24,
                            height: 24
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color:
                            window.location.pathname === '/dashboard/stores'
                              ? returnEmphasisColor()
                              : returnColor()
                        }}
                        primary="Store"
                      />
                    </ListItem>
                  </StyleButtonHover>
                </ItemMenu>
                {stripe_profiles?.stripe_login_link ? (
                  <a
                    target="_blank"
                    href={stripe_profiles.stripe_login_link}
                    style={{ textDecoration: 'none' }}
                  >
                    <StyleButtonHover>
                      <ListItem button className="d-config-chefs d-control">
                        <div style={{ height: 24, width: 24 }}>
                          <img
                            style={{ height: '100%', width: '100%' }}
                            src={stripeLogo}
                            alt=""
                          />
                        </div>
                        <ListItemText
                          style={{ marginLeft: '32px', color: returnColor() }}
                          primary="Stripe Login"
                        />
                      </ListItem>
                    </StyleButtonHover>
                  </a>
                ) : null}
                <UserProfilePanel />
              </Grid>
            )}
            {waiter && (
              <Grid
                style={{ height: '100%', marginTop: drawerOpen ? '0px' : '70px' }}
                container
                direction="column"
              >
                <ItemMenu to="/dashboard/order">
                  <StyleButtonHover>
                    <ListItem button className="d-order d-control">
                      <ListItemIcon>
                        <KitchenIcon
                          className="d-order d-control-icon"
                          style={{ color: returnColor(), width: 24, height: 24 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Orders"
                        style={{
                          color:
                            window.location.pathname === '/dashboard/order'
                              ? returnEmphasisColor()
                              : returnColor()
                        }}
                      />
                    </ListItem>
                  </StyleButtonHover>
                </ItemMenu>
                {stripe_profiles?.stripe_login_link ? (
                  <a
                    target="_blank"
                    href={stripe_profiles.stripe_login_link}
                    style={{ textDecoration: 'none' }}
                  >
                    <StyleButtonHover>
                      <ListItem button className="d-config-chefs d-control">
                        <div style={{ height: 24, width: 24 }}>
                          <img
                            style={{ height: '100%', width: '100%' }}
                            src={stripeLogo}
                            alt=""
                          />
                        </div>
                        <ListItemText
                          style={{ marginLeft: '32px', color: returnColor() }}
                          primary="Stripe Login"
                        />
                      </ListItem>
                    </StyleButtonHover>
                  </a>
                ) : null}
                <StyleButtonHover ><UserProfilePanel /></StyleButtonHover>
              </Grid>
            )}
          </>
        }
      </StyleButton>
    </>
  );
}
