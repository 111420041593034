import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setKitchenStatus, setKitchenRevertStatus } from 'redux/ducks/kitchen';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';
import IStore from 'interfaces/store';
import Grid from '@material-ui/core/Grid';

import Order from './Order';
import "./styles.css"
import { NETWORK } from 'settings';
interface IProps {
  headerTitle: string;
  headerStyle: string;
  bodyStyle: string;
  orders: IKitchen[];
  showOKBtn?: boolean;
  showBackBtn?: boolean;
  showIsPaid?: boolean;
  showPrintBtn?: boolean;
  showScheduleBtn?: boolean;
  showDeliveryTrackBtn?: boolean;
  showExtraInfo?: boolean;
  showDriverBtn?: boolean;
  isFirstStage?: boolean;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    headerTitle,
    bodyStyle,
    orders,
    showOKBtn,
    showBackBtn,
    showIsPaid,
    showPrintBtn,
    showScheduleBtn,
    showDeliveryTrackBtn,
    showExtraInfo,
    showDriverBtn,
    isFirstStage
  } = props;
  const dispatch = useDispatch();
  const { currentOrderID } = useSelector<IStore, IKitchenState>(
    (state) => state.kitchen
  );
  // const diplayOrder = (order) => {
  //   if (NETWORK === "4" && headerTitle === 'NEW ORDERS' && order.payment === 'none') return "none"
  //   if (order.owner) {
  //     // if (order.payment === "none" && (order?.owner?.phone_number === "" || order?.owner?.phone_number.includes(".")) && order?.owner?.email === "") return "none"
      
  //   }
  //   if (order.payment === "none") return "none"
  //   return "block"
  // }
  const diplayOrder = (order) => {
    if (NETWORK === "4" && headerTitle === 'NEW ORDERS' && order.payment === 'none') return "none";
    if (order.owner) {
      if (
        order.payment === "none" &&
        (
          (order?.owner?.phone_number === "" || (order?.owner?.phone_number && order.owner.phone_number.includes("."))) &&
          (order?.owner?.email === "")
        )
      ) {
        return "none";
      }
    }
    if (order.payment === "none") return "none";
    return "block";
  }
  return (
    <Grid
      item
      className="order-element-wrapper"
      style={{ padding: '5px', }}

    >
      {orders.length > 0 &&
        orders.map((order) => (
          <div
            style={{
              display: diplayOrder(order)
            }}
          >
            <Order
              key={order.order_id}
              bodyStyle={bodyStyle}
              order={order}
              showBackBtn={showBackBtn}
              showDriverBtn={showDriverBtn}
              showOKBtn={showOKBtn}
              showIsPaid={showIsPaid}
              showPrintBtn={showPrintBtn}
              showScheduleBtn={showScheduleBtn}
              showDeliveryTrackBtn={showDeliveryTrackBtn}
              showExtraInfo={showExtraInfo}
              isFirstStage={isFirstStage}
              headerTitle={headerTitle}
              currentOrderID={currentOrderID}
              setKitchenStatus={() => {
                dispatch(setKitchenStatus(order))

              }}
              setRevertKitchenStatus={() =>
                dispatch(setKitchenRevertStatus(order))
              }
            />
          </div>
        ))}
    </Grid>
  );
}

Panel.defaultProps = {
  showOKBtn: true,
  showBackBtn: true,
  showIsPaid: false,
  showPrintBtn: false,
  showExtraInfo: false
};
