import React from 'react';
import { Typography, IconButton, Radio, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Product } from 'interfaces/stock';
import Currency from 'utils/currency';
interface IProps {
    product: Product;
    addextra: (extra_id) => void;
    removeExtra: (extra_id) => void;
    pickVariation: (selected_variation) => void
    selectedVariation: { id: number, name: string, price: number; }[];
    deselectProduct: () => void;
    increaseVariationAmount: (variation_id) => void,
    decreaseVariationAmount: (variation_id) => void
}

export default function ProductDetails(props: IProps) {
    const { product, addextra, removeExtra, pickVariation, selectedVariation, increaseVariationAmount, decreaseVariationAmount } = props;
    const isSelected = (variation_id) => {
        return selectedVariation.filter(el => el.id === variation_id).length > 0

    }
    const amount = (variation_id) => {
        var variation = selectedVariation.filter(el => el.id === variation_id)
        if (variation[0]) {
            return variation[0]["amount"]
        }
        return 0
    }
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                {product.variation.length > 0 ?
                    <>
                        {product.variation.map((variation, idx) => (
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }} onClick={() => pickVariation(variation)}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "-10px" }}>
                                        <Radio checked={isSelected(variation.id)} />
                                        <Typography>{variation.name}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Typography><Currency /> {new Intl.NumberFormat('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(variation.price)}</Typography>

                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    transition: "height 0.3s",
                                    overflow: "hidden",
                                    height: isSelected(variation.id) ? "45px" : "0px"
                                }} >

                                    <IconButton onClick={() => decreaseVariationAmount(variation.id)}><RemoveIcon /> </IconButton>
                                    <Typography>{amount(variation.id)}</Typography>
                                    <IconButton onClick={() => increaseVariationAmount(variation.id)}><AddIcon /> </IconButton>

                                </div>
                                <Divider />

                            </div>
                        ))}
                    </> :
                    <>
                        {product.extras.map((extra, idx) => (
                            <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <Typography style={{ fontWeight: 500 }}>{extra.ingredient}</Typography>
                                        <Typography style={{ fontWeight: 400 }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(extra.price)}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <IconButton onClick={() => removeExtra(idx)}><RemoveIcon /> </IconButton>
                                        <Typography>{extra.quantity - 1}</Typography>
                                        <IconButton onClick={() => addextra(idx)}><AddIcon /> </IconButton>

                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                }
            </div>
        </div>
    );
}
