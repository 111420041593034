import React from 'react';
import { Typography } from '@material-ui/core';
import { CardWithValue, CardBody, CardLabel } from './styles';
import NumberFormat from 'react-number-format';
import Currency from 'utils/currency';

interface Props {
  value: number;
  label: string;
  header?: number;
  percentage?: number;
}

const ExpensesCard: React.FC<Props> = (props: Props): JSX.Element => {
  const { value, label, header, percentage } = props;
  return (
    <CardWithValue>
      <CardBody>
        <CardLabel paragraph align="center">
          {label.toUpperCase()}
        </CardLabel>

        <Typography
          variant="h4"
          style={{ color: value >= 0 ? '#124c4d' : '#DE1C1C' }}
        >
          <Currency />
          {value.toFixed(2)}
        </Typography>
        {header ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h5" style={{ color: '#124c4d' }}>
              <NumberFormat
                value={header}
                displayType={'text'}
                thousandSeparator={true}
              />{' '}
              orders
            </Typography>
            {percentage ? (
              <Typography variant="h5" style={{ color: '#ff5a23', marginLeft: "8px" }}>
                {' '}
                <Currency />
                {value.toFixed(2)}%

              </Typography>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
           {/* <Typography variant="h5" style={{ color: '#124c4d' }}>
              <Currency />
              0.00

            </Typography> */}
            <Typography variant="h5" style={{ color: '#ff5a23' }}>
              0.00%
            </Typography>
          </div>
        )}
      </CardBody>
    </CardWithValue>
  );
};

export default ExpensesCard;
