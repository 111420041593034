import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, IconButton, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getStore, editStoreMenus } from 'redux/ducks/store';
import { IStoreBase } from 'interfaces/store_test';
import './styles.css';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import EditMenusTable from './EditMenusTable';

//Overrides theme of Dropzone component

class Menu {
  menu_id: number;
  name: string;
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  menus: Menu[];
  store: IStoreBase;
  fetchedMenus: any[];
}

export default function ModalCreateProduct(props: SimpleDialogProps) {
  const { onClose, open } = props;
  // const [image, setImage] = React.useState<File>();

  const [selectedMenus, setSelectedMenus] = useState([]);
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedMenus(props.store?.menus);
  }, [props.store]);

  const submitCreation = async () => {
    dispatch(editStoreMenus(props.store?.id, { menus: selectedMenus })).then(
      (result: boolean) => {
        dispatch(getStore());
        if (result) handleClose();
      }
    );
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <DialogTitle id="simple-dialog-title">
          Add Menu to the Store {props.store?.name}
        </DialogTitle>
        {props.store ? (
          <EditMenusTable
            menus={props.fetchedMenus}
            store_menus={props.store?.menus}
            selectedMenus={selectedMenus}
            setSelectedMenus={setSelectedMenus}
          />
        ) : null}
        <Button onClick={submitCreation} variant="outlined" color="primary">
          {' '}
          Save{' '}
        </Button>
      </div>
    </Modal>
  );
}
