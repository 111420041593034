import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IKitchen } from 'interfaces/kitchen';
import { Button, DialogContentText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {  setKitchenRevertStatus } from 'redux/ducks/kitchen';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  order: IKitchen;
}

export default function RefundOrderModal(props: SimpleDialogProps) {
  const { onClose, open, order } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const submitRefund = async () => {
    dispatch(setKitchenRevertStatus(order)).then(
      (result: boolean) => {
        if (result) handleClose();
      }
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Refund order: {order?.order_code}
      </DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DialogContentText style={{ width: '90%' }} id="simple-dialog-title">
          Are you sure that you want to refund this order?
        </DialogContentText>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        
        <Button onClick={handleClose}> No </Button>
        <Button onClick={submitRefund} color="primary">
          {' '}
          Yes
        </Button>
      </div>
    </Dialog>
  );
}
