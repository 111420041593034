import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Backdrop, Fade, Typography, IconButton } from '@material-ui/core';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { fetch } from 'utils/request';
import Currency from 'utils/currency';

interface IProps {
    open: boolean;
    handleClose: () => void;
    order_id: string
}

export default function OrderDetails(props: IProps) {
    const { open, handleClose, order_id } = props;
    const classes = useStyles();
    const [data, setData] = useState([])
    const getData = async () => {
        try {
            const response = await fetch({
                method: 'GET',
                url: `orders/api/orders/${order_id}/get_order/`,
            });
            setData(response.items)
        } catch (error) {
            console.log("error", error)
        }
    }
    const onClose = () => {
        setData([])
        handleClose()
    }
    useEffect(() => {
        if (order_id) getData()
    }, [order_id])
    const getVariation = (item) => {
        var variation = item.product.variation.find(el => el.id === item.variation.id)
        return variation
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.customer_modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <div className={classes.order_details_paper}>
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                cursor: 'pointer'
                            }}
                            onClick={() => onClose()}
                        >
                            <IconButton
                                style={{ width: '50px' }}
                                color="secondary"
                                onClick={() => onClose()}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography color="secondary">Close</Typography>
                        </div>
                        <Typography variant="h5">#{order_id} products</Typography>
                        <div style={{ marginTop: "15px" }}>
                            {data.map((el, i) => {
                                if (el.quantity > 0) {
                                    return (
                                        <div style={{ marginBottom: "10px" }} key={"order item " + i}>
                                            <div>
                                                <img src={el.product.image} style={{ height: "75px", width: "75px", objectFit: "contain" }} alt="" />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid lightgrey" }}>
                                                <div>
                                                    <div>
                                                        <Typography>{el.quantity}x {el.product.name}</Typography>
                                                        {el.variation ? <Typography style={{ marginLeft: "10px", color: "gray" }}>{getVariation(el).name}</Typography> : null}
                                                    </div>
                                                </div>
                                                <Typography> <b><Currency /> {el.variation ? getVariation(el).price.toFixed(2) : el.item_price?.toFixed(2)}</b></Typography>
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            })}
                        </div>
                    </>
                </div>
            </Fade>
        </Modal>
    );
}
