import IBaseAction from 'interfaces/redux';

interface ICurrency {
  currency: string;
}
export type GetState = () => any;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
export enum ETypesCurrency {
  SET = 'CURRENCY/SET'
}
export type ICurrencyAction = ISetAction;

export interface ISetAction extends IBaseAction<ETypesCurrency, null> {
  type: ETypesCurrency.SET;
}
export type PromiseAction = Promise<ICurrencyAction>;

type Dispatch = (action: ISetAction | ThunkAction | PromiseAction) => any;
const initialState: ICurrency = {
  currency: ''
};

/* Authentication Reducer. */
export default (
  state: ICurrency = initialState,
  action: {
    payload: string;
    type: ETypesCurrency;
  }
): ICurrency => {
  if (action.type === ETypesCurrency.SET) {
    return {
      ...state,
      currency: action.payload
    };
  }
  return state;
};

/* Authentication Side Effects Functions. */
export const setCurrency =
  (currency: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setCurrencyAction(currency));
  };

export const setCurrencyAction = (payload): ISetAction => ({
  type: ETypesCurrency.SET,
  payload
});
