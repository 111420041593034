import React from 'react';
import { IBalcony } from 'interfaces/balcony';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// import { v4 as uuidv4 } from 'uuid';
import { IProduction } from 'interfaces/production';
// import { useStyles } from './styles';
import Order from './Order';
import Production from './Production';
import { orderTypes } from './index';
import CancelIcon from '@material-ui/icons/Cancel';
import { PRIMARY_COLOR } from 'settings';
import './styles.css';
interface IProps {
  orders: IBalcony[];
  production: IProduction[];
  ingredients: IProduction[];
  activeOrders: string;
  handleActiveOrders: Function;
  orderType: orderTypes[];
  searchID: string;
  initialDate: Date;
  finalDate: Date;
  isFiltering: Boolean;
  filter: any;
  setSearchID: any;
  setInitialDate: any;
  setFinalDate: any;
  clearFilter: any;
  message: string;
}

export default function Panel(props: IProps): JSX.Element {
  const {
    orders,
    production,
    ingredients,
    activeOrders,
    // handleActiveOrders,
    // orderType,
    searchID,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    isFiltering,
    filter,
    setSearchID,
    clearFilter,
    message
  } = props;
  // const classes = useStyles();

  // const handleActiveOrder = (value: orderTypes) => {
  //   handleActiveOrders(value);
  // };

  const handleInitalDateChange = (date: Date) => {
    setInitialDate(date);
  };

  const handleFinalDateChange = (date: Date) => {
    setFinalDate(date);
  };

  const filterMessage = () => {
    const style = {
      color: PRIMARY_COLOR(),
      padding: '15px 0 0 0',
      fontWeight: 600
    };

    if (isFiltering) {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 5, fontSize: '0.6em', padding: '1x 16px' }}
            onClick={clearFilter}
          >
            <CancelIcon fontSize="small" style={{ marginRight: 5 }} />
            Clean filter
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 10 }}>
          <span style={style}>{message}</span>
        </div>
      );
    }
  };

  return (
    <Grid item container xs={12} sm={12} md={12} lg={12} direction="column">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 15,
          padding: 15,
          backgroundColor: '#f5f5f5',
          borderRadius: 5
        }}
      >
        {/* <Grid container style={{ marginBottom: 15 }}>
          {orderType.map((elmnt) => (
            <Button
              key={uuidv4()}
              variant="contained"
              color="primary"
              style={{ marginRight: 5 }}
              className={
                activeOrders === elmnt
                  ? `${classes.active} ${classes.btn}`
                  : `${classes.btn}`
              }
              onClick={() => handleActiveOrder(elmnt)}
            >
              {elmnt}
            </Button>
          ))}
        </Grid> */}
        <div className="column-to-row">
          <Grid
            style={{
              alignItems: 'center',
              display: 'inline-flex'
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="column-to-row">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Initial Date</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-initial"
                    value={initialDate}
                    placeholder="                ---------------"
                    onChange={handleInitalDateChange}
                    autoOk={true}
                    style={{ width: 189, marginRight: 5 }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Final Date</span>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-final"
                    margin="none"
                    value={finalDate}
                    onChange={handleFinalDateChange}
                    autoOk={true}
                    style={{ width: 189 }}
                  />
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </Grid>

          <div className="column-to-row input-and-filter">
            <FormControl>
              <Input
                id="my-input"
                value={searchID}
                type="default"
                onChange={(event) => setSearchID(event.target.value)}
                aria-describedby="my-helper-text"
                placeholder="Order number or client name"
                style={{ width: 300 }}
              />
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginLeft: 5,
                fontSize: '0.8em',
                padding: '5px 16px',
                width: '80px'
              }}
              onClick={filter}
            >
              Filter
            </Button>
          </div>
        </div>
        {filterMessage()}
      </div>
      {activeOrders === orderTypes.ENCOMENDAS && <Order orders={orders} />}
      {activeOrders === orderTypes.PRODUCAO && (
        <Production orders={production} />
      )}
      {activeOrders === orderTypes.COMPRAS && (
        <Production orders={ingredients} />
      )}
    </Grid>
  );
}
