import { makeStyles } from '@material-ui/core';
import { Grid, MobileStepper } from '@material-ui/core';
import { EMethod } from 'enums/method';
import { EAlertVariant } from 'interfaces/alert';
import { Product } from 'interfaces/stock';
import { IToken } from 'interfaces/authentication';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { successAuthentication } from 'redux/ducks/authentication';
import { sendAlert } from 'redux/ducks/alert';
import { fetch } from 'utils/request';
import CreateStore from './CreateStore';
import WelcomePage from './WelcomePage';
import CreateCategory from './CreateCategory';
import CreateProduct from './createProduct';
import CreateMenu from './CreateMenu';
import { Loading } from 'react-loading-dot';
import ShowMenu from './ShowMenu';
import moment from 'moment';
import CreateUserAddress from './CreateUserAddress';
import CreateOpenHours from './CreateOpenHours';
import CreateStoreAddress from './CreateStoreAddress';
import { editOpenHours } from 'redux/ducks/store';
import Logo from 'helpers/logo';

import { NETWORK, PRIMARY_COLOR } from 'settings';

//import AddressPage from './addAddress';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: '#ff5a23'
    },
    marginTop: '-85px'
  }
});

export default function Onboarding() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const localStore = localStorage.getItem('state');
  const state = JSON.parse(localStore);

  if (!state) {
    window.location.href = '/';
  }

  const { id } = state.authentication;
  const userId = id;

  const [welcomePage, setWelcomePage] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [store, setStore] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [uniqueSlugError, setUniqueSlugError] = useState(false);
  const [uniquePhoneError, setUniquePhoneError] = useState(false);
  const [uniqueEmailError, setUniqueEmailError] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [menu, setMenu] = useState({
    menu_id: 0,
    name: '',
    active: true,
    products: []
  });
  const [storeData, setStoreData] = useState({
    name: '',
    store_email: '',
    network: 0
  });
  const [userAddress, setUserAddress] = useState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSaveStore = async (data: any) => {
    try {
      setLoading(true);

      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      const response = await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/stores/`,
        data: body
      });

      setStore(response.id);

      dispatch(
        sendAlert(
          'Your store has been created successfully.',
          EAlertVariant.SUCCESS
        )
      );

      handleNext();
      setLoading(false);
    } catch (error) {
      console.log('error - ', error);
      if (typeof error === 'object') {
        Object.keys(error).forEach((field) => {
          switch (field) {
            case 'slug':
              setUniqueSlugError(true);
            case 'phone_number':
              setUniquePhoneError(true);
            case 'email':
              setUniqueEmailError(true);
            default:
          }
        });
        dispatch(
          sendAlert('Please check the errors below', EAlertVariant.ERROR)
        );
      } else {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
      setLoading(false);
    }
  };

  const handleSaveStoreAddress = async (data: any) => {
    try {
      setLoading(true);
      await fetch({
        method: EMethod.PATCH,
        headers: { 'content-type': 'application/json' },
        url: `products/api/stores/${store}/`,
        data: data
      });

      dispatch(
        sendAlert(
          'Store address has been created successfully',
          EAlertVariant.SUCCESS
        )
      );

      handleNext();
      setLoading(false);
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };

  const handleSaveUserAddress = async (data: any) => {
    setUserAddress(data);
    data.is_stripe_owner = true;
    try {
      setLoading(true);

      await fetch({
        method: EMethod.POST,
        url: `users/api/address/`,
        data
      });

      await fetch({
        method: EMethod.GET,
        url: `users/api/clients/check_stripe_accounts?network=${NETWORK}&user_id=${userId}`
      });

      dispatch(
        sendAlert(
          'Your address was successfully created',
          EAlertVariant.SUCCESS,
          3000
        )
      );
      setLoading(false);

      handleNext();
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };

  const handleSaveOpenHours = async (days: any) => {
    var daysOfTheWeek = {
      id: store,
      monday: {
        opened: days[0].closedAllDAy
          ? '00:00'
          : moment(days[0].opened).format('HH:mm'),
        closed: days[0].closedAllDAy
          ? '00:00'
          : moment(days[0].closed).format('HH:mm'),
        closed_all_day: days[0].closed_all_day
      },
      tuesday: {
        opened: days[1].closedAllDAy
          ? '00:00'
          : moment(days[1].opened).format('HH:mm'),
        closed: days[1].closedAllDAy
          ? '00:00'
          : moment(days[1].closed).format('HH:mm'),
        closed_all_day: days[1].closed_all_day
      },
      wednesday: {
        opened: days[2].closedAllDAy
          ? '00:00'
          : moment(days[2].opened).format('HH:mm'),
        closed: days[2].closedAllDAy
          ? '00:00'
          : moment(days[2].closed).format('HH:mm'),
        closed_all_day: days[2].closed_all_day
      },
      thursday: {
        opened: days[3].closedAllDAy
          ? '00:00'
          : moment(days[3].opened).format('HH:mm'),
        closed: days[3].closedAllDAy
          ? '00:00'
          : moment(days[3].closed).format('HH:mm'),
        closed_all_day: days[3].closed_all_day
      },
      friday: {
        opened: days[4].closedAllDAy
          ? '00:00'
          : moment(days[4].opened).format('HH:mm'),
        closed: days[4].closedAllDAy
          ? '00:00'
          : moment(days[4].closed).format('HH:mm'),
        closed_all_day: days[4].closed_all_day
      },
      saturday: {
        opened: days[5].closedAllDAy
          ? '00:00'
          : moment(days[5].opened).format('HH:mm'),
        closed: days[5].closedAllDAy
          ? '00:00'
          : moment(days[5].closed).format('HH:mm'),
        closed_all_day: days[5].closed_all_day
      },
      sunday: {
        opened: days[6].closedAllDAy
          ? '00:00'
          : moment(days[6].opened).format('HH:mm'),
        closed: days[6].closedAllDAy
          ? '00:00'
          : moment(days[6].closed).format('HH:mm'),
        closed_all_day: days[6].closed_all_day
      }
    };

    await dispatch(editOpenHours(daysOfTheWeek, store)).then(() => {
      handleNext();
    });
  };

  const handleSaveCategory = async (data: any) => {
    try {
      setLoading(true);

      await fetch({
        method: EMethod.POST,
        url: `products/api/categories/`,
        data
      });
      // setCategoryId(response.id);
      dispatch(
        sendAlert(
          'Your categories were successfully created.',
          EAlertVariant.SUCCESS
        )
      );
      setLoading(false);

      handleNext();
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };
  const handleSaveProduct = async (data: any) => {
    try {
      setLoading(true);
      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      const response = await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/products/`,
        data: body
      });
      dispatch(
        sendAlert(
          'Your product was successfully created.',
          EAlertVariant.SUCCESS
        )
      );
      const x = [...products];
      x.push(response);
      setProducts(x);
      handleNext();
      setLoading(false);
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };
  const handleSaveMenu = async (data: any) => {
    try {
      setLoading(true);
      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/menus/`,
        data: body
      }).then(async (response) => {
        setMenu(response);
        dispatch(
          sendAlert('Your menu was successfully created', EAlertVariant.SUCCESS)
        );
        handleNext();
        setLoading(false);
      });

      //setMenu(response);
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };
  const finish = async () => {
    setLoading(true);
    const store: string = localStorage.getItem('state');
    const state = JSON.parse(store);
    const {
      is_superuser,
      is_manager,
      is_waiter_manager,
      is_waiter,
      is_store_owner,
      token,
      id,
      email,
      exp,
      name,
      phone,
      profile,
      account_stripe_KYC_status
    } = state.authentication;

    const payload: IToken = {
      token,
      name,
      phone,
      id,
      is_superuser,
      is_manager,
      is_store_owner,
      is_waiter,
      is_waiter_manager,
      new_user: false,
      email,
      exp,
      stripe_profiles: {
        network_id: 0,
        account_stripe_link: '',
        account_connect_status: 'Enabled',
        stripe_login_link: ''
      },
      account_stripe_KYC_status: account_stripe_KYC_status
    };
    if (profile) {
      payload.stripe_profiles = profile.stripe_profiles;
    }
    await handleUpdateClient();
    setLoading(false);
    dispatch(successAuthentication(payload));
    localStorage.setItem('firstAccess', 'true');
    window.location.href = '/finish-onboarding';
  };
  /* const handleSaveAddress = async (data: any) => {
    try {
      setLoading(true);
      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      setLoading(false);
      dispatch(
        sendAlert('Your store was successfully created', EAlertVariant.SUCCESS)
      );
      setLoading(false);

      handleNext();
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  }; */

  const handleUpdateClient = async () => {
    const store: string = localStorage.getItem('state');
    const state = JSON.parse(store);
    const { id } = state.authentication;
    await fetch({
      method: EMethod.PATCH,
      url: `users/api/clients/${id}/`,
      data: {
        profile: {
          is_onboarding: false
        }
      }
    });
  };
  /*const handleResetPassword = async (token, uid, password) => {
    await fetch({
      method: EMethod.POST,
      url: `/users/api/clients/realtime_password_reset/`,
      data: {
         uid: uid,
          token: token,
          password: password,
          confirm_password: password
      }
    });
  };  
    const handleSaveVendor = async (data: any) => {
    try {
      setLoading(true);

      await fetch({
        method: EMethod.POST,
        url: `waiter/api/waiter/`,
        data
      });
      const store: string = localStorage.getItem('state');
      const state = JSON.parse(store);
      const {
        is_superuser,
        is_manager,
        is_waiter_manager,
        is_waiter,
        is_store_owner,
        token,
        id,
        email,
        exp,
        name,
        phone,
        account_stripe_KYC_status
      } = state.authentication;

      const payload: IToken = {
        token,
        phone,
        name,
        id,
        is_superuser,
        is_manager,
        is_store_owner,
        is_waiter,
        is_waiter_manager,
        new_user: false,
        email,
        exp,
        stripe_profiles: {
          network_id: 0,
          account_stripe_link: '',
          account_connect_status: 'Enabled',
          stripe_login_link: ''
        },
        account_stripe_KYC_status: account_stripe_KYC_status
      };
      //  await handleResetPassword(token, id, data.password);
      await handleUpdateClient();
      dispatch(successAuthentication(payload));
      setLoading(false);
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      setLoading(false);
    }
  };
  */

  return (
    <div>
      <Grid
        style={{
          width: '250px',
          height: '120px',
          marginLeft: '40px',
          marginTop: '33px'
        }}
      >
        <Logo styling={{ width: '100%', height: '100%' }} />
      </Grid>
      {loading ? (
        <Loading size="1rem" background={PRIMARY_COLOR()} />
      ) : (
        <>
          {' '}
          {welcomePage && (
            <WelcomePage
              done={() => {
                setWelcomePage(false);
              }}
            />
          )}
          {!welcomePage && (
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MobileStepper
                variant="dots"
                steps={8}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                backButton={<div></div>}
                nextButton={<div></div>}
              />
              {activeStep === 0 && (
                <CreateUserAddress
                  handleSave={(data) => handleSaveUserAddress(data)}
                />
              )}
              {activeStep === 1 && (
                <CreateStore
                  store_id={store}
                  uniquePhoneError={uniquePhoneError}
                  uniqueSlugError={uniqueSlugError}
                  uniqueEmailError={uniqueEmailError}
                  handleSave={(data) => {
                    setStoreData(data);
                    handleSaveStore(data);
                  }}
                />
              )}
              {activeStep === 2 && (
                <CreateStoreAddress
                  handleSave={(data) => handleSaveStoreAddress(data)}
                  userAddress={userAddress}
                />
              )}
              {activeStep === 3 && (
                <CreateOpenHours
                  storeData={storeData}
                  handleSave={(data) => handleSaveOpenHours(data)}
                />
              )}
              {activeStep === 4 && (
                <CreateCategory
                  handleSave={handleSaveCategory}
                  store={store}
                  handleChangeCategory={(name: string) => {
                    setCategoryName(name);
                  }}
                />
              )}

              {activeStep === 5 && (
                <CreateProduct
                  handleSave={handleSaveProduct}
                  store={store}
                  categoryName={categoryName}
                />
              )}
              {activeStep === 6 && (
                <CreateMenu
                  handleSave={handleSaveMenu}
                  store={store}
                  categoryName={categoryName}
                />
              )}
              {activeStep === 7 && (
                <ShowMenu
                  menu={menu}
                  store={store}
                  handleSave={() => finish()}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </div>
  );
}
