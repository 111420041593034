import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { ITable } from './table';

export interface merchant_items {
  index: number;
  id: number;
  name: string;
  quantity: number;
}

export interface merchant_delivery_address {
  streetName: string;
  streetNumber: string;
  neighborhood: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  formattedAddress: string;
}

export interface IKitchen {
  discount: number;
  order_id: number;
  delivery_tracking_number:string;
  delivery_company: string;
  sub_total: number;
  type_of_order: string;
  store_name: string;
  store_id: number;
  table_code: string;
  vat_total: number;
  pi: string;
  pi_status: string;
  stuart_tracking_url: string;
  stuart_delivery_status: string;
  items: [
    {
      item_price: number;
      quantity: number;
      flavors: [
        {
          id: number;
          name: string;
        }
      ];
      product: {
        double?: boolean;
        half?: boolean;
        product_id: number;
        name: string;
        price: number;
        ingredients: [
          {
            ingredient_id: number;
            name: string;
            quantity: string;
            cost: number;
            measure: string;
          }
        ];
        image?: string;
        extras?: [
          {
            id: number;
            ingredient: string;
            limit: number;
            price: string;
          }
        ];
        colour_hex: string;
        artist: string;
        material: string;
      };
      variation: {
        id: number;
        name: string;
        barcode: string;
        sku: string;
        price: number;
        stock: number;
        cost: number;
        product: number;
      };
      size: {
        id: number;
        portion: string;
        serves: number;
        price: number;
        product: number;
      };
      comment: string;
      extra_orders?: [
        {
          id: number;
          ingredient: string;
          quantity: number;
          price: number;
          measure: string;
          measure_quantity: number;
        }
      ];
    }
  ];
  order_code: string;
  status: string;
  last_status?: string;
  payment: string;
  cash_change: string;
  cash_paid: string;
  is_paid: boolean;
  delivery_address: string;
  billing_address: string;
  store_to_collect: string;
  table: ITable;
  driver: {
    id: number;
    name: string;
    phone_number: string;
  };
  gomotoboy_driver_name: string;
  final_comment: string;
  client: {
    id: number;
    address: {
      address_id: number;
      name?: string;
      street: string;
      street_number: string;
      neighborhood: string;
      city?: string;
      state?: string;
      zipcode?: string;
      active: boolean;
    };
    name: string;
    email: string;
    phone: string;
    cpf: string;
  };
  owner: {
    profile_id: number;
    name: string;
    phone_number: string;
    cpf: string;
    user:{
      email:string
    }
  };
  date_ordered: string;
  date_delivery: string;
  doing_at: string;
  done_at: string;
  depot_at: string;
  transit_at?: string;
  delivered_at?: string;
  pagarme_id?: string;
  finished_at: string;
  canceled_at: string;
  delivery_fee: number;
  customer_service_fee: number;
  total_order: number;
  set_driver_qr: string;
  merchant_customer_cpf: string;
  merchant_customer_name: string;
  merchant_delivery_address: string;
  merchant_id: string;
  merchant_identifier: string;
  merchant_items: merchant_items[];
  merchant_order_id: number;
  merchant_order_reference: string;
}

export interface IKitchenOnlyRequired {
  type_of_order: string;

  date_delivery: string;
}

export interface IKitchenState {
  fetch: boolean;
  count: number;
  next?: string;
  currentOrderID: number;
  previous?: string;
  results: IKitchen[];
  todo: IKitchen[];
  doing: IKitchen[];
  done: IKitchen[];
  baking: IKitchen[];
  depot: IKitchen[];
  transit: IKitchen[];
  delivered: IKitchen[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesKitchen {
  FETCH = 'KITCHEN/FETCH',
  SUCCESS = 'KITCHEN/SUCCESS',
  FAILURE = 'KITCHEN/FAILURE',
  SET = 'KITCHEN/SET',
  UNSET = 'KITCHEN/UNSET',
  CURRENT_ORDER_ID = 'KITCHEN/CURRENT_ORDER_ID',
  UPDATE_ORDER = 'KITCHEN/UPDATE_ORDER',
  RESET_FETCH = 'KITCHEN/RESET_FETCH'
}

export interface IFetchAction extends IBaseAction<ETypesKitchen, null> {
  type: ETypesKitchen.FETCH;
}

export interface IResetFetchAction extends IBaseAction<ETypesKitchen, null> {
  type: ETypesKitchen.RESET_FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesKitchen, IKitchenState> {
  type: ETypesKitchen.SUCCESS;
  payload: IKitchenState;
}

export interface ICurrentOrderIDAction
  extends IBaseAction<ETypesKitchen, number> {
  type: ETypesKitchen.CURRENT_ORDER_ID;
  payload: number;
}

export interface ISetAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.SET;
  payload: IKitchen;
}

export interface IUnsetAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.UNSET;
  payload: IKitchen;
}

export interface IFailureAction extends IBaseAction<ETypesKitchen, string> {
  type: ETypesKitchen.FAILURE;
  payload: string;
}

export interface IUpdateAction extends IBaseAction<ETypesKitchen, IKitchen> {
  type: ETypesKitchen.UPDATE_ORDER;
  payload: IKitchen;
}

export type IKitchenAction =
  | IFetchAction
  | ISuccessAction
  | ICurrentOrderIDAction
  | ISetAction
  | IUnsetAction
  | IFailureAction
  | IUpdateAction
  | IResetFetchAction;

export type GetState = () => any;

export type PromiseAction = Promise<IKitchenAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IKitchenAction
    | ThunkAction
    | PromiseAction
    | Array<IKitchenAction>
    | IAlertAction
) => any;
