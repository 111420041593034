export enum EProductionStatus {
  TODO = 'A Fazer',
  DOING = 'Fazendo',
  DONE = 'Feito'
}

export enum EKitchenStatus {
  TODO = 'A fazer',
  DOING = 'Fazendo',
  DONE = 'Feito',
  DEPOT = 'Depot',
  BAKING = 'Baking',
  TRANSIT = 'Transit',
  COMPLETED = 'Completed',
  DELIVERED = 'Delivered',
  CANCELLED = 'Canceled',
  CANCELLED_REFUNDED = 'Canceled and Refunded'
}

export enum EBalconyStatus {
  TODO = 'To do',
  DOING = 'Doing',
  DONE = 'Done',
  TRANSIT = 'Transit',
  COMPLETED = 'Completed',
  CANCELLED = 'Canceled',
  CANCELLED_REFUNDED = 'Canceled and Refunded'
}

export enum EOrderType {
  'M' = 'Table',
  'D' = 'Delivery',
  'C' = 'Collection',
  'O-M' = 'Table',
  'O-D' = 'Delivery',
  'O-C' = 'Collection',
  'O-E' = 'eDelivery',
  'BC-D' = 'Bread Club Delivery',
  'BC-C' = 'Bread Club Collection'
}
