import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBalcony } from 'redux/ducks/balcony';
// import { getProduction } from 'redux/ducks/production';
import { IProductionState } from 'interfaces/production';

import IStore from 'interfaces/store';
import { IBalconyState } from 'interfaces/balcony';
import { EBalconyStatus } from 'enums/order';

import Grid from '@material-ui/core/Grid';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import BeatLoader from 'react-spinners/BeatLoader';
import { getProduction } from 'redux/ducks/production';
import moment from 'moment';

export enum orderTypes {
  ENCOMENDAS = 'ORDERS',
  PRODUCAO = 'PRODUCTION',
  COMPRAS = 'PURCHASES'
}

const orderType = [
  orderTypes.ENCOMENDAS,
  orderTypes.PRODUCAO,
  orderTypes.COMPRAS
];

export default function Balcony(): JSX.Element {
  const dispatch = useDispatch();
  const [more, setMore] = useState(false);
  const [activeOrders, setActiveOrders] = useState(orderTypes.ENCOMENDAS);
  const [searchID, setSearchID] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(new Date());
  const [isFiltering, setIsFiltering] = useState(false);
  const [isStarting, setIsStarting] = useState(true);
  const [message, setMessage] = useState('Show all orders');
  const orders = useSelector<IStore, IBalconyState>((state) => state.balcony);
  const production = useSelector<IStore, IProductionState>(
    (state) => state.production
  );

  const fetchData = () => {
    if (activeOrders === orderTypes.ENCOMENDAS) dispatch(getBalcony());
    else if (activeOrders === orderTypes.PRODUCAO)
      dispatch(getProduction({ order_type: 'Produto' }));
    else if (activeOrders === orderTypes.COMPRAS)
      dispatch(getProduction({ order_type: 'Insumo' }));
  };

  const clearFilter = () => {
    setIsFiltering(false);
    setInitialDate(null);
    setFinalDate(new Date());
    setSearchID('');
    setMessage('Show all orders');
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isStarting) clearFilter();
    else setIsStarting(false);
  }, [activeOrders]);

  const getMore = async () => {
    setMore(true);
    if (activeOrders === orderTypes.ENCOMENDAS)
      await dispatch(getBalcony(orders.next));
    setMore(false);
    // console.log('teste')
  };

  const handleActiveOrders = (value: orderTypes) => {
    setActiveOrders(value);
  };

  const filter = () => {
    const _initialDate = initialDate
      ? moment(initialDate).format('YYYY-MM-DD')
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format('YYYY-MM-DD')
      : null;

    if (activeOrders === orderTypes.ENCOMENDAS)
      dispatch(
        getBalcony(null, {
          initial_date: _initialDate,
          final_date: _finalDate,
          search: searchID
        })
      );
    else if (activeOrders === orderTypes.PRODUCAO)
      dispatch(
        getProduction({
          order_type: 'Produto',
          initial_date: _initialDate,
          final_date: _finalDate,
          search: searchID
        })
      );
    else if (activeOrders === orderTypes.COMPRAS)
      dispatch(
        getProduction({
          order_type: 'Insumo',
          initial_date: _initialDate,
          final_date: _finalDate,
          search: searchID
        })
      );
    setIsFiltering(true);
    const _message = `Showing orders from ${
      initialDate ? moment(initialDate).format('DD/MM/YYYY') : 'Initial date'
    } to ${moment(finalDate).format('DD/MM/YYYY')} ${
      searchID ? `contain '${searchID}'` : ''
    }`;
    setMessage(_message);
  };

  function loadMore(): JSX.Element {
    if (
      activeOrders === orderTypes.ENCOMENDAS &&
      orders.next &&
      !orders.fetch &&
      !more
    )
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            cursor: 'pointer'
          }}
          onClick={getMore}
        >
          Load more
        </div>
      );
    else return null;
  }

  return (
    <Grid container>
      <Loading show={orders.fetch || production.fetch} />
      {!orders.fetch && !production.fetch && (
        <Panel
          activeOrders={activeOrders}
          handleActiveOrders={handleActiveOrders}
          searchID={searchID}
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          isFiltering={isFiltering}
          filter={filter}
          setSearchID={setSearchID}
          clearFilter={clearFilter}
          message={message}
          orderType={orderType}
          orders={orders.results.filter(
            (order) => order.status === EBalconyStatus.COMPLETED
          )}
          production={production.results.filter(
            (order) =>
              order.status === EBalconyStatus.COMPLETED &&
              order.order_type === 'Produto'
          )}
          ingredients={production.results.filter(
            (order) =>
              order.status === EBalconyStatus.COMPLETED &&
              order.order_type === 'Insumo'
          )}
        />
      )}
      {loadMore()}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <BeatLoader size={15} color={'#123abc'} loading={more} />
      </div>
    </Grid>
  );
}
