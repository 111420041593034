import React from 'react';
import {
  Button,
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  FormHelperText,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { createProvider, fetchProviders } from 'redux/ducks/stock';
import { useEffect } from 'react';
import { fetch } from 'utils/request';
import { EMethod } from 'enums/method';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [providerPricing, setProviderPricing] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState();
  const [storeError, setStoreError] = React.useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    onClose();
  };
  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert a name');
      entered = true;
    }
    if (phone === '') {
      setPhoneError('Please insert a phone number');
      entered = true;
    }
    if (store === null || store === '' || store === undefined) {
      setStoreError('Please insert store');
      entered = true;
    }
    return entered;
  };

  const handleName = (event: any) => {
    setName(event.target.value);
    setNameError('');
  };
  const handleStore = (event: any) => {
    setStore(event);
    setStoreError('');
  };

  const handleProviderPricing = (event: any) => {
    setProviderPricing(event.target.value);
  };
  const handlePhone = (event: any) => {
    setPhone(event.target.value);
    setPhoneError('');
  };
  const _getStores = async () => {
    try {
      const response = await fetch({
        method: EMethod.GET,
        url: '/products/api/stores/'
      });
      setStores(response.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    _getStores();
  }, []);

  const submitCreation = async () => {
    if (!checkForm()) {
      console.log({
        name,
        phone,
        // provider_pricing: providerPricing,
        store
      });
      const x = await dispatch(
        createProvider({
          name,
          phone,
          provider_pricing: providerPricing,
          store
        })
      );
      if (x === 1) {
        handleClose();
        dispatch(fetchProviders());
      }
    }
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>

        <h2>Add Provider</h2>
        <Container maxWidth="md">
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={nameError !== ''}
            >
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={handleName}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Provider Price</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={providerPricing}
                onChange={handleProviderPricing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required
              error={phoneError !== ''}
            >
              <InputLabel htmlFor="name-input">Phone Number</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={phone}
                onChange={handlePhone}
              />
              <FormHelperText>{phoneError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={storeError !== ''}
            >
              <InputLabel htmlFor="name-input">Store</InputLabel>
              <Select
                labelId="label"
                id="select-template"
                value={store}
                onChange={(e) => handleStore(e.target.value)}
              >
                {stores.map((element) => {
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{storeError}</FormHelperText>
            </FormControl>
          </Grid>

          {/* End of left column */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                {' '}
                Create{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
