import React from 'react';
import {
    Grid, Typography, FormControl, Input, InputLabel, TextField,
    FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import allCountries from 'components/Onboarding/countryList';

interface props {
    deliveryError: {
        street: string,
        street_number: string
        neighborhood: string,
        city: string,
        state: string,
        country: string,
        zipcode: string,
    },
    delivery: {
        street: string,
        street_number: string
        neighborhood: string,
        city: string,
        state: string,
        country: string,
        zipcode: string,
    },
    deliveryCountryQuery: string,
    setDelivery: (any) => void,
    setDeliveryCountryQuery: (any) => void,
}
export default function DeliveryForm(props: props) {
    var { delivery, deliveryError, setDelivery, setDeliveryCountryQuery, deliveryCountryQuery } = props
    return (
        <Grid sm={12} md={10} lg={10} xs={10} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "20px" }}>
            <Typography>Delivery address</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <FormControl
                        style={{ marginBottom: '1rem', width: '100%' }}
                        required
                        error={deliveryError.street !== "" && (delivery.street == "" || delivery.street == null)}
                    >
                        <InputLabel htmlFor="name-input">Street and number</InputLabel>
                        <Input
                            id="name-input"
                            type="string"
                            value={delivery.street ? delivery.street : ""}
                            onChange={(e) => {
                                setDelivery({ ...delivery, street: e.target.value })
                            }}
                        />
                        {deliveryError.street !== "" && (delivery.street == "" || delivery.street == null) ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FormControl
                        style={{ marginBottom: '1rem', width: '100%' }}
                        required
                        error={deliveryError.city !== "" && (delivery.city == "" || delivery.city == null)}

                    >
                        <InputLabel htmlFor="name-input">City</InputLabel>
                        <Input
                            id="name-input"
                            type="string"
                            value={delivery.city ? delivery.city : ""}
                            onChange={(e) => {
                                setDelivery({ ...delivery, city: e.target.value })
                            }}
                        />
                        {deliveryError.city !== "" && (delivery.city == "" || delivery.city == null) ? <FormHelperText>This field cannot be empty</FormHelperText> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FormControl
                        style={{ marginBottom: '1rem', width: '100%' }}
                        error={deliveryError.neighborhood !== "" && (delivery.neighborhood == "" || delivery.neighborhood == null)}

                    >
                        <InputLabel htmlFor="name-input">Neighbourhood</InputLabel>
                        <Input
                            id="name-input"
                            type="string"
                            value={delivery.neighborhood ? delivery.neighborhood : ""}
                            onChange={(e) => {
                                setDelivery({ ...delivery, neighborhood: e.target.value })
                            }}
                        />
                        {deliveryError.neighborhood !== "" && (delivery.neighborhood == "" || delivery.neighborhood == null) ? <FormHelperText>This field cannot be empty</FormHelperText> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FormControl
                        style={{ marginBottom: '1rem', width: '100%' }}
                        error={deliveryError.state !== "" && (delivery.state == "" || delivery.state == null)}
                    >
                        <InputLabel htmlFor="name-input">State/county</InputLabel>
                        <Input
                            id="name-input"
                            type="string"
                            value={delivery.state ? delivery.state : ""}
                            onChange={(e) => {
                                setDelivery({ ...delivery, state: e.target.value })
                            }}
                        />
                        {deliveryError.state !== "" && (delivery.state == "" || delivery.state == null) ? <FormHelperText>This field cannot be empty</FormHelperText> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={4} sm={12} md={4} lg={3}>
                    <FormControl
                        style={{ marginBottom: '1rem', width: '100%' }}
                        required
                        error={deliveryError.zipcode !== "" && (delivery.zipcode == "" || delivery.zipcode == null)}

                    >
                        <InputLabel htmlFor="name-input">Postal code</InputLabel>
                        <Input
                            id="name-input"
                            type="string"
                            value={delivery.zipcode ? delivery.zipcode : ""}
                            onChange={(e) => {
                                setDelivery({ ...delivery, zipcode: e.target.value })
                            }} />
                        {deliveryError.zipcode !== "" && (delivery.zipcode == "" || delivery.zipcode == null) ? <FormHelperText>This field cannot be empty</FormHelperText> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <FormControl
                        style={{
                            marginTop: '0px',
                        }}
                        margin={'normal'}
                        fullWidth
                        required
                        error={deliveryError.country !== ""}
                    >
                        <Autocomplete
                            value={delivery.country}

                            onChange={(event: any, newValue: string | null) => {
                                setDelivery({ ...delivery, country: newValue })
                            }}
                            inputValue={deliveryCountryQuery}
                            onInputChange={(event, newInputValue) => {
                                setDeliveryCountryQuery(newInputValue);
                            }}
                            id="country-autocomplete"
                            options={allCountries}
                            getOptionLabel={(option) => {
                                if (option["name"] !== undefined) {
                                    return option["name"];
                                } else return '';
                            }}
                            renderOption={(option) =>
                                option['name'] !== undefined ? option['name'] : ''
                            }
                            loadingText={'Loading countries'}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" />
                            )}
                        />
                        {deliveryError.country !== "" && !delivery.country ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}