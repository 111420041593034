import {
  IHistory,
  IHistoryState,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  ISearchAction,
  IHistoryAction,
  Dispatch,
  ThunkAction,
  ETypesHistory,
  IFirstLoadAction,
  GetState
} from 'interfaces/history';

import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from 'utils/query';
import { NETWORK } from 'settings';

/* History State. */
const initialState: IHistoryState = {
  fetch: false,
  count: 0,
  results: [],
  next: '',
  previous: '',
  error: ''
};

/* History Reducer. */
export default (
  state: IHistoryState = initialState,
  action: IHistoryAction
): IHistoryState => {
  switch (action.type) {
    case ETypesHistory.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesHistory.FIRSTLOAD:
      return {
        ...state,
        fetch: false,
        count: action.payload.count,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesHistory.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesHistory.SEARCH:
      return {
        ...state,
        fetch: false,
        count: 0,
        results: [action.payload],
        next: '',
        previous: '',
        error: ''
      };
    case ETypesHistory.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* History Action Creators Functions. */
export const fetchHistory = (): IFetchAction => ({
  type: ETypesHistory.FETCH
});

export const successHistory = (payload: IHistoryState): ISuccessAction => ({
  type: ETypesHistory.SUCCESS,
  payload
});

export const firstLoadHistory = (payload: IHistoryState): IFirstLoadAction => ({
  type: ETypesHistory.FIRSTLOAD,
  payload
});

export const failureHistory = (payload: string): IFailureAction => ({
  type: ETypesHistory.FAILURE,
  payload
});

export const searchHistory = (payload: IHistory): ISearchAction => ({
  type: ETypesHistory.SEARCH,
  payload
});

/* History Side Effects Functions. */
export const getHistory =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const { history } = getState();
      let request_url;
      dispatch(fetchHistory());
      const query = params
        ? `${mountQueryURL(params)}&network=${NETWORK}`
        : `?network=${NETWORK}`;
      request_url = `/waiter/api/waiter/get_orders_network/${query}`;
      const response = await fetch({
        method: EMethod.GET,
        url: request_url
      });
      let results: IHistory[] = [];
      results.push(...response);
      dispatch(successHistory({ ...history, results: results }));
    } catch (error) {
      console.log(error);
      dispatch(failureHistory(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createOrder =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      let request_url;
      request_url = `/orders/api/orders/`;
      await fetch({
        method: EMethod.POST,
        url: request_url,
        data
      });
      dispatch(sendAlert('Invoice created successfuly', EAlertVariant.SUCCESS));
      return 1;
    } catch (error) {
      dispatch(failureHistory(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return 0;
    }
  };

export const searchHistoryByID =
  (id: string): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await fetch({
        method: EMethod.GET,
        url: `orders/api/orders_History_resumed/${id}/get_order/`
      });
      dispatch(searchHistory(response));
    } catch (error) {
      dispatch(failureHistory(error));
    }
  };
