import React, { useEffect, useState } from 'react';
import { fetchArtist } from 'redux/ducks/stock'
import ArtistsTable from './ArtistsTable'
export default function Artists(): JSX.Element {
    const [artists, setArtists] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const getArtists = async () => {
        fetchArtist({ page: page }).then((response: any) => {
            setArtists(oldArray => [...oldArray, ...response.results]);
            if (response.next) setHasMore(true)
            else setHasMore(false)
        })

    }
    useEffect(() => {
        getArtists()
    }, [page])
    return (
        <>
            <ArtistsTable refresh={() => getArtists()} artists={artists} getMore={() => setPage(page + 1)} hasMore={hasMore} />
        </>
    );
}
