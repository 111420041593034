import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IKitchen } from 'interfaces/kitchen';
import { Button, FormControl, FormHelperText, Input, Select, MenuItem, InputLabel } from '@material-ui/core';
import { addTrackingNumber } from 'redux/ducks/kitchen';
import { useDispatch } from 'react-redux';
// import Typography from '@material-ui/core/Typography';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { pdf } from '@react-pdf/renderer';
import PetshopReceipt from 'helpers/Receipt/petshopReceitPDF';
import MangelReceipt from 'helpers/Receipt/mangelReceiptPDF';
import { sendPDF } from 'redux/ducks/kitchen';
import { NETWORK } from 'settings';
export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    order: IKitchen;
}

export default function TrakingNumberModal(props: SimpleDialogProps) {
    const { onClose, open, order } = props;
    const [tracking, setTracking] = useState("")
    const [selectedCompany, setSelectedCompany] = useState<any>("")
    const companies = ['OTHER', 'DHL', 'HERMESDE', 'UPS', 'FEDEX', 'ROYALMAIL']
    const [error, setError] = useState(false)
    const [companyError, setCompanyError] = useState(false)
    const handleClose = () => {
        setTracking("")
        setSelectedCompany("")
        onClose();
    };
    useEffect(() => {
        if (open && order.delivery_tracking_number) {
            setTracking(order.delivery_tracking_number)
        }
        if (open && order.delivery_company) {
            setSelectedCompany(order.delivery_company)
        }
    }, [open])
    const dispatch = useDispatch()
    const generateOrderPDF = async (order) => {

        const doc = (
            <>
                {NETWORK === "6" && <PetshopReceipt order={order} />}
                {NETWORK === "15" && <MangelReceipt order={order} />}
            </>
        );
        await pdf(doc)
            .toBlob()
            .then((blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    sendPDF(base64data, order.order_id)
                };
            })
            .catch((e) => {
                console.log(e);
            });



    }
    const submit = async () => {
        try {
            if (tracking === "") {
                setError(true)
                return
            }
            if (selectedCompany === "") {
                setCompanyError(true)
                return
            }
            addTrackingNumber(order, { delivery_tracking_number: tracking, delivery_company: selectedCompany }).then(
                () => {
                    generateOrderPDF({ ...order, delivery_tracking_number: tracking, delivery_company: selectedCompany })
                    dispatch(sendAlert("Tracking number successfully added", EAlertVariant.SUCCESS))
                    handleClose();
                }
            ).catch(e => {
                dispatch(sendAlert("Somethin went wrong", EAlertVariant.ERROR))

            })
        } catch (error) {

        }
    };
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            style={{ minWidth: "40vw" }}
        >
            <DialogTitle id="simple-dialog-title">
                Order: {order?.order_code}
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center', padding: "10px", flexDirection: "column", alignItems: "center" }}>
                <FormControl error={error && tracking === ""} fullWidth>
                    <Input
                        value={tracking}
                        onChange={(e) => setTracking(e.target.value)}
                        placeholder='Tracking number'
                    />
                    {error && tracking === "" && <FormHelperText>The tracking number is required</FormHelperText>}

                </FormControl>
                <FormControl error={error && tracking === ""} fullWidth>
                    <InputLabel htmlFor="stock-input">Delivery company</InputLabel>

                    <Select
                        labelId="label"
                        label="Delivery company"
                        id="select-template"
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                    >
                        {companies.map((element) => {
                            return (
                                <MenuItem key={element} value={element}>
                                    {element}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {companyError && selectedCompany === "" && <FormHelperText>The delivery company is required</FormHelperText>}

                </FormControl>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClose} color="primary">
                    {' '}
                    Cancel
                </Button>
                <Button onClick={submit}> Save </Button>
            </div>
        </Dialog>
    );
}
