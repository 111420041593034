import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { Button } from '@material-ui/core';
import { getTable, getTableOrder } from 'redux/ducks/table';
import { ITableState } from 'interfaces/table';
import { v4 as uuidv4 } from 'uuid';
import { SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';
import { useStyles } from './styles';
import { ESocketStatus } from 'enums/socket';

enum ETableFilter {
  ACTIVE_TABLES = 'ACTIVE TABLES',
  CLOSED_TABLES = 'CLOSED TABLES',
}

const tablesFilter = [ETableFilter.ACTIVE_TABLES, ETableFilter.CLOSED_TABLES];

export default function Table() {
  const dispatch = useDispatch();
  const [activeTableFilter, setActiveTableFilter] = React.useState('ACTIVE TABLES')
  const { fetch, results } = useSelector<IStore, ITableState>(
    (state) => state.table
  );
  const [online, setOnline] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => setOnline(true);

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data)
      if (new_data.message.table) {
        if (activeTableFilter === 'ACTIVE TABLES') {
          dispatch(getTable())
        }
        else {
          dispatch(getTableOrder());
        }
      }
    }

    kitchenSocket.onerror = () => kitchenSocket.close();

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, activeTableFilter]);

  const handleActiveTableFilter = (value: string) => {
    setActiveTableFilter(value);
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    // const d = document.querySelectorAll<HTMLElement>('.d-table');
    // d.item(0).style.color = '#ff5a23';

    // d.item(1).style.color = '#ff5a23';

  });

  useEffect(() => {
    if (activeTableFilter === ETableFilter.ACTIVE_TABLES) {
      dispatch(getTable());
    } else {
      dispatch(getTableOrder());
    }
  }, [dispatch, activeTableFilter]);

  return (
    <>
      <Loading show={fetch} />
      <Grid item container direction="row">
        {tablesFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            variant="outlined"
            onClick={() => {
              handleActiveTableFilter(elmnt);
            }}
            color="primary"
            size="small"
            className={activeTableFilter === elmnt ? classes.activeButton : classes.button}
          >
            {elmnt}
          </Button>
        ))}

        
      </Grid>
      {!fetch && results.length === 0 && (
        <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
          Currently there's no tables.
        </Typography>
      )}
      <Grid container spacing={3}>
        {results.map((batch) => (
          <Panel key={uuidv4()} batch={batch} />
        ))}
      </Grid>
    </>
  );
}
