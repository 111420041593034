import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
      position: 'fixed',
      top: theme.spacing(8.1),
      left: theme.spacing(2),
      zIndex:4000,
      width: '100%',
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: 'rgba(18, 76, 77,0.3) !important'
      },
      '& MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#124c4d !important'
      },
      '& .MuiLinearProgress-bar2Indeterminate': {
        backgroundColor: '#124c4d !important'
      },
      '& .MuiLinearProgress-bar1Indeterminate': {
        backgroundColor: '#124c4d !important'
      }
    }
  })
);
