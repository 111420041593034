import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_NOTIFICATION } from 'settings';
import { connect } from 'utils/socket';

import { getKitchen, updateKitchen } from 'redux/ducks/kitchen';
import { getStore } from 'redux/ducks/store';

import IStore from 'interfaces/store';
import { IStoreState } from 'interfaces/store_test';

import { IKitchen, IKitchenState } from 'interfaces/kitchen';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

enum EDateFilter {
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  ALL = 'ALL'
}

const dateFilter = [EDateFilter.TODAY, EDateFilter.WEEK, EDateFilter.ALL];

var storeFilter: { id: number; name: string }[] = [];

export default function Kitchen() {
  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-delivery');
    d.item(0).style.color = '#ff5a23';

    d.item(1).style.color = '#ff5a23';
  });

  const dispatch = useDispatch();
  const { fetch, depot, transit, delivered } = useSelector<
    IStore,
    IKitchenState
  >((state) => state.kitchen);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  const { authentication } = useSelector<IStore, IStore>((state) => state);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  storeFilter.push({ id: 0, name: 'All' });
  const classes = useStyles();

  const [online, setOnline] = useState(false);
  const [filteredDepot, setFilteredDepot] = useState(depot);
  const [filteredTransit, setFilteredTransit] = useState(transit);
  const [filteredCompleted, setFilteredCompleted] = useState(delivered);
  const [activeDateFilter, setActiveDateFilter] = useState('ALL');
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);

  const handleActiveDateFilter = (value: string) => {
    setActiveDateFilter(value);
  };

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  const getWeekDates = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const numDay = now.getDate();

    const start = new Date(now);
    start.setDate(numDay - dayOfWeek + 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  const getTodayDates = () => {
    const now = new Date();

    const start = new Date(now);
    start.setHours(0, 0, 0, 0);

    const end = new Date(now);
    end.setHours(23, 59, 59, 59);

    return [start, end];
  };

  useEffect(() => {
    dispatch(getStore());
  }, [dispatch, activeDateFilter, activeStoreFilter]);

  useEffect(() => {
    dispatch(
      getKitchen({
        date_delivery: activeDateFilter,
        depot: true,
        transit: true,
        delivered: true,
        store: activeStoreFilter
      })
    );
  }, [dispatch, activeDateFilter, activeStoreFilter]);

  //Find a way to find the store_id in the storeFilter list

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => setOnline(true);

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data);
      if (activeStoreFilter !== 0) {
        if (new_data && new_data.message.store_id === activeStoreFilter) {
          dispatch(updateKitchen(JSON.parse(event.data)));
        }
      } else if (activeStoreFilter === 0) {
        if (!authentication.is_superuser) {
          try {
          
            dispatch(updateKitchen(JSON.parse(event.data)));
          } catch (err) {
            console.log(err);
          }
        } else dispatch(updateKitchen(JSON.parse(event.data)));
      }
    };

    kitchenSocket.onerror = () => kitchenSocket.close();

    kitchenSocket.onclose = () => kitchenSocket.close();

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (kitchenSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, dispatch, activeStoreFilter]);

  useEffect(() => {
    const [start, end] =
      activeDateFilter === EDateFilter.TODAY
        ? getTodayDates()
        : activeDateFilter === EDateFilter.WEEK
        ? getWeekDates()
        : [null, null];

    function filterDates(order: IKitchen) {
      if (start && end) {
        return (
          (new Date(order.date_ordered) >= start &&
            new Date(order.date_ordered) <= end) ||
          (new Date(order.date_delivery) >= start &&
            new Date(order.date_delivery) <= end)
        );
      } else {
        return order;
      }
    }

    const depotfilter = depot.filter((order) => filterDates(order));
    setFilteredDepot(depotfilter);

    const transitFilter = transit.filter((order) => filterDates(order));
    setFilteredTransit(transitFilter);

    const completedFilter = delivered.filter((order) => filterDates(order));
    setFilteredCompleted(completedFilter);
  }, [activeDateFilter, depot, transit, delivered]);

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderDepot,
      headerTitle: 'DEPOSIT',
      bodyStyle: classes.cardBodyDepot,
      orders: filteredDepot,
      showOKBtn: true,
      showBackBtn: true,
      showIsPaid: true,
      showExtraInfo: true,
      showDriverBtn: true,
      showPrintBtn: true
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'ON THE WAY',
      bodyStyle: classes.cardBodyDoing,
      orders: filteredTransit,
      showPrintBtn: true,
      showIsPaid: true
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'DELIVERED',
      bodyStyle: classes.cardBodyDone,
      orders: filteredCompleted,
      showPrintBtn: true,
      showIsPaid: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`store: `}</Typography>
        {storeFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveStoreFilter(elmnt.id);
            }}
            variant="outlined"
            size="small"
            className={
              activeStoreFilter === elmnt.id
                ? classes.activeButton
                : classes.inactiveButton
            }
          >
            {elmnt.name}
          </Button>
        ))}
      </Grid>
      <Grid style={{ marginBottom: 10 }}>
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`for: `}</Typography>
        {dateFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveDateFilter(elmnt);
            }}
            variant="outlined"
            size="small"
            className={
              activeDateFilter === elmnt
                ? classes.activeButton
                : classes.inactiveButton
            }
          >
            {elmnt}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={3}>
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showIsPaid={panel.showIsPaid}
            showPrintBtn={panel.showPrintBtn}
            showDriverBtn={panel.showDriverBtn}
            showExtraInfo={panel.showExtraInfo}
          />
        ))}
      </Grid>
      {!fetch &&
        depot.length === 0 &&
        transit.length === 0 &&
        delivered.length === 0 && (
          <Typography
            variant="h6"
            align="center"
            style={{ marginBottom: 10 }}
          ></Typography>
        )}
    </>
  );
}
