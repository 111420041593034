import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  ICreateAction,
  IUpdateAction,
  IDeleteAction,
  IFailureAction,
  IResetAction,
  IStockState,
  Product,
  ETypesStock,
  IManagerAction,
  GetState
} from 'interfaces/stock';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { mountQueryURL } from '../../utils/query';
import { NETWORK } from 'settings';
import { resolve } from 'path';
import { reject } from 'lodash';

/* Authentication State. */
const initialState: IStockState = {
  fetch: false,
  ingredients: [],
  products: [],
  productsForStockPage: [],
  providers: [],
  combos: [],
  categories: [],
  hasMore: true
};

/* Authentication Reducer. */
export default (
  state: IStockState = initialState,
  action: IManagerAction
): IStockState => {
  switch (action.type) {
    case ETypesStock.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesStock.SUCCESS:
      return {
        ...action.payload,
        fetch: false
      };
    case ETypesStock.CREATE_PRODUCT:
      const createdResults = state.products.map((product) => {
        return product;
      });
      createdResults.push(action.payload as Product);
      return {
        ...state,
        products: createdResults
      };
    case ETypesStock.UPDATE_PRODUCT:
      const updatedResults = state.products.map((product) => {
        if (product.product_id === action.payload.product_id) {
          return action.payload;
        }
        return product;
      });
      return {
        ...state,
        products: updatedResults
      };
    case ETypesStock.DELETE_PRODUCT:
      const deletedResults = state.products.filter(
        (product) => product.product_id !== action.payload
      );
      return {
        ...state,
        products: deletedResults
      };
    case ETypesStock.FAILURE:
      return {
        ...state,
        fetch: false
      };
    case ETypesStock.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStock = (): IFetchAction => ({
  type: ETypesStock.FETCH
});

export const successFetchStock = (payload: IStockState): ISuccessAction => ({
  type: ETypesStock.SUCCESS,
  payload
});

export const createProductOnProducts = (payload: Product): ICreateAction => ({
  type: ETypesStock.CREATE_PRODUCT,
  payload
});

export const editProductOnProducts = (payload: Product): IUpdateAction => ({
  type: ETypesStock.UPDATE_PRODUCT,
  payload
});

export const deleteProductOnProducts = (payload: number): IDeleteAction => ({
  type: ETypesStock.DELETE_PRODUCT,
  payload
});

export const failureManagerDashboard = (payload: string): IFailureAction => ({
  type: ETypesStock.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesStock.RESET
});

/* Authentication Side Effects Functions. */

export const fetchProductsGeneral =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';

        let url = `products/api/products/calculate_stock_products/${query_url}`;
        const results = [];
        while (url !== null) {
          const response = await fetch({
            method: EMethod.GET,
            url
          });
          url = response.next;
          const r = response.results;
          r.map((element) => {
            results.push(element);
          });
        }
        dispatch(successFetchStock({ ...stock, products: results }));
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const fetchProductsCategoriesFilter =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());

        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/products/calculate_stock_products/${query_url}`;
        const results = [];
        while (url !== null) {
          const response = await fetch({
            method: EMethod.GET,
            url
          });
          url = response.next;
          const r = response.results;
          r.map((element) => {
            results.push(element);
          });
        }
        dispatch(successFetchStock({ ...stock, products: results }));
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const fetchProductsPagination =
  (params: {} = undefined, reset = false): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/products/calculate_stock_products/${query_url}`;
        const results = [];
        const response = await fetch({
          method: EMethod.GET,
          url
        });
        url = response.next;
        const r = response.results;
        r.map((element) => {
          results.push(element);
        });
      
        dispatch(
          successFetchStock({
            ...stock,
            productsForStockPage: reset
              ? results
              : stock.productsForStockPage
                ? stock.productsForStockPage.concat(results)
                : results,
            hasMore: url !== null
          })
        );
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const fetchAllProducts =
  (params: {} = undefined, reset = false): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/products/calculate_stock_products/${query_url}`;
        const results = [];
        var response;
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        dispatch(
          successFetchStock({
            ...stock,
            productsForStockPage: results,
            hasMore: url !== null
          })
        );
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const fetchProducts =
  (): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        const response = await fetch({
          method: EMethod.GET,
          url: 'products/api/products/'
        });
        dispatch(successFetchStock({ ...stock, products: response.results }));
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const fetchCombosGeneral =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/combos/${query_url}`;
        let response;
        let results = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        response.results = results;
        dispatch(successFetchStock({ ...stock, combos: results }));
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const createCategory =
  (data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        var ingredientsToFilterBy = data['ingredientsToFilterBy'];
        delete data['ingredientsToFilterBy'];
        await fetch({
          method: EMethod.POST,
          url: 'products/api/categories/',
          data: data
        });
        dispatch(
          sendAlert('Category successfully created', EAlertVariant.SUCCESS)
        );
        dispatch(
          fetchCategoriesGeneral({ categoriesToFilterBy: ingredientsToFilterBy })
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const editCategory =
  (
    category_id: number = undefined,
    data: {} = undefined,
    store: number
  ): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      var ingredientsToFilterBy = data['ingredientsToFilterBy'];
      delete data['ingredientsToFilterBy'];
      try {
        await fetch({
          method: EMethod.PATCH,
          url: `products/api/categories/${category_id}/`,
          data: data
        });
        dispatch(
          sendAlert('Category successfully edited', EAlertVariant.SUCCESS)
        );
        var params = {
          categoriesToFilterBy: ingredientsToFilterBy,
          store: store,
          network: NETWORK,
          search: ''
        };
        if (store === 0) {
          delete params.store;
        } else {
          delete params.network;
        }
        dispatch(fetchCategoriesGeneral(params));
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const deleteCategory =
  (category_id: number = undefined, ingredientsToFilterBy: any): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/categories/${category_id}/`
        });
        dispatch(
          sendAlert('Category successfully deleted', EAlertVariant.SUCCESS)
        );
        dispatch(
          fetchCategoriesGeneral({ categoriesToFilterBy: ingredientsToFilterBy })
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const createIngredient =
  (data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        var store;

        Object.keys(data).forEach((key) => {
          if (key === 'store') store = data[key];
        });
        await fetch({
          method: EMethod.POST,
          url: 'products/api/ingredients/',
          data: data
        });
        var ingredientsToFilterBy = data['ingredientsToFilterBy'];
        delete data['ingredientsToFilterBy'];
        dispatch(sendAlert('Supply successfully created', EAlertVariant.SUCCESS));
        dispatch(
          fetchIngredients({
            store: store,
            extrasToFilterBy: ingredientsToFilterBy
          })
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const editIngredient =
  (ingredient_id: number = undefined, data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        const body = new FormData();
        var store;

        Object.keys(data).forEach((key) => {
          switch (key) {
            case 'store':
              store = data[key];
              break;
            default:
              body.append(key, data[key]);
              break;
          }
        });
        var ingredientsToFilterBy = data['ingredientsToFilterBy'];
        delete data['ingredientsToFilterBy'];
        await fetch({
          method: EMethod.PATCH,
          url: `products/api/ingredients/${ingredient_id}/?store=${store}`,
          data: data
        });
        dispatch(sendAlert('Supply successfully edited', EAlertVariant.SUCCESS));
        dispatch(
          fetchIngredients({
            store: store,
            extrasToFilterBy: ingredientsToFilterBy
          })
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const createProduct =
  (data: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<any> => {
      try {
        const body = new FormData();
        const productImages = data['product_images'];
        delete data['product_images'];
        //  var tags: any;
        class Ingredient {
          id: number;
          ingredient_id: number;
          ingredient_stock: {
            measure: string;
            quantity: number;
          };
        }

        class RelatedProduct {
          id: number;
          product_id: number;
        }

        class Extras {
          id: number;
          ingredient: string;
          limit: number;
          price: string;
          is_sauce: number;
          quantity: number;
        }
        var ingredientsAarray: Ingredient[] = [];
        var extras: Extras[] = [];
        var relatedProductsArray: RelatedProduct[] = [];
        Object.keys(data).forEach((key) => {
          if (key === 'image') {
            if (data[key]) {
              body.append(key, data[key]);
            } else {
              return null;
            }
          } else {
            if (key === 'extras') {
              extras = data[key];
            } else {
              if (key !== 'ingredients') {
                //   if (key === 'tags') {
                //   tags = data[key];
                // } else {

                if (key === 'related_products') {
                  relatedProductsArray = data[key];
                } else body.append(key, data[key]);
                //}
              } else {
                ingredientsAarray = data[key];
              }
            }
          }
        });
        await fetch({
          method: EMethod.POST,
          headers: { 'content-type': 'multipart/form-data' },
          url: `products/api/products/?network=${NETWORK}`,
          data: body
        }).then(async (response) => {
          if (data['size']) {
            const sizes = data['size'];
            sizes.map((size) => {
              handleSize(response.product_id, size);
            });
          }
          productImages.map((image, sort_key) => {
            addImages(response.product_id, image, sort_key);
          });
          if (NETWORK !== '6') {
            for (let index = 0; index < ingredientsAarray.length; index++) {
              const element = ingredientsAarray[index];
              var ingredientBody = {};
              Object.keys(element).forEach((key) => {
                if (key !== 'id') {
                  ingredientBody[key] = element[key];
                }
              });

              await fetch({
                method: EMethod.POST,
                headers: {
                  'content-type': 'application/json'
                },
                url: `products/api/products/${response.product_id}/add_ingredient_to_product/`,
                data: ingredientBody
              });
            }
            for (let index = 0; index < relatedProductsArray.length; index++) {
              const element = relatedProductsArray[index];
              var relatedProductBody = {};
              Object.keys(element).forEach((key) => {
                if (key !== 'id') {
                  relatedProductBody[key] = element[key];
                }
              });

              await fetch({
                method: EMethod.POST,
                headers: {
                  'content-type': 'application/json'
                },
                url: `products/api/products/${response.product_id}/add_related_product_to_product/`,
                data: relatedProductBody
              });
            }
            for (let index = 0; index < extras.length; index++) {
              const element = extras[index];
              await fetch({
                method: EMethod.POST,
                headers: { 'content-type': 'application/json' },
                url: `products/api/products/${response.product_id}/add_extra_to_product/`,
                data: element
              });
            }
          }
          dispatch(getProduct(response['slug']))

          dispatch(
            sendAlert('Product successfully created', EAlertVariant.SUCCESS)
          );
          return "done"
        });
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

const addImages = async (id, image, sort_key) => {
  return new Promise<void>(async (resolve, reject) => {
    const body = new FormData();
    body.append('sort_key', sort_key);
    body.append('image', image);
    try {
      await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: `/products/api/products/${id}/add_image_to_product/`,
        data: body
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const handleSize = async (id, data) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      data.product = id;
      data.price = data.price === '' ? 0 : parseFloat(data.price);
      data.stock = data.stock === '' ? 0 : parseInt(data.stock);
      if (!data.id && !data.deleted) {
        await fetch({
          method: EMethod.POST,
          url: `/products/api/variations/`,
          data: data
        });
      }
      if (data.changed && !data.deleted && data.id) {
        await fetch({
          method: EMethod.PATCH,
          url: `/products/api/variations/${data.id}/`,
          data: data
        });
      }
      if (data.deleted && data.id) {
        await fetch({
          method: EMethod.DELETE,
          url: `/products/api/variations/${data.id}/`,
          data: data
        });
      }

      resolve();
    } catch (error) {
      reject();
    }
  });
};
const updateSizes = (sizes: any[], product_id) => {
  return new Promise<void>((resolve, reject) => {
    if (sizes) {
      sizes.map((size) => {
        handleSize(product_id, size);
      });
      resolve();
    } else {
      resolve();
    }
  });
};
const deleteImage = async (id) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await fetch({
        method: EMethod.DELETE,
        url: `/products/api/productimages/${id}/`
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const patchImage = async (id, sort_key) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await fetch({
        method: EMethod.PATCH,
        url: `/products/api/productimages/${id}/`,
        data: { sort_key: sort_key }
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const lastValue = (array) => {
  var value = 0;
  array.map((el) => {
    if (el > 0) {
      value = el;
    }
  });
  return value;
};
const addImagesMap = (images: any[], product_id) => {
  return new Promise<void>(async (resolve, reject) => {
    const sort_keys = [];
    if (images.length < 1) {
      resolve();
    }
    images.map((image, idx) => {
      if (!image.image) {
        sort_keys.push(lastValue(sort_keys) + 1);
      } else {
        if (!image.deleted) {
          sort_keys.push(lastValue(sort_keys) + 1);
        } else {
          sort_keys.push(0);
        }
      }
    });
    if (images) {
      images.map(async (image, idx) => {
        if (!image?.image) {
          await addImages(product_id, image, sort_keys[idx]);
        } else {
          if (image['deleted']) {
            await deleteImage(image?.image['id']);
          } else {
            await patchImage(image?.image['id'], sort_keys[idx]);
          }
        }
        if (idx === images.length - 1) {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
};
export const editProduct =
  (
    product_id: number = undefined,
    data: {} = undefined,
    store: number
  ): ThunkAction =>
    async (dispatch: Dispatch): Promise<any> => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const body = new FormData();
          var oldExtras: any[] = [];
          var oldIngredients: any[] = [];
          var newExtras: any[] = [];
          var store;
          var newIngredients: any[] = [];
          const images: any[] = data['product_images'];
          Object.keys(data).forEach((key) => {
            if (key === 'image') {
              if (data[key]) {
                body.append(key, data[key]);
              } else {
                return null;
              }
            } else {
              switch (key) {
                case 'oldExtras':
                  oldExtras = data[key];
                  break;
                case 'oldIngredients':
                  oldIngredients = data[key];
                  break;
                case 'newExtras':
                  newExtras = data[key];
                  break;
                case 'newIngredients':
                  newIngredients = data[key];
                  break;
                case 'store':
                  store = data[key];
                  break;
                default:
                  body.append(key, data[key]);
                  break;
              }
            }
          });

          var response = await fetch({
            method: EMethod.PATCH,
            headers: { 'content-type': 'multipart/form-data' },
            url: `products/api/products/${product_id}/?store=${store}`,
            data: body
          });
          Promise.all([
            await updateSizes(data['size'], product_id),
            await addImagesMap(images, product_id)
          ]).then(async () => {
            for (let index = 0; index < oldIngredients.length; index++) {
              const element = oldIngredients[index];
              await fetch({
                method: EMethod.PATCH,
                url: `/products/api/stocks/${element.stock}/`,
                data: {
                  quantity: element.quantity,
                  measure: element.measure,
                  ingredient_id: element.ingredient
                }
              });
            }
            for (let index = 0; index < newIngredients.length; index++) {
              const element = newIngredients[index];
              await fetch({
                method: EMethod.POST,
                url: `products/api/products/${product_id}/add_ingredient_to_product/`,
                data: element
              });
            }
            for (let index = 0; index < oldExtras.length; index++) {
              const element = oldExtras[index];
              await fetch({
                method: EMethod.PATCH,
                headers: {
                  'content-type': 'application/json'
                },
                url: `/products/api/extras/${element.id}/`,
                data: element
              });
            }
            for (let index = 0; index < newExtras.length; index++) {
              const element = newExtras[index];

              await fetch({
                method: EMethod.POST,
                headers: { 'content-type': 'application/json' },
                url: `products/api/products/${product_id}/add_extra_to_product/`,
                data: element
              });
            }
            dispatch(getProduct(data['slug']))
            dispatch(
              sendAlert('Product successfully edited', EAlertVariant.SUCCESS)
            );
            resolve(response);
          });
        } catch (error) {
          dispatch(failureManagerDashboard(error));
          dispatch(sendAlert(error, EAlertVariant.ERROR));
          reject(false);
        }
      });
    };

export const deleteProduct =
  (product_id: number = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/products/${product_id}/`
        });
        dispatch(deleteProductOnProducts(product_id));
        dispatch(
          sendAlert('Product deleted successfully', EAlertVariant.SUCCESS)
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };
export const deleteProvider =
  (id: number = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/providers/${id}/`
        });
        dispatch(
          sendAlert('Fornecedor excluído com sucesso', EAlertVariant.SUCCESS)
        );
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const deleteIngredient =
  (
    ingredient_id: number = undefined,
    showAlert: boolean = true,
    ingredientsToFilterBy: any
  ): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/ingredients/${ingredient_id}/`
        });
        if (showAlert) {
          dispatch(
            sendAlert('Supply successfully deleted', EAlertVariant.SUCCESS)
          );
        }

        dispatch(
          fetchIngredients({
            extrasToFilterBy: ingredientsToFilterBy
          })
        );
        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const deleteIngredientStock =
  (ingredient_id: number = undefined, showAlert: boolean = true): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/stocks/${ingredient_id}/`
        });
        if (showAlert) {
          dispatch(
            sendAlert('Supply successfully deleted', EAlertVariant.SUCCESS)
          );
        }

        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const deleteExtra =
  (extra_id: number = undefined, showAlert: boolean = true): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/extras/${extra_id}/`
        });
        if (showAlert) {
          dispatch(
            sendAlert('Extra deletado com sucesso', EAlertVariant.SUCCESS)
          );
        }

        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const fetchIngredients =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<any> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        var extrasToFilterBy = params['extrasToFilterBy'];
        delete params['extrasToFilterBy'];
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/ingredients/${query_url}`;
        let response;
        let results = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        response.results = results;
        if (extrasToFilterBy !== 'ALL' && extrasToFilterBy) {
          results = results.filter(function (el) {
            return extrasToFilterBy.includes(el.ingredient_id);
          });
        }

        dispatch(successFetchStock({ ...stock, ingredients: results }));
        return results;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };

export const submitProductProductionOrder =
  (data: {}): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.POST,
          url: 'products/api/products/production_order/',
          data: data
        });
        dispatch(
          sendAlert('Ordem de Produção criada com sucesso', EAlertVariant.SUCCESS)
        );
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const submitIngredientProductionOrder =
  (data: {}): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        var ingredientsToFilterBy = data['ingredientsToFilterBy'];
        delete data['ingredientsToFilterBy'];
        await fetch({
          method: EMethod.POST,
          url: 'products/api/ingredients/production_order/',
          data: data
        });
        dispatch(
          sendAlert('Ordem de Compra criada com sucesso', EAlertVariant.SUCCESS)
        );
        dispatch(
          fetchIngredients({
            extrasToFilterBy: ingredientsToFilterBy
          })
        );
        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const fetchProviders =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<void> => {
      try {
        const { stock } = getState();
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/providers/${query_url}`;
        let response;
        let results = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;

          results.push(...response.results);
        } while (response.next);
        response.results = results;

        dispatch(successFetchStock({ ...stock, providers: results }));
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const createProvider =
  (data: {}): ThunkAction =>
    async (dispatch: Dispatch): Promise<number> => {
      try {
        let url = `products/api/providers/`;
        await fetch({
          method: EMethod.POST,
          url,
          data
        });
        dispatch(
          sendAlert('Fornecedor criado com sucesso', EAlertVariant.SUCCESS)
        );
        return 1;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return 0;
      }
    };

export const fetchCategoriesGeneral =
  (params: {} = undefined): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<any> => {
      try {
        const { stock } = getState();
        var categoriesToFilterBy = params['categoriesToFilterBy'];
        delete params['categoriesToFilterBy'];
        dispatch(fetchStock());
        let query_url;
        if (params) query_url = mountQueryURL(params);
        else query_url = '';
        let url = `products/api/categories/${query_url}&network=${NETWORK}`;
        let response;
        let results = [];
        do {
          response = await fetch({
            method: EMethod.GET,
            url: url
          });
          url = response.next;
          results.push(...response.results);
        } while (response.next);
        response.results = results;
        if (categoriesToFilterBy !== 'ALL') {
          results = results.filter(function (el) {
            return categoriesToFilterBy.includes(el.id);
          });
        }
        if (response.results.length > 0) {
          dispatch(successFetchStock({ ...stock, categories: response.results }));
        }
        return true;
      } catch (error) {
        console.log("error detected -> ", error)
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const fetchArtist = async (params) => {
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = '';
      var response = await fetch({
        method: EMethod.GET,
        url: `products/api/artist/${query_url}&network=${NETWORK}`
      });
      resolve(response);
    } catch (error) {
      reject();
    }
  });
};

export const fetchAllArtists = async () => {
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      var page = 1;
      var results = [];
      do {
        var response: any = await fetchArtist({
          page
        });
        if (response.next) page += 1;
        results.push(...response.results);
      } while (response.next);

      resolve(results);
    } catch (error) {
      reject();
    }
  });
};
export const deleteArtist =
  (id: number = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/artist/${id}/`
        });
        dispatch(sendAlert('Artist successfully deleted', EAlertVariant.SUCCESS));

        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const AddArtist = (name: string, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await fetch({
        method: EMethod.POST,
        url: `products/api/artist/`,
        data: {
          name
        }
      });
      dispatch(sendAlert('Artist successfully created', EAlertVariant.SUCCESS));
      resolve(response);
    } catch (error) {
      dispatch(failureManagerDashboard(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      reject(error);
    }
  });
};

export const PatchArtist =
  (name: string, id: number): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.PATCH,
          url: `products/api/artist/${id}/`,
          data: {
            name
          }
        });
        dispatch(sendAlert('Artist successfully edited', EAlertVariant.SUCCESS));

        return true;
      } catch (error) {
        dispatch(failureManagerDashboard(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const fetchTags = async (params) => {
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      var query_url = '';
      if (params) query_url = mountQueryURL(params);
      var url = `products/api/tags/${query_url}`
      var results = []
      do {
        const response = await fetch({
          method: EMethod.GET,
          url,
        });
        results.push(...response.results)
        if (response.next) {
          url = response.next
        } else {
          url = ""
        }
      } while (url !== "");

      resolve(results);
    } catch (error) {
      reject(error);
    }
  });
};

export const createTag =
  (data): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.POST,
          url: `products/api/tags/`,
          data: data
        });
        dispatch(sendAlert('Tag successfully created', EAlertVariant.SUCCESS));

        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };
export const editTag =
  (data, id): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.PATCH,
          url: `products/api/tags/${id}/`,
          data: data
        });
        dispatch(sendAlert('Tag successfully edited', EAlertVariant.SUCCESS));

        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };
export const deleteTag =
  (tag_id: number = undefined): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.DELETE,
          url: `products/api/tags/${tag_id}/`
        });
        dispatch(sendAlert('Tag successfully deleted', EAlertVariant.SUCCESS));

        return true;
      } catch (error) {
        return false;
      }
    };

export const fetchGiftCards = async (params) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      var query_url = '';
      if (params) query_url = mountQueryURL(params);

      const response = await fetch({
        method: EMethod.GET,
        url: `/orders/api/giftcard/${query_url}`
      });
      resolve(response.results);
    } catch (error) {
      reject(error);
    }
  });
};

export const toggleGiftCard = async (data, id, dispatch): Promise<void> => {
  try {
    await fetch({
      method: EMethod.PATCH,
      url: `/orders/api/giftcard/${id}/`,
      data: data
    });
    dispatch(
      sendAlert(
        `Giftcard successfully ${data.active ? 'activated' : 'deactivated'}`,
        EAlertVariant.SUCCESS
      )
    );

    resolve();
  } catch (error) {
    dispatch(sendAlert(error, EAlertVariant.ERROR));
    reject(error);
  }
};

export const editGiftCard =
  (data, id): ThunkAction =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        await fetch({
          method: EMethod.PATCH,
          url: `/orders/api/giftcard/${id}/`,
          data: data
        });
        dispatch(
          sendAlert('Giftcard successfully edited', EAlertVariant.SUCCESS)
        );

        return true;
      } catch (error) {
        dispatch(sendAlert(error, EAlertVariant.ERROR));
        return false;
      }
    };

export const getProduct =
  (
    slug: string = undefined
  ): ThunkAction =>
    async (dispatch: Dispatch, getState: GetState): Promise<any> => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const { stock } = getState();
          var url = `products/api/products/calculate_stock_products/?slug=${slug}&network=${NETWORK}`
          var { results } = await fetch({
            method: EMethod.GET,
            url
          })
          var found = false
          var products = stock.productsForStockPage.map((el) => {
            if (el.product_id === results[0].product_id) {
              found = true
              return results[0]
            }
            return el
          })
          if (!found) {
            products.push(results[0])
          }
          dispatch(
            successFetchStock({
              ...stock,
              productsForStockPage: products,
              hasMore: url !== null
            })
          );

        } catch (error) {
          console.log("error detected -> ", error)
          dispatch(failureManagerDashboard(error));
          dispatch(sendAlert(error, EAlertVariant.ERROR));
          reject(false);
        }
      });
    };
