import React from 'react';
import {
  Button,
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createOwner } from 'redux/ducks/owner';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    onClose();
  };

  const handleFirstName = (event: any) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event: any) => {
    setLastName(event.target.value);
  };
  const handleEmail = (event: any) => {
    setEmail(event.target.value.trim());
  };
  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  const submitCreation = async () => {
    dispatch(
      createOwner({
        first_name: firstName,
        last_name: lastName,
        email,
        password: 'wordpass234',
        profile: {
          phone_number: phoneNumber,
          is_stripe_owner: true
        }
      })
    ).then((result: boolean) => {
      if (result) handleClose();
    });
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <h2>Add Store Owner</h2>
        <Container maxWidth="md">
          <Grid container xs={12} spacing={1} sm={12}>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">First name</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={firstName}
                  onChange={handleFirstName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">Last name</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={lastName}
                  onChange={handleLastName}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={1} sm={12}>
            <Grid item xs={4} sm={4}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">Email</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={email}
                  onChange={handleEmail}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="name-input">Phone number</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* End of left column */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                {' '}
                Create{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
