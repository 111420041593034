import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography
} from '@material-ui/core';
import { PRIMARY_COLOR } from 'settings';

interface IProps {
  handleSave: (data: { name: string; store: number }) => void;
  store: number;
  handleChangeCategory: (name: string) => void;
}

export default function CreateCategory(props: IProps) {
  const { handleSave, store, handleChangeCategory } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleName = (event: any) => {
    var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var str = event.target.value;
    if (numbers.includes(str[0])) {
      var tmp = str.split('');
      tmp.splice(0, 1);
      str = tmp.join('');
    }
    if (numbers.includes(str[str.length - 1])) {
      var tmp = str.split('');
      tmp.splice(str.length - 1, 1);
      str = tmp.join('');
    }
    var tmp = str.split('');
    var _str = [];
    var alphaNumericFound = false;
    tmp.map((str, idx) => {
      if (!alphaNumericFound) {
        if (str.replace(/[^a-zA-Z0-9]/g, '')) {
          alphaNumericFound = true;
          _str.push(str);
        }
      } else {
        _str.push(str);
      }
    });
    setName(capitalizeFirstLetter(str));
    setNameError('');
  };
  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert name');
      entered = true;
    }
    return entered;
  };
  const submit = () => {
    if (!checkForm()) {
      handleChangeCategory(name);
      handleSave({ name, store });
    }
  };
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <Typography
        style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
      >
        Create category
      </Typography>
      <Typography
        style={{
          fontFamily: 'Work Sans, sans-serif',
          fontSize: '13px',
          width: '70%',
          textAlign: 'left'
        }}
      >
        {' '}
        The category appears in your Menu, with the related products.
        <br />
        For examples: Burgers, Catering, Starters, Main courses, Desserts.
      </Typography>
      <FormControl
        style={{ marginBottom: '1rem', width: '70%' }}
        required
        error={nameError !== ''}
      >
        <InputLabel htmlFor="name-input">Name</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={name}
          onChange={handleName}
        />
        <FormHelperText>{nameError}</FormHelperText>
      </FormControl>
      <Button
        style={{
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={() => submit()}
      >
        Save
      </Button>
    </Grid>
  );
}
