import React from 'react';
import {
  Button,
  InputLabel,
  Input,
  // Select,
  // MenuItem,
  Container,
  Grid,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createIngredient } from 'redux/ducks/stock';
import './styles.css';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
export interface SimpleDialogProps {
  open: boolean;
  store?: number;
  onClose: () => void;
  ingredientsToFilterBy: any;
}

export default function ModalCreateMenu(props: SimpleDialogProps) {
  const { onClose, open, store } = props;
  const [name, setName] = React.useState('');
  // const [cost, setCost] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [measure, setMeasure] = React.useState('Unit');
  const dispatch = useDispatch();

  const handleClose = () => {
    setName('');
    setMeasure('Unit');
    setQuantity(0);
    // setCost(0);
    onClose();
  };

  const handleName = (event: any) => {
    setName(event.target.value);
  };
  // const handleCost = (event: any) => {
  //   setCost(event.target.value);
  // };
  const handleQuantity = (event: any) => {
    setQuantity(event.target.value);
  };
  const handleMeasure = (event: any) => {
    setMeasure(event.target.value);
  };

  const submitCreation = async () => {
    dispatch(
      createIngredient({
        name,
        measure,
        quantity,
        // cost,
        store
      })
    ).then((result: boolean) => {
      if (result) handleClose();
    });
  };
  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
      <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <h2>Add Extra</h2>
        <Container maxWidth="md">
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={handleName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Stock</InputLabel>
              <Input
                id="name-input"
                type="number"
                value={quantity}
                onChange={handleQuantity}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Cost</InputLabel>
              <Input
                id="name-input"
                type="number"
                value={cost}
                onChange={handleCost}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Measure</InputLabel>
              <Input
                id="name-input"
                value="Unit"
                disabled={true}
                onChange={handleMeasure}
              />
            </FormControl>
          </Grid>

          {/* End of left column */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                {' '}
                Create{' '}
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
