import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, InputLabel, Input, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createNotificationStatus, fetchNotificationsStatus } from 'redux/ducks/notification'

// import i18n from '../i18n/i18n';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ModalCreateNotificationStatus(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState('A fazer');

  const dispatch = useDispatch();

  const handleClose = () => {
    setMessage('');
    onClose();
  };

  const handleMessage = (event: any) => {
    setMessage(event.target.value)
  }

  const handleStatus = (event: any) => {
    setStatus(event.target.value)
  }

  const submitCreation = async () => {
    dispatch(createNotificationStatus({
      message: message,
      status: status
    }))
      .then((result: boolean) => {
        dispatch(fetchNotificationsStatus())
        if (result) handleClose()
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title">
          
      </DialogTitle>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel htmlFor="message-input">Mensage</InputLabel>
        <Input id="message-input" type="string" value={message} onChange={handleMessage} />
      </FormControl>
      <FormControl style={{ margin: '0px 10px' }}>
        <InputLabel id="label">Status</InputLabel>
        <Select labelId="label" id="select" value={status} onChange={handleStatus}>
          <MenuItem value="A fazer">To do</MenuItem>
          <MenuItem value="Fazendo">Accepted</MenuItem>
          <MenuItem value="Feito">Preparing</MenuItem>
          <MenuItem value="Depot">Ready</MenuItem>
          <MenuItem value="Transit">On The Way</MenuItem>
          <MenuItem value="Delivered">Delivered</MenuItem>
          <MenuItem value="Completed">Complete</MenuItem>
          <MenuItem value="Canceled">Canceled</MenuItem>
          <MenuItem value="Canceled and Refunded">Canceled & Refunded</MenuItem>
        </Select>
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={submitCreation} color="primary"> Create </Button>
        <Button onClick={handleClose}> Cancel </Button>
      </div>
    </Dialog>
  );
}