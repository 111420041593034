import { Page, Document, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import thankYou from './../../assets/img/petshop/thank-you.png'
//import logo from './../../assets/img/mangel_logo.png'
//import { IKitchen } from 'interfaces/kitchen';
import React from 'react';
import { NETWORK } from 'settings'
const styles = StyleSheet.create({
    receiptHeader: {
        width: '100%',
        marginVertical: 50,
        paddingHorizontal: "30%",
        marginHorizontal: 10,
    },
    relativePosition: {
        width: '100%',
        border: "1px solid black",
        padding: "30px 10px"
    },
    imagePosition: {
        position: 'absolute',
        top: 63,
        left: 30,
        width: 52,
        height: "auto"
    },
    flexColumn: {
        flexDirection: 'column',
        width: '85%',
    },
    marginTop15: {
        marginTop: 15,
    },
    sectionWithBorderTop: {
        marginTop: 40,
        borderTop: 1,
        paddingTop: 5,
        paddingBottom: 13,
    },
    borderTopPadding: {
        borderTop: 1,
        paddingTop: 5,
        paddingBottom: 10,
    },
    flexRowWithGap: {
        flexDirection: 'row',
    },
    font: {
        fontSize: "0.3968cm"
    },
    pricePadding: {
        width: "3cm"
    },
    textGap: {
        height: "0.1cm",
        width: "1cm"
    }
});

const MangelReceipt = ({ order }) => {
    const getProductName = (item) => {
        var name = 0
        if (item.variation) {
            name = item.product.variation.find((el) => (el.id === item.variation.id)).name
            return name
        }
        return ""
    }
    const returnLast4 = () => {
        if (order.owner["user"]) {
            var profile = order.owner["user"]['profile']["stripe_profiles"].find(el => el.network_id == NETWORK)
            if (profile) {
                return profile.last4
            }
        }
        return ""
    }
    const last4 = returnLast4()
    return (
        <Document>
            <Page size="A4">
                <View style={styles.receiptHeader}>
                    <View style={styles.relativePosition}>
                        <View style={styles.flexColumn}>
                            <Image src={thankYou} />
                            <Image style={styles.marginTop15} src={"https://res.cloudinary.com/arbid/image/upload/v1705000266/Petshop/M%C3%A4ngelquartett%20by%20karhard.png"} />
                        </View>
                        <View style={styles.sectionWithBorderTop}>
                            <Text style={styles.font}>Date: {moment(order.date_ordered).format('DD.MM.YYYY')}</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>Time: {moment(order.date_ordered).format('HH:mm')}</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>Receipt Nr.: {order.order_id}</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>Customer: {order?.owner?.name}</Text>
                            {order.billing_address && <>
                                <Text style={styles.font}>Billing address: {order.billing_address.replaceAll("None,", "")}</Text>
                                <View style={styles.textGap} />
                            </>}
                            {order.delivery_address && order.type_of_order.includes("D") && <>
                                <Text style={styles.font}>Delivery address: {order.delivery_address.replaceAll("None,", "")}</Text>
                                <View style={styles.textGap} />
                            </>}
                            {order.delivery_tracking_number && <>
                                <Text style={styles.font}>Tracking number: {order.delivery_tracking_number}</Text>
                                <View style={styles.textGap} />
                            </>}
                            {order.delivery_company && <>
                                <Text style={styles.font}>Delivery company: {order.delivery_company}</Text>
                                <View style={styles.textGap} />
                            </>}
                        </View>
                        {order.items.map((item) => (
                            <View key={item.id} style={styles.sectionWithBorderTop}>
                                <Text style={styles.font} >{item?.product?.name}</Text>
                                <View style={styles.textGap} />
                                <Text style={styles.font} >{getProductName(item)}</Text>
                                <View style={styles.textGap} />
                                <Text style={styles.font}>{item?.product?.artist}</Text>
                                <View style={styles.textGap} />
                                <Text style={styles.font}> {item?.product?.colour_hex && item?.product?.colour_hex !== "null" ? item?.product?.colour_hex : ""}</Text>
                                <View style={styles.textGap} />
                                <Text style={styles.font}>{item?.quantity} x {new Intl.NumberFormat('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(item?.variation ? item?.variation?.price : item?.product?.price)} EUR</Text>
                            </View>
                        ))}
                        <View style={styles.borderTopPadding}>
                            <View style={styles.flexRowWithGap}>
                                <View style={styles.pricePadding}>
                                    <Text style={styles.font}>Subtotal:</Text>
                                </View>
                                <Text style={styles.font}>{new Intl.NumberFormat('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(order.sub_total)} EUR</Text>
                            </View>
                        </View>
                        {order.vat_total > 0 &&
                            <View style={styles.borderTopPadding}>
                                <View style={styles.flexRowWithGap}>
                                    <View style={styles.pricePadding}>
                                        <Text style={styles.font}>MwSt. 19%:</Text>
                                    </View>
                                    <Text style={styles.font}>{new Intl.NumberFormat('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(order.vat_total)} EUR</Text>
                                </View>
                            </View>
                        }
                        {order.delivery_fee > 0 && order.type_of_order.includes("-D") &&
                            <View style={styles.borderTopPadding}>
                                <View style={styles.flexRowWithGap}>
                                    <View style={styles.pricePadding}>
                                        <Text style={styles.font}>Delivery fee:</Text>
                                    </View>
                                    <Text style={styles.font}>{new Intl.NumberFormat('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(order.delivery_fee)} EUR</Text>
                                </View>
                            </View>
                        }
                        {order.discount > 0 &&
                            <View style={styles.borderTopPadding}>
                                <View style={styles.flexRowWithGap}>
                                    <View style={styles.pricePadding}>
                                        <Text style={styles.font}>Discount:</Text>
                                    </View>
                                    <Text style={styles.font}>{new Intl.NumberFormat('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(order.discount)} EUR</Text>
                                </View>
                            </View>
                        }
                        <View style={styles.borderTopPadding}>
                            <View style={styles.flexRowWithGap}>
                                <View style={styles.pricePadding}>
                                    <Text style={styles.font}>Total:</Text>
                                </View>
                                <Text style={styles.font}>{new Intl.NumberFormat('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(order.total_order)} EUR</Text>
                            </View>
                        </View>
                        {order.payment === "Card" &&
                            <View style={styles.sectionWithBorderTop}>
                                <Text style={styles.font}>Payment method: Credit card</Text>
                                <View style={styles.textGap} />
                                <Text style={styles.font}>Date: {moment(order.date_ordered).format('DD.MM.YYYY')}</Text>
                                <View style={styles.textGap} />
                                {last4 ? <Text style={styles.font}>Cardnr: ******{last4}</Text>
                                    : null}
                            </View>
                        }
                        <View style={styles.borderTopPadding}>
                            <Text style={styles.font}>Name: karhard design GbR</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>Thomas Karsten, Alexandra Erhard</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>IBAN: DE22 1001 0010 0552 8211 01</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>BIC: PBNKDEFF (Postbank)</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>VAT-ID: DE 261 413 525</Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>EORI: DE918636845613880</Text>
                        </View>
                        <View style={styles.sectionWithBorderTop}>
                            <Text style={styles.font}>Mängelquartett by karhard®</Text>
                            <View style={{ height: "0.6cm" }} />
                            <Text style={styles.font}>
                                Bevernstrasse 5
                            </Text>
                            <Text style={styles.font}>
                                10997 Berlin
                            </Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>
                                Deutschland
                            </Text>
                            <View style={{ height: "0.6cm" }} />
                            <Text style={styles.font}>
                                shop@maengelquartett.de
                            </Text>
                            <View style={styles.textGap} />
                            <Text style={styles.font}>
                                + 49 30 61107848
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default MangelReceipt;
