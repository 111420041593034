import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';
import { INetworkAction } from 'redux/ducks/networks';
export interface IClaims {
  token_type?: string;
  exp: number;
  is_superuser: boolean;
  is_manager: boolean;
  is_store_owner: boolean;
  is_waiter_manager: boolean;
  is_waiter: boolean;
  new_user: boolean;
  jti?: string;
  id: number;
}

export interface IToken extends IClaims {
  token: string;
  email: string;
  name: string;
  phone: string;
  stripe_profiles: IStripeProfiles;
  account_stripe_KYC_status: boolean;
}

export interface IAuthenticationState {
  fetch: boolean;
  token: string;
  id: number;
  email: string;
  is_superuser: boolean;
  is_manager: boolean;
  is_store_owner: boolean;
  is_waiter_manager: boolean;
  is_waiter: boolean;
  new_user: boolean;
  name: string;
  phone: string;
  exp: number;
  authorized: boolean;
  error: string;
  stripe_profiles: IStripeProfiles;
  account_stripe_KYC_status: boolean;
}
interface IStripeProfiles {
  network_id: number;
  account_stripe_link: string;
  account_stripe_KYC_status?: boolean;
  account_connect_status: string;
  stripe_login_link: string;
}
export interface ICredentials {
  email: string;
  password: string;
}
export interface ICredentialsWaiter {
  username: string;
  password: string;
}
/**
 * Redux types.
 */
export enum ETypesAuthentication {
  FETCH = 'AUTHENTICATION/FETCH',
  SUCCESS = 'AUTHENTICATION/SUCCESS',
  FAILURE = 'AUTHENTICATION/FAILURE',
  RESET = 'AUTHENTICATION/RESET'
}

export interface IFetchAction extends IBaseAction<ETypesAuthentication, null> {
  type: ETypesAuthentication.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesAuthentication, IToken> {
  type: ETypesAuthentication.SUCCESS;
  payload: IToken;
}

export interface IFailureAction
  extends IBaseAction<ETypesAuthentication, string> {
  type: ETypesAuthentication.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesAuthentication, null> {
  type: ETypesAuthentication.RESET;
}

export type IAuthenticationAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type PromiseAction = Promise<IAuthenticationAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IAuthenticationAction
    | ThunkAction
    | PromiseAction
    | Array<IAuthenticationAction>
    | IAlertAction
    | INetworkAction
    | any
) => any;
