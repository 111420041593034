import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { resetAuthentication } from '../../redux/ducks/authentication';
import Logo from 'helpers/logo';
import './styles.css';

export default function StripeKYC() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetAuthentication());
      window.location.href = '/';
    }, 5000);
  }, []);
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '65vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '5rem',
        marginLeft: '10rem'
      }}
    >
      <div>
        <Grid
          style={{
            width: '250px',
            height: '120px',
            marginLeft: '-30px',
            marginTop: '0px'
          }}
        >
          <Logo styling={{ width: '100%', height: '100%' }}
          />
        </Grid>

        <Typography style={{ fontSize: '22px', marginBottom: '10px' }}>
          You've completed on boarding!
        </Typography>
      </div>
      <div style={{ width: '75%', marginBottom: '20px' }}>
        {' '}
        <Typography style={{ fontSize: '15px' }}>
          We have sent you an email to complete your KYC with Stripe. We will
          log you out.
        </Typography>
      </div>
    </Grid>
  );
}
