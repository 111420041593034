import {
  Dispatch,
  ThunkAction,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IStoreState,
  ETypesStore,
  IStoreAction,
  IStoreBase
} from 'interfaces/store_test';

import { EAlertVariant } from 'interfaces/alert';
import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';

import { NETWORK } from 'settings';

/* Authentication State. */
const initialState: IStoreState = {
  fetch: false,
  next: '',
  previous: '',
  results: [],
  error: ''
};

/* Authentication Reducer. */
export default (
  state: IStoreState = initialState,
  action: IStoreAction
): IStoreState => {
  switch (action.type) {
    case ETypesStore.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesStore.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: ''
      };
    case ETypesStore.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesStore.RESET:
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchStore = (): IFetchAction => ({
  type: ETypesStore.FETCH
});

export const successStore = (payload: IStoreState): ISuccessAction => ({
  type: ETypesStore.SUCCESS,
  payload
});

export const failureStore = (payload: string): IFailureAction => ({
  type: ETypesStore.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesStore.RESET
});

/* Authentication Side Effects Functions. */
export const getStore =
  (params: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchStore());

      let url = `/products/api/stores/?networks=${NETWORK}`;
      let response;
      let results: IStoreBase[] = [];
      do {
        response = await fetch({
          method: EMethod.GET,
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      dispatch(successStore(response));
    } catch (error) {
      dispatch(failureStore(error));
      dispatch(sendAlert(error, EAlertVariant.ERROR));
    }
  };

export const createStore =
  (data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await fetch({
        method: EMethod.POST,
        headers: { 'content-type': 'multipart/form-data' },
        url: `products/api/stores/`,
        data: body
      }); 
      dispatch(sendAlert('Loja criada com sucesso', EAlertVariant.SUCCESS));
      dispatch(getStore());
      return true;
    } catch (error) {
      console.log('error ', error);
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
export const editStore =
  (data: {} = undefined, id: number): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await fetch({
        method: EMethod.PATCH,
        url: `products/api/stores/${id}/`,
        data: body
      });
      dispatch(sendAlert('Store successfully edited', EAlertVariant.SUCCESS));
      dispatch(getStore());
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const editOpenHours =
  (data: {} = undefined, id): ThunkAction =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: `/orders/api/open_hours/`,
        data: data
      }).then(async (response) => {
        await fetch({
          method: EMethod.POST,
          url: `/products/api/stores/${id}/store_open_hours/`,
          data: { open_hour_id: response.id }
        }).then((res) => {
          dispatch(
            sendAlert('Open hours successfuly added.', EAlertVariant.SUCCESS)
          );
          dispatch(getStore());
          return res;
        });
      });
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return null;
    }
  };

export const editStoreImport =
  (store_id: number = undefined, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.PATCH,
        url: `products/api/stores/${store_id}/`,
        data: data
      });
      dispatch(
        sendAlert('Dados da loja selecionada importados', EAlertVariant.SUCCESS)
      );
      dispatch(getStore());
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const editStoreMenus =
  (store_id: number = undefined, data: {} = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.PATCH,
        url: `products/api/stores/${store_id}/`,
        data: data
      });
      dispatch(
        sendAlert('Menus da loja editados com sucesso', EAlertVariant.SUCCESS)
      );
      dispatch(getStore());
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };

export const closeStore =
  (id: number = undefined): ThunkAction =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      await fetch({
        method: EMethod.POST,
        url: `products/api/stores/${id}/store_closure/`
      });
      dispatch(getStore());
      dispatch(sendAlert('Store Closed', EAlertVariant.SUCCESS));
      return true;
    } catch (error) {
      dispatch(sendAlert(error, EAlertVariant.ERROR));
      return false;
    }
  };
