import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Seller } from 'interfaces/customersAndSellers';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ModalCreateVendedor from './ModalCreateVendedor';
import ModalEditVendedor from './ModalEditVendedor';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    marginTop: '0.5vw'
  }
});

interface Props {
  sellers: Seller[];
}

export default function SellersTable(props: Props) {
  const classes = useStyles();
  const { sellers } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedSeller, setSelectedSeller] = React.useState(undefined);

  const handleClickOpenEdit = (seller: Seller) => {
    setSelectedSeller(seller);
    setOpenEdit(true);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        className={classes.activeButton}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Seller
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Orders</TableCell>
            <TableCell align="right">Total (£)</TableCell>
            <TableCell align="right">To Pay (£)</TableCell>
            <TableCell align="right">Profit (£)</TableCell>
            <TableCell align="right">Average Per Order (£)</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sellers ? (
            <>
              {sellers.map((seller) => (
                <TableRow key={seller.id}>
                  <TableCell component="th" scope="row">
                    {seller.username}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.orders_placed}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.total_billed.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.outstanding_to_pay.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {(
                      seller.profile.total_billed -
                      seller.profile.outstanding_to_pay
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    {seller.profile.value_of_order_average?.toFixed(2)}
                  </TableCell>
                  <TableCell align="right" style={{ padding: '5px' }}>
                    <EditIcon onClick={() => handleClickOpenEdit(seller)} />{' '}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
      <ModalCreateVendedor
        open={open}
        onClose={() => {
          setOpen(false);
          setOpenEdit(false);
        }}
      />
      <ModalEditVendedor
        open={openEdit}
        onClose={() => {
          setOpen(false);
          setOpenEdit(false);
        }}
        seller={selectedSeller}
      />
    </TableContainer>
  );
}
