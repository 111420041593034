import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './../styles';
import Receipt from 'helpers/Receipt';
import { IKitchen } from 'interfaces/kitchen';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import Currency from 'utils/currency';
import { shopURL } from 'utils/shopURL';
interface IProps {
    handleClose: () => void;
    order: null | IKitchen;
    isPaid: boolean;
    editId: string;
}

export default function CustomerModal(props: IProps) {
    const classes = useStyles()
    const { handleClose, order, isPaid, editId } = props;
    const getVariationPrice = (id, product) => {
        var variation = product.variation.find((el) => el.id === id)
        if (variation) return variation.price
        return 0
    }
    const getVariationName = (id, product) => {
        var variation = product.variation.find((el) => el.id === id)
        if (variation) return variation.name
        return ""
    }
    const productPrice = (product) => {
        if (product?.variation) {
            return getVariationPrice(product.variation.id, product.product) * product.quantity
        }
        return product.quantity * product.product.price
    }
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "space-between" }}>

                <Typography variant="h4">Order {editId ? "edited" : "created"}</Typography>
                <IconButton
                    style={{ width: '50px' }}
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Typography variant="h5">Order: {order?.order_code}</Typography>
                {!isPaid ? <Typography style={{ marginTop: "15px" }}>Your order has been created! We have sent an sms to the client to proceed with the payment. You can access the payment page by clicking this <a target="_blank" href={`${shopURL()}payment/${order?.order_id}`}>link</a>.</Typography> : null}

                <div style={{ marginTop: "25px" }}>
                    <Typography variant="h5">Items</Typography>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {order?.items.map((item) => (
                            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "10px 10px", backgroundColor: "#EFEFEF", marginTop: "10px", display: "flex", flexDirection: "column" }}>
                                <div style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", display: "flex", }}>
                                    <Typography style={{ width: "fit-content" }} >{item.quantity}x {item.product.name}</Typography>
                                    <Typography style={{ width: "fit-content" }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }
                                    ).format(productPrice(item))}</Typography>
                                </div>
                                {item.variation && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "15px" }}>
                                    <Typography style={{ fontWeight: 400, color: "GrayText" }}>{getVariationName(item.variation.id, item.product)}</Typography>

                                </div>}
                            </div>
                        ))}
                    </div>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                        <Typography>Subtotal: </Typography>
                        <Typography> <Currency /> {new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                        ).format(order?.sub_total /*- order?.vat_total */)}</Typography>
                    </div>
                    {order.customer_service_fee > 0 &&
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography>Service fee: </Typography>
                            <Typography> <Currency /> {new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                            ).format(order?.customer_service_fee)}</Typography>
                        </div>}

                    {order.type_of_order.includes("-D") ?
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography>Delivery fee: </Typography>
                            <Typography> {(order?.delivery_fee === 0)
                                ? "Free"
                                : <> <Currency /> {new Intl.NumberFormat('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }
                                ).format(order?.delivery_fee)}</>}</Typography>
                        </div>
                        : null}
                    {order.vat_total > 0 &&
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography>VAT: </Typography>
                            <Typography> <Currency /> {new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                            ).format(order?.vat_total)}</Typography>
                        </div>}
                    {order.discount > 0 &&
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography>Discount: </Typography>
                            <Typography> <Currency /> -{new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                            ).format(order?.discount)}</Typography>
                        </div>}
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography>Total: </Typography>
                        <Typography> <Currency /> {new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                        ).format(order?.total_order)}</Typography>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>

                    <button
                        onClick={() => {
                            handleClose()
                        }}
                        className={classes.submitButton}><Typography style={{ fontWeight: 500 }}>Create new order</Typography></button>
                    <Receipt trigger={
                        <button

                            className={classes.submitButton}><Typography style={{ fontWeight: 500 }}>Print invoice</Typography> <PrintIcon /></button>} order={order} />
                </div>
            </div>
        </>
    );
}
