import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Menu } from 'interfaces/menu';
import ModalCreateMenu from './ModalCreateMenu';
import ModalEditMenu from './ModalEditMenu';
import ModalDeleteMenu from './ModalDeleteMenu';
import ModalEditProductsMenu from './ModalEditProductsMenu';
import { Button, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
//import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { handleActiveMenu } from 'redux/ducks/menu';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import './styles.css';
import { NETWORK, PRIMARY_COLOR } from 'settings';
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  menus: Menu[];
  store?: number;
  categories: any[];
}

export default function MenusTable(props: IProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { menus, store, categories } = props;
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditProducts, setOpenEditProducts] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(undefined);
  const [hasOnlyOneMenu, setHasOnlyOneMenu] = React.useState(false);

  React.useEffect(() => {
    if (menus.length === 1) {
      setHasOnlyOneMenu(true);
    } else {
      setHasOnlyOneMenu(false);
    }
  }, [menus]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenEditProducts(false);
    setOpenDelete(false);
    setSelectedMenu(undefined);
  };

  const handleClickOpenEdit = (menu: Menu) => {
    setOpenEdit(true);
    setSelectedMenu(menu);
  };

  const handleClickOpenEditProducts = (menu: Menu) => {
    setOpenEditProducts(true);
    setSelectedMenu(menu);
  };

  const handleClickOpenDelete = (menu: Menu) => {
    if (!hasOnlyOneMenu) {
      setOpenDelete(true);
      setSelectedMenu(menu);
    }
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      {store !== 0 ? (
        <Button
          style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }}
          color="primary"
          onClick={handleClickOpen}
          className={classes.activeButton}
        >
          Add {NETWORK === "6" ? "Group" : "Menu"}
        </Button>
      ) : null}

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{NETWORK === "6" ? "Group" : "Menu"} Name</TableCell>
            {store > 0 && (
              <>
                <TableCell>Products</TableCell>
                <TableCell>Disable/Enable</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {menus?.map((menu) => (
            <TableRow key={menu.menu_id}>
              <TableCell component="th" scope="row">
                {menu.menu_id}
              </TableCell>
              <TableCell>{menu.name}</TableCell>
              {store > 0 && (
                <>
                  <TableCell>
                    <AddIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClickOpenEditProducts(menu)}
                    />{' '}
                  </TableCell>
                  <TableCell>
                    <Switch
                      style={{ cursor: 'pointer' }}
                      checked={menu.active}
                      onChange={() => {
                        dispatch(handleActiveMenu(menu.menu_id));
                      }}
                      color="primary"
                      name="checkedA"
                      disabled={hasOnlyOneMenu}
                    />
                  </TableCell>
                  <TableCell>
                    <EditIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClickOpenEdit(menu)}
                    />{' '}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        hasOnlyOneMenu
                          ? 'Cannot delete if there is only one menu'
                          : 'Delete'
                      }
                    >
                      <DeleteIcon
                        style={{
                          cursor: 'pointer',
                          color: hasOnlyOneMenu ? 'grey' : 'black'
                        }}
                        onClick={() => handleClickOpenDelete(menu)}
                      />
                    </Tooltip>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateMenu
        categories={categories}
        open={open}
        onClose={handleClose}
        store={store}
      />
      <ModalEditMenu
        open={openEdit}
        onClose={handleClose}
        menu={selectedMenu}
        categories={categories}
      />
      <ModalEditProductsMenu
        open={openEditProducts}
        onClose={handleClose}
        menu={selectedMenu}
        store={store}
      />
      <ModalDeleteMenu
        open={openDelete}
        onClose={handleClose}
        menu={selectedMenu}
      />
    </TableContainer>
  );
}
