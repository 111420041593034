import { NETWORK } from 'settings';

import GoLogo from 'assets/img/flogi-logo.svg';
import YallaLogo from 'assets/img/logo-yalla.svg';
import PetshopLogo from 'assets/img/petshop_logo.svg';
import NeuroshakeLogo from 'assets/img/neuroshake.svg';

import CashLogo from 'assets/img/cash.png';
import PicpayLogo from 'assets/img/picpay.png';
import NubankLogo from 'assets/img/nubank.png';
import CardLogo from 'assets/img/card.png';
import TransferLogo from 'assets/img/transfer.jpg';
import PagarMeLogo from 'assets/img/pagarme.jpg';
import MachineLogo from 'assets/img/machine.png';
import IFood from 'assets/img/ifood.png';
import { IKitchen } from 'interfaces/kitchen';

export function getLogo() {
  const logo = {
    '2': GoLogo,
    '4': YallaLogo,
    '5': NeuroshakeLogo,
    '6': PetshopLogo
  };
  if (!logo[NETWORK]) {
    return GoLogo;
  }
  return logo[NETWORK];
}

export function getPaymentLogo(value: string) {
  const logo = {
    cash: CashLogo,
    nubank: NubankLogo,
    picpay: PicpayLogo,
    card: CardLogo,
    transfer: TransferLogo,
    pagarme: PagarMeLogo,
    machine: MachineLogo,
    Ifood: IFood
  };

  return logo[value];
}

export function getPaymentType(value: string) {
  const logo = {
    cash: 'Dinheiro',
    nubank: 'Nubank',
    picpay: 'Picpay',
    Card: 'Card Online',
    transfer: 'Transferência',
    pagarme: 'Pagar Me',
    machine: 'Maquineta'
  };

  if (!(value in logo)) {
    return 'Uninformed';
  }

  return logo[value];
}

export function getUrlName(pathname: string): string {
  const urlName = {
    home: 'Home',
    order: 'Orders',
    delivery: 'Deliveries',
    completed: 'Orders completed',
    production: 'Production',
    product: 'Produção',
    ingredientpackage: 'Pacotes de compras',
    ingredient: 'Compras',
    package: 'Production packages',
    manager: 'Statistics',
    stock: 'Inventory',
    ceps: 'Delivery districts',
    stores: 'Stores',
    tables: 'Tables',
    owners: 'Store Owner',
    providers: 'Suppliers',
    menus: 'Menus / Price tables',
    customers: 'Customers',
    sellers: 'Sellers',
    products: 'Produtos',
    notifications: 'Notifications',
    history: 'Invoices',
    owner: 'Store owner',
    artists: 'Artists',
    comercialInvoice: 'Commercial invoice'
  };

  if (pathname.split('/')[2] === 'production') {
    return urlName[pathname.split('/')[3]] || '';
  }

  return urlName[pathname.split('/')[2]] || '';
}

export function getWhatsappWithPicPayMessage(order: IKitchen) {
  const phone = order.client
    ? order.client.phone.replace(/\D/g, '')
    : order.owner.phone_number.replace(/\D/g, '');
  const br = '%0a';
  let message = `ORDER Nº: *${order.order_code}*${br}Your order is already being prepared.${br}`;

  message += `${br}*Payment type*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: £: ${order?.total_order?.toFixed(2)}`;
  message += `${br}To make the payment, access the link: https://app.picpay.com/payment?type=store%26sellerId=141411%26value=${order?.total_order.toFixed(
    2
  )}`;
  message += `${br}${br}Obrigado pela Thank you for your preference.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}

export function getWhatsappWithPagarmeMessage(
  order: IKitchen,
  pagarMeURL: string
) {
  const phone = order.client
    ? order.client.phone.replace(/\D/g, '')
    : order.owner.phone_number.replace(/\D/g, '');
  const br = '%0a';
  let message = `ORDER Nº: *${order.order_code}*${br}Your order is already being prepared.${br}`;

  message += `${br}*Payment type*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: £: ${order?.total_order?.toFixed(2)}`;
  message += `${br}To make the payment, access the link: ${pagarMeURL}`;
  message += `${br}${br}Thank you for your preference.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}

export const formatCellPhone = (phoneNumber: string) => {
  const phoneWithoutCharacter = phoneNumber.replace(/\D/g, '');
  if (phoneWithoutCharacter[0] === '5' && phoneWithoutCharacter[1] === '5') {
    const stateCode = phoneWithoutCharacter.slice(2, 4);
    const phoneNumberPartOne = phoneWithoutCharacter.slice(4, 9);
    const phoneNumberPartTwo = phoneWithoutCharacter.slice(9, 13);

    return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
  }
  const stateCode = phoneWithoutCharacter.slice(0, 2);
  const phoneNumberPartOne = phoneWithoutCharacter.slice(2, 7);
  const phoneNumberPartTwo = phoneWithoutCharacter.slice(7, 11);

  return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
};
