import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteTag } from 'redux/ducks/stock';
import { Itags } from 'interfaces/store';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    tag: Itags;
    fetchTags: () => void
}

export default function ModalDeleteTag(props: SimpleDialogProps) {
    const { onClose, open, tag, fetchTags } = props;

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const submitDelete = async () => {
        dispatch(deleteTag(tag?.id)).then(
            (result: boolean) => {
                if (result) {
                    fetchTags()
                    handleClose();
                }
            }
        );
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Delete tag {tag?.name}?
            </DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitDelete} color="primary">
                    {' '}
                    Delete{' '}
                </Button>
                <Button onClick={handleClose}> Cancel </Button>
            </div>
        </Dialog>
    );
}
