import React from 'react'
import { useSelector } from 'react-redux';

export default function Currency() {
  const { currency } = useSelector((state: any) => state.currency);
  return (
    <>
      {currency} {" "}
    </>
  );
}
