import IBaseAction from 'interfaces/redux';
import { IAlertAction } from 'interfaces/alert';

export interface IIngredientPackage {
  batch: number;
  ingredients: [{
    ingredient: string;
    quantity: number;
    provider: string;
    cost: number;
  }]
}

export interface IIngredientPackageState {
  fetch: boolean;
  results: IIngredientPackage[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesIngredientPackage {
  FETCH = 'INGREDIENTPACKAGE/FETCH',
  SUCCESS = 'INGREDIENTPACKAGE/SUCCESS',
  FAILURE = 'INGREDIENTPACKAGE/FAILURE',
}

export interface IFetchAction extends IBaseAction<ETypesIngredientPackage, null> {
  type: ETypesIngredientPackage.FETCH;
}

export interface ISuccessAction
  extends IBaseAction<ETypesIngredientPackage, IIngredientPackageState> {
  type: ETypesIngredientPackage.SUCCESS;
  payload: IIngredientPackageState;
}

export interface IFailureAction extends IBaseAction<ETypesIngredientPackage, string> {
  type: ETypesIngredientPackage.FAILURE;
  payload: string;
}

export type IIngredientPackageAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction;

export type GetState = () => any;

export type PromiseAction = Promise<IIngredientPackageAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IIngredientPackageAction
    | ThunkAction
    | PromiseAction
    | Array<IIngredientPackageAction>
    | IAlertAction
) => any;
