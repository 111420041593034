import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { getHistory } from 'redux/ducks/history';
// import {
// MuiPickersUtilsProvider,
// KeyboardDatePicker
// } from '@material-ui/pickers';
import IStore from 'interfaces/store';
import Loading from 'components/Common/Loading';
import HistoryTable from './HistoryTable';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';
// import DateFnsUtils from '@date-io/date-fns';
// import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
// import formatDate from 'date-fns/format';
import { IHistoryState } from 'interfaces/history';
import moment from 'moment';
import Select from 'react-select';
import { PRIMARY_COLOR } from 'settings';
import { setCurrency } from 'redux/ducks/currency';

let storeFilter: { id: number; name: string }[] = [];

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initialDate, setInitialDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [finalDate, setFinalDate] = useState(new Date());
  const [isFiltering, setIsFiltering] = useState(false);
  const [message, setMessage] = useState('Showing all orders.');
  const orders = useSelector<IStore, IHistoryState>((state) => state.history);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const { authentication } = useSelector<IStore, IStore>((state) => state);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const [data, setData] = useState([]);
  storeFilter = [];
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: PRIMARY_COLOR(),
      boxShadow: 'none',
      ':hover': {
        borderColor: PRIMARY_COLOR()
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
    })
  };

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });

  useEffect(() => {
    if (!activeStoreFilter && results[0]) {
      setActiveStoreFilter(storeFilter[0]?.id);
      dispatch(setCurrency(results[0].currency))
    }

  }, [results]);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = PRIMARY_COLOR();
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-history');
    if (d.item(0)) {
      d.item(0).style.color = '#ff5a23';

      d.item(1).style.color = '#ff5a23';
    }
  });

  const getOrders = () =>{
    if (activeStoreFilter) {
      const _initialDate = initialDate
        ? moment(initialDate).format('YYYY-MM-DD')
        : null;
      const _finalDate = finalDate
        ? moment(finalDate).format('YYYY-MM-DD')
        : null;
      dispatch(
        getHistory({
          starts: _initialDate,
          ends: _finalDate,
          store: activeStoreFilter
        })
      );
      filter();
    }
    dispatch(getStore());
  }
  useEffect(() => {
    getOrders()
  }, [dispatch, activeStoreFilter]);

  useEffect(() => {
    filter();
  }, [orders.results]);
  const filter = () => {
    var filteredOrders = orders.results;
    filteredOrders = filteredOrders.filter((order) => {
      var date = new Date(order.date_ordered);
      if (
        date.getTime() <= finalDate.getTime() &&
        date.getTime() >= initialDate.getTime() &&
        (activeStoreFilter === 0 || activeStoreFilter === order.store_id)
      ) {
        return true;
      }
      return false;
    });
    filteredOrders.sort((a,b) =>(  b.order_id-a.order_id))
    setData(filteredOrders);
    setIsFiltering(true);
    const _message = `Showing orders from ${initialDate ? moment(initialDate).format('DD/MM/YYYY') : 'beginning'
      } until ${moment(finalDate).format('DD/MM/YYYY')}`;
    setMessage(_message);
  };
  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };
  // const [date, setDate] = useState(new Date());

  // const handleDateRange = () => {
  //   dispatch(fetchProducts());
  // };
  const showStores = () => {
    var options = [];
    for (let index = 0; index < storeFilter.length; index++) {
      const element = storeFilter[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    if (authentication.is_superuser) {
      options.push({ value: 0, label: 'ALL' });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeStoreFilter;
    });
    return (
      <>
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeStoreFilter === 0 ? 0 : activeStoreFilter,
            label:
              activeStoreFilter === 0
                ? 'All'
                : storeFilter[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => handleActiveStoreFilter(e.value)}
          styles={customStyles}
        />
      </>
    );
  };
  return (
    <>
      <Loading show={orders.fetch} />
      <Grid container spacing={3}>
        {storeFilter.length > 2 && <Grid
          style={{
            marginBottom: 0,
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px'
          }}
        >
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>{' '}
          {showStores()}
        </Grid>}
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <Grid item container xs={4} alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-initial"
                label="Selecione a Data"
                value={date}
                onChange={setDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
            <Button style={{ alignSelf: 'flex-end' }} onClick={handleDateRange}>
              <SearchIcon className={classes.datePickerButton} />
            </Button>
          </Grid> */}
        </Grid>

        <HistoryTable
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          isFiltering={isFiltering}
          filter={filter}
          message={message}
          orders={data}
          getOrders={getOrders}
        />
      </Grid>
    </>
  );
}
