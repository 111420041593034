import React from 'react';
import { ITable } from 'interfaces/table';
import { Grid, Card, Typography } from '@material-ui/core'; //,Button
import Receipt from './Receipt';

// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

import formatDate from 'date-fns/format';

// interface IProps {
//   batch: ITable;
//   toPrint?: boolean;
// }

interface IProps {
  batch: ITable;
  initialDate?: Date;
  finalDate?: Date;
  isFiltering?: Boolean;
  filter?: any;
  setInitialDate?: any;
  setFinalDate?: any;
  message?: string;
}

export default function Panel(props: IProps): JSX.Element {
  // const { batch, toPrint } = props;
  const {
    batch
    // initialDate,
    // setInitialDate,
    // finalDate,
    // setFinalDate,
    // isFiltering,
    // filter,
    // message
  } = props;

  // console.log(orders)

  // const handleInitalDateChange = (date: Date) => {
  //   setInitialDate(date);
  // };

  // const handleFinalDateChange = (date: Date) => {
  //   setFinalDate(date);
  // };

  // const filterMessage = () => {
  //   const style = {
  //     color: '#233472',
  //     padding: '15px 0 0 0',
  //     fontWeight: 600
  //   };

  //   if (isFiltering) {
  //     return (
  //       <div style={{ marginTop: 10 }}>
  //         <span style={style}>{message}</span>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ marginTop: 10 }}>
  //         <span style={style}>{message}</span>
  //       </div>
  //     );
  //   }
  // };
  return (
    <Grid item sm={6} md={3} lg={3}>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 15,
          padding: 15,
          backgroundColor: '#f5f5f5',
          borderRadius: 5
        }}
      >
        <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
          <Grid
            style={{
              alignItems: 'center',
              display: 'inline-flex'
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Data Inicial</span>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-initial"
                  value={initialDate}
                  placeholder="                ---------------"
                  onChange={handleInitalDateChange}
                  autoOk={true}
                  style={{ width: 189, marginRight: 5 }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Data Final</span>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-final"
                  margin="none"
                  value={finalDate}
                  onChange={handleFinalDateChange}
                  autoOk={true}
                  style={{ width: 189 }}
                />
              </div>
            </MuiPickersUtilsProvider>
          </Grid>

          <div style={{ display: 'inline-flex', marginLeft: 5 }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: 5, fontSize: '0.8em', padding: '5px 16px' }}
              onClick={filter}
            >
              Filtrar
            </Button>
          </div>
        </div>

        {filterMessage()}
      </div> */}
      <Card style={{ padding: 10, height: '100%' }}>
        <Typography
          variant="subtitle2"
          align="left"
          style={{ fontWeight: 300, fontSize: 16 }}
        >
          {batch?.created_at &&
            `${formatDate(new Date(batch?.created_at), 'HH:mm - dd/MM/yyyy')}`}
        </Typography>
        <Typography
          variant="subtitle2"
          align="left"
          style={{ fontWeight: 300, fontSize: 16 }}
        >
          {batch?.order_code
            ? `${batch.order_code}`
            : `${batch.id} - ${batch.name}`}
        </Typography>
        {batch.orders.map((order) => (
          <>
            <Typography
              variant="subtitle2"
              align="left"
              style={{ fontWeight: 300, fontSize: 16 }}
            >
              {`${order.order_code}`}
            </Typography>
            <hr />
            {order?.items?.map((item, index) => (
              <Typography key={index} align="left" style={{ fontSize: 12 }}>
                {`${item.quantity} x ${item.product.name}`}
              </Typography>
            ))}
            <Typography
              align="left"
              style={{ fontWeight: 600, fontSize: 14, marginTop: 10 }}
            >
              {`Order Total: £ ${order.total_order}`}
            </Typography>
            <hr />
          </>
        ))}
        <Grid>
          {batch?.order_code && (
            <Receipt
              table={batch}
              // disabled={currentOrderID === order.order_id}
            />
          )}
          {batch.table_total ? (
            <Typography align="right" style={{ fontWeight: 600, fontSize: 14 }}>
              {`Total: £ ${batch.table_total}`}
            </Typography>
          ) : null}
          {batch.status ? (
            <Typography align="right" style={{ fontWeight: 600, fontSize: 14 }}>
              {`${
                batch.status === 'Available'
                  ? 'Available'
                  : batch.status === 'Active'
                  ? 'Active'
                  : batch.status === 'Need Payment'
                  ? 'Need Payment'
                  : null
              }`}
            </Typography>
          ) : (
            <Typography align="right" style={{ fontWeight: 600, fontSize: 14 }}>
              Complete
            </Typography>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}
