import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  fetchBestBuyers,
  fetchLastOrder
} from 'redux/ducks/customersAndSellers';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import CustomersTable from './CustomersTable';
import LastOrderModal from './LastOrderModal';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';
import useStyles from './styles';
import Select from 'react-select';
import { NETWORK } from 'settings';
import './styles.css';

var storeFilter: { id: number; name: string }[] = [];
const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: '#124c4d',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#124c4d'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#124c4d' : '#fdfdfd'
  })
};

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { customersAndSellers, authentication } = useSelector<IStore, IStore>(
    (state) => state
  );

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const [query, setQuery] = useState("")
  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });

  useEffect(() => {
    if (activeStoreFilter === undefined) {
      setActiveStoreFilter(storeFilter[0]?.id);
    }
  }, [results]);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    if (control) {
      control.forEach((c) => {
        c.style.color = '#124c4d';
      });
    }

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    if (icon) {
      icon.forEach((c) => {
        c.style.color = '#BBBBBB';
      });
    }

    const d = document.querySelectorAll<HTMLElement>('.d-customers');
    if (d.item(0)) {
      d.item(0).style.color = '#ff5a23';

      d.item(1).style.color = '#ff5a23';
    }
  });
  const filter = () => {
    if (query) {
      return customersAndSellers.customers.filter(el => {
        return el.name.toLowerCase().includes(query.toLowerCase()) || el.email.toLowerCase().includes(query.toLowerCase())
      })
    }
    return customersAndSellers.customers
  }
  const getCustomers = () => {
    var params = {
      store: activeStoreFilter,
      network: NETWORK,
      period: "All"
    };
    if (!authentication.is_superuser || activeStoreFilter !== 0) {
      delete params.network;
    } else {
      delete params.store;
    }
    dispatch(fetchBestBuyers(params));
    dispatch(getStore());
  }
  useEffect(() => {
    getCustomers()
  }, [dispatch, activeStoreFilter]);


  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  const handleLastOrder = async (orderId: number) => {
    setLoading(true);
    setOpenModal(true);
    await dispatch(fetchLastOrder(orderId));
    setLoading(false);
  };

  const showStores = () => {
    var options = [];
    for (let index = 0; index < storeFilter.length; index++) {
      const element = storeFilter[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeStoreFilter;
    });
    return (
      <>
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeStoreFilter === 0 ? 0 : activeStoreFilter,
            label:
              activeStoreFilter === 0
                ? 'All'
                : storeFilter[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => handleActiveStoreFilter(e.value)}
          styles={customStyles}
        />
        <Button
          onClick={() => {
            handleActiveStoreFilter(0);
          }}
          variant="outlined"
          size="small"
          className={
            activeStoreFilter === 0 ? classes.activeButton : classes.button
          }
        >
          All
        </Button>
      </>
    );
  };
  return (
    <>
      <Loading show={customersAndSellers.fetch} />
      <LastOrderModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        orderToShow={customersAndSellers.last_order}
        loading={loading}
      />

      {!authentication.is_superuser && (
        <Grid
          style={{
            marginBottom: 10,
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>{' '}
          {showStores()}
        </Grid>
      )}
      <Grid container spacing={3}>
        <CustomersTable
          customers={filter()}
          handleLastOrder={handleLastOrder}
          query={query}
          setQuery={(e) => setQuery(e)}
          getCustomers={() => getCustomers()}
        />
      </Grid>
    </>
  );
}
