import React from 'react';
import { Typography, IconButton, Radio, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './styles';
import { NETWORK } from 'settings';
import { Product } from 'interfaces/stock';
import { useDispatch } from 'react-redux';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import Currency from 'utils/currency';

interface IProps {
    handleClose: () => void;
    product: Product;
    addextra: (extra_id) => void;
    removeExtra: (extra_id) => void;
    pickSize: (selected_size: { id: number; portion: string; price: number; serves: number; }) => void
    selectedSize: { id: number; portion: string; price: number; serves: number; }
}

export default function CustomerModal(props: IProps) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { handleClose, product, addextra, removeExtra, pickSize, selectedSize } = props;
    const isDisabled = () => {
        if (NETWORK === "6") {
            if (selectedSize.id !== 0) return false
            else return true
        } else {
            return false
        }
    }
    return (
        <div className={classes.product_details_wrapper}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "space-between" }}>

                <Typography variant="h5">{product.name} details</Typography>
                <IconButton
                    style={{ width: '50px' }}
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                {NETWORK === "6" ?
                    <>
                        {product.sizes.map((size, idx) => (
                            <>
                                {
                                    selectedSize &&
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Radio checked={selectedSize.id === size.id} onClick={() => pickSize(size)} />
                                            <Typography>{size.portion}</Typography>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Typography><Currency /> {new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }).format(size.price)}</Typography>

                                        </div>
                                    </div>
                                }

                            </>
                        ))}
                    </> :
                    <>
                        {product.extras.map((extra, idx) => (
                            <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <Typography style={{ fontWeight: 500 }}>{extra.ingredient}</Typography>
                                        <Typography style={{ fontWeight: 400 }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(extra.price)}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <IconButton onClick={() => removeExtra(idx)}><RemoveIcon /> </IconButton>
                                        <Typography>{extra.quantity - 1}</Typography>
                                        <IconButton onClick={() => addextra(idx)}><AddIcon /> </IconButton>

                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                }
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={isDisabled() ? "Please select a size to proceed" : ""}>
                        <button
                            onClick={() => {
                                if (!isDisabled()) handleClose()
                                else dispatch(sendAlert("Please select a size to proceed", EAlertVariant.ERROR))
                            }}
                            className={isDisabled() ? classes.disabledSubmitButton : classes.submitButton}><Typography style={{ fontWeight: 500 }}>Save</Typography></button>

                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
