import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { useStyles } from './styles';

import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { EMethod } from 'enums/method';

import Axios from 'axios';
import { API_ENDPOINT } from '../../settings';
import Logo from 'helpers/logo';
export default function ForgotPassword(): JSX.Element {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const indexStartUid = window.location.href.indexOf('=') + 1;
  const indexFinalUid = window.location.href.indexOf('&');

  const uid = window.location.href.slice(indexStartUid, indexFinalUid);

  const indexStartToken = window.location.href.indexOf('&') + 7;

  const token = window.location.href.slice(indexStartToken);

  const handleSendPassword = async (
    password: string,
    confirmPassword: string
  ) => {
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
    } else if (password === null) {
      setMessage("Passsword can't be blank");
    } else if (password.trim().length < 8) {
      setMessage('Your password must have at least 8 characters');
    } else if (Number(password)) {
      setMessage("Your password can't be numeric only");
    } else {
      const request_url = `${API_ENDPOINT}/users/api/clients/realtime_password_reset/`;
      setLoading(true);
      await Axios({
        method: EMethod.POST,
        data: {
          uid,
          token,
          password,
          confirm_password: confirmPassword
        },
        url: request_url
      })
        .then((response) => {
          console.log(response);
          setLoading(false);
          setMessage(
            'Password defined successfully. We will redirect you to login.'
          );
          window.location.href = '/';
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          console.log(err);
          setMessage("This email doesn't exist");
        });
    }
  };

  const handlePassword = (event: any) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleClickShowConfirmPassword = () => {
    showConfirmPassword
      ? setShowConfirmPassword(false)
      : setShowConfirmPassword(true);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo styling={{ width: "300px" }} />
        <FormValidation onSubmit={() => { }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              className={classes.passwordTextField}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-adornment-confirmPassword">
              Confirm your password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirmPassword"
              className={classes.passwordTextField}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>

          <Button
            data-testid="submit"
            type="submit"
            fullWidth
            disabled={false}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              handleSendPassword(password, confirmPassword);
            }}
          >
            Send
          </Button>
          {loading ? (
            <span>
              <LinearProgress />
            </span>
          ) : (
            <span
              className={
                message ===
                  'Password defined successfully. We will redirect you to login.'
                  ? classes.successfully
                  : classes.error
              }
            >
              {message}
            </span>
          )}
        </FormValidation>
      </div>
    </Container>
  );
}
