import React from 'react';

import { IKitchen } from 'interfaces/kitchen';

import formatDate from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';

import Logo from 'helpers/logo';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Print from 'components/Common/Print';

import { useStyles } from './styles';

interface IProps {
    order: IKitchen;
    trigger: any
}

export default function Receipt(props: IProps): JSX.Element {
    const { order, trigger } = props;
    const { order_code, date_ordered, items, date_delivery } = order;
    const classes = useStyles();
    return (
        <Print
            trigger={
                <Button size="medium" title="Print">
                    {trigger}
                </Button>
            }
        >
            <Card>
                <Grid container justify="center" className={classes.receiptHeader}>
                    <Grid>
                        <Logo
                            styling={{
                                marginBottom: '-50px', width: "250px",
                                height: "250px"
                            }}

                        />
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                marginTop: 10,
                                fontSize: 22,
                                marginBottom: 10
                            }}
                        >
                            {order_code?.toUpperCase()}
                        </Typography>
                        {order?.table && (
                            <Typography style={{ marginTop: 10 }}>
                                Table: {order?.table?.name?.toUpperCase()}
                            </Typography>
                        )}
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>Order date: </span>
                            {formatDate(new Date(date_ordered), 'dd/MM/yyyy - hh:mm')}
                        </Typography>
                        <Typography style={{ marginBottom: 10 }}>
                            <span style={{ fontWeight: 'bold' }}>
                                {order?.type_of_order === 'O-D'
                                    ? 'Delivery date: '
                                    : 'Collection date: '}
                            </span>
                            {formatDate(new Date(date_delivery), 'dd/MM/yyyy - hh:mm')}
                        </Typography>

                        {order?.owner ? (
                            <>
                                {' '}
                                <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>
                                    Client infos
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Name: </span>
                                    {order?.owner?.name}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Phone number: </span>
                                    {order?.owner?.phone_number}
                                </Typography>
                            </>
                        ) : null}
                        {order?.type_of_order === 'O-D' ? (
                            <>
                                <Typography
                                    style={{ fontWeight: 'bold', fontSize: 22, marginTop: 10 }}
                                >
                                    Delivery address
                                </Typography>
                                <Typography style={{ maxWidth: 200 }}>
                                    {`Address:  ${order?.delivery_address}`}
                                </Typography>
                            </>
                        ) : null}

                        <Typography
                            style={{ fontWeight: 'bold', fontSize: 22, marginTop: 10 }}
                        >
                            Order summary
                        </Typography>

                        {items.map((item) =>
                            item.quantity > 0 ? (
                                <span key={uuidv4()}>
                                    <Typography
                                        style={{ fontWeight: 'bold' }}
                                        key={item?.product?.product_id}
                                    >
                                        <span style={{ fontWeight: 'bold' }}>
                                            {item?.quantity}x
                                        </span>{' '}
                                        {`${item?.product?.name}`}
                                    </Typography>
                                    {item?.comment ? (
                                        <Typography>
                                            <span>
                                                Comment:{` ${item?.comment}`}
                                                {'. '}
                                            </span>
                                        </Typography>
                                    ) : null}
                                    {item?.extra_orders.length > 0 ? (
                                        <Typography>
                                            Extras:
                                            {item?.extra_orders.map((extra) => (
                                                <Typography key={extra.id}>
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        {' '}
                                                        {extra?.quantity}x
                                                    </span>
                                                    {` ${extra?.ingredient}`};
                                                </Typography>
                                            ))}
                                        </Typography>
                                    ) : null}
                                </span>
                            ) : null
                        )}

                        <Typography
                            style={{ fontWeight: 'bold', marginTop: 10, fontSize: 22 }}
                        >
                            Payment details
                        </Typography>
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>Subtotal: </span>£
                            {(
                                Number(order?.total_order?.toFixed(2)) -
                                Number(order?.delivery_fee) -
                                Number(order?.customer_service_fee)
                            ).toFixed(2)}
                        </Typography>
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>Service fee: </span>£
                            {/* {Number(order?.customer_service_fee).toFixed(2)} */}
                            {new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }).format(order?.customer_service_fee)}
                        </Typography>
                        {order?.type_of_order.includes('D') ? (
                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>Delivery fee: </span>£
                                {/* {Number(order?.delivery_fee).toFixed(2)} */}
                                {new Intl.NumberFormat('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }).format(order?.delivery_fee)}
                            </Typography>
                        ) : null}
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>Total: </span>£
                            {new Intl.NumberFormat('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }).format(order?.total_order)}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Print>
    );
}

Receipt.defaultProps = {
    disabled: false
};
