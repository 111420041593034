import React from 'react';
import { DialogContent, DialogTitle, Dialog, Table, TableContainer, TableRow, Paper, TableCell, TableHead, TableBody } from '@material-ui/core'
import { Product } from 'interfaces/stock';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    product: Product
}
const useStyles = makeStyles({


    table: {
        minWidth: '100%'
    },
    tableContainer: {
        margin: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
export default function ModalResevedProducts(props: SimpleDialogProps) {
    const classes = useStyles();

    const { onClose, open, product } = props;


    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                {product?.name}
            </DialogTitle>
            <DialogContent style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                {product !== undefined && <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    product &&
                                    product?.next_days.map((value, index) => (
                                        <TableCell
                                            align="center"
                                            className={index === 5 ? 'hide-on-mobile' : ''}
                                            style={{ padding: '5px' }}
                                            key={index}
                                        >
                                            {value.date}
                                        </TableCell>
                                    ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {product.next_days.map((value, index) => (
                                    <TableCell
                                        align="center"
                                        style={{ padding: '5px' }}
                                        key={index}
                                    >
                                        {value?.value}
                                    </TableCell>
                                ))}

                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>}
            </DialogContent>
        </Dialog>
    );
}
