import React, { useState } from "react";
import { IGiftCard } from "interfaces/store";
import { TableRow, TableCell, IconButton, Switch, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
//import ModalDeleteGiftCard from "./ModalDeleteGiftCard";
import ModalEditGiftCard from "./ModalEditGiftCard";
import { toggleGiftCard } from "redux/ducks/stock";
import { useDispatch } from "react-redux";
import Currency from 'utils/currency';

interface Iprops {
    giftCard: IGiftCard,
    store: number,
    fetchGiftCards: () => void
}

export default function GiftCardRow(props: Iprops) {
    const dispatch = useDispatch()
    const { giftCard, store, fetchGiftCards } = props
    const [edit, setEdit] = useState(false);
    //const [deleteModal, setDeleteModal] = useState(false);
    return (
        <>
            <TableRow key={giftCard.id}>
                <TableCell >
                    {giftCard.id}
                </TableCell>
                <TableCell><Switch checked={giftCard.active} onChange={() => {
                    toggleGiftCard({ active: !giftCard.active }, giftCard.id, dispatch).then(() => {
                        fetchGiftCards()
                    })
                }} /></TableCell>
                <TableCell>
                    <Typography>
                        <Currency />
                        {new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(giftCard.value)}
                    </Typography></TableCell>
                <TableCell>
                    <Typography>
                        <Currency />
                        {new Intl.NumberFormat('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(giftCard.balance)}
                    </Typography>
                </TableCell>
                <TableCell>{giftCard.buyer_user}</TableCell>

                <TableCell>{giftCard.code}</TableCell>
                {store > 0 && (
                    <>
                        <TableCell component="th" scope="row">
                            <IconButton onClick={() => setEdit(true)}><Edit /></IconButton>
                        </TableCell>
                    </>
                )}
            </TableRow>
            {/*  <ModalDeleteGiftCard giftCard={giftCard} open={deleteModal} onClose={() => setDeleteModal(false)} fetchGiftCards={() => fetchGiftCards()} /> */}
            <ModalEditGiftCard giftCard={giftCard} open={edit} onClose={() => setEdit(false)} fetchGiftCards={() => fetchGiftCards()} />
        </>
    )
}