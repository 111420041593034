import jwtDecode from 'jwt-decode';

import {
  IAuthenticationState,
  ICredentials,
  IClaims,
  IFetchAction,
  ISuccessAction,
  IFailureAction,
  IResetAction,
  IAuthenticationAction,
  Dispatch,
  ThunkAction,
  ETypesAuthentication,
  IToken,
  ICredentialsWaiter
} from 'interfaces/authentication';

import { EAlertVariant } from 'interfaces/alert';

import { sendAlert } from './alert';

import { fetchAuth, fetchAuthWaiter } from 'utils/request';
import { NETWORK, NETWORKS_LIST } from 'settings';
import { setNetworks } from './networks';
/* Authentication State. */
const initialState: IAuthenticationState = {
  fetch: false,
  token: '',
  email: '',
  name: '',
  phone: '',
  is_superuser: false,
  is_manager: false,
  is_store_owner: false,
  is_waiter_manager: false,
  is_waiter: false,
  new_user: false,
  exp: 0,
  id: 0,
  authorized: false,
  error: '',
  stripe_profiles: {
    network_id: 0,
    account_stripe_link: '',
    account_connect_status: '',
    stripe_login_link: ''
  },
  account_stripe_KYC_status: false
};

/* Authentication Reducer. */
export default (
  state: IAuthenticationState = initialState,
  action: IAuthenticationAction
): IAuthenticationState => {
  switch (action.type) {
    case ETypesAuthentication.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesAuthentication.SUCCESS:
      return {
        ...state,
        fetch: false,
        token: action.payload.token,
        email: action.payload.email,
        phone: action.payload.phone,
        is_superuser: action.payload.is_superuser,
        is_manager: action.payload.is_manager,
        is_waiter_manager: action.payload.is_waiter_manager,
        is_waiter: action.payload.is_waiter,
        is_store_owner: action.payload.is_store_owner,
        new_user: action.payload.new_user,
        id: action.payload.id,
        exp: action.payload.exp,
        name: action.payload.name,
        authorized: true,
        error: '',
        stripe_profiles: action.payload.stripe_profiles,
        account_stripe_KYC_status: action.payload.account_stripe_KYC_status
      };
    case ETypesAuthentication.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    case ETypesAuthentication.RESET:
      if (localStorage.getItem('network')) {
        localStorage.removeItem('network');
        window.location.reload();
      }
      return initialState;
    default:
      return state;
  }
};

/* Authentication Action Creators Functions. */
export const fetchAuthentication = (): IFetchAction => ({
  type: ETypesAuthentication.FETCH
});

export const successAuthentication = (payload: IToken): ISuccessAction => ({
  type: ETypesAuthentication.SUCCESS,
  payload
});

export const failureAuthentication = (payload: string): IFailureAction => ({
  type: ETypesAuthentication.FAILURE,
  payload
});

export const resetAuthentication = (): IResetAction => ({
  type: ETypesAuthentication.RESET
});

/* Authentication Side Effects Functions. */
export const checkAuthentication =
  (credentials: ICredentials, network: string): ThunkAction =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(fetchAuthentication());
        const response = await fetchAuth(credentials);
        console.log("response data ", response)
        const { token, user } = response;
        const claims: IClaims = jwtDecode(token);
        const { exp } = claims;
        const { id, email, is_superuser, profile, stores } = user;
        var networks = [];
        stores.map((store) => {
          if (
            !networks.some((e) => e.network === store.networks[0]) &&
            NETWORKS_LIST.some((e) => e.id === store.networks[0])
          ) {
            networks.push(store.networks[0]);
          }
        });
        dispatch(setNetworks(networks))
        console.log("networks ", networks, NETWORK)
        var userNetwork = networks[0] ?? NETWORK
        var profileObject;
        profile.stripe_profiles.forEach((profile) => {
          if (profile.network_id == userNetwork) {
            profileObject = profile;
          }
        });
        var payload: IToken = {
          token,
          id,
          name: (profile !== null && profile.name) || '',
          phone: (profile !== null && profile.phone_number) || '',
          is_superuser,
          is_manager: profile.is_stripe_owner,
          is_store_owner: false,
          is_waiter: false,
          is_waiter_manager: false,
          new_user: profile !== null && profile.is_onboarding,
          email,
          exp,
          stripe_profiles: profileObject ? profileObject : null,
          account_stripe_KYC_status: is_superuser
            ? true
            : profileObject
              ? profileObject.account_stripe_KYC_status
              : false
        };

        console.log("payload data ", payload)
        if (is_superuser) {
          payload.stripe_profiles = {
            network_id: userNetwork,
            account_stripe_link: '',
            account_stripe_KYC_status: true,
            account_connect_status: '',
            stripe_login_link: ''
          };
        }
        if (
          (window.location.href === 'https://network.flogi.co.uk/' ||
            window.location.href === 'http://localhost:3000/' || userNetwork == 15) && networks.length < 2
        ) {

          localStorage.setItem('network', userNetwork);
          window.location.reload();

        }
        dispatch(successAuthentication(payload));
      } catch (error) {
        switch (error) {
          case 'Não reconhecemos seu usuário ou senha':
            error = "We don't recognize your user or password";
            break;
          default:
            break;
        }
        dispatch(failureAuthentication(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
export const checkAuthenticationWaiter =
  (credentials: ICredentialsWaiter): ThunkAction =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(fetchAuthentication());
        const { token, user } = await fetchAuthWaiter(credentials);

        const claims: IClaims = jwtDecode(token);
        const { exp } = claims;
        const { id, email, is_manager, first_name, phone } = user;

        const payload: IToken = {
          token,
          id,
          name: first_name,
          phone: phone,
          is_superuser: false,
          is_manager: false,
          is_store_owner: false,
          is_waiter: !is_manager,
          is_waiter_manager: is_manager,
          new_user: false,
          email,
          exp,
          stripe_profiles: {
            network_id: 0,
            account_stripe_link: '',
            account_connect_status: 'Enabled',
            stripe_login_link: ''
          },
          account_stripe_KYC_status: false
        };

        dispatch(successAuthentication(payload));
      } catch (error) {
        dispatch(failureAuthentication(error));
        dispatch(sendAlert(error, EAlertVariant.ERROR));
      }
    };
