import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchMenusGeneral } from 'redux/ducks/menu';
import IStore from 'interfaces/store';
import { IStoreState } from 'interfaces/store_test';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { NETWORK } from 'settings';
import Loading from 'components/Common/Loading';
import MenusTable from './MenusTable';
import useStyles from './styles';
import { getStore } from 'redux/ducks/store';
import { fetchCategoriesGeneral } from 'redux/ducks/stock';
var storeFilter: { id: number; name: string }[] = [];

export default function Menus(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { stock, menu } = useSelector<IStore, IStore>((state) => state);
  const { authentication } = useSelector<IStore, IStore>((state) => state);
  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  if (authentication.is_superuser) {
    storeFilter.push({ id: 0, name: 'TODAS' });
  }
  useEffect(() => {
    if (!authentication.is_superuser && !activeStoreFilter) {
      setActiveStoreFilter(storeFilter[0]?.id);
    }
  }, [storeFilter]);

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    const d = document.querySelectorAll<HTMLElement>('.d-config');
    d.item(0).style.color = '#ff5a23';

    d.item(1).style.color = '#ff5a23';
  });
  const returnCategoriesToFilter = () => {
    var categoriesToFilter = 'ALL';
    for (let index = 0; index < results.length; index++) {
      const element = results[index];
      if (element.id === activeStoreFilter) {
        return element.categories;
      }
    }
    return categoriesToFilter;
  };
  useEffect(() => {
    dispatch(getStore());
    if (!authentication.is_superuser) {
      if (activeStoreFilter !== 0 && activeStoreFilter !== undefined) {
        dispatch(fetchMenusGeneral({ store: activeStoreFilter }));
        var categoriesParams = {
          store: activeStoreFilter,
          categoriesToFilterBy:
            activeStoreFilter === 0 ? 'ALL' : returnCategoriesToFilter(),
          network: NETWORK
        };
        if (!authentication.is_superuser) {
          delete categoriesParams.network;
        }
        dispatch(fetchCategoriesGeneral(categoriesParams));
      }
    } else {
      if (activeStoreFilter !== undefined) {
        dispatch(fetchMenusGeneral({ store: activeStoreFilter }));
      }
    }
  }, [dispatch, activeStoreFilter]);

  return (
    <>
      <Loading show={menu.fetch} />
      <Grid style={{ marginBottom: 10, marginLeft: 15 }}>
        <Typography
          color="primary"
          variant="overline"
          className={`${classes.toolBarTitles}`}
        >{`store: `}</Typography>
        {storeFilter.map((elmnt) => (
          <Button
            key={uuidv4()}
            onClick={() => {
              handleActiveStoreFilter(elmnt.id);
            }}
            variant="outlined"
            size="small"
            className={
              activeStoreFilter === elmnt.id
                ? classes.activeButton
                : classes.button
            }
          >
            {elmnt.name}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={3}>
        <MenusTable
          menus={menu.menus}
          store={activeStoreFilter}
          categories={stock.categories}
        />
      </Grid>
    </>
  );
}
