import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GeneralNotification } from 'interfaces/notification';
import ModalCreateNotificationGeneral from './ModalCreateNotificationGeneral'
import { Button } from '@material-ui/core';
import formatDate from 'date-fns/format';

// import i18n from '../../i18n/i18n';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  notifications: GeneralNotification[];
}


export default function GeneralNotificationsTable(props: IProps) {
  const classes = useStyles();
  const { notifications } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button style={{ fontWeight: 'bold', fontSize: 12, margin: '10px 10px' }} color="primary" onClick={handleClickOpen} className={classes.activeButton}>Add General Notifications</Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Criation Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications?.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell component="th" scope="row">
                {notification.id}
              </TableCell>
              <TableCell>{notification.title}</TableCell>
              <TableCell>{notification.message}</TableCell>
              <TableCell>{formatDate(new Date(notification.created_date), 'dd/MM/yyyy - hh:mm')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateNotificationGeneral
        open={open}
        onClose={handleClose}
      />
    </TableContainer>
  );
}
