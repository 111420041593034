import React from 'react';
import {
    Typography, Divider, CircularProgress, IconButton, Input, InputLabel, FormControl, Button, FormHelperText
} from '@material-ui/core';
import useStyles from './../styles'
import ProductDetails from './../ProductModal'
import { NETWORK } from 'settings';
import { IKitchen } from 'interfaces/kitchen';
import OrderSuccess from './OrderSuccess'
import EditIcon from '@material-ui/icons/Edit';
import EditAddress from './editAddressModal';
import Cart from './cartIcon'
import Currency from 'utils/currency';

interface IAddress {
    street: string,
    street_number: string
    neighborhood: string,
    city: string,
    state: string,
    country: string,
    zipcode: string,
}
interface props {
    openProductDetails: boolean,
    setProductDetails: (any) => void,
    setOpenProductDetails: (boolean) => void,
    addExtra: (id: string, extra_id: number) => void,
    removeExtra: (id: string, extra_id: number) => void,
    productDetails: any,
    selectedProduct: any,
    billing: {
        street: string,
        street_number: string
        neighborhood: string,
        city: string,
        state: string,
        country: string,
        zipcode: string,
    },
    delivery: {
        street: string,
        street_number: string
        neighborhood: string,
        city: string,
        state: string,
        country: string,
        zipcode: string,
    },
    typeOfOrder: string,
    returnAddressAsString: (billing: props["billing"]) => string,
    deliveryFee: {
        service_price: number,
        delivery_price: number,
        checkout_price: number,
        discount: number,
        stuart_price: number,
        distance: string,
        duration: string,
        message: string,
    },
    submit: () => void,
    getdeliveryFee: (storeAddress: {
        city: string;
        country: string;
        neighborhood: string;
        street: string;
        street_number: string;
        zipcode: string;
    }, deliveryAddress: props["delivery"], ignoreCheckForm: boolean) => void,
    getStoreAddress: () => {
        city: string;
        country: string;
        neighborhood: string;
        street: string;
        street_number: string;
        zipcode: string;
    },
    pickSize: (selected_size: { id: number; portion: string; price: number; serves: number; }, product_id: number) => void,
    deselectProduct: (id: number) => void;
    deliveryFeeCalculated: boolean;
    orderSuccessfull: null | IKitchen;
    handleCloseOrderSuccess: () => void;
    loading: boolean;
    handleChangeBillingAddress: (value: IAddress) => void;
    checkBilling: () => boolean;
    giftCardCode: string;
    setGiftCardCode: (e) => void;
    isPaid: boolean;
    editId: string;
    selecteduser: any;
    checkGiftCard: () => void;
    giftCardError: string;
    giftCardValue: number
}
export default function OrderSummary(props: props) {
    var {
        openProductDetails,
        setProductDetails,
        setOpenProductDetails,
        addExtra,
        removeExtra,
        productDetails,
        selectedProduct,
        billing,
        typeOfOrder,
        returnAddressAsString,
        deliveryFee,
        submit,
        getdeliveryFee,
        delivery,
        getStoreAddress,
        pickSize,
        deselectProduct,
        deliveryFeeCalculated,
        orderSuccessfull,
        handleCloseOrderSuccess,
        loading,
        handleChangeBillingAddress,
        checkBilling,
        giftCardCode,
        setGiftCardCode,
        isPaid,
        editId,
        selecteduser,
        checkGiftCard,
        giftCardError,
        giftCardValue
    } = props
    const classes = useStyles()
    const [openEditAddress, setOpenEditAddress] = React.useState(false)
    const getSelectedSize = () => {
        const _product = selectedProduct.find((_product) => (_product.product_id === productDetails.product_id))
        if (_product.selected_size) {
            return _product.selected_size
        }
        return { id: 0, portion: '', price: 0, serves: 0, }
    }
    const productPrice = (product) => {
        if (product?.selected_variation.length > 0) {
            var sum = 0
            product?.selected_variation.map((el) => { sum += el.price * el.amount })
            return sum
        } else {
            return product.price * product.amount
        }
    }
    const orderTotal = () => {
        var total = 0;
        selectedProduct.map((product) => {
            var totalExtras = 0
            product.extras.map((extra) => {
                if (extra.quantity > 1) {
                    totalExtras += extra.quantity - 1 * extra.price
                }
            })
            total += productPrice(product) + totalExtras
        })
        if (typeOfOrder === "Delivery") {
            total += deliveryFee.delivery_price
        }
        return total - giftCardValue <= 0 ? 0 : total - giftCardValue
    }
    const displayOrderItems = () => {
        return (
            <>
                {
                    selectedProduct.map((product) => {
                        if (product.selected_variation.length > 0) {
                            return (
                                <>
                                    {product.selected_variation.map((variation) => (
                                        <>
                                            <div style={{ marginTop: "10px" }}>
                                                <div key={product.product_id} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                    <Typography>{variation.amount}x {product.name}</Typography>
                                                    <Typography style={{ fontWeight: 500 }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(variation.amount * variation.price)}</Typography>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "15px" }}>
                                                    <Typography style={{ fontWeight: 400, color: "GrayText" }}>{variation.name}</Typography>

                                                </div>
                                                <Divider />
                                            </div>
                                        </>
                                    ))}
                                </>
                            )

                        } else {
                            return (
                                <>
                                    <div style={{ marginTop: "10px" }}>
                                        <div key={product.product_id} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <Typography>{product.amount}x {product.name}</Typography>
                                            <Typography style={{ fontWeight: 500 }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }).format(productPrice(product))}</Typography>
                                        </div>
                                        {product.extras.map((extra) => (
                                            <>
                                                {extra.quantity > 1 ?
                                                    <>
                                                        <div key={extra.id} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "15px" }}>
                                                            <Typography style={{ fontWeight: 300, color: "GrayText" }}>{extra.quantity - 1}+ {extra.ingredient}</Typography>
                                                            <Typography style={{ fontWeight: 400, color: "GrayText" }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(extra.price * (extra.quantity - 1))}</Typography>
                                                        </div>
                                                    </> : null}
                                            </>
                                        ))}
                                        <Divider />
                                    </div>
                                </>
                            )
                        }

                    })
                }
            </>
        )
    }
    return (
        <div className={classes.left_side_content}>
            {orderSuccessfull === null ? <>
                {openProductDetails && window.innerWidth > 600 ?
                    <ProductDetails
                        pickSize={(selected_size) => pickSize(selected_size, productDetails.product_id)}
                        product={productDetails}
                        handleClose={() => {
                            var selectedSize = getSelectedSize()
                            if (NETWORK === "6" && selectedSize.id === 0) {
                                deselectProduct(productDetails.product_id)
                            }
                            setProductDetails({})
                            setOpenProductDetails(false)
                        }}
                        addextra={(extra_idx) => addExtra(productDetails["product_id"], extra_idx)}
                        removeExtra={(extra_idx) => removeExtra(productDetails["product_id"], extra_idx)}
                        selectedSize={getSelectedSize()}
                    /> :
                    <>
                        {selectedProduct.length > 0 &&
                            <>
                                {!loading ?
                                    <div id="summary">
                                        <Cart items={selectedProduct.length} />
                                        <Typography variant="h5">Order summary</Typography>
                                        <>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", marginTop: "10px", marginBottom: "20px" }}>
                                                <Typography style={{ fontWeight: 500 }}>Billing address: </Typography>
                                                <Typography style={{ marginLeft: "5px" }} >
                                                    {billing.city ? returnAddressAsString(billing) : ""}
                                                </Typography>

                                                <IconButton onClick={() => setOpenEditAddress(true)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </div>
                                            {checkBilling() && <Typography style={{ marginLeft: "5px", color: "red", marginTop: "-15px" }} >
                                                {Object.keys(selecteduser).length < 1 ? "Select a customer to continue" :  "Fill all the fields of the billing address to continue" }
                                            </Typography>}
                                        </>
                                        {NETWORK === "6" ?
                                            <div style={{ marginTop: "5px", display: "flex", flexDirection: "row", gap: "5px", alignItems: "end", justifyContent: "space-between" }}>
                                                <FormControl
                                                    style={{ width: '50%' }}
                                                    error={giftCardError !== ""}
                                                >
                                                    <InputLabel htmlFor="name-input">Giftcard code</InputLabel>
                                                    <Input
                                                        id="name-input"
                                                        type="string"
                                                        value={giftCardCode}
                                                        onChange={(e) => {
                                                            setGiftCardCode(e.target.value)
                                                        }} />
                                                    {giftCardError !== "" && <FormHelperText>{giftCardError}</FormHelperText>}
                                                </FormControl>
                                                {giftCardCode === "" ? null : <Button onClick={() => checkGiftCard()} variant='outlined'>Check code</Button>}
                                            </div> : null}
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                                            {displayOrderItems()}

                                            {(deliveryFee.delivery_price || deliveryFeeCalculated === true) && typeOfOrder === "Delivery" ?
                                                <>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                                                        <Typography>Delivery fee</Typography>
                                                        <Typography style={{ fontWeight: 500 }}>
                                                            {(deliveryFee.delivery_price === 0 && deliveryFeeCalculated === true)
                                                                ? "Free"
                                                                : <> <Currency /> {new Intl.NumberFormat('en-IN', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }
                                                                ).format(deliveryFee.delivery_price)}</>}
                                                        </Typography>
                                                    </div>
                                                    <Divider />
                                                </>
                                                : null}
                                            {giftCardValue > 0 ?
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                                                    <Typography>Gift card</Typography>
                                                    <Typography style={{ fontWeight: 500 }}><Currency /> -{new Intl.NumberFormat('en-IN', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(giftCardValue)}</Typography>
                                                </div>
                                                : null}
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                                                <Typography>Total</Typography>
                                                <Typography style={{ fontWeight: 500 }}><Currency /> {new Intl.NumberFormat('en-IN', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }).format(orderTotal())}</Typography>
                                            </div>
                                            <Divider />
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {(deliveryFee.delivery_price === 0 && deliveryFeeCalculated === false) && typeOfOrder === "Delivery" && NETWORK  !== "6" ?
                                                    <button onClick={() => getdeliveryFee(getStoreAddress(), delivery, false)} className={classes.submitButton}><Typography style={{ fontWeight: 500 }}>Calculate order</Typography></button>
                                                    :
                                                    <button onClick={() => submit()} disabled={checkBilling()} className={classes.submitButton}><Typography style={{ fontWeight: 500 }}>{editId ? "Edit" : "Create"} order</Typography></button>}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                        <CircularProgress />
                                    </div>}
                            </>
                        }
                    </>
                }</> : <OrderSuccess order={orderSuccessfull} isPaid={isPaid} editId={editId} handleClose={() => { handleCloseOrderSuccess() }} />}
            <EditAddress
                open={openEditAddress}
                address={billing}
                delivery={delivery}
                edit={(value) => handleChangeBillingAddress(value)}
                handleClose={() => setOpenEditAddress(false)}
            />
        </div>

    )
}