import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { PRIMARY_COLOR } from 'settings';
import ModalDeleteArtist from './ModalDeleteArtist'
import ModalAddArtist from './ModalAddArtist'
import ModalEditArtist from './ModalEditArtist'
const useStyles = makeStyles({
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: '15px'
    },
    input: {
        flex: 1,
        paddingLeft: '8px'
    },
    iconButton: {
        padding: 10
    },
    table: {
        minWidth: 650
    },
    tableContainer: {
        margin: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    activeButton: {
        margin: '0 5px',
        color: '#fff',
        border: 'none',
        backgroundColor: PRIMARY_COLOR(),
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
});

interface Props {
    artists: { name: string, id: number }[];
    refresh: () => void;
    getMore: () => void;
    hasMore: boolean
}

export default function IngredientsTable(props: Props) {
    const classes = useStyles();
    const { artists, refresh, getMore, hasMore } = props;
    const [openAddArtist, setOpenAddArtist] = React.useState(false);
    const [openEditArtist, setOpenEditArtist] = React.useState(false);
    const [openDeleteArtist, setOpenDeleteArtist] = React.useState(false);
    const [selectedArtist, setSelectedArtist] = React.useState(undefined);

    const handleClose = () => {
        setOpenAddArtist(false);
        setOpenEditArtist(false);
        setOpenDeleteArtist(false);
        setSelectedArtist(undefined);
    };


    const handleClickOpenEditIngredient = (artist: { name: string, id: number }) => {
        setOpenEditArtist(true);
        setSelectedArtist(artist);
    };
    const handleClickOpenDeleteIngredient = (artist: { name: string, id: number }) => {
        setOpenDeleteArtist(true);
        setSelectedArtist(artist);
    };


    return (
        <TableContainer className={classes.tableContainer} component={Paper}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <Button
                    style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        margin: '10px 10px',
                        height: '35px'
                    }}
                    color="primary"
                    onClick={() => setOpenAddArtist(true)}
                    className={classes.activeButton}
                >
                    Add artist
                </Button>
            </div>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {artists?.map((artist) => (
                        <TableRow key={artist.id}>
                            <TableCell component="th" scope="row">
                                {artist.name}
                            </TableCell>
                            <TableCell align="right">
                                <EditIcon
                                    onClick={() => handleClickOpenEditIngredient(artist)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <DeleteIcon
                                    onClick={() => handleClickOpenDeleteIngredient(artist)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>

            </Table>
            {hasMore ?
                <div onClick={() => getMore()} style={{ width: "100%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", padding: "6px 0px" }}>
                    <Typography>Show more artists</Typography>
                </div>
                : null}
            <ModalAddArtist
                open={openAddArtist}
                refresh={() => refresh()}
                onClose={handleClose}
            />
            <ModalEditArtist
                open={openEditArtist}
                onClose={handleClose}
                artist={selectedArtist}
                refresh={() => refresh()}

            />
            <ModalDeleteArtist
                onClose={handleClose}
                open={openDeleteArtist}
                artist={selectedArtist}
                refresh={() => refresh()}

            />
        </TableContainer>
    );
}
