import React from 'react';
import {
  Button, Grid, Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EFilters } from '../../enums/stock';
import { PRIMARY_COLOR } from 'settings';
import { NETWORK } from 'settings'
const useStyles = makeStyles(() => ({
  button: {
    margin: '0 5px',
    color: PRIMARY_COLOR(),
    border: 'none',
    backgroundColor: '#fdfdfd',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  datePickerButton: {
    color: PRIMARY_COLOR()
  },
  datePicker: {
    width: '20%',
    marginRight: '10px'
  },
  dateMessage: {
    color: PRIMARY_COLOR(),
    fontWeight: 'bold',
    marginLeft: '10px'
  },
  dateMessageCancelButton: {
    marginLeft: 10,
    width: 15
  }
}));

interface Props {
  filters: Array<{
    label: string;
    value: EFilters;
  }>;
  activeFilter: EFilters;
  setActiveFilter: (filter: EFilters) => void;
  sortBy: string;
  setSortBy: (e) => void;
}

const ProductFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const { filters, activeFilter, setActiveFilter, sortBy, setSortBy } = props;
  const classes = useStyles();

  const handleFilterClick = (filterValue: EFilters) => {
    setActiveFilter(filterValue);
  };

  return (
    <Grid item container direction="row" justifyContent='space-between'>
      <>
        <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          {filters.map((filter) => (
            <>
              {(NETWORK === "6" || NETWORK === "15") && filter.label === "Extras" ? null : < Button
                key={filter.value}
                variant="outlined"
                size="small"
                className={
                  activeFilter === filter.value
                    ? classes.activeButton
                    : classes.button
                }
                onClick={() => handleFilterClick(filter.value)}
              >
                {filter.label}
              </Button>}
            </>
          ))
          }
        </div>
        {NETWORK === "6" || NETWORK === "15" ? <FormControl
          variant="standard"
          style={{
            width: '250px',
          }}
          fullWidth={true}
          margin={'normal'}
        >
          <InputLabel>Sort by</InputLabel>
          <Select
            value={sortBy}
            placeholder={"Payment method"}
            onChange={(e) => {
              setSortBy(e.target.value)
            }}
            error={false}
            variant="standard"
            label="Sort by"
          >
            <MenuItem value={"last"}>First created - last created</MenuItem>
            <MenuItem value={"first"}>Last created - first created</MenuItem>
            <MenuItem value={"sold"}>Sold out</MenuItem>
          </Select>
        </FormControl> : <div />}
      </>

    </Grid >
  );
};

export default ProductFilter;
