import React from 'react';

import DefaultMenu from './menuTabs/default';
import PetshopMenu from './menuTabs/petshop';
import { NETWORK } from 'settings';
//import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
interface IProps {
  openDrawer: () => void;
  drawerOpen: boolean;
}
export default function Menu(props: IProps) {
  const { openDrawer, drawerOpen } = props;

  return (
    <>
      {NETWORK === "6" ? <PetshopMenu openDrawer={openDrawer} drawerOpen={drawerOpen} /> : <DefaultMenu openDrawer={openDrawer} drawerOpen={drawerOpen} />}
    </>
  );
}
