import React, { useState } from 'react';
import {
  Button,
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  FormHelperText,
  ThemeProvider,
  createTheme,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editStore } from 'redux/ducks/store';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import './styles.css';
// import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
//import { fetch } from './../../utils/request';
//import { sendAlert } from 'redux/ducks/alert';
//import { EAlertVariant } from './../../interfaces/alert';
import { CLASSIFICATION_CHOICES } from 'settings';
import './styles.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getStore } from 'redux/ducks/store';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  store: any;
  stores: any[];
}

/*const STORE_TYPES = [
  'Burger',
  'Pizza',
  'Indian',
  'Latino',
  'Chinese',
  'Italian',
  'Arabic',
  'Salad Bar',
  'Vegeterian',
  'Vegan',
  'Sushi',
  'Grocery',
  'Dessert',
  'Clothes',
  'Electronics',
  'Coffee'
]; */

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        maxWidth: '25vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

export default function ModalEditStore(props: SimpleDialogProps) {
  const { onClose, open, store } = props;
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [id, setId] = React.useState(0);
  const [currency, setCurrency] = React.useState('£');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [storeType, setStoreType] = React.useState('');
  const [storeTypeError, setStoreTypeError] = React.useState('');
  const [image, setImage] = React.useState('');
  const [darkLogo, setDarkLogo] = React.useState('');
  const [lightLogo, setLightLogo] = React.useState('');
  const [imageError, setImageError] = React.useState('');
  const [preparationTime, setPreparationTime] = useState(0);
  const [preparationTimeError, setPreparationTimeError] = useState('');

  // const [merchantOrderId, setMerchantOrderId] = React.useState('');
  // const [stripeKey, setStripeKey] = React.useState('');

  const [postal_code, setPostalCode] = useState('');
  const [street, setStreet] = useState('');
  const [neighbourhood, setNeighbourhood] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');

  const [postal_codeError, setPostalCodeError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [neighbourhoodError, setNeighbourhoodError] = useState('');
  const [cityError, setCityError] = useState('');

  const [storeAddress, setStoreAddress] = React.useState('');

  const [ownerEmail, setOwnerEmail] = React.useState('');
  const [ownerEmailError, setOwnerEmailError] = React.useState('');

  const [orderPrefix, setOrderPrefix] = React.useState('');
  // const [addressUrl, setAddressUrl] = React.useState('');
  // const [imageFile, setImageFile] = React.useState<File>();
  const [description, setDescription] = React.useState('')
  const dispatch = useDispatch();
  const store_local: string = localStorage.getItem('state');
  const state = JSON.parse(store_local);
  const { is_superuser, is_manager } = state.authentication;

  //ad is_store_owner etc to line above
  const [superUser, setSuperUser] = React.useState(is_superuser);
  // const [managerUser, setManagerUser] = React.useState(is_manager);
  // const [owner, setOwner] = React.useState(is_store_owner);
  // const [waiter, setWaiter] = React.useState(is_waiter);
  // const [manager, setManager] = React.useState(is_manager);
  const [sameDayDelivery, setSameDayDelivery] = React.useState(false);
  const [promote, setPromote] = React.useState(false);
  const [onlyDelivery, setOnlyDelivery] = React.useState(false);
  const [ownDelivery, setOwnDelivery] = useState(false);
  const [deliveryCostPerKm, setDeliveryCostPerKm] = useState(0);
  const [fixedDeliveryCost, setFixedDeliveryCost] = useState(0);
  const ckEditorToolbarConfig = [
    'Heading',
    '|',
    'bold',
    'italic',
    '|',
    'BulletedList',
    'NumberedList',
    '|',
    'Indent',
    '|',
    'BlockQuote',
    'undo',
    'redo'
  ];
  useEffect(() => {
    const { is_superuser } = state.authentication;
    setSuperUser(is_superuser);
    // setOwner(is_store_owner);
    // setWaiter(is_waiter);
    // setManagerUser(is_manager);
  }, [state.authentication]);
  useEffect(() => {
    if (store) {
      setId(store.id);
      setName(store.name);
      // setStripeKey(store.stripe_key);
      setPhoneNumber(store.phone_number);
      setOwnerEmail(store.store_email);
      // setMerchantOrderId('');
      setLightLogo(store.light_logo ? `https://res.cloudinary.com/arbid/${store.light_logo}` : '')
      setDarkLogo(store.dark_logo ? `https://res.cloudinary.com/arbid/${store.dark_logo}` : '')
      setOrderPrefix(store.order_prefix);
      setPostalCode(store.address.zipcode);
      setStreet(store.address.street);
      setCity(store.address.city);
      setNeighbourhood(store.address.neighborhood);
      setNumber(store.address.street_number);
      setOwnDelivery(store.own_delivery);
      setDeliveryCostPerKm(store.delivery_cost_per_km);
      setFixedDeliveryCost(store.fixed_delivery_cost);
      setPreparationTime(store.preperation_time);
      setDescription(store.description)
      /* setStoreAddress(
        `${store.address.street_number} ${store.address.street}, ${store.address.neighborhood}, ${store.address.city}, ${store.address.zipcode}`
      );*/
      // setMerchantOrderId(store.merchant_ifood_id);}
      setImage(
        store.image ? `https://res.cloudinary.com/arbid/${store.image}` : ''
      );
      if (store.slug) {
        setSlug(store.slug);
      }
      if (store.sameday_delivery) {
        setSameDayDelivery(store.sameday_delivery);
      }
      if (store.promote) {
        setPromote(store.promote);
      }
      if (store.only_delivery) {
        setOnlyDelivery(store.only_delivery);
      }
      if (store.classification) {
        setStoreType(store.classification);
      }
    }
  }, [store]);
  const handleClose = () => {
    setName('');
    setNameError('');
    setPhoneNumber('');
    // setStripeKey('');
    setPhoneNumberError('');
    setLightLogo('')
    setDarkLogo('')
    // setSlug('');
    setImage('');
    setImageError('');
    setSlugError('');
    setStoreTypeError('');
    setOwnerEmail('');
    setOwnerEmailError('');
    setOrderPrefix('');
    setStreet('');
    setPostalCode('');
    setNeighbourhood('');
    setNumber('');
    setCity('');
    setStreetError('');
    setPreparationTime(0);
    setPreparationTimeError('');
    setPostalCodeError('');
    setNeighbourhoodError('');
    setCityError('');
    setStoreAddress('');
    // setAddressUrl('');
    // setMerchantOrderId('');
    setSameDayDelivery(false);
    setStoreType('');
    onClose();
  };

  const handleName = (event: any) => {
    setName(event.target.value);
  };
  // const handleMerchantOrderId = (event: any) => {
  //   // setMerchantOrderId(event.target.value);
  // };
  const handleSlug = (event: any) => {
    setSlug(event.target.value);
    setSlugError('');
  };
  // const handleStripeKey = (event: any) => {
  //   // setStripeKey(event.target.value);
  // };
  // const handleStore = (event: any) => {
  //   setStoreToBeEditted(event.target.value);
  // };
  const handleCurrency = (event: any) => {
    setCurrency(event.target.value);
  };

  /* const handleStoreAddress = (event: any) => {
    setStoreAddress(event.target.value);
  };
 */
  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImage(event[0]);
      setImageError('');
    }
  };
  const handleDarkLogo = (event: any) => {
    if (event.length > 0) {
      setDarkLogo(event[0]);
    }
  };
  const handleLightLogo = (event: any) => {
    if (event.length > 0) {
      setLightLogo(event[0]);
    }
  };
  // const handleAddressUrl = (event: any) => {
  //   setAddressUrl(event.target.value);
  // };
  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event.target.value);
  };
  const handlePrefix = (event: any) => {
    if (event.target.value.length <= 6 && /^[^\W_]*$/.test(event.target.value))
      setOrderPrefix(event.target.value);
  };
  const handleOwnerEmail = (event: any) => {
    setOwnerEmail(event.target.value.trim());
  };
  const handleSameDayDelivery = (event: boolean) => {
    setSameDayDelivery(event);
  };
  const handlePromote = (event: boolean) => {
    setPromote(event);
  };
  const handleOnlyDelivery = (event: boolean) => {
    setOnlyDelivery(event);
  };
  const checkForm = async () => {
    let entered = false;
    if (name === '' || name === null) {
      entered = true;
      setNameError('Please insert the name');
    }
    if (phoneNumber === '' || phoneNumber === null) {
      entered = true;
      setPhoneNumberError('Please insert phone');
    }

    if (ownerEmail === '' || ownerEmail === null) {
      entered = true;
      setOwnerEmailError('Please insert email');
    }
    if (slug === '') {
      entered = true;
      setSlugError('Please insert slug');
    }
    if (image === '') {
      entered = true;
      setImageError('This field cannot be empty');
    }
    if (storeType === '') {
      entered = true;
      setStoreTypeError('Please select store type');
    }
    if (preparationTime === 0) {
      entered = true;
      setPreparationTimeError('Please insert the preparation time');
    }
    /* if (street === '') {
      entered = true;
      setStreetError('Please insert the street name');
    }

    if (neighbourhood === '') {
      entered = true;
      setNeighbourhoodError('Please insert the neighbourhood');
    }
    if (city === '') {
      entered = true;
      setCityError('Please insert the city');
    }
    if (postal_code === '') {
      entered = true;
      setPostalCodeError('Please insert the postal code');
    } */

    return entered;
  };
  const handleStoreType = (event: any) => {
    setStoreType(event.target.value);
    setStoreTypeError('');
  };

  const sanitizeImageUrl = (image: string | File) => {
    if (typeof image !== 'string') return image;
    if (image.includes('https://res.cloudinary.com')) {
      const formated = image.split('/').slice(4).join('/');
      return formated;
    }
    return image;
  };
  /*const validateAddress = async () => {
    new Promise(async (resolve, reject) => {
      const address = `${number} ${street}, ${neighbourhood}, ${city}, ${postal_code}`;
      await fetch({
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data'
        },
        url: `products/api/users/api/clients/validate_delivery_address/`,

        data: { delivery_address: address }
      })
        .then((res) => {
          if (res.error) {
            dispatch(sendAlert(res.message, EAlertVariant.ERROR));
            setStreetError(res.message);
            setNeighbourhoodError(res.message);
            setCityError(res.message);
            setPostalCodeError(res.message);
            reject(false);
          } else {
            setStreetError('');
            setNeighbourhoodError('');
            setCityError('');
            setPostalCodeError('');
            resolve(true);
          }
        })
        .catch((e) => {
          dispatch(sendAlert(e.message, EAlertVariant.ERROR));
          reject(false);
        });
    });
  }; */
  const submitCreation = async () => {
    if ((await checkForm()) === false) {
      dispatch(
        editStore(
          {
            name,
            order_prefix: orderPrefix,
            currency,
            store,
            // stripe_key: stripeKey,
            store_address: storeAddress,
            phone_number: phoneNumber,
            owner_email: ownerEmail,
            image: sanitizeImageUrl(image),
            light_logo: sanitizeImageUrl(lightLogo),
            dark_logo: sanitizeImageUrl(darkLogo),
            sameday_delivery: sameDayDelivery,
            promote: promote,
            only_delivery: onlyDelivery,
            classification: storeType,
            preperation_time: preparationTime,
            address: {
              city,
              neighborhood: neighbourhood,
              street,
              street_number: number,
              zipcode: postal_code
            },
            own_delivery: ownDelivery,
            delivery_cost_per_km: deliveryCostPerKm,
            fixed_delivery_cost: fixedDeliveryCost,
            description: description
            // slug,
            // address_url: addressUrl,
            // merchant_ifood_id: merchantOrderId
          },
          id
        )
      ).then((result: boolean) => {
        if (result) {
          dispatch(getStore());
          handleClose()
        };
      });
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>

        <h2>Edit Store</h2>
        <Container maxWidth="md">
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={nameError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                disabled={!superUser ? true : false}
                value={name}
                onChange={handleName}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={ownerEmailError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Email</InputLabel>
              <Input
                id="name-input"
                type="string"
                disabled={!superUser ? true : false}
                value={ownerEmail}
                onChange={handleOwnerEmail}
              />
              <FormHelperText>{ownerEmailError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={phoneNumberError !== ''}
            >
              <InputLabel htmlFor="name-input">Establishment Phone</InputLabel>
              <Input
                id="name-input"
                type="string"
                disabled={!superUser ? true : false}
                value={phoneNumber}
                onChange={handlePhoneNumber}
              />
              <FormHelperText>{phoneNumberError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container>
            <Grid item sm={12} spacing={5} md={6} lg={6}>
              <FormControl
                error={streetError !== ''}
                style={{ marginBottom: '15px', width: '95%' }}
              >
                <InputLabel htmlFor="name-input">Street</InputLabel>
                <Input
                  // onBlur={() => validateAddress()}
                  id="name-input"
                  type="string"
                  disabled={!superUser ? true : false}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <FormHelperText>{streetError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} spacing={5} md={6} lg={6}>
              <FormControl
                error={neighbourhoodError !== ''}
                style={{ marginBottom: '15px', width: '95%' }}
              >
                <InputLabel htmlFor="name-input">Town</InputLabel>
                <Input
                  //   onBlur={() => validateAddress()}
                  id="name-input"
                  type="string"
                  disabled={!superUser ? true : false}
                  value={neighbourhood}
                  onChange={(e) => setNeighbourhood(e.target.value)}
                />
                <FormHelperText>{neighbourhoodError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} spacing={5} md={6} lg={6}>
              <FormControl
                error={postal_codeError !== ''}
                style={{ marginBottom: '15px', width: '95%' }}
              >
                <InputLabel htmlFor="name-input">Postal code</InputLabel>
                <Input
                  //   onBlur={() => validateAddress()}
                  id="name-input"
                  type="string"
                  disabled={!superUser ? true : false}
                  value={postal_code}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
                <FormHelperText>{postal_codeError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} spacing={5} md={6} lg={6}>
              <FormControl
                error={cityError !== ''}
                style={{ marginBottom: '15px', width: '95%' }}
              >
                <InputLabel htmlFor="name-input">City</InputLabel>
                <Input
                  //   onBlur={() => validateAddress()}
                  id="name-input"
                  type="string"
                  disabled={!superUser ? true : false}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <FormHelperText>{cityError}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={slugError !== ''}
            >
              <InputLabel htmlFor="name-input">Slug</InputLabel>
              <Input
                id="name-input"
                type="string"
                disabled={!superUser ? true : false}
                value={slug}
                onChange={handleSlug}
              />
              <FormHelperText>{slugError}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">
                Establishment Currency
              </InputLabel>
              <Select
                id="category-input"
                disabled={true}
                value={currency}
                onChange={handleCurrency}
              >
                {/* <MenuItem value={'$'}>{'$'}</MenuItem>
                <MenuItem value={'€'}>{'€'}</MenuItem> */}
                <MenuItem value={'£'}>{'£'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              style={{ marginBottom: '15px' }}
              required={true}
              error={preparationTimeError !== ''}
            >
              <InputLabel htmlFor="name-input">Preparation time</InputLabel>
              <Input
                id="name-input"
                type="number"
                value={preparationTime}
                onChange={(e) => {
                  if (parseInt(e.target.value) > -1) {
                    setPreparationTime(parseInt(e.target.value));
                  }
                }}
              />
              <FormHelperText>{preparationTimeError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <Typography>Store description</Typography>

            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ckEditorToolbarConfig
              }}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </Grid>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ margin: '15px 0', display: 'flex' }}
            >
              <ThemeProvider theme={theme}>
                <div style={{ display: 'block', width: '30vw' }}>
                  <Typography>Store banner</Typography>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={
                      'Drag and drop or click to upload the store banner'
                    }
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleImage(files)}
                    filesLimit={1}
                  // initialFiles={[image]}
                  />

                  {imageError ? (
                    <FormHelperText error>{imageError}</FormHelperText>
                  ) : null}
                </div>
              </ThemeProvider>
              {typeof image === 'string' && image !== '' ? (
                <img
                  src={image}
                  style={{
                    width: '130px',
                    height: 'auto',
                    marginLeft: '20px', objectFit: "cover"
                  }}
                />
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ margin: '15px 0', display: 'flex' }}
            >
              <ThemeProvider theme={theme}>
                <div style={{ display: 'block', width: '30vw' }}>
                  <Typography>Dark store logo</Typography>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={
                      'Drag and drop or click to upload the dark store logo'
                    }
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleDarkLogo(files)}
                    filesLimit={1}
                  // initialFiles={[image]}
                  />
                </div>
              </ThemeProvider>
              {typeof darkLogo === 'string' && darkLogo !== '' ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <IconButton onClick={() => setDarkLogo("")}>
                    <CloseIcon />
                  </IconButton>
                  <img
                    src={darkLogo}
                    alt=""
                    style={{
                      width: '130px',
                      height: 'auto',
                      marginLeft: '20px', objectFit: "cover"
                    }}
                  />
                </div>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ margin: '15px 0', display: 'flex' }}
            >
              <ThemeProvider theme={theme}>
                <div style={{ display: 'block', width: '30vw' }}>
                  <Typography>Light store logo</Typography>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={
                      'Drag and drop or click to upload the light store logo'
                    }
                    showPreviewsInDropzone={true}
                    onChange={(files) => handleLightLogo(files)}
                    filesLimit={1}
                  // initialFiles={[image]}
                  />
                </div>
              </ThemeProvider>
              {typeof lightLogo === 'string' && lightLogo !== '' ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                  <IconButton onClick={() => setLightLogo("")}>
                    <CloseIcon />
                  </IconButton>
                  <img
                    src={lightLogo}
                    alt=""
                    style={{
                      width: '130px',
                      height: 'auto',
                      marginLeft: '20px', objectFit: "cover"
                    }}
                  />
                </div>
              ) : null}
            </Grid>
          </div>
          {/* <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">URL of the Address</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={addressUrl}
                onChange={handleAddressUrl}
              />
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Stripe Key</InputLabel>
              <Input
                id="name-input"
                type="string"
                // value={stripeKey}
                // onChange={handleStripeKey}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Merchant Ifood id</InputLabel>
              <Input
                id="name-input"
                type="string"
                // value={merchantOrderId}
                // onChange={handleMerchantOrderId}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Order of sale prefix</InputLabel>
              <Input
                id="name-input"
                type="string"
                disabled={!superUser ? true : false}
                value={orderPrefix}
                onChange={handlePrefix}
              />
            </FormControl>
          </Grid>
          {is_superuser ? (
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <InputLabel htmlFor="storeType-input">Type of Store</InputLabel>
                <Select
                  id="storeType-input"
                  disabled={!is_superuser && true}
                  value={storeType}
                  onChange={handleStoreType}
                  error={storeTypeError !== ''}
                >
                  {CLASSIFICATION_CHOICES.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Typography variant="h5">Delivery settings</Typography>
          {is_superuser || is_manager ? (
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl style={{ marginBottom: '15px' }}>
                <Tooltip title="Your store will be available to receive orders scheduled for today">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameDayDelivery}
                        onChange={(e) =>
                          handleSameDayDelivery(e.target.checked)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Same day delivery"
                  />
                </Tooltip>
              </FormControl>
            </Grid>
          ) : null}
          {is_superuser || is_manager ? (
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl style={{ marginBottom: '15px' }}>
                <Tooltip
                  style={{ left: '100px' }}
                  className="tooltip-edit-store-modal"
                  title="Your store will only receive delivery orders, collection orders will no longer be available in the website"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={onlyDelivery}
                        onChange={(e) => handleOnlyDelivery(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Only delivery"
                  />
                </Tooltip>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ownDelivery}
                    onChange={(e) => setOwnDelivery(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Supply your own delivery service"
              />
            </FormControl>
          </Grid>
          {ownDelivery && (
            <Grid container>
              <Grid item xs={12} spacing={5} md={6} lg={6}>
                <FormControl style={{ marginBottom: '15px', width: '95%' }}>
                  <InputLabel htmlFor="name-input">
                    Delivery cost per Kilometer
                  </InputLabel>
                  <Input
                    //   onBlur={() => validateAddress()}
                    id="name-input"
                    type="number"
                    value={deliveryCostPerKm}
                    onChange={(e) => {
                      setDeliveryCostPerKm(parseFloat(e.target.value));
                      setFixedDeliveryCost(0);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} spacing={5} md={6} lg={6}>
                <FormControl style={{ marginBottom: '15px', width: '95%' }}>
                  <InputLabel htmlFor="name-input">
                    Fixed delivery cost
                  </InputLabel>
                  <Input
                    //   onBlur={() => validateAddress()}
                    id="name-input"
                    type="number"
                    value={fixedDeliveryCost}
                    onChange={(e) => {
                      setFixedDeliveryCost(parseFloat(e.target.value));
                      setDeliveryCostPerKm(0);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          {is_superuser && (
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={promote}
                      onChange={(e) => handlePromote(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Promote"
                />
              </FormControl>
            </Grid>
          )}
          {/* {is_superuser && (
            
          )} */}

          {/* End of left column */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{ marginBottom: '30px' }}
                variant="outlined"
                onClick={submitCreation}
                color="primary"
              >
                Update & Save
              </Button>
            </div>
          </Grid>
        </Container>
      </div>
    </Modal>
  );
}
