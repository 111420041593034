import { EBalconyStatus } from 'enums/order';
import { IKitchen } from 'interfaces/kitchen';
import {getPaymentType} from 'helpers/common'

export function statusToUpper(status: EBalconyStatus) {
  return status.toUpperCase();
}

export function getWhastappUrl(order: IKitchen, phone: string) {
  const br = '%0a';
  let message = `ORDER Nº: *${order.order_code}*${br}Your order is already being prepared.${br}${br}*ORDER:*${br}`;

  order.items.forEach((item) => {
    message += `${item?.quantity} x ${item?.product?.name}${br}`;
    item?.extra_orders.forEach((extra) => {
      message += `  ${extra?.quantity} x ${extra?.ingredient} Extra${br}`;
    });
  });


  message += `${br}*Type of payment*: ${getPaymentType(order.payment)}.`;
  message += `${br}*Total*: £: ${order?.total_order?.toFixed(2)}`;
  message += `${br}Thank you for your preference.`;

  return `https://web.whatsapp.com/send?phone=55${phone}&text=${message}&source&data`;
}
