import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  //fetchProductsPagination,
  fetchAllProducts,
  fetchIngredients,
  fetchCategoriesGeneral,
  fetchTags,
  fetchGiftCards
} from 'redux/ducks/stock';
import { setCurrency } from 'redux/ducks/currency';
import { EFilters } from '../../enums/stock';
import IStore, { Iingredients, } from 'interfaces/store';
import Loading from 'components/Common/Loading';
import ProductFilter from './ProductFilter';
import IngredientsTable from './IngredientsTable';
import CategoriesTable from './CategoriesTable';
import ProductsTable from './ProductsTable';
import { getStore } from 'redux/ducks/store';
import { IStoreState } from 'interfaces/store_test';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { fetch } from './../../utils/request';
import { fetchMenusGeneral } from 'redux/ducks/menu';
import MenusTable from 'components/Menus/MenusTable';
import Select from 'react-select';
import { NETWORK, PRIMARY_COLOR } from 'settings';
import TagsTable from './Tags/TagsTable';
import GiftCardTable from './GiftCards/GiftCardsTable';
const filters = [
  {
    label: 'Products',
    value: EFilters.PRODUCTS
  },
  {
    label: 'Extras',
    value: EFilters.INGREDIENTS
  },
  {
    label: 'Categories',
    value: EFilters.CATEGORIES
  },
  {
    label: "Tags",
    value: EFilters.TAGS
  },
  {
    label: 'Gift cards',
    value: EFilters.GIFT_CARDS
  },
  {
    label: NETWORK === "6" ? "Groups" : 'Menus',
    value: EFilters.MENUS
  }
];

var storeFilter: { id: number; name: string }[] = [];
const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: PRIMARY_COLOR(),
    boxShadow: 'none',
    ':hover': {
      borderColor: PRIMARY_COLOR()
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? PRIMARY_COLOR() : '#fdfdfd'
  })
};
export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<EFilters>(EFilters.PRODUCTS);
  const { stock, authentication } = useSelector<IStore, IStore>(
    (state) => state
  );

  const [activeStoreFilter, setActiveStoreFilter] = useState(0);
  const classes = useStyles();
  const [fetchedIngredients, setFetchedIngredients] = useState<Iingredients[]>(
    []
  );
  const { menu } = useSelector<IStore, IStore>((state) => state);
  const { results } = useSelector<IStore, IStoreState>((state) => state.store);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [currentPage, setCurrentPage] = useState(0)
  const [searchForCategory, setSearchForCategory] = React.useState('');
  const [searchForIngredients, setSearchForIngredients] = React.useState('');
  const [searchForTags, setSearchForTags] = React.useState('');
  const [searchForGiftCards, setSearchForGiftCards] = React.useState('');
  const [firstRender, setFirstRender] = useState(true);
  const [tags, setTags] = useState([])
  const [giftCards, setGiftCards] = useState([])
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [sortBy, setSortBy] = useState("last")
  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name });
  });
  if (authentication.is_superuser) {
    storeFilter.push({ id: 0, name: 'ALL' });
  }
  // useEffect(() => {
  //   if (!authentication.is_superuser && activeStoreFilter !== 0) {
  //     setActiveStoreFilter(storeFilter[0]?.id);
  //   }
  // }, [storeFilter]);

  useEffect(() => {
    if (firstRender && results.length > 0) {
      setActiveStoreFilter(results[0].id);
      dispatch(setCurrency(results[0]["currency"]))
      setFirstRender(false);
      setFirstRender(false);
    }
  }, [results]);
  useEffect(() => {
    if (activeStoreFilter === undefined) {
      setActiveStoreFilter(storeFilter[0]?.id);
    }
  }, [results]);

  const handleActiveStoreFilter = (value: number) => {
    setActiveStoreFilter(value);
    setPage(1);
  };

  const getIngredients = async () => {
    try {
      let url = `products/api/ingredients/`;
      let response;
      let results = [];
      do {
        response = await fetch({
          method: 'GET',
          url: url
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      var extrasTofilterBy = [];
      extrasTofilterBy = returnExtrasToFilter();
      if (activeStoreFilter !== 0 && results) {
        results = results.filter(function (el) {
          return extrasTofilterBy.includes(el.ingredient_id);
        });
      }
      setFetchedIngredients(results);
    } catch (error) {
      console.log('Error fetching supplies');
    }
  };

  const getCategories = async () => {
    try {
      var categoriesParams = {
        store: activeStoreFilter,
        search: searchForCategory,
        categoriesToFilterBy:
          activeStoreFilter === 0 ? 'ALL' : returnCategoriesToFilter(),
        network: NETWORK
      };
      if (!authentication.is_superuser) {
        delete categoriesParams.network;
      }
      dispatch(fetchCategoriesGeneral(categoriesParams));
    } catch (error) {
      console.log('Error fetching supplies');
    }
  };
  const fetchProducts = (query, reset) => {
    if (
      //page !== 0 &&
      activeStoreFilter !== undefined &&
      !firstRender
    ) {
      var mainWrapper = document.getElementById('main')
      var scrollPos = mainWrapper.scrollTop
      setLoadingProduct(true)
      var params = {
        store: activeStoreFilter,
        network: NETWORK,
        //page,
        search: query
      };
      if (!authentication.is_superuser) {
        delete params.network;
      }
      if (activeStoreFilter === 0 && authentication.is_superuser) {
        delete params.store;
      }
      //if (reset) delete params.page
      dispatch(fetchAllProducts(params, reset)).then(() => {
        setLoadingProduct(false)
        if (page > 1) {

          mainWrapper.scrollTo(0, scrollPos);
        }
      }).catch(e => {
        setLoadingProduct(false)
      })
    }
  }


  const returnStoreSlug = () => {
    var storeData = results.find(
      (storeElement) => storeElement.id === activeStoreFilter
    );
    return storeData?.slug ? storeData?.slug : storeData?.id;
  };

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-menus');
    if (d.item(0)) {
      d.item(0).style.color = '#ff5a23';

      d.item(1).style.color = '#ff5a23';
    }
  });

  const getProductsReset = () => {
    setPage(1);
    fetchProducts(search, true)
  };
  const getCategoriesSearch = (data: string) => {
    var params = {
      store: activeStoreFilter,
      search: data,
      network: NETWORK
    };

    dispatch(fetchCategoriesGeneral(params));
  };
  const getTagsSearch = () => {
    var tagsparams = {
      store: activeStoreFilter,
      search: searchForTags,
      network: NETWORK
    };
    if (!authentication.is_superuser) {
      delete tagsparams.network;
    }
    fetchTags(tagsparams).then((res: any) => {
      setTags(res)
    })
  };
  const getGiftCardsSearch = () => {
    var giftCardsparams = {
      store: activeStoreFilter,
      search: searchForGiftCards,
      network: NETWORK
    };
    if (!authentication.is_superuser) {
      delete giftCardsparams.network;
    }
    fetchGiftCards(giftCardsparams).then((res: any) => {
      setGiftCards(res)
    })
  };
  const getIngredientsSearch = (data: string) => {
    dispatch(
      fetchIngredients({
        store: activeStoreFilter,
        name: data
      })
    );
  };
  const returnCategoriesToFilter = () => {
    var categoriesToFilter = 'ALL';
    for (let index = 0; index < results.length; index++) {
      const element = results[index];
      if (element.id === activeStoreFilter) {
        return element.categories;
      }
    }
    return categoriesToFilter;
  };
  const returnExtrasToFilter = () => {
    var filteredIngredients = [];
    for (let index = 0; index < results.length; index++) {
      const element = results[index];
      if (element.id === activeStoreFilter) {
        filteredIngredients = element.ingredients;
      }
    }
    return filteredIngredients;
  };

  useEffect(() => {
    if (activeFilter === EFilters.PRODUCTS) {
      fetchProducts(search, false)
      getCategories();
      getIngredients();
      getTagsSearch()
    } else if (activeFilter === EFilters.INGREDIENTS) {
      var extraParams = {
        store: activeStoreFilter,
        name: searchForIngredients,
        extrasToFilterBy:
          activeStoreFilter === 0 ? 'ALL' : returnExtrasToFilter(),
        network: NETWORK
      };

      if (!authentication.is_superuser) {
        delete extraParams.network;
      }
      dispatch(fetchIngredients(extraParams));
    } else if (activeFilter === EFilters.CATEGORIES) {
      var categoriesParams = {
        store: activeStoreFilter,
        search: searchForCategory,
        categoriesToFilterBy:
          activeStoreFilter === 0 ? 'ALL' : returnCategoriesToFilter(),
        network: NETWORK
      };
      if (!authentication.is_superuser) {
        delete categoriesParams.network;
      }
      dispatch(fetchCategoriesGeneral(categoriesParams));
    } else if (activeFilter === EFilters.TAGS) {
      getTagsSearch()
    } else if (activeFilter === EFilters.GIFT_CARDS) {
      getGiftCardsSearch()
    } {
      var menuParams = { store: activeStoreFilter, network: NETWORK };
      if (!authentication.is_superuser) {
        delete menuParams.network;
      }
      getCategories();
      dispatch(fetchMenusGeneral(menuParams));
    }
    dispatch(getStore());
  }, [dispatch, activeFilter, activeStoreFilter, page, firstRender]);

  const sortProducts = () => {
    if (!stock.productsForStockPage) return []
    if (NETWORK === "6" || NETWORK === "15") {
      if (sortBy === "last") {
        return stock.productsForStockPage.sort(function (a, b) {
          return a.product_id - b.product_id;
        });
      }
      if (sortBy === "first") {
        return stock.productsForStockPage.sort(function (a, b) {
          return b.product_id - a.product_id;
        });
      }
      return stock.productsForStockPage.filter(el => el.stock < 1)
    }
    return stock.productsForStockPage
  }

  const showStores = () => {
    var options = [];
    for (let index = 0; index < storeFilter.length; index++) {
      const element = storeFilter[index];
      options.push({
        value: element.id,
        label: element.name
      });
    }
    var activeFilterIndex = options.findIndex((object) => {
      return object.value === activeStoreFilter;
    });
    return (
      <>
        <Select
          className={classes.selectInput}
          classNamePrefix="select"
          value={{
            value: activeStoreFilter === 0 ? 0 : activeStoreFilter,
            label:
              activeStoreFilter === 0
                ? 'All'
                : storeFilter[activeFilterIndex]?.name
          }}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          options={options}
          onChange={(e: any) => handleActiveStoreFilter(e.value)}
          styles={customStyles}
        />
      </>
    );
  };
  return (
    <>
      <Loading show={stock.fetch || menu.fetch} />
      <Grid container spacing={3}>
        {storeFilter.length > 2 && <Grid
          style={{
            marginBottom: 10,
            marginTop: 20,
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography
            color="primary"
            variant="overline"
            className={`${classes.toolBarTitles}`}
          >{`store: `}</Typography>{' '}
          {showStores()}
        </Grid>}
        <ProductFilter
          activeFilter={activeFilter}
          setActiveFilter={(newFilter: EFilters) => setActiveFilter(newFilter)}
          filters={filters}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
        />
        {activeFilter === EFilters.PRODUCTS ? (
          <ProductsTable
            tags={tags}
            store={activeStoreFilter}
            loading={loadingProduct}
            storeSlug={returnStoreSlug()}
            ingredients={fetchedIngredients}
            products={sortProducts()}
            categories={stock.categories}
            hasMore={stock.hasMore}
            more={() => {
              setCurrentPage(currentPage + 1);
            }}
            reset={() => {
              getProductsReset();
            }}
            searchData={(data: any) => {
              setSearch(data);
              fetchProducts(data, true);
            }}
            currentPage={currentPage}
          />
        ) : activeFilter === EFilters.INGREDIENTS ? (
          <IngredientsTable
            ingredients={stock.ingredients}
            store={activeStoreFilter}
            searchData={(data: any) => {
              setSearchForIngredients(data);
              getIngredientsSearch(data);
            }}
            ingredientsToFilterBy={
              activeStoreFilter === 0 ? 'ALL' : returnExtrasToFilter()
            }
          />
        ) : activeFilter === EFilters.CATEGORIES ? (
          <CategoriesTable
            categories={stock.categories}
            store={activeStoreFilter}
            searchData={(data: any) => {
              setSearchForTags(data);
              getCategoriesSearch(data);
            }}
            ingredientsToFilterBy={
              activeStoreFilter === 0 ? 'ALL' : returnExtrasToFilter()
            }
          />
        ) : activeFilter === EFilters.TAGS ? (<TagsTable store={activeStoreFilter} fetchTags={() => getTagsSearch()} tags={tags} searchData={(data: any) => {
          setSearchForCategory(data);
          getCategoriesSearch(data);
        }} />) : activeFilter === EFilters.GIFT_CARDS ? (<GiftCardTable store={activeStoreFilter} fetchGiftCards={() => getGiftCardsSearch()} giftCards={giftCards} searchData={(data: any) => {
          setSearchForGiftCards(data);
          getGiftCardsSearch();
        }} />) : (
          <MenusTable
            menus={menu.menus}
            store={activeStoreFilter}
            categories={stock.categories}
          />
        )}
      </Grid>
    </>
  );
}
