import { useStyles } from './styles';
// import { IKitchen } from 'interfaces/kitchen';
import Animate from 'components/Common/Animate';
import { Grid, Button, Typography } from '@material-ui/core';
import Client from './Client';
import Summary from './Summary';
import Payment from './Payment';
import Timeline from './Timeline';
import { EBalconyStatus } from 'enums/order';
import React, { useState } from 'react';
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { IBalcony } from 'interfaces/balcony';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  orders: IBalcony[];
}

export default function Order(props: IProps): JSX.Element {
  const { orders } = props;
  const [orderID, setOrderID] = useState(0);
  const [visible, setVisible] = useState(false);
  const classes = useStyles();

  function toggleOrder(id: number): void {
    setOrderID(id);
    if (visible && id === orderID) {
      setVisible(!visible);
    } else {
      setVisible(true);
    }
  }

  return (
    <>
      {orders.map((order) => (
        <Animate key={uuidv4()}>
          <Grid container>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid container justifyContent="flex-start">
                <Button
                  onClick={() => toggleOrder(order.order_id)}
                  style={{ marginTop: -8 }}
                >
                  {visible && orderID === order.order_id ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  )}
                </Button>
                <Typography
                  variant="h5"
                  onClick={() => toggleOrder(order.order_id)}
                  style={{ marginBottom: 10, cursor: 'pointer' }}
                >
                  {order.order_code} -{' '}
                  {order?.owner?.name ?? order?.client?.name}
                </Typography>
              </Grid>
            </Grid>

            {visible && orderID === order.order_id && (
              <Animate>
                <Grid container justifyContent="center" className={`${classes.card}`}>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classes.info}
                  >
                    <Client order={order} />
                    <Summary order={order} />
                    <Payment order={order} />
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classes.panel}
                  >
                    <Timeline
                      status={EBalconyStatus.TODO}
                      date={order.date_ordered}
                      color={classes.todoPanel}
                    />
                    <Timeline
                      status={EBalconyStatus.DOING}
                      date={order.doing_at}
                      color={classes.doingPanel}
                    />
                    <Timeline
                      status={EBalconyStatus.DONE}
                      date={order.done_at}
                      color={classes.donePanel}
                    />
                    <Timeline
                      status={EBalconyStatus.TRANSIT}
                      date={order.depot_at}
                      color={classes.depotPanel}
                    />
                    <Timeline
                      status={EBalconyStatus.COMPLETED}
                      date={order.finished_at}
                      color={classes.completedPanel}
                    />
                  </Grid>
                </Grid>
              </Animate>
            )}
          </Grid>
        </Animate>
      ))}
    </>
  );
}
