
import React, { useEffect, useState } from "react";
import {
    Dialog, DialogContent, DialogTitle, Typography, Grid, FormControl, Input, InputLabel, TextField, IconButton, DialogActions, Button, FormHelperText, Checkbox
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import allCountries from 'components/Onboarding/countryList';
import CloseIcon from '@material-ui/icons/Close';

interface IAddress {
    street: string,
    street_number: string
    neighborhood: string,
    city: string,
    state: string,
    country: string,
    zipcode: string,
}
interface IProps {
    open: boolean;
    edit: (value: IAddress) => void;
    address: IAddress;
    handleClose: () => void;
    delivery: IAddress;
}
export default function EditAddress(props: IProps) {
    const { open, edit, address, handleClose, delivery } = props
    const [countryQuery, setCountryQuery] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [copyBilling, setCopyBilling] = useState(false)
    const [billingAddress, setBillingAddress] = useState<any>({
        street: "",
        street_number: "",
        neighborhood: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
    })
    const checkBilling = () => {
        var entered = false
        var keys = ['street',
            'city',
            'country',
            'zipcode']
        keys.map(key => {
            if (key === "neighborhood" || key === "state") {
                entered = false
            } else {
                if (billingAddress[key] === "" || billingAddress[key] === null || !billingAddress[key]) {
                    console.log("coming here ", key)
                    entered = true
                }
            }

        })
        return entered
    }
    const handleChange = (prop, value) => {
        setBillingAddress({ ...billingAddress, [prop]: value })
    }
    const fillAddresses = () => {
        var newAddress = {}
        Object.keys(address).map(key => {
            newAddress[key] = address[key] ? address[key] : ""
        })

        setBillingAddress(newAddress)
    }
    useEffect(() => {
        if (open) fillAddresses()
    }, [open])
    const handleCopyBilling = () => {
        if (!copyBilling) {
            setBillingAddress({
                street: delivery.street ? delivery.street : "",
                street_number: delivery.street_number ? delivery.street_number : "",
                neighborhood: delivery.neighborhood ? delivery.neighborhood : "",
                city: delivery.city ? delivery.city : "",
                state: delivery.state ? delivery.state : "",
                country: delivery.country ? delivery.country : "",
                zipcode: delivery.zipcode ? delivery.zipcode : "",
            })
        } else {
            setBillingAddress({
                street: "",
                street_number: "",
                neighborhood: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
            })
        }
        setCopyBilling(!copyBilling)
    }
    return (
        <Dialog onClose={() => handleClose()} open={open}>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        cursor: 'pointer',
                        marginLeft: "-15px"
                    }}
                    onClick={() => handleClose()}
                >
                    <IconButton
                        style={{ width: '50px' }}
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography color="secondary">Close</Typography>
                </div>

                <Typography variant="h5">Edit address</Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox checked={copyBilling} onClick={() => handleCopyBilling()} /> <Typography onClick={() => handleCopyBilling()} style={{ cursor: "pointer" }}>Same as shipping address</Typography>
                </div>
                <Grid sm={12} md={12} lg={12} xs={12} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "20px" }}>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl
                                style={{ marginBottom: '1rem', width: '100%' }}
                                required
                                error={billingAddress.street === "" && submitted}
                            >
                                <InputLabel htmlFor="name-input">Street and number</InputLabel>
                                <Input
                                    disabled={copyBilling}
                                    id="name-input"
                                    type="string"
                                    value={billingAddress.street}
                                    onChange={(e) => {
                                        handleChange("street", e.target.value)
                                    }}
                                />
                                {billingAddress.street === "" && submitted ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <FormControl
                                style={{ marginBottom: '1rem', width: '100%' }}
                                required
                                error={billingAddress.city === "" && submitted}

                            >
                                <InputLabel htmlFor="name-input">City</InputLabel>
                                <Input
                                    disabled={copyBilling}
                                    id="name-input"
                                    type="string"
                                    value={billingAddress.city}
                                    onChange={(e) => {
                                        handleChange("city", e.target.value)
                                    }}
                                />
                                {billingAddress.city === "" && submitted ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <FormControl
                                style={{ marginBottom: '1rem', width: '100%' }}
                                required

                            >
                                <InputLabel htmlFor="name-input">Neighbourhood</InputLabel>
                                <Input
                                    disabled={copyBilling}
                                    id="name-input"
                                    type="string"
                                    value={billingAddress.neighborhood}
                                    onChange={(e) => {
                                        handleChange("neighborhood", e.target.value)
                                    }}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <FormControl
                                style={{ marginBottom: '1rem', width: '100%' }}
                                required
                            >
                                <InputLabel htmlFor="name-input">State/county</InputLabel>
                                <Input
                                    disabled={copyBilling}
                                    id="name-input"
                                    type="string"
                                    value={billingAddress.state}
                                    onChange={(e) => {
                                        handleChange("state", e.target.value)
                                    }}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl
                                style={{ marginBottom: '1rem', width: '100%' }}
                                required
                                error={billingAddress.zipcode === "" && submitted}

                            >
                                <InputLabel htmlFor="name-input">Postal code</InputLabel>
                                <Input
                                    disabled={copyBilling}
                                    id="name-input"
                                    type="string"
                                    value={billingAddress.zipcode}
                                    onChange={(e) => {
                                        handleChange("zipcode", e.target.value)
                                    }} />
                                {billingAddress.zipcode === "" && submitted ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <FormControl
                                style={{
                                    marginTop: '0px',
                                }}
                                margin={'normal'}
                                fullWidth
                                required
                                error={(billingAddress.country === null || !billingAddress.country) && submitted}
                            >
                                <Autocomplete
                                    value={billingAddress.country}

                                    disabled={copyBilling}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleChange("country", newValue)
                                    }}
                                    inputValue={countryQuery}
                                    onInputChange={(event, newInputValue) => {
                                        setCountryQuery(newInputValue);
                                    }}
                                    id="country-autocomplete"
                                    options={allCountries}
                                    getOptionLabel={(option) => {
                                        if (option.name !== undefined) {
                                            return option.name;
                                        } else return '';
                                    }}
                                    renderOption={(option) =>
                                        option.name !== undefined ? option.name : ''
                                    }
                                    loadingText={'Loading countries'}
                                    renderInput={(params) => (
                                        <TextField {...params} error={billingAddress.country === "" && submitted}
                                            label="Country" />
                                    )}
                                />
                                {(billingAddress.country === null || !billingAddress.country) && submitted ? <FormHelperText>This field cannot be empty</FormHelperText> : null}

                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center", gap: "10px" }} >
                <Button variant="text" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    setSubmitted(true)
                    if (checkBilling() === false) {
                        edit(billingAddress)
                        handleClose()
                    }
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}