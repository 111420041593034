import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography
} from '@material-ui/core';
import { PRIMARY_COLOR } from 'settings';

import 'react-phone-input-2/lib/material.css';
import './styles.css';

interface IProps {
  userAddress?: any;
  handleSave: (data: {
    address: {
      street: string;
      street_number: string;
      neighborhood: string;
      city: string;
      zipcode: string;
      country: string;
    };
  }) => void;
}

interface IFormData {
  street: string;
  neighborhood: string;
  postalCode: string;
  city: string;
  // country: string;
}

interface IErrors {
  street: boolean;
  neighborhood: boolean;
  postalCode: boolean;
  city: boolean;
  // country: boolean;
}

const errorMensages = {
  required: 'This field is required'
};

export default function CreateStoreAddress(props: IProps) {
  const { userAddress, handleSave } = props;

  const [hasSubmitedOnce, setHasSubmitedOnce] = useState<boolean>(false);
  const [sameAddressAsPersonal, setSameAddressAsPersonal] = useState(false);
  const [addressFormData, setAddressFormData] = useState<IFormData>({
    street: '',
    neighborhood: '',
    postalCode: '',
    city: ''
  });

  const [addressFormDataErrors, setAddressFormDataErrors] = useState<IErrors>({
    street: false,
    neighborhood: false,
    postalCode: false,
    city: false
  });

  useEffect(() => {
    if (sameAddressAsPersonal) {
      setAddressFormData({
        street: userAddress.street,
        neighborhood: userAddress.neighborhood,
        postalCode: userAddress.zipcode,
        city: userAddress.city
      });
    }
  }, [sameAddressAsPersonal]);

  const handleSameAddress = () => {
    setSameAddressAsPersonal(!sameAddressAsPersonal);
  };

  const handleFormData = (event: any) => {
    const input = event.target.id;
    const value = event.target.value;

    setSameAddressAsPersonal(false);

    switch (input) {
      case 'street':
        setAddressFormData((currentData) => ({
          ...currentData,
          street: value
        }));
        break;
      case 'neighborhood':
        setAddressFormData((currentData) => ({
          ...currentData,
          neighborhood: value
        }));
        break;
      case 'postal-code':
        setAddressFormData((currentData) => ({
          ...currentData,
          postalCode: value
        }));
        break;
      case 'city':
        setAddressFormData((currentData) => ({
          ...currentData,
          city: value
        }));
        break;
      default:
        break;
    }
  };

  const validadeAddressFields = () => {
    if (!hasSubmitedOnce) return;

    for (const [key, value] of Object.entries(addressFormData)) {
      setAddressFormDataErrors((currentData) => ({
        ...currentData,
        [key]: value ? false : true
      }));
    }
  };

  const submit = () => {
    let proceed = true;
    setHasSubmitedOnce(true);

    validadeAddressFields();

    for (const [_key, value] of Object.entries(addressFormData)) {
      if (value === '') {
        proceed = false;
      }
    }

    if (proceed) {
      handleSave({
        address: {
          street: addressFormData.street,
          street_number: '',
          neighborhood: addressFormData.neighborhood,
          city: addressFormData.city,
          zipcode: addressFormData.postalCode,
          country: 'GB'
        }
      });
    }
  };

  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <Typography
        style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
      >
        Create your store address
      </Typography>
      <FormControl
        style={{ marginBottom: '1rem', marginTop: '1rem', width: '75%' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={sameAddressAsPersonal}
              onChange={handleSameAddress}
              name="checkedB"
              color="primary"
            />
          }
          label="Same as your address?"
        />
      </FormControl>
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={addressFormDataErrors.street}
      >
        <InputLabel htmlFor="street">First line of the address</InputLabel>
        <Input
          id="street"
          type="string"
          value={addressFormData.street}
          onChange={handleFormData}
        />
        <FormHelperText>
          {addressFormDataErrors.street && errorMensages.required}
        </FormHelperText>
      </FormControl>{' '}
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={addressFormDataErrors.neighborhood}
      >
        <InputLabel htmlFor="neighborhood">Town</InputLabel>
        <Input
          id="neighborhood"
          type="string"
          value={addressFormData.neighborhood}
          onChange={handleFormData}
        />
        <FormHelperText>
          {addressFormDataErrors.neighborhood && errorMensages.required}
        </FormHelperText>
      </FormControl>{' '}
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={addressFormDataErrors.city}
      >
        <InputLabel htmlFor="city">City</InputLabel>
        <Input
          id="city"
          type="string"
          value={addressFormData.city}
          onChange={handleFormData}
        />
        <FormHelperText>
          {addressFormDataErrors.city && errorMensages.required}
        </FormHelperText>
      </FormControl>{' '}
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={addressFormDataErrors.postalCode}
      >
        <InputLabel htmlFor="postal-code">Postal Code</InputLabel>
        <Input
          id="postal-code"
          type="string"
          value={addressFormData.postalCode}
          onChange={handleFormData}
        />
        <FormHelperText>
          {addressFormDataErrors.postalCode && errorMensages.required}
        </FormHelperText>
      </FormControl>{' '}
      <Button
        style={{
          marginTop: '1rem',
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={() => submit()}
      >
        Save and continue
      </Button>
    </Grid>
  );
}
