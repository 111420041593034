import { IAlertAction } from './alert';
import IBaseAction from './redux';

export interface ICategoriesBase {
  icon: string;
  id: number;
  name: string;
}
export interface IStoreBase {
  id: number;
  name: string;
  menus: number[];
  address: {
    city: string;
    country: string;
    neighborhood: string;
    street: string;
    street_number: string;
    zipcode: string;
  };
  is_own_store: boolean;
  force_close: boolean;
  slug: string;
  categories: ICategoriesBase[];
  ingredients: number[];
  own_delivery: boolean;
  currency: string;
}

export interface IStoreState {
  fetch: boolean;
  next: string;
  previous: string;
  results: IStoreBase[];
  error: string;
}

/**
 * Redux types.
 */
export enum ETypesStore {
  FETCH = 'STORE/FETCH',
  SUCCESS = 'STORE/SUCCESS',
  FAILURE = 'STORE/FAILURE',
  RESET = 'STORE/RESET'
}

export interface IFetchAction extends IBaseAction<ETypesStore, null> {
  type: ETypesStore.FETCH;
}

export interface ISuccessAction extends IBaseAction<ETypesStore, IStoreState> {
  type: ETypesStore.SUCCESS;
  payload: IStoreState;
}

export interface IFailureAction extends IBaseAction<ETypesStore, string> {
  type: ETypesStore.FAILURE;
  payload: string;
}

export interface IResetAction extends IBaseAction<ETypesStore, null> {
  type: ETypesStore.RESET;
}

export type IStoreAction =
  | IFetchAction
  | ISuccessAction
  | IFailureAction
  | IResetAction;

export type GetState = () => any;

export type PromiseAction = Promise<IStoreAction>;

export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

export type Dispatch = (
  action:
    | IStoreAction
    | ThunkAction
    | PromiseAction
    | Array<IStoreAction>
    | IAlertAction
) => any;
