import React from "react";
import { NETWORK } from 'settings';

import GoLogo from 'assets/img/flogi-logo.svg';
import YallaLogo from 'assets/img/logo-yalla.svg';
import PetshopLogo from 'assets/img/petshop_logo.svg';
import NeuroshakeLogo from 'assets/img/neuroshake.svg';
import MangelLogo from 'assets/img/mangel_logo.svg'
import "./../App.css"
export default function Logo({ styling }) {
  const logo = {
    '2': GoLogo,
    '4': YallaLogo,
    '5': NeuroshakeLogo,
    '6': PetshopLogo,
    '15': MangelLogo
  };
  const name = {
    '2': "Flogi",
    '3': "Doois",
    '4': "Yalla",
    '5': "Neuroshake",
    '6': "Petshop",
    '8': "Oliver Charles",
    '9': "Soly Coffee",
    '10': "Sweet & Kind",
    '11': "Eredi Conconi",
    '15': "Mängelquartett"
  };
  if (!logo[NETWORK]) {

    return (
      <svg textAnchor="middle" style={styling}>
        <text x="50%" y="58%" textAnchor="middle" style={{ fontSize: "x-large", fontFamily: "helvetica" }}>{name[NETWORK] ? name[NETWORK] : "Portal"}</text>
      </svg>)


  }
  return (
    <img alt="" src={logo[NETWORK]} style={styling} />
  )
}