import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  Container,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
// import moment from 'moment';

import { IKitchen } from 'interfaces/kitchen';
import { scheduleDeliveryDate } from 'redux/ducks/kitchen';

import { fetch } from 'utils/request';
import formatDate from 'date-fns/format';
import './styles.css';
import { NETWORK } from 'settings';
interface IProps {
  onClose: () => void;
  open: boolean;
  order: IKitchen;
  // createDHLShipment: () => void;
}

export default function ModalScheduleDate(props: IProps) {
  const { onClose, open, order } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    setTimeSlots([]);
    setAllValidTimeslots([]);
    setTimeSlotNotFound(false);
    setShowSubmitButton(false);
    setLoading(false);
    setSelectedTimeslot('');
    onClose();
  };

  useEffect(() => {
    if (open && !loading && NETWORK !== "6") {
      getOrderTimeSlots();
    }
  }, [open]);

  useEffect(() => {
    setSelectedDate(new Date(order.date_delivery))
  }, [open])

  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeslot] = useState('');
  const [allValidTimeslots, setAllValidTimeslots] = useState([]);

  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  const [timeslotNotFound, setTimeSlotNotFound] = useState(false);
  const [timeSlotsNonSelectedError, setTimeSlotsNonSelectedError] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const getOrderTimeSlots = async () => {
    setLoading(true);
    await fetch({
      method: 'GET',
      url: `/orders/api/orders/${order.order_id}/get_timeslots/`
    }).then((res) => {
      setLoading(false);
      if (!res.error) {
        //Set only the 5 slots starting after the order.date_delivery
        const filteredTimeslots = res.slots?.filter(
          (time) =>
            new Date(time.start_time).getTime() >
            new Date(order.date_delivery).getTime()
        );
        setAllValidTimeslots(filteredTimeslots);
        setTimeSlots(filteredTimeslots.slice(0, 5));
        setShowSubmitButton(true);
      } else {
        setTimeSlotNotFound(true);
        setShowSubmitButton(false);
      }
    });

    return null;
  };

  const addMoreTimeslots = () => {
    const currentLengthPlus = timeSlots.length + 5;
    setTimeSlots(allValidTimeslots.slice(0, currentLengthPlus));

    controlAddMoreButtonVisibility();

    return;
  };

  const controlAddMoreButtonVisibility = () => {
    if (timeSlots.length < allValidTimeslots.length) {
      setShowLoadMoreButton(true);
    } else {
      setShowLoadMoreButton(false);
    }

    return;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeslot((event.target as HTMLInputElement).value);
  };

  const TimeSlotRadioGroup = () => {
    return !timeslotNotFound && NETWORK !== "6" ? (
      <div style={{ marginBottom: '50px' }}>
        <FormControl component="fieldset" style={{ margin: '24px 12px' }}>
          <FormLabel component="legend">
            {loading ? 'Loading available time slots...' : 'Please select one'}
          </FormLabel>
          <RadioGroup
            aria-label="Timeslot"
            name="timeslot"
            value={selectedTimeSlot}
            onChange={handleChange}
          >
            {timeSlots.map((slot, index) => (
              <FormControlLabel
                key={`timeslotradio${index}`}
                value={slot.end_time}
                control={<Radio />}
                label={`${formatDate(
                  new Date(slot.start_time),
                  'EEE  dd/MMM HH:mm'
                )} to ${formatDate(
                  new Date(slot.end_time),
                  'EEE  dd/MMM HH:mm'
                )}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {showLoadMoreButton && !loading && (
          <Button
            variant="outlined"
            fullWidth
            onClick={() => addMoreTimeslots()}
          >
            Load more
          </Button>
        )}
      </div>
    ) : (
      <DatePicker />
    );
  };

  const [selectedDate, setSelectedDate] = useState<null | Date>(new Date());

  const handleDateChange = (date: null | Date) => {
    setSelectedDate(date);
  };
  const DatePicker = () => {
    return (
      <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl
              style={{
                marginLeft: '12px',
                marginTop: '0px'
              }}
              fullWidth={true}
              margin={'normal'}
            >
              <KeyboardDateTimePicker
                ampm={false}
                // disablePast

                variant="inline"
                margin="normal"
                id="date-picker-dialog"
                label="Please update the date"
                format="EEE  dd/MMM HH:mm"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </div>
        <Button
          onClick={() => updateDeliveryDate()}
          variant="outlined"
          color="primary"
          style={{
            marginLeft: '60px'
          }}
        >
          Check availability
        </Button>
      </div>
    );
  };

  async function submit() {
    if (!selectedTimeSlot) {
      setTimeSlotsNonSelectedError(true);
      return;
    }

    await dispatch(scheduleDeliveryDate(order.order_id, selectedTimeSlot)).then(
      () => {
        handleClose();
      }
    );
    return;
  }

  async function updateDeliveryDate() {
    const formatedSelectedDate = formatDate(
      new Date(selectedDate),
      'yyyy-MM-dd HH:mm'
    );
    order.date_delivery = String(selectedDate);
    await dispatch(scheduleDeliveryDate(order.order_id, formatedSelectedDate)).then(
      () => {
        handleClose();
      }
    );
    return;
  }

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            Schedule date for order {order?.order_code}
            {showSubmitButton && (
              <Button
                onClick={() => submit()}
                variant="outlined"
                color="primary"
                disabled={timeSlotsNonSelectedError}
              >
                Submit
              </Button>
            )}
          </div>
        </DialogTitle>
        <Container maxWidth="md">
          <Typography variant="body1" style={{ margin: '12px' }}>
            Current scheduled date:{' '}
            {formatDate(new Date(selectedDate), 'EEE  dd/MMM HH:mm')}
          </Typography>
          <TimeSlotRadioGroup />
        </Container>
      </div>
    </Modal>
  );
}
