import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getOwner } from 'redux/ducks/owner';

import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import OwnersTable from './OwnersTable';
export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const { owner } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });

    //d.item(1).style.color = '#EA571C';
  });

  useEffect(() => {
    dispatch(getOwner());
  }, [dispatch]);

  return (
    <>
      <Loading show={owner ? owner.fetch : false} />

      <Grid container spacing={3}>
        <OwnersTable owners={owner.results} />
      </Grid>
    </>
  );
}
