import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, Select, MenuItem, InputLabel, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { GET_NETWORK_NAME } from "settings";
export default function Networks() {
    const networks = useSelector<any>(state => state.networks)
    const [network, setNetwork] = useState<any>("")
    const classes = useStyles()
    const submit = () => {
        localStorage.setItem("network", network)
        window.location.assign("/dashboard/order")
        //window.location.reload()
    }
    return (
        <div className={classes.networks}>
            <div className={classes.network_wrapper}>
                <Typography>Select the network you want to access</Typography>
                <FormControl variant='outlined' style={{ marginTop: "10px" }} fullWidth>
                    <InputLabel id="network-label" >Network</InputLabel>
                    <Select label="Network" labelId="network-label" id="select" value={network} onChange={(e) => setNetwork(e.target.value)}>
                        {networks['network'].map(el => (
                            <MenuItem value={el}>{GET_NETWORK_NAME(el)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {network !== "" ? <Button onClick={submit} type="submit"
                    fullWidth
                    disabled={network === ""}
                    variant="contained"
                    color="primary" style={{ marginTop: "30px" }}>Continue</Button> : null}
            </div>
        </div>
    )
}