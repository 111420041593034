import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const ItemMenu = styled(Link)`
  text-decoration: none;
  color: #124c4d;
`;
export const StyleButton = styled.div`
  margin: 0 5px;
  color: #fff;
  border: none;
  background-color: #124c4d;
  background: #fff;

`;

export const StyleButtonHover = styled.div`
  &:hover {
    color: ${shade(0.2, '#124c4d')};
    }

    &:hover svg {
    color: ${shade(0.2, 'red')};
    }
`;

