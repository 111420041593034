import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Minus from '@material-ui/icons/Remove';
import { useDispatch } from 'react-redux';
import { editOrder, getProduction } from 'redux/ducks/production';

interface Iprops {
  open: boolean;
  onClose: () => void;
  selectedOrder: any;
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

export default function ModalEditProduction(props: Iprops) {
  const classes = useStyles();
  const { open, onClose, selectedOrder } = props;
  const [item, setItem] = React.useState('');
  const [itemImage, setItemImage] = React.useState('');
  const [quantity, setQuantity] = React.useState(0);
  const handleClose = () => {
    onClose();
    dispatch(getProduction({ order_type: 'Product' }));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedOrder) {
      setItem(selectedOrder.item.name);
      setItemImage(selectedOrder.item.image);
      setQuantity(selectedOrder.quantity);
    }
  }, [selectedOrder]);
  const submitEdit = async () => {
    const x = await dispatch(editOrder(selectedOrder.id, { quantity }));
    if (x === 1) {
      handleClose();
    }
  };

  return (
    <Modal
      className="create-menu-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-create-menu-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={() => handleClose()}
        >
          <IconButton
            style={{ width: '50px' }}
            color="secondary"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography color="secondary">Close</Typography>
        </div>
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            Edit Purchase
            <Button
              onClick={() => {
                submitEdit();
              }}
              variant="outlined"
              color="primary"
            >
              Save
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={9}>
            <Typography variant="h5">Items</Typography>
            <Grid
              className={classes.row}
              style={{
                justifyContent: 'space-between'
              }}
            >
              {itemImage && itemImage !== null && itemImage !== '' && (
                <img
                  src={itemImage}
                  style={{ width: '4vw', height: '4vw', objectFit: 'cover' }}
                />
              )}
              <Typography>{item}</Typography>
              <Grid className={classes.row}>
                <IconButton
                  onClick={() => {
                    setQuantity(quantity + 1);
                  }}
                >
                  <Add />
                </IconButton>
                <Typography>{quantity}</Typography>
                <IconButton
                  onClick={() => {
                    if (quantity !== 0) {
                      setQuantity(quantity - 1);
                    }
                  }}
                >
                  <Minus />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Modal>
  );
}
