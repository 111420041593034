import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { createTheme } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { CLASSIFICATION_CHOICES } from 'settings';
import { PRIMARY_COLOR } from 'settings';

import 'react-phone-input-2/lib/material.css';
import './styles.css';
import { NETWORK, APP_URL } from 'settings';
interface IProps {
  uniquePhoneError: boolean;
  uniqueSlugError: boolean;
  uniqueEmailError: boolean;
  store_id: number;
  handleSave: (data: {
    name: string;
    phone_number: string;
    image: File;
    store_email: string;
    network: any;
    slug: string;
    classification: string;
    only_delivery: boolean;
    sameday_delivery: boolean;
    own_delivery: boolean;
  }) => void;
}

const theme = (createTheme as any)({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        width: '75%',
        marginBottom: '1vw'
      },
      text: {
        fontSize: '1rem'
      },
      icon: {
        width: '33px'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        justifyContent: 'center',
        position: 'absolute',
        top: 0
      },
      imageContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
});

const network = parseInt(NETWORK);

export default function CreateStore(props: IProps) {
  const { handleSave, uniquePhoneError, uniqueSlugError, uniqueEmailError } =
    props;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [slug, setSlug] = useState('');
  const [classification, setClassification] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [ownDelivery, setOwnDelivery] = useState(false);
  const [imageSource, setImageSource] = useState<File>();
  const [storeEmail, setStoreEmail] = useState('');
  const [storeEmailError, setStoreEmailError] = useState('');
  const [imageError, setImageError] = useState('');

  const [onlyDelivery, setOnlyDelivery] = useState(true);
  const [sameDayDelivery, setSameDayDelivery] = useState(true);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleName = (event: any) => {
    var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var str = event.target.value;
    if (numbers.includes(str[0])) {
      var tmp = str.split('');
      tmp.splice(0, 1);
      str = tmp.join('');
    }
    if (numbers.includes(str[str.length - 1])) {
      var tmp = str.split('');
      tmp.splice(str.length - 1, 1);
      str = tmp.join('');
    }
    setNameError('');

    setName(capitalizeFirstLetter(str));

    handleSlug(str);
  };

  const clean_slug = (word) => {
    var _word = word.split('');
    var newWord = [];
    _word.map((el, idx) => {
      if (
        (_word[idx + 1] !== '-' && _word.length - 1 !== idx) ||
        (!_word[idx + 1] && el !== '-') ||
        el !== '-'
      ) {
        if (idx !== 0 || idx !== _word.length) {
          newWord.push(el);
        } else {
          if (el !== '-') newWord.push(el);
        }
      }
    });
    if (newWord[0] === '-') {
      newWord.splice(0, 1);
    }
    return newWord.join('');
  };

  const handleSlug = (event: any) => {
    var slugfied = event.trim().replace(/[ ]/g, '-');

    slugfied = slugfied.replace(/[^a-zA-Z0-9 -]/g, '-');
    slugfied = slugfied.toLowerCase();

    if (slugfied[0] === '-') {
      var tmp = slugfied.split('');
      tmp.splice(0, 1);
      slugfied = tmp.join('');
    }
    if (slugfied[slugfied.length - 1] === '-') {
      var tmp = slugfied.split('');
      tmp.splice(slugfied.length - 1, 1);
      slugfied = tmp.join('');
    }
    setSlug(clean_slug(slugfied));
  };

  const checkForm = () => {
    let entered = false;
    if (name === '') {
      setNameError('Please insert store name');
      entered = true;
    }
    if (storeEmail === '') {
      entered = true;
      setStoreEmailError('Please insert store email');
    }
    if (!isValidEmail(storeEmail)) {
      entered = true;
      setStoreEmailError('Please insert a valid email');
    }
    if (phone === '') {
      entered = true;
      setPhoneNumberError('Please insert a valid phone number');
    }
    if (!imageSource) {
      entered = true;
      setImageError('Please insert a store logo');
    }
    return entered;
  };

  const handlePhone = (event: any) => {
    setPhone(event);
    setPhoneNumberError('');
  };
  const handleClassification = (event: any) => {
    setClassification(event.target.value);
  };
  const handleOnlyDelivery = (event: any) => {
    setOnlyDelivery(!onlyDelivery);
  };
  const handleSameDayDelivery = (event: any) => {
    setSameDayDelivery(!sameDayDelivery);
  };
  const handleImage = (event: any) => {
    if (event.length > 0) {
      setImageSource(event[0]);
      setImageError('');
    }
  };
  const handleStoreEmail = (event: any) => {
    setStoreEmail(event.target.value);
    setStoreEmailError('');
  };
  const submit = () => {
    if (!checkForm()) {
      handleSave({
        name,
        phone_number: phone,
        image: imageSource,
        store_email: storeEmail,
        network: network,
        slug: slug,
        classification: classification,
        only_delivery: onlyDelivery,
        sameday_delivery: sameDayDelivery,
        own_delivery: ownDelivery
      });
    }
  };

  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <Typography
        style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
      >
        Create your Store
      </Typography>
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={nameError !== ''}
      >
        <InputLabel htmlFor="name-input">Store name</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={name}
          onChange={handleName}
        />
        <FormHelperText>{nameError}</FormHelperText>
      </FormControl>{' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          height: '50px',
          width: '75%'
        }}
      >
        <Typography style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
          {APP_URL}/
        </Typography>
        <FormControl
          style={{ marginBottom: '1.5rem', width: '100%', paddingTop: '28px' }}
          required={true}
          error={uniqueSlugError && slug == ''}
        >
          <Input
            id="name-input"
            type="string"
            value={slug}
            onChange={handleSlug}
          />
          <FormHelperText>
            {uniqueSlugError && slug == ''
              ? 'Store with this clean URL already exists'
              : ''}
          </FormHelperText>
        </FormControl>{' '}
      </div>
      <FormControl
        style={{ marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={storeEmailError !== ''}
      >
        <InputLabel htmlFor="name-input">Store email</InputLabel>
        <Input
          id="name-input"
          type="string"
          value={storeEmail}
          onChange={handleStoreEmail}
        />
        <FormHelperText>
          {storeEmailError
            ? storeEmailError
            : uniqueEmailError
            ? uniqueEmailError
            : ''}
        </FormHelperText>
      </FormControl>
      <FormControl
        style={{ marginTop: '1.5rem', marginBottom: '1.5rem', width: '75%' }}
        required={true}
        error={phoneNumberError !== '' || uniqueSlugError}
      >
        <PhoneInput
          country={'gb'}
          preferredCountries={['gb', 'ie']}
          placeholder="44 5412 123456"
          value={phone}
          specialLabel=""
          onChange={(event) => handlePhone(event)}
          onBlur={() => setPhone(phone)}
          inputClass="phone-input"
          containerClass="phone-input-container"
        />
        <FormHelperText>
          {phoneNumberError
            ? phoneNumberError
            : uniquePhoneError
            ? uniquePhoneError
            : ''}
        </FormHelperText>
      </FormControl>
      <FormControl style={{ marginBottom: '1.5rem', width: '75%' }} required>
        <InputLabel id="label">Classification</InputLabel>
        <Select value={classification} onChange={handleClassification}>
          {CLASSIFICATION_CHOICES.map((entry) => (
            <MenuItem value={entry}>{entry}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: '75%', marginTop: '1rem' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyDelivery}
              onChange={handleOnlyDelivery}
              color="primary"
            />
          }
          label="Only delivery, collection orders not allowed"
        />
      </FormControl>
      <FormControl
        style={{ marginBottom: '1rem', marginTop: '1rem', width: '75%' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={sameDayDelivery}
              onChange={handleSameDayDelivery}
              color="primary"
            />
          }
          label="Allow same day delivery"
        />
      </FormControl>
      <FormControl style={{ marginBottom: '1rem', width: '75%' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={ownDelivery}
              onChange={(e) => {
                setOwnDelivery(e.target.checked);
              }}
              color="primary"
            />
          }
          label="Supply your own delivery service"
        />
      </FormControl>
      <FormControl
        className="dropZone-wrapper"
        style={{ marginBottom: '1rem', width: '75%' }}
        required={true}
        error={imageError !== ''}
      >
        <ThemeProvider theme={theme}>
          <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText={'Drag and drop Store Logo here'}
            showPreviewsInDropzone={true}
            onChange={(files) => handleImage(files)}
            filesLimit={1}
          />
        </ThemeProvider>
        <FormHelperText>{imageError}</FormHelperText>
      </FormControl>
      <Button
        style={{
          marginTop: '1rem',
          backgroundColor: PRIMARY_COLOR(),
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        onClick={() => submit()}
      >
        Save and continue
      </Button>
    </Grid>
  );
}
