import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import './styles.css';
import Logo from 'helpers/logo';

export default function StripeKYC() {
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '65vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '8rem',
        marginLeft: '10rem'
      }}
    >
      <div>
        <Grid
          style={{
            width: '250px',
            height: '120px',
            marginLeft: '-30px',
            marginTop: '0px'
          }}
        >
          <Logo styling={{ width: "100%", height: "100%" }} />
        </Grid>
        <Typography style={{ fontSize: '22px', marginBottom: '10px' }}>
          Your account has been Deauthorized
        </Typography>
      </div>
    </Grid>
  );
}
