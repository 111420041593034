import React, { useState } from 'react';

import {
  Button,
  FormControl,
  Grid,

  // ThemeProvider,
  Typography
} from '@material-ui/core';
// import { DropzoneArea } from 'material-ui-dropzone';
// import { createTheme } from '@material-ui/core';
// import PhoneInput from 'react-phone-input-2';
import { PRIMARY_COLOR } from 'settings';

import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import 'react-phone-input-2/lib/material.css';
import './styles.css';
// import { useSelector } from 'react-redux';
// import IStore from 'interfaces/store';
// import { NETWORK } from 'settings';
interface IProps {
  handleSave?: (data: any) => void;
  storeData: {
    name: string;
    store_email: string;
    network: number;
  };
}

// const errorMensages = {
//   required: 'This field is required'
// };

const dayNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

const weekDays = [];

dayNames.map((day) => {
  weekDays.push({
    day: day,
    opened: moment('08:00', 'HH:mm'),
    closed: moment('18:00', 'HH:mm'),
    maxDateError: false,
    closedAllDAy: false
  });
});

export default function CreateOpenHours(props: IProps) {
  const { handleSave, storeData } = props;

  const [days, setDays] = useState(weekDays);

  const handleChange = (_day, prop, time) => {
    setDays(
      days.map((item) => (item.day === _day ? { ...item, [prop]: time } : item))
    );
  };

  const submit = () => {
    handleSave(days);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Grid
        style={{
          // border: '1px solid gray',
          borderRadius: '0.5vw',
          width: '40vw',
          padding: '1vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2vw'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '22px', marginBottom: '12px' }}>
            Set {storeData.name} opening hours
          </Typography>
        </div>

        <Typography
          style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
        >
          Set open hours
        </Typography>
        <div style={{}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {days.map((element, idx) => (
              <>
                <Typography
                  style={{
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: '16px'
                  }}
                >
                  {capitalizeFirstLetter(element.day)}
                </Typography>

                <Grid
                  style={{ display: 'flex', alignItems: 'end' }}
                  container
                  spacing={2}
                >
                  {' '}
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker-opened"
                        label="Opened"
                        value={element.opened}
                        disabled={element.closedAllDAy}
                        onChange={(e) => {
                          handleChange(element.day, 'opened', e);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker-closed"
                        label="Closed"
                        disabled={element.closedAllDAy}
                        value={element.closed}
                        maxDateMessage={
                          element.maxDateError
                            ? 'The closed time must be after the opened time'
                            : ''
                        }
                        onChange={(e) => {
                          handleChange(element.day, 'closed', e);
                          /**                          
                           const minTime = moment(element.opened, 'HH:mm');
                          if (minTime.isBefore(e)) {
                            handleChange(element.day, 'closed', e);
                            handleChange(element.day, 'maxDateError', false);
                          } else {
                            handleChange(element.day, 'maxDateError', true);
                          } */
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: '15px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={element.closedAllDAy}
                            onChange={(e) =>
                              handleChange(
                                element.day,
                                'closedAllDAy',
                                e.target.checked
                              )
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Closed all day"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ))}
          </MuiPickersUtilsProvider>
          <Typography
            style={{
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '12px',
              marginBottom: '1rem'
            }}
          >
            These settings can be changed later.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                backgroundColor: PRIMARY_COLOR(),
                color: 'white',
                borderRadius: '0.7vw',
                height: '2.5vw',
                paddingLeft: '0.5vw',
                paddingRight: '0.5vw',
                fontFamily: 'Work Sans, sans-serif'
              }}
              onClick={() => submit()}
            >
              Save and continue
            </Button>
          </div>
        </div>
      </Grid>
    </>
  );
}
