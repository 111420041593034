import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import EditProductsTable from './EditProductsTable';
import { Menu } from 'interfaces/menu';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductsCategoriesFilter,
  fetchProductsGeneral
} from 'redux/ducks/stock';
import { editMenuProducts } from 'redux/ducks/menu';
import IStore from 'interfaces/store';
import { sendAlert } from 'redux/ducks/alert';
import { EAlertVariant } from 'interfaces/alert';
import { NETWORK } from 'settings'
// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (products: any) => void;
  menu: Menu;
  returnProducts?: (_products: any) => any;
  store?: number;
}

export default function ModalEditMenu(props: SimpleDialogProps) {
  const dispatch = useDispatch();
  const { onClose, open, menu, returnProducts, store } = props;

  const [selectedProducts, setSelectedProducts] = useState([]);

  const { stock } = useSelector<IStore, IStore>((state) => state);

  const handleClose = () => {
    onClose(selectedProducts);
  };

  useEffect(() => {
    setSelectedProducts(menu?.products);
    const categoriesFilter = [];
    if (menu) {
      if (menu.sections) {
        menu.sections.map((element) => {
          categoriesFilter.push(element.category);
        });
        dispatch(
          fetchProductsCategoriesFilter({
            store: store
          })
        );
      } else {
        fetchProductsGeneral({
          store: store
        });
      }
    }
  }, [menu]);

  const submitEdit = async () => {
    if (checkSaveAvailability()) {
      dispatch(
        editMenuProducts(menu?.menu_id, {
          products: selectedProducts
        })
      ).then((result: boolean) => {
        if (returnProducts) {
          returnProducts(selectedProducts);
        }
        if (result) handleClose();
      });
    } else {
      dispatch(
        sendAlert(
          'You must choose at least one product.',
          EAlertVariant.WARNING
        )
      );
    }
  };
  const checkSaveAvailability = () => {
    if (selectedProducts.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">
        Products of {NETWORK === "6" ? "group" : "menu"}: {menu?.name}
      </DialogTitle>
      {menu ? (
        <EditProductsTable
          products={stock.products}
          menu_products={menu?.products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : null}
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
      >
        <Button onClick={submitEdit} color="primary">
          Save
        </Button>
        <Button onClick={handleClose}> Close </Button>
      </div>
    </Dialog>
  );
}
