import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Provider } from 'interfaces/stock';
import { Button, IconButton } from '@material-ui/core';
import ModalCreateProvider from './ModalCreateProvider';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDeleteProvider from './ModalDeleteProvider';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: '#124c4d',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none'
  }
});

interface IProps {
  providers: Provider[];
  store: number;
}

export default function ProductsTable(props: IProps) {
  const classes = useStyles();
  const { providers } = props;
  const [openCreateProvider, setOpenCreateProvider] = React.useState(false);
  const [openDeleteProvider, setOpenDeleteProvider] = React.useState(false);
  const [providerSelected, setProviderSelected] = React.useState<Provider>();

  const handleClose = () => {
    setOpenCreateProvider(false);
    setOpenDeleteProvider(false);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Button
        className={classes.activeButton}
        onClick={() => setOpenCreateProvider(true)}
      >
        Add Provider
      </Button>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '5px' }}>ID</TableCell>
            <TableCell style={{ padding: '5px' }}>Name</TableCell>{' '}
            <TableCell style={{ padding: '5px' }} align="right">
              Delete
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providers?.map((provider) => (
            <TableRow key={provider.id}>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {provider.id}
              </TableCell>
              <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                {provider.name}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ padding: '5px' }}
                align="right"
              >
                <IconButton
                  onClick={() => {
                    setProviderSelected(provider);
                    setOpenDeleteProvider(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ModalCreateProvider onClose={handleClose} open={openCreateProvider} />
      <ModalDeleteProvider
        onClose={handleClose}
        open={openDeleteProvider}
        provider={providerSelected}
      />
    </TableContainer>
  );
}
