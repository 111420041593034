import {
  Button,
  Grid,
  IconButton,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import ModalEditProductsMenu from '../Menus/ModalEditProductsMenu';
import { useDispatch } from 'react-redux';
import { handleActiveMenu } from 'redux/ducks/menu';
import { PRIMARY_COLOR } from 'settings';

const useStyles = makeStyles({
  table: {
    // minWidth: 650
  },
  tableContainer: {
    margin: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  activeButton: {
    margin: '0 5px',
    color: '#fff',
    border: 'none',
    backgroundColor: PRIMARY_COLOR(),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
});

interface IProps {
  menu: { menu_id: number; name: string; active: boolean };
  store: number;
  handleSave: () => void;
}

export default function ShowMenu(props: IProps) {
  const dispatch = useDispatch();
  const { menu, store, handleSave } = props;
  const classes = useStyles();
  const [openEditProducts, setOpenEditProducts] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(undefined);
  const [checked, setChecked] = React.useState(menu.active);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleClickOpenEditProducts = (menu: {
    menu_id: number;
    name: string;
    active: boolean;
  }) => {
    setOpenEditProducts(true);
    setSelectedMenu(menu);
  };
  const handleClose = () => {
    setOpenEditProducts(false);
    setSelectedMenu(undefined);
  };
  const submit = () => {
    handleSave();
  };
  return (
    <Grid
      style={{
        borderRadius: '0.5vw',
        width: '40vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2vw'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          style={{ fontFamily: 'Work Sans, sans-serif', fontSize: '18px' }}
        >
          Add products to your new menu
        </Typography>
        <Tooltip title="You can activate and deactivate your Menu in the portal. When active, will show on the web store.">
          <svg
            height={25}
            style={{ marginLeft: '10px' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
          >
            <g fill="#ff5a23" data-name="Question Mark">
              <path
                stroke-width="2"
                stroke="#ff5a23"
                d="M32 56.735A24.735 24.735 0 1 1 56.736 32 24.764 24.764 0 0 1 32 56.735Zm0-46.47A21.735 21.735 0 1 0 53.736 32 21.76 21.76 0 0 0 32 10.265Z"
              />
              <path
                stroke-width="2"
                stroke="#ff5a23"
                d="M32 44.97a2.015 2.015 0 0 1-2-2 1.722 1.722 0 0 1 .04-.39 1.643 1.643 0 0 1 .11-.38 2.329 2.329 0 0 1 .19-.341 2.174 2.174 0 0 1 .55-.549 1.589 1.589 0 0 1 .34-.19 1.757 1.757 0 0 1 .38-.11 1.994 1.994 0 0 1 1.8.55 1.419 1.419 0 0 1 .25.3 2.329 2.329 0 0 1 .19.341 1.643 1.643 0 0 1 .11.38 1.722 1.722 0 0 1 .04.39 2.015 2.015 0 0 1-2 2zm-7.3-19.141a1.5 1.5 0 0 1-1.368-2.1c.085-.192 2.159-4.7 8.669-4.7a1.5 1.5 0 0 1 0 3c-4.491 0-5.877 2.815-5.934 2.935a1.515 1.515 0 0 1-1.367.865z"
              />
              <path
                stroke-width="2"
                stroke="#ff5a23"
                d="M32 38.9a1.5 1.5 0 0 1-1.5-1.5v-1.892c0-2.311 2.01-3.587 3.953-4.82 2.755-1.75 4.4-2.97 3.455-5.777-.18-.347-1.646-2.881-5.908-2.881a1.5 1.5 0 0 1 0-3c6.51 0 8.584 4.51 8.669 4.7.017.037.031.074.044.111 1.844 5.254-2.066 7.735-4.652 9.377-1.2.762-2.561 1.626-2.561 2.288V37.4a1.5 1.5 0 0 1-1.5 1.5Z"
              />
            </g>
          </svg>
        </Tooltip>
      </div>
      <Typography
        style={{
          fontFamily: 'Work Sans, sans-serif',
          fontSize: '13px',
          width: '70%',
          textAlign: 'left'
        }}
      >
        {' '}
        To activate your products inside the menu click on the + icon then
        select the product you made and click 'save'.
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Menu name</TableCell>
            <TableCell>Products</TableCell>
            <TableCell>Disable/enable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={menu.menu_id}>
            <TableCell component="th" scope="row">
              {menu.menu_id}
            </TableCell>
            <TableCell>{menu.name}</TableCell>
            <TableCell>
              <IconButton>
                <AddIcon onClick={() => handleClickOpenEditProducts(menu)} />
              </IconButton>
            </TableCell>
            <TableCell>
              <Switch
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  dispatch(handleActiveMenu(menu.menu_id));
                }}
                color="primary"
                name="checkedA"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button
        style={{
          backgroundColor: PRIMARY_COLOR(),
          marginTop: '1vw',
          color: 'white',
          borderRadius: '0.7vw',
          height: '2.5vw',
          paddingLeft: '0.5vw',
          paddingRight: '0.5vw',
          fontFamily: 'Work Sans, sans-serif'
        }}
        disabled={selectedProducts.length === 0}
        onClick={() => submit()}
      >
        Save
      </Button>
      <ModalEditProductsMenu
        open={openEditProducts}
        onClose={(products) => {
          setSelectedProducts(products);
          handleClose();
        }}
        menu={selectedMenu}
        store={store}
      />
    </Grid>
  );
}
