import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_PRODUCTION } from 'settings';
import { connect } from 'utils/socket';

import { getProduction, updateProduction } from 'redux/ducks/production';
import { IProductionState } from 'interfaces/production'
import { getStore } from 'redux/ducks/store';

import IStore from 'interfaces/store';
import { IStoreState } from 'interfaces/store_test';

import { ESocketStatus } from 'enums/socket';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

import Panel from './Panel';
import Loading from 'components/Common/Loading';

var storeFilter: { id: number; name: string; }[] = [];

export default function Production() {
  const dispatch = useDispatch();
  const { fetch, todo, doing, done } = useSelector<IStore, IProductionState>(
    (state) => state.production
  );
  const classes = useStyles();

  const [online, setOnline] = useState(false);

  const { results } = useSelector<
    IStore,
    IStoreState
  >((state) => state.store);

  const { authentication } = useSelector<
    IStore,
    IStore
  >((state) => state);

  storeFilter = [];

  results.map((store) => {
    storeFilter.push({ id: store.id, name: store.name })
  });
  storeFilter.push({ id: 0, name: 'TODAS' });

  useEffect(() => {
    dispatch(getProduction({ order_type: 'Product' }));
    dispatch(getStore());

    let productionSocket = connect(SOCKET_PRODUCTION);

    productionSocket.onopen = () => setOnline(true);

    productionSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data)
      if (new_data && new_data.message.order_type === 'Product') {
        if (!authentication.is_superuser) {
          try {
            dispatch(updateProduction(JSON.parse(event.data)));
          }
          catch (err) {
            console.log(err)
          }
        } else
          dispatch(updateProduction(JSON.parse(event.data)));
      }
    }

    productionSocket.onerror = () => productionSocket.close();

    productionSocket.onclose = () => productionSocket.close();

    const heartbeat = setInterval(() => {
      if (productionSocket.readyState === ESocketStatus.OPEN) setOnline(true);

      if (productionSocket.readyState !== ESocketStatus.OPEN) setOnline(false);

      if (!online) productionSocket = connect(SOCKET_PRODUCTION);
    }, 5000);

    return () => {
      productionSocket.close();
      clearInterval(heartbeat);
    };
  }, [dispatch, online]);

  useEffect(() => {
    const control = document.querySelectorAll<HTMLElement>('.d-control');
    control.forEach((c) => {
      c.style.color = '#124c4d';
    });

    const icon = document.querySelectorAll<HTMLElement>('.d-control-icon');
    icon.forEach((c) => {
      c.style.color = '#BBBBBB';
    });
    const d = document.querySelectorAll<HTMLElement>('.d-ingredient-package');
    d.item(0).style.color = '#ff5a23';

   // d.item(1).style.color = '#ff5a23';

  });

  const panels = [
    {
      key: 1,
      headerStyle: classes.cardHeaderTodo,
      headerTitle: 'REQUIRED',
      bodyStyle: classes.cardBodyTodo,
      orders: todo,
      showCounter: true,
      showBackBtn: false
    },
    {
      key: 2,
      headerStyle: classes.cardHeaderDoing,
      headerTitle: 'DOING',
      bodyStyle: classes.cardBodyDoing,
      orders: doing
    },
    {
      key: 3,
      headerStyle: classes.cardHeaderDone,
      headerTitle: 'DONE',
      bodyStyle: classes.cardBodyDone,
      orders: done,
      showOKBtn: true,
      showBackBtn: true,
      showPrintBtn: true
    }
  ];

  return (
    <>
      <Loading show={fetch} />
      {!fetch && todo.length === 0 && doing.length === 0 && done.length === 0 && (
        <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
          There are no production orders
        </Typography>
      )}
      <Grid container spacing={3}>
        {panels.map((panel) => (
          <Panel
            key={panel.key}
            headerStyle={panel.headerStyle}
            headerTitle={panel.headerTitle}
            bodyStyle={panel.bodyStyle}
            orders={panel.orders}
            showOKBtn={panel.showOKBtn}
            showBackBtn={panel.showBackBtn}
            showPrintBtn={panel.showPrintBtn}
          />
        ))}
      </Grid>
    </>
  );
}
