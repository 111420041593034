import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PatchArtist } from 'redux/ducks/stock';

// import Typography from '@material-ui/core/Typography';

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    artist: { artist_id: number, name: string };
    refresh: () => void;

}

export default function ModalDeleteIngredient(props: SimpleDialogProps) {
    const { onClose, open, artist, refresh } = props;

    const dispatch = useDispatch();
    const [name, setName] = useState(artist?.name)
    useEffect(() => {
        if (artist?.name) setName(artist.name)
    }, [artist])
    const handleClose = () => {
        onClose();
    };

    const submitEdit = async () => {
        dispatch(
            PatchArtist(name, artist.artist_id)
        ).then((result: boolean) => {
            if (result) {
                refresh()
                setName("")
                handleClose();
            }
        });
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Edit artist {artist?.name}
            </DialogTitle>
            <DialogContent style={{ minWidth: "400px" }}
            >
                <TextField
                    style={{ width: "100%" }}
                    placeholder='Artist'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'center', gap:"5px" }}>

                    <Button onClick={handleClose}> Cancel </Button>
                    < Button variant='outlined' onClick={submitEdit} color="primary">
                        {' '}
                        Edit{' '}
                    </Button>
                </div>
            </DialogActions>

        </Dialog >
    );
}
