import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import IStore from 'interfaces/store';
import { IAuthenticationState } from 'interfaces/authentication';
import { fetch } from 'utils/request';
import { EMethod } from 'enums/method';
import './styles.css';
import { Loading } from 'react-loading-dot';
import Logo from 'helpers/logo';
import { NETWORK, PRIMARY_COLOR } from 'settings';

export default function StripeKYC() {
  const localStore = localStorage.getItem('state');
  const state = JSON.parse(localStore);
  const { name, stripe_profiles } = state.authentication;

  const [stripeLink, setStripeLink] = useState(
    stripe_profiles ? stripe_profiles.account_stripe_link : ''
  );
  const [loading, setLoading] = useState(true);
  const { id } = state.authentication;
  const authentication = useSelector<IStore, IAuthenticationState>(
    (state) => state.authentication
  );

  if (!authentication.authorized) {
    window.location.href = '/';
  }

  const getLink = async () => {
    try {
      var toRedirect = false;
      setLoading(true);
      var response = await fetch({
        method: EMethod.GET,
        url: `https://portal-api.gorealtime.io/users/api/clients/${id}/refresh_account_link/?network=${NETWORK}`
      });
      response.user.profile.stripe_profiles.forEach((profile) => {
        if (profile.network_id == NETWORK) {
          if (profile.account_stripe_KYC_status) {
            toRedirect = true;
          } else {
            setStripeLink(profile.account_stripe_link);
          }
        }
      });

      if (toRedirect) {
        window.location.href = '/dashboard/order';
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLink();
  }, []);
  return (
    <Grid
      style={{
        // border: '1px solid gray',
        borderRadius: '0.5vw',
        width: '65vw',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '8rem',
        marginLeft: '10rem'
      }}
    >
      {loading ? (
        <Loading size="1rem" background={PRIMARY_COLOR()} />
      ) : (
        <>
          {' '}
          <div>
            <Grid
              style={{
                width: '250px',
                height: '120px',
                marginLeft: '-30px',
                marginTop: '0px'
              }}
            >
              <Logo styling={{ width: '100%', height: '100%' }}
              />
            </Grid>
            <Typography style={{ fontSize: '22px', marginBottom: '10px' }}>
              You are logged in!
            </Typography>
          </div>
          <div style={{ width: '75%', marginBottom: '20px' }}>
            {' '}
            <Typography style={{ fontSize: '15px' }}>
              {name}, you must complete your KYC with Stripe, we sent and email.
              This is required to be live on the market place and start earning
              money.
            </Typography>
          </div>
          <div style={{ width: '75%', marginTop: '20px' }}>
            {' '}
            <a
              style={{
                width: '5rem',
                padding: '10px 30px 10px 30px',
                backgroundColor: PRIMARY_COLOR(),
                color: 'white',
                textDecoration: 'none',
                fontFamily: 'Work Sans, sans-serif'
              }}
              href={stripeLink}
            >
              Complete your KYC
            </a>
          </div>
        </>
      )}
    </Grid>
  );
}
