import { EAlertVariant } from 'interfaces/alert';

import { EMethod } from 'enums/method';

import { sendAlert } from './alert';

import { fetch } from 'utils/request';
import { IIngredientPackageState, ETypesIngredientPackage, IIngredientPackageAction, IFetchAction, ISuccessAction, IFailureAction, ThunkAction, Dispatch } from 'interfaces/ingredientPackage';

/* Production State. */
const initialState: IIngredientPackageState = {
  fetch: false,
  results: [],
  error: ''
};

/* Production Reducer. */
export default (
  state: IIngredientPackageState = initialState,
  action: IIngredientPackageAction
): IIngredientPackageState => {
  switch (action.type) {
    case ETypesIngredientPackage.FETCH:
      return {
        ...state,
        fetch: true
      };
    case ETypesIngredientPackage.SUCCESS:
      return {
        ...state,
        fetch: false,
        results: action.payload.results,
        error: ''
      };
    case ETypesIngredientPackage.FAILURE:
      return {
        ...state,
        fetch: false,
        error: action.payload
      };
    default:
      return state;
  }
};

/* Production Action Creators Functions. */
export const fetchIngredientPackage = (): IFetchAction => ({
  type: ETypesIngredientPackage.FETCH
});

export const successIngredientPackage = (
  payload: IIngredientPackageState
): ISuccessAction => ({
  type: ETypesIngredientPackage.SUCCESS,
  payload
});

export const failureIngredientPackage = (payload: string): IFailureAction => ({
  type: ETypesIngredientPackage.FAILURE,
  payload
});

/* Production Side Effects Functions. */
export const getIngredientPackage = (): ThunkAction => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch(fetchIngredientPackage());
    let response;
    let url = `/orders/api/production_orders/ingredients_recipe/`
    response = await fetch({
      method: EMethod.GET,
      url: url
    });
    response.results = response;
    dispatch(successIngredientPackage(response));
  } catch (error) {
    dispatch(failureIngredientPackage(error));
    dispatch(sendAlert(error, EAlertVariant.ERROR));
  }
};
